import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { noop } from '../util/functional'

type DebugContextType = {
  useNewEditor: boolean
  toggleUseNewEditor: () => void
}

const DebugContext = createContext<DebugContextType>({
  useNewEditor: false,
  toggleUseNewEditor: noop,
})

export const DebugProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [useNewEditor, setUseNewEditor] = useState<boolean>(false)
  const toggleUseNewEditor = useCallback(() => setUseNewEditor((prev) => !prev), [])

  return (
    <DebugContext.Provider
      value={{
        useNewEditor,
        toggleUseNewEditor,
      }}
    >
      {children}
    </DebugContext.Provider>
  )
}

export const useDebug = () => {
  const context = useContext(DebugContext)
  if (!context) throw new Error('Expected to be wrapped in a DebugContextProvider!')
  return context
}
