import { DEFAULT_MAP_OPTIONS, EMPTY_CONTENT_FALLBACK } from '../common/constants'
import { generateUserInitials } from '../composites/UserMenu/helpers'
import { GameBoard } from '../pages/GameEditor/types'
import {
  AllowedOption,
  CreativeAnswerOption,
  CreativeSubtask,
  Game,
  GameMap,
  GameMapsAndExercises,
  GameStatus,
  MemberRole,
  Report,
  SecondaryGameStatus,
  Subtask,
  SubtaskPreview,
  TAddMember,
  TBusinessSettings,
  TGameCard,
  TMember,
  TUser,
  Task,
  TaskPreview,
} from '../types/commonTypes'
import { isNullOrZero } from '../util/number'
import { isNullOrEmpty } from '../util/string'
import { AddMember, BusinessSettingsData, Member } from './businessSettingsTypes'
import {
  Exercise,
  ExerciseType,
  GetGameResponse,
  GetGamesResponse,
  GetLibraryGamesResponse,
  LibrarySource,
  MapType,
} from './gameTypes'
import { User } from './userTypes'

const getGameStatus = (game: GetGameResponse, librarySource?: LibrarySource): GameStatus => {
  if ([librarySource, game.source].includes(LibrarySource.COMMUNITY)) {
    return game.sponsored ? GameStatus.SPONSORED : GameStatus.COMMUNITY
  }
  if ([librarySource, game.source].includes(LibrarySource.ORG)) {
    return game.corporate ? GameStatus.TEMPLATE : GameStatus.LIBRARY
  }
  if ([librarySource, game.source].includes(LibrarySource.TEMPLATE)) {
    return GameStatus.TEMPLATE
  }
  if (game.is_archived) {
    return GameStatus.ARCHIVED
  }
  if (game.open) {
    return GameStatus.IN_PROGRESS
  }
  if (!isNullOrZero(game.answers_count)) {
    return GameStatus.PAUSED
  }
  return GameStatus.DRAFT
}

const getGameSecondaryStatus = (
  game: GetGameResponse,
  librarySource?: LibrarySource,
): SecondaryGameStatus | undefined => {
  if (![librarySource, game.source].includes(LibrarySource.OWN)) {
    return undefined
  }
  if (game.imported) {
    return SecondaryGameStatus.DOWNLOADED
  }
  if (game.exported) {
    return SecondaryGameStatus.PUBLISHED
  }
}

export const parseGamesResponseToGameCards = (data: GetGamesResponse, librarySource?: LibrarySource): TGameCard[] => {
  return data.my_games.map((game): TGameCard => {
    return {
      id: game.id.toString(),
      gameName: game.name,
      gameOwners: game.owner_names || [game.creator],
      modifiedAt: game.updated_at
        ? `${new Date(game.updated_at.toString()).toLocaleDateString()} ${new Date(
            game.updated_at.toString(),
          ).toLocaleTimeString()}`
        : EMPTY_CONTENT_FALLBACK,
      status: getGameStatus(game, librarySource),
      secondaryStatus: getGameSecondaryStatus(game, librarySource),
      librarySource: librarySource ?? game.source ?? LibrarySource.COMMUNITY,
      thumbnail: game.thumbnail || game.thumbnail_url || game.map_url || game.map,
      exercisesCount: game.exercises?.length ?? 0,
      storyHtml: game.description_html,
      rulesHtml: game.rules_html ?? game.rules,
      instructionsForTeacherHtml: game.instructions_for_teacher,
      ages: game.age,
      subjects: game.subject,
      language: game.language,
      mapsCount: (game.additional_maps?.length ?? 0) + 1,
      ...(!isNullOrEmpty(game.keywords) && { tags: game.keywords.split(',').map((keyword) => keyword.trim()) }),
      ...(game.short_description != null && { description: game.short_description }),
      ...(game.rating_details?.[0] != null && {
        rating: {
          average: game.rating_details[0].av_rating,
          count: game.rating_details[0].details.length,
        },
      }),
      ...(game.downloaded != null && { downloaded: game.downloaded }),
      ...(game.player_info != null && { playersCount: game.player_info }),
    }
  })
}

export const parseLibraryGamesResponseToGameCards = (
  data: GetLibraryGamesResponse,
  gameType: LibrarySource,
): TGameCard[] => {
  return parseGamesResponseToGameCards(
    {
      my_games: data.games,
      count: data.count,
      active_acount: 0,
    },
    gameType,
  )
}

const getGameMapsForGameResponse = (game: GetGameResponse): GameMap[] => {
  const firstMap: GameMap = {
    url: game.map_url ?? game.map,
  }
  return [
    firstMap,
    ...game.additional_maps.map((map) => ({
      url: map.map_url,
    })),
  ]
}

const getSubtaskPreviewForExercise = (exercise: Exercise): SubtaskPreview => {
  return {
    descriptionHtml: exercise.description_html || exercise.description,
    type: exercise.type,
    points: exercise.points,
  }
}

const getPointsSum = (exercise: Exercise): number => {
  const subtaskPoints = (exercise.children || []).reduce((sum, currSubtask) => sum + currSubtask.points ?? 0, 0)
  return (exercise.points ?? 0) + subtaskPoints
}

const getExercisesForGameResponse = (game: GetGameResponse): TaskPreview[] => {
  return game.exercises?.map((ex: Exercise): TaskPreview => {
    return {
      name: ex.name,
      id: ex.id.toString(),
      subtasks: [getSubtaskPreviewForExercise(ex), ...(ex.children || []).map(getSubtaskPreviewForExercise)],
      pointsSum: getPointsSum(ex),
    }
  })
}

export const parseGameResponseToGameMapsAndExercises = (game: GetGameResponse): GameMapsAndExercises => {
  return {
    gameId: game.id,
    maps: getGameMapsForGameResponse(game),
    exercises: getExercisesForGameResponse(game),
  }
}

const getEditorGameBoards = (game: GetGameResponse): GameBoard[] => {
  return [
    {
      name: game.map,
      fileName: game.map_file_name,
      url: game.map_url,
    },
    ...game.additional_maps.map((map) => ({
      name: map.name ?? '',
      fileName: map.map_id.toString(),
      url: map.map_url,
    })),
  ]
}

export const parseGameResponseToGameVm = (game: GetGameResponse): Game => {
  return {
    gameId: game.id,
    tasks: getExercisesForGameResponse(game),
    name: game.name,
    description: game.description,
    ages: game.age,
    instructionsForTeacherHtml: game.instructions_for_teacher,
    keywords: game.keywords?.split(',') || [],
    language: game.language,
    rulesHtml: game.rules_html,
    topics: game.subject,
    gameBoardSettings: {
      gameBoardType: game.map_type,
      liveMap:
        game.map_type === MapType.LIVE
          ? {
              center: [game.lng, game.lat],
              zoom: game.zoom,
            }
          : DEFAULT_MAP_OPTIONS,
      gameBoards: getEditorGameBoards(game),
    },
    ...(game.notification_settings != null && {
      notificationSettings: {
        messages: game.notification_settings.messages,
        answers: game.notification_settings.answers,
        instantEnabled: game.notification_settings.instant_enabled,
        summaryEnabled: game.notification_settings.summary_enabled,
        emails: game.notification_settings.emails,
      },
    }),
    gpsEnabled: !game.no_gps,
    chatEnabled: !game.no_chat,
    levelsEnabled: game.levels_enabled,
    allowBranching: game.branch_type === 'TREE' ? true : false,
    playerContinuesSentAnsEnabled: game.player_continues_sent_ans_enabled,
    noPointsGame: game.no_points_game,
    hideScoreboard: game.hide_scoreboard,
    happyOrNot: game.happy_or_not,
    explorationMode: game.advanced_mode,
  }
}

export const parseUserInfoResponseToTUser = (user: User): TUser => {
  const allowedOptions: AllowedOption[] = (user.allowed_options || '').split(',').map((opt) => {
    return AllowedOption[opt as keyof typeof AllowedOption]
  })

  const businesses = user.businesses.map((business) => {
    return {
      id: business.id,
      name: business.name,
      validUntil: business.good_trough,
      country: business.country,
      loginCount: business.login_count || 0,
      industry: business.industry,
    }
  })

  const tags = user.classes?.map((tag) => {
    return {
      id: tag.id,
      label: tag.group_tag,
      businessId: tag.business_id,
    }
  })

  return {
    id: user.id,
    name: user.name,
    contactName: user.contact_name ?? '',
    userInitials: generateUserInitials(user.name),
    email: user.email,
    language: user.language,
    chatName: user.real_name,
    hasPlayerAccounts: user.allow_player_account,
    isBusinessAdmin: user.is_business_admin,
    isBusinessGroupMaster: user.is_business_group_master,
    isBusinessGroupTechMaster: user.is_business_group_tech_master,
    isBusinessSuperUser: user.is_business_super_user,
    isStudentTeacher: user.is_student_teacher,
    isGradingInstructor: user.is_grading_instructor,
    isSponsoredUser: user.is_sponsored_user,
    // TODO: Check if this should remain as it is ->
    // according to PR comment https://github.com/Liitis/ux3.0/pull/65#discussion_r1165301825
    isTrial: user.businesses[0]?.business_type === 6,
    isCorporate: user.businesses[0]?.industry === 'Corporate',
    isEducation: user.businesses[0]?.industry === 'Education',
    hasOrgLibrary: user.allow_private_library,
    hasCommunity: user.allow_content_library,
    hasTemplates: user.allowed_options?.includes(AllowedOption.CORPORATE_LIBRARY),
    passwordChanged: user.password_changed,
    termsAccepted: !!user.terms_accepted,
    hasTeacherReporting: !!user.teacher_reporting_path,
    allowedOptions: allowedOptions,
    activeBusiness: businesses[0],
    primaryBusinessId: businesses[0]?.id,
    businesses: businesses,
    tags: tags || [],
    createGame: user.create_game,
    createdAt: user.created_at,
    theme: {
      themeLogoUrl: user.theme_app_bar_icon,
      themeAllowedLanguages: user.theme_language_list,
      themeMainColor: user.theme_main_color,
      themeSecondaryColor: user.theme_turquoise_color,
      themeFaviconUrl: user.theme_tab_logo_url,
    },
    reports: getReportsForUser(user),
    businessReportUrl: user.business_reporting_path,
    useOldDashboard: user.use_dashboard2,
  }
}

const getReportsForUser = (user: User): Report[] => {
  const reports: Report[] = []
  if (user.is_business_admin) {
    reports.push(Report.BUSINESS)
  }
  if (user.is_business_group_master) {
    reports.push(Report.GROUP)
  }
  if (user.owns_games) {
    if (user.allowed_options?.includes(AllowedOption.TEACHER_REPORTING)) {
      reports.push(Report.TEACHER)
    }
    if (user.allowed_options?.includes(AllowedOption.PLAYER_REPORTING)) {
      reports.push(Report.PLAYER)
    }
    if (user.allowed_options?.includes(AllowedOption.SPONSORED_GAMES_REPORTING)) {
      reports.push(Report.SPONSORED_GAMES)
    }
  }
  return reports
}

const taskToSubtask = (taskResponse: Exercise): Subtask | CreativeSubtask => {
  return {
    description: taskResponse.description_html,
    type: taskResponse.type,
    ...(taskResponse.type === ExerciseType.CreativeExercise && {
      creative: {
        answerOptions: [
          taskResponse.allows_text ? CreativeAnswerOption.Text : null,
          taskResponse.allows_image ? CreativeAnswerOption.Images : null,
          taskResponse.allows_audio ? CreativeAnswerOption.Audio : null,
          taskResponse.allows_video ? CreativeAnswerOption.Video : null,
        ].filter((item) => item != null),
        hasAutomatedFeedback: taskResponse.has_default_feedback,
        hasEvaluationGuideline: taskResponse.has_reference_answer,
        points: taskResponse.points,
        automatedFeedback: taskResponse.default_feedback,
        autoScorePercentage: taskResponse.auto_score_percentage,
        evaluationGuideline: taskResponse.reference_answer,
      },
    }),
  }
}

export const parseTaskResponseToTaskVm = (taskResponse: Exercise): Task => {
  return {
    name: taskResponse.name,
    subtasks: [taskToSubtask(taskResponse), ...taskResponse.children.map(taskToSubtask)],
    advanced: {},
  }
}

export const parseMemberDataToVm = (member: Member): TMember => {
  return {
    id: member.id,
    email: member.name,
    name: member.contact_name ?? EMPTY_CONTENT_FALLBACK,
    role: member.is_admin ? MemberRole.ADMIN : MemberRole.INSTRUCTOR,
  }
}

export const parseAdminSettingsDataToVm = (data: BusinessSettingsData): TBusinessSettings => {
  return {
    businessInfo: {
      validUntil: data.good_trough.toString(),
      totalPlayersCount: data.all_time_player_count,
    },
    members: (data.members || []).map(parseMemberDataToVm),
  }
}

export const parseAddMemberVmToAddMemberData = (user: TAddMember): AddMember => {
  return {
    email: user.email,
    is_admin: user.role === MemberRole.ADMIN,
    name: user.name,
  }
}
