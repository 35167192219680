import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import { Button } from '../../../common/components/button/Button'
import { CloseButton } from '../../../common/components/button/CloseButton'
import { getIcon } from '../../../common/components/icons/utils'
import { HELP_MENU_CONTROL_ID, useHelpMenu } from '../../../contexts/HelpMenuContextProvider'
import { useUser } from '../../../contexts/userContext'
import { useStorage } from '../../../hooks/useStorage'
import { TUser } from '../../../types/commonTypes'
import styles from '../WelcomeBlock.module.css'
import { HIDDEN_MESSAGES } from '../constants'
import {
  EntityMessages,
  getPrimaryLink,
  getUserActiveDays,
  hideDistinctMessage,
  isMessageHidden,
  pinkBubbleIconSelector,
  primaryActionSelector,
  secondaryActionSelector,
} from '../helpers'
import { LinkButton } from '../../../common/components/button/LinkButton'

type WelcomeBannerProps = {
  messages: EntityMessages
}

export const WelcomeBanner: React.FC<WelcomeBannerProps> = ({
  messages: { id, title, body, primaryButton, secondaryButton },
}) => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { toggleDisplayMenu } = useHelpMenu()
  const { storedValue, storeValue } = useStorage<string[] | null>(HIDDEN_MESSAGES, null)
  const navigate = useNavigate()

  if (user == null) {
    return null
  }

  const handleClickCloseMessage = () => {
    hideDistinctMessage(id, storedValue, storeValue)
  }

  const isLinkButton = (activeDays: number, { isCorporate, isTrial, isEducation }: TUser) => {
    return (
      (activeDays >= 4 && activeDays < 6) ||
      (isTrial && isCorporate && !isEducation && activeDays >= 6 && activeDays < 8) ||
      (activeDays >= 12 && activeDays < 15)
    )
  }

  const handleClickPrimaryButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const primaryActionKey = primaryActionSelector(user)
    if (primaryActionKey.includes('/')) {
      navigate(primaryActionKey)
    }
    if (primaryActionKey.includes('https://')) {
      return primaryActionKey
    }
    if (primaryActionKey === 'help-menu') {
      toggleDisplayMenu()
    }
  }

  const getPrimaryButton = () => {
    if (primaryButton != null && user != null) {
      const activeDays = getUserActiveDays(user.createdAt)
      if (isLinkButton(activeDays, user)) {
        return (
          <LinkButton
            iconName={'receipt'}
            href={getPrimaryLink(user)}
            label={t(primaryButton)}
            target='_blank'
            rel='noreferrer'
          />
        )
      } else {
        return (
          <Button
            buttonStyles={{
              letterSpacing: '0.06em',
              whiteSpace: 'nowrap',
            }}
            label={t(primaryButton)}
            iconLeft='receipt'
            styleVariant='secondary'
            sizeVariant='small'
            iconLeftContainerStyle={{ fontSize: '1.5rem' }}
            onClick={handleClickPrimaryButton}
            id={`${HELP_MENU_CONTROL_ID}_trialMessagePrimaryButton`}
          />
        )
      }
    }
    return
  }

  if (storedValue != null && isMessageHidden(id, storedValue)) {
    return null
  }

  const handleClickSecondaryButton = () => {
    secondaryActionSelector(user)
  }

  return (
    <div className={styles.relativeContainer}>
      <div className={styles.bannerContainer}>
        <div className={styles.bannerContainerLeftSide}>
          <div className={styles.title}>{t(title)}</div>
          <div className={styles.welcomeText}>
            <p className='normal'>{t(body)}</p>
          </div>
          <div className={styles.buttonsRow}>
            {getPrimaryButton()}
            {secondaryButton != null && (
              <Button
                buttonStyles={{
                  letterSpacing: '0.06em',
                  background: '#ffffff',
                  whiteSpace: 'nowrap',
                }}
                label={t(secondaryButton)}
                iconLeft='selectMap'
                styleVariant='tertiary'
                sizeVariant='small'
                onClick={handleClickSecondaryButton}
              />
            )}
          </div>
        </div>

        <div className={classNames(styles.bannerContainerRightSide, styles.alignItemsEnd)}>
          <CloseButton
            buttonStyle={{ color: 'var(--tertiary-color)' }}
            onClick={handleClickCloseMessage}
            accessibilityLabel={t('accessibility.close_banner_button', 'Dismiss banner')}
          />
        </div>
        <div className={styles.circlesContainer}>
          <div className={styles.firstCircle}>
            <span className={styles.firstIcon}>{getIcon(pinkBubbleIconSelector(user)[0])}</span>
            <span className={styles.secondIcon}>
              {pinkBubbleIconSelector(user).length > 1 && getIcon(pinkBubbleIconSelector(user)[1])}
            </span>
          </div>
          <div className={styles.secondCircle} />
        </div>
      </div>
    </div>
  )
}
