import React from 'react'
import { Tab } from '../Tab/Tab'
import styles from './TabGroup.module.css'

type TabGroupProps = {
  activeTab: string
  onClickTab: (id: string) => void
}

const tabList = [
  {
    title: 'Build',
    label: 'Draft',
  },
  {
    title: 'Test',
    label: 'All ok',
  },
  {
    title: 'Play',
    label: 'Add players',
  },
  {
    title: 'Report',
    label: '0% done',
  },
]

export const TabGroup: React.FC<TabGroupProps> = ({ activeTab, onClickTab }) => {
  return (
    <div className={styles.container}>
      {tabList.map((tab, i) => {
        return (
          <Tab
            key={`${tab.title}-${i}`}
            title={tab.title}
            label={tab.label}
            isActive={tab.title === activeTab}
            onClickTab={onClickTab}
          />
        )
      })}
    </div>
  )
}
