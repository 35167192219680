import classNames from 'classnames'
import { TFunction } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { SelectableIconFormField, SelectableIconItem } from '../../../../../../../common/components/Form/CheckboxGroupField/SelectableIconFormField'
import { EditorFormField } from '../../../../../../../common/components/Form/EditorFormField/EditorFormField'
import { FormFieldSpy } from '../../../../../../../common/components/Form/FormFieldSpy/FormFieldSpy'
import { InputFormField } from '../../../../../../../common/components/Form/InputFormField/InputFormField'
import { CreativeAnswerOption, CreativeSubtask, PointsType } from '../../../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../../../util/string'
import { ExpandableToggle } from '../components/ExpandableToggle'
import { PointsInput } from '../components/PointsInput'
import styles from './CreativeSubtask.module.css'

const getTranslatedAnswerOptions = (t: TFunction): SelectableIconItem[] => [
  {
    id: CreativeAnswerOption.Text,
    iconName: 'text',
    text: t('answer_options.text_option.text', 'Text'),
    tooltipText: t('answer_options.text_option.tooltip', 'Allow users to type text as answer'),
  },
  {
    id: CreativeAnswerOption.Images,
    iconName: 'camera',
    text: t('answer_options.images_option.text', 'Images'),
    tooltipText: t('answer_options.images_option.tooltip', 'Allow users to take or upload images as answer'),
  },
  {
    id: CreativeAnswerOption.Video,
    iconName: 'video',
    text: t('answer_options.video_option.text', 'Video'),
    tooltipText: t('answer_options.video_option.tooltip', 'Allow users to record or upload videos as answer'),
  },
  {
    id: CreativeAnswerOption.Audio,
    iconName: 'audio',
    text: t('answer_options.audio_option.text', 'Audio'),
    tooltipText: t('answer_options.audio_option.tooltip', 'Allow users to record or upload audio as answer'),
  },
]

type CreativeSubtaskProps = {
  value: CreativeSubtask
  name: string
}

export const CreativeSubtaskFields: React.FC<CreativeSubtaskProps> = ({ name }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'create_task_modal.creative_task' })

  const answerOptions = useMemo(() => {
    return getTranslatedAnswerOptions(t)
  }, [t])

  const { input: autoScorePercentageInput } = useField(`${name}.creative.autoScorePercentage`)

  const [pointsType, setPointsType] = useState<PointsType>(() => {
    return safeIsNullOrEmpty(autoScorePercentageInput.value) ? PointsType.Evaluate : PointsType.Automatic
  })

  const handlePointsTypeClick = useCallback((pointsType: PointsType) => () => {
    setPointsType(pointsType)
  }, [])

  useEffect(() => {
    if (pointsType === PointsType.Evaluate && autoScorePercentageInput.value != null) {
      autoScorePercentageInput.onChange(null)
    }
  }, [autoScorePercentageInput, pointsType])

  return (
    <>
      <h5>{t('answer_options.section_title', 'Answer options')}</h5>
      <div style={{ minHeight: 50 }}>
        <SelectableIconFormField
          label={t('answer_options.label', 'Select answer options')}
          srOnlyLabel
          name={`${name}.creative.answerOptions`}
          options={answerOptions}
          size='Large'
          iconClassName={styles.answerSelectableIcon}
        />
      </div>
      <div className={styles.pointsContainer}>
        <h5>{t('points.section_title', 'Points')}</h5>
        <div className={styles.pointsButtonsContainer}>
          <button
            className={classNames(styles.pointsButton, pointsType === PointsType.Evaluate && styles.pointsButtonActive)}
            type='button'
            onClick={handlePointsTypeClick(PointsType.Evaluate)}
          >
            {t('points.points_type_button.evaluate', 'Evaluate')}
          </button>
          <button
            className={classNames(styles.pointsButton, pointsType === PointsType.Automatic && styles.pointsButtonActive)}
            type='button'
            onClick={handlePointsTypeClick(PointsType.Automatic)}
          >
            {t('points.points_type_button.automatic', 'Automatic')}
          </button>
        </div>
        <p>{t('points.general_description', 'Define points to be awarded by writing directly into the points area below')}</p>
        <PointsInput
          name={`${name}.creative.points`}
          description={t('points.input_fields.sent_answer_points.label', 'Points for the answer a player sends')}
        />
        { pointsType === PointsType.Automatic && (
          <InputFormField
            name={`${name}.creative.autoScorePercentage`}
            label={t('points.input_fields.auto_score_percentage.label', 'Automatic points')}
            type='number'
            max={100}
            min={1}
            inputStyles={{
              maxWidth: '10rem',
            }}
          />
        ) }
      </div>
      <h5>{t('evaluation_guidelines_and_automation.section_title', 'Evaluation guidelines and automation')}</h5>
      <ExpandableToggle
        name={`${name}.creative.hasEvaluationGuideline`}
        title={t('evaluation_guidelines.toggle.title', 'Evaluation guidelines')}
        description={t('evaluation_guidelines.toggle.description', 'Add evaluation criteria or correct answer to speed up evaluation. Visible only to instructors')}
        id='evaluation-guideline'
      >
        <EditorFormField
          name={`${name}.creative.evaluationGuideline`}
          label={t('evaluation_guidelines.input.label', 'Evaluation guidelines')}
          srOnlyLabel
          onlyBasicOptions
          initProps={{ height: 200 }}
        />
      </ExpandableToggle>
      <FormFieldSpy
        spyFieldName={`${name}.creative.hasEvaluationGuideline`}
        dependantFieldName={`${name}.creative.evaluationGuideline`}
      />
      <ExpandableToggle
        name={`${name}.creative.hasAutomatedFeedback`}
        title={t('automated_feedback.toggle.title', 'Automated feedback')}
        description={t('automated_feedback.toggle.description', 'Define automated feedback to be given to players immediately after answering')}
        id='automated-feedback'
      >
        <EditorFormField
          name={`${name}.creative.automatedFeedback`}
          label={t('automated_feedback.input.label', 'Automated feedback')}
          srOnlyLabel
          onlyBasicOptions
          initProps={{ height: 200 }}
        />
      </ExpandableToggle>
      <FormFieldSpy
        spyFieldName={`${name}.creative.hasAutomatedFeedback`}
        dependantFieldName={`${name}.creative.automatedFeedback`}
      />
    </>
  )
}
