import { getIcon, IconName } from '../../../../../common/components/icons/utils'
import { Tooltip } from '../../../../../common/components/tooltip/Tooltip'
import { safeIsNullOrEmpty } from '../../../../../util/string'
import styles from './TaskModal.module.css'

type AddSubtaskItemProps = {
  icon: IconName
  title: string
  description: string
  tooltipContent?: string
  onClick: () => void
}

export const AddSubtaskItem: React.FC<AddSubtaskItemProps> = ({
  icon,
  title,
  description,
  tooltipContent,
  onClick,
}) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={styles.addSubtaskButton}
    >
      { !safeIsNullOrEmpty(tooltipContent) && (
        <div className={styles.addSubtaskTooltip}>
          <Tooltip
            text={tooltipContent!}
            iconName='info'
          />
        </div>
      ) }
      <span className={styles.addSubtaskButtonIcon}>
        {getIcon(icon)}
      </span>
      <div>
        {title}
      </div>
      <div className={styles.addSubtaskButtonDescription}>
        {description}
      </div>
    </button>
  )
}
