import React from 'react'
import { getIcon, IconName } from '../icons/utils'
import styles from './Tooltip.module.css'
import classNames from 'classnames'

type TooltipProps = {
  text: string
  iconName?: IconName
  iconColor?: string
  iconClassName?: string
}

export const Tooltip: React.FC<TooltipProps> = ({ text, iconName = 'info', iconColor, iconClassName }) => {

  return (
    <div className={styles.tooltip} tabIndex={0}>
      <span
        className={classNames(styles.icon, iconClassName)}
        style={{ color: iconColor != null ? iconColor : 'var(--primary-normal)' }}
      >
        {getIcon(iconName)}
      </span>
      <span className={styles.tooltipText}>{text}</span>
    </div>
  )
}
