import { NotificationItem, NotificationItemProps } from './NotificationItem'
import styles from './Notification.module.css'
import { ModalWrapper } from '../../common/components/ModalWrapper/ModalWrapper'

type NotificationsWrapperProps = {
  notifications: NotificationItemProps[]
}

export const NotificationsWrapper: React.FC<NotificationsWrapperProps> = ({ notifications }) => {
  return (
    <ModalWrapper>
      <div className={styles.notificationsWrapper}>
        {notifications.map((notification) => (
          <NotificationItem key={`notification_${notification.id}`} {...notification} />
        ))}
      </div>
    </ModalWrapper>
  )
}
