// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoNavbarLayout_outlet__sns7- {\n  min-height: 100vh;\n  width: 100%;\n  background-color: var(--grey-100);\n}\n", "",{"version":3,"sources":["webpack://./src/navigation/NoNavbarLayout.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,iCAAiC;AACnC","sourcesContent":[".outlet {\n  min-height: 100vh;\n  width: 100%;\n  background-color: var(--grey-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outlet": "NoNavbarLayout_outlet__sns7-"
};
export default ___CSS_LOADER_EXPORT___;
