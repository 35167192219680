// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Members_topContainer__9XePi {\n  display: flex;\n  justify-content: space-between;\n}\n\n.Members_actionsContainer__WlJJT {\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/settings/organization/components/Members.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX","sourcesContent":[".topContainer {\n  display: flex;\n  justify-content: space-between;\n}\n\n.actionsContainer {\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topContainer": "Members_topContainer__9XePi",
	"actionsContainer": "Members_actionsContainer__WlJJT"
};
export default ___CSS_LOADER_EXPORT___;
