import { useEffect } from 'react'

export const useDisableBodyScroll = (isActive: boolean = true) => {
  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isActive])
}
