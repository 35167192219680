import React from 'react'
import { useTranslation } from 'react-i18next'

import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'
import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { WelcomeBlock } from '../../composites/WelcomeBlock/WelcomeBlock'
import { useGameData } from '../../contexts/OwnGamesContextProvider'
import { useUser } from '../../contexts/userContext'
import { useGameEditorNavigation } from '../../hooks/useGameEditorNavigation'
import { isNullOrZero } from '../../util/number'

import styles from './Dashboard.module.css'
import { getDashboardEmptyContent } from '../../util/game'

export const Dashboard: React.FC = () => {
  const { user } = useUser()
  const gameData = useGameData()
  const { t } = useTranslation()
  const { goToCreateGame } = useGameEditorNavigation()

  const handleClickCreateGame = async () => {
    await goToCreateGame()
  }

  return (
    <div className={styles.container}>
      {user != null && <WelcomeBlock />}
      {isNullOrZero(gameData.pageData?.length) && gameData.loading ? (
        <LoaderBlock />
      ) : (
        <GamesOverview
          title={t('dashboard.recent_games', 'Recent games')}
          onClickCreateGame={handleClickCreateGame}
          showCreateGameCard
          gameData={gameData}
          emptyContent={getDashboardEmptyContent(user, t)}
        />
      )}
    </div>
  )
}
