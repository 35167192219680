import { KeyboardEventHandler, useMemo, useState } from 'react'
import { components, MultiValue, default as ReactSelect, SingleValue, StylesConfig, Theme } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { getIcon, IconName } from '../icons/utils'

export enum SelectVariant {
  Small = 'small',
  Large = 'large',
  XLarge = 'xLarge',
}

interface BaseSelectProps {
  options: SelectOption[]
  id?: string
  disabled?: boolean
  className?: string
  hasError?: boolean
  defaultValue?: SelectOption
  isDisabled?: boolean
  variant?: SelectVariant
  isSearchable?: boolean
  isClearable?: boolean
  placeholder?: string
  autoFocus?: boolean
  // NOTE: if passing in the prefix element, memoize it! Changing the prefix element recalculates ValueContainer.
  // If ValueContainer changes after selecting an option, it breaks onBlur when clicking outside the component.
  prefixElement?: SelectElement
}

export interface SelectProps extends BaseSelectProps {
  value?: SelectOption
  onChange?: (option: SingleValue<SelectOption>) => void
}

export interface MultiSelectProps extends BaseSelectProps {
  value?: SelectOption[]
  onChange?: (options: MultiValue<SelectOption>) => void
}

export interface CreatableMultiSelectProps {
  value: SelectOption[]
  onChange: (options: MultiValue<SelectOption>) => void
  id?: string
  disabled?: boolean
  className?: string
  hasError?: boolean
  defaultValue?: SelectOption[]
  isDisabled?: boolean
  variant?: SelectVariant
  isClearable?: boolean
  placeholder?: string
}

export interface SelectElement {
  type: 'icon' | 'rawContent'
  icon?: {
    name: IconName
    customActiveColor?: string
  }
  rawContent?: string | JSX.Element
  styles?: React.CSSProperties
}

export interface SelectOption {
  value: string
  label: string
  prefixElement?: SelectElement
}

const getStyles = <T extends boolean>(variant: SelectVariant, hasError?: boolean): StylesConfig<SelectOption, T> => ({
  control: (styles, props) => ({
    ...styles,
    boxShadow: 'none',
    minHeight: variant === SelectVariant.XLarge ? '3rem' : variant === SelectVariant.Large ? '2.5rem' : '2rem',
    fontSize: variant === SelectVariant.XLarge ? '1.1rem' : variant === SelectVariant.Large ? '1rem' : '0.875rem',
    ...(hasError && !props.isFocused && { outline: '2px solid var(--validation-error)' }),
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 0.5rem',
    display: 'flex',
    alignItems: 'center',
  }),
  menu: (styles) => ({
    ...styles,
    overflow: 'hidden',
    boxShadow: '0rem 0.5rem 1rem -0.375rem rgba(0, 0, 0, 0.1)',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '0',
    cursor: 'pointer',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    cursor: 'pointer',
    padding: '0 0.5rem 0 0',
  }),
})

const createValueContainer = (prefixElement?: SelectElement) => (props: any) => {
  const { children, ...rest } = props
  const iconColor =
    prefixElement?.type === 'icon' && prefixElement?.icon != null
      ? props.hasValue
        ? prefixElement.icon?.customActiveColor ?? 'var(--primary-normal)'
        : 'var(--grey-400)'
      : undefined
  return (
    <components.ValueContainer {...rest}>
      {prefixElement && (
        <span
          style={{
            color: iconColor,
            marginRight: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            ...prefixElement.styles,
          }}
        >
          {prefixElement.type === 'icon' && prefixElement?.icon != null
            ? getIcon(prefixElement?.icon?.name)
            : prefixElement.rawContent}
        </span>
      )}
      {children}
    </components.ValueContainer>
  )
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        style={{
          rotate: props.selectProps.menuIsOpen ? '180deg' : '0deg',
          fontSize: '1.25rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {getIcon('chevron')}
      </span>
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        style={{
          fontSize: '0.75rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {getIcon('close')}
      </span>
    </components.DropdownIndicator>
  )
}

const formatOptionLabel = ({ label, prefixElement }: SelectOption) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {prefixElement && (
        <span
          style={{
            marginRight: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            ...prefixElement.styles,
          }}
        >
          {prefixElement.type === 'icon' && prefixElement?.icon != null
            ? getIcon(prefixElement.icon.name)
            : prefixElement.rawContent}
        </span>
      )}
      {label}
    </div>
  )
}

// TODO: update colors when the design is finalized (docs: https://react-select.com/styles#overriding-the-theme)
const getTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--primary-normal)',
    primary75: 'var(--primary-dark)',
    primary50: 'var(--primary-light)',
    primary25: 'var(--primary-background)',
  },
  borderRadius: 9,
})

export const Select: React.FC<SelectProps> = ({
  options,
  isDisabled = false,
  variant = SelectVariant.Large,
  hasError = false,
  prefixElement,
  isSearchable = false,
  autoFocus = false,
  ...rest
}) => {
  const ValueContainer = useMemo(() => createValueContainer(prefixElement), [prefixElement])

  return (
    <ReactSelect
      styles={getStyles<false>(variant, hasError)}
      theme={getTheme}
      components={{ ValueContainer, DropdownIndicator, ClearIndicator }}
      isDisabled={isDisabled}
      options={options}
      formatOptionLabel={formatOptionLabel}
      isSearchable={isSearchable}
      autoFocus={autoFocus ?? false}
      {...rest}
    />
  )
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  isDisabled = false,
  variant = SelectVariant.Large,
  hasError = false,
  prefixElement,
  isSearchable = false,
  ...rest
}) => {
  const ValueContainer = useMemo(() => createValueContainer(prefixElement), [prefixElement])

  return (
    <ReactSelect
      styles={getStyles<true>(variant, hasError)}
      theme={getTheme}
      components={{ ValueContainer, DropdownIndicator, ClearIndicator }}
      isDisabled={isDisabled}
      options={options}
      isSearchable={isSearchable}
      formatOptionLabel={formatOptionLabel}
      isMulti
      {...rest}
    />
  )
}

export const CreatableMultiSelect: React.FC<CreatableMultiSelectProps> = ({
  isDisabled = false,
  variant = SelectVariant.Large,
  hasError = false,
  onChange,
  value,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case ',':
      case 'Tab':
        const newValues = [...(value || []), { label: inputValue, value: inputValue }]
        onChange?.(newValues)
        setInputValue('')
        event.preventDefault()
    }
  }

  return (
    <CreatableSelect
      styles={getStyles<true>(variant, hasError)}
      theme={getTheme}
      components={{ DropdownIndicator: null, ClearIndicator }}
      isClearable
      isMulti
      menuIsOpen={false}
      inputValue={inputValue}
      value={value}
      onKeyDown={handleKeyDown}
      onInputChange={(newValue) => setInputValue(newValue)}
      onChange={onChange}
      {...rest}
    />
  )
}
