import { useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { RoundButton } from '../../../../common/components/button/RoundButton'
import { getIcon } from '../../../../common/components/icons/utils'
import { UserMenuContainer } from '../../../../composites/UserMenu/UserMenu'
import { getActiveBusiness } from '../../../../composites/UserMenu/helpers'
import { useUser } from '../../../../contexts/userContext'
import styles from './UserMenu.module.css'

export const UserMenu: React.FC = () => {
  const { user } = useUser()
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(menuRef, () => {
    setIsUserMenuVisible(false)
  })

  return (
    <div className={styles.menuContainer}>
      <div onClick={() => setIsUserMenuVisible(true)} className={styles.menu}>
        <button className={styles.userIcon}>
          <span>{getIcon('userIdle')}</span>
        </button>
        <div className={styles.menuContent}>
          <p className={styles.name}>{user?.name}</p>
          <p className={styles.organization}>{user && getActiveBusiness(user).name}</p>
        </div>
        <UserMenuContainer
          ref={menuRef}
          isUserMenuOpen={isUserMenuVisible}
          onMenuClose={() => setIsUserMenuVisible(false)}
        />
        <RoundButton
          buttonStyle={{
            fontSize: 18,
            background: 'transparent',
            boxShadow: 'none',
          }}
          icon={'arrowDown'}
        />
      </div>
    </div>
  )
}
