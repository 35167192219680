import classNames from 'classnames'
import React from 'react'

import { Link } from 'react-router-dom'
import shared from '../../styles/shared.module.css'
import { getIcon } from '../icons/utils'
import styles from './Card.module.css'

type BrowseGamesCardProps = {
  label: string
  route: string
}

export const BrowseGamesCard: React.FC<BrowseGamesCardProps> = ({ label, route }) => {
  return (
    <div>
      <Link
        to={route}
        className={classNames(styles.container, styles.outlined, styles.browseGamesCard, shared.mAxisC, shared.sAxisC)}
      >
        <span className={styles.iconWrapper}>{getIcon('community')}</span>
        <p className={styles.text}>{label}</p>
      </Link>
    </div>
  )
}
