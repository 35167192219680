import React, { useEffect, useState } from 'react'
import { UserContext, UserContextType } from '../../contexts/userContext'
import { TUser } from '../../types/commonTypes'
import { TrialEndBanner } from './components/TrialEndBanner'
import { WelcomeBanner } from './components/WelcomeBanner'
import { getUserActiveDays, messageSelector } from './helpers'

const isTrialUserAndTrialEnded = (user: TUser, userActiveDays: number) =>
  user.isTrial != null && user.isTrial && userActiveDays >= 15

const isNotTrialUser = (user: TUser, userActiveDays: number) => !user.isTrial && userActiveDays < 22

export const WelcomeBlock: React.FC = () => {
  const { user } = React.useContext(UserContext) as UserContextType
  const [userActiveDays, setUserActiveDays] = useState<number | null>(null)

  useEffect(() => {
    if (user != null && messageSelector(user) != null) {
      const activeDays = getUserActiveDays(user.createdAt)
      setUserActiveDays(activeDays)
    }
  }, [user])

  if (user == null || userActiveDays == null || messageSelector(user) == null) {
    return null
  }

  return (
    <>
      {isTrialUserAndTrialEnded(user, userActiveDays) && <TrialEndBanner messages={messageSelector(user)!} />}
      {userActiveDays < 15 || isNotTrialUser(user, userActiveDays) ? (
        <WelcomeBanner messages={messageSelector(user)!} />
      ) : null}
    </>
  )
}
