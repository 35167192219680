import { Editor } from '@tinymce/tinymce-react'
import { useCallback, useRef, useState } from 'react'
import { FormFieldProps } from '../../../../types/commonTypes'
import { EditorFormField, EditorFormFieldProps } from './EditorFormField'
import './EditorFormField.module.css'
import {
  anyMissingWordFieldRegex, getMissingWordFieldHtml,
  getMissingWordFieldRegex, getRandomId,
} from './helpers'

const MAX_FIELDS = 10
const MISSING_WORD_SVG = '<svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 -0.00268555C16.4477 -0.00268555 16 0.44503 16 0.997314C16 1.5496 16.4477 1.99731 17 1.99731C19.761 1.99731 22 4.23627 22 6.99731C22 6.99731 22 6.99732 22 6.99731V7.99733H3C1.34372 7.99733 0 9.34105 0 10.9973V20.9973C0 22.6536 1.34372 23.9973 3 23.9973H22V24.9973C22 27.7584 19.761 29.9973 17 29.9973C16.4477 29.9973 16 30.445 16 30.9973C16 31.5496 16.4477 31.9973 17 31.9973C19.5461 31.9973 21.775 30.6375 23 28.6046C24.225 30.6375 26.4539 31.9973 29 31.9973C29.5523 31.9973 30 31.5496 30 30.9973C30 30.445 29.5523 29.9973 29 29.9973C26.239 29.9973 24 27.7584 24 24.9973V23.9973H29C30.6563 23.9973 32 22.6536 32 20.9973V10.9973C32 9.34105 30.6563 7.99733 29 7.99733H24V6.99733C24 6.99732 24 6.99733 24 6.99733C24 4.23628 26.239 1.99731 29 1.99731C29.5523 1.99731 30 1.5496 30 0.997314C30 0.44503 29.5523 -0.00268555 29 -0.00268555C26.4539 -0.00268555 24.225 1.35712 23 3.39004C21.775 1.35712 19.5461 -0.00268555 17 -0.00268555ZM22 9.99733H3C2.44828 9.99733 2 10.4456 2 10.9973V20.9973C2 21.549 2.44828 21.9973 3 21.9973H22V9.99733ZM24 21.9973V9.99733H29C29.5517 9.99733 30 10.4456 30 10.9973V20.9973C30 21.549 29.5517 21.9973 29 21.9973H24Z" fill="currentColor"/></svg>'

export const MissingWordEditorFormField: React.FC<FormFieldProps<EditorFormFieldProps>> = (props) => {
  const editorRef = useRef<Editor>(null)
  const [missingWordFieldIds, setMissingWordFieldIds] = useState<string[]>([])

  const handleEditorChange = useCallback((value: string) => {
    const currentFields = editorRef.current?.editor?.getContent().match(anyMissingWordFieldRegex)
    const expectedFields = currentFields?.map((field, index) => field.replace(/Field-\d+/g, `Field-${index + 1}`))
    if (currentFields?.join(':') !== expectedFields?.join(':')) {
      let newContent: string = value
      currentFields?.forEach((currField, index) => {
        if (expectedFields?.[index] != null) {
          newContent = newContent.replace(currField, expectedFields?.[index])
        } else {
          newContent = newContent.replace(currField, '')
        }
      })
      setMissingWordFieldIds((prev) => prev.filter((mwId) => expectedFields?.join(':').includes(`id="${mwId}"`)))
      editorRef.current?.editor?.resetContent(newContent)
    } else if (expectedFields?.length !== missingWordFieldIds.length) {
      setMissingWordFieldIds(expectedFields?.map((field) => {
        return field.match(/id="[-\w]+"/g)![0].replace('id="', '').replace('"', '')
      }) || [])
    }
  }, [missingWordFieldIds])

  const handleAddMissingWordField = useCallback(() => {
    const uniqueId = getRandomId()
    const nextIndex = (editorRef.current?.editor?.getContent().match(anyMissingWordFieldRegex) || []).length + 1
    if (nextIndex > MAX_FIELDS) {
      alert(`The maximum allowed number of fields is ${MAX_FIELDS}`)
    } else {
      setMissingWordFieldIds((prev) => [...prev, uniqueId])
      editorRef.current?.editor?.insertContent(getMissingWordFieldHtml(uniqueId, nextIndex))
    }
  }, [])

  const handleRemoveMissingWordField = useCallback((id: string) => () => {
    setMissingWordFieldIds((prev) => prev.filter((item) => item !== id))
    const content = editorRef.current?.editor?.getContent()
    const newContent = content?.replace(getMissingWordFieldRegex(id), '')
    editorRef.current?.editor?.resetContent(newContent)
  }, [])

  return (
    <>
      <EditorFormField
        ref={editorRef}
        onEditorChange={handleEditorChange}
        initProps={{
          setup: (editor) => {
            editor.ui.registry.addIcon('addMissingWordFieldIcon', MISSING_WORD_SVG)
            editor.ui.registry.addButton('addMissingWordFieldButton', {
              tooltip: 'Insert missing word field',
              icon: 'addMissingWordFieldIcon',
              onAction: handleAddMissingWordField,
            })
          },
        }}
        additionalToolbarOptions='addMissingWordFieldButton'
        {...props}
      />
      { missingWordFieldIds.map((id, index) => (
        <button type='button' key={`missingWordField:${id}`} onClick={handleRemoveMissingWordField(id)}>remove field {index + 1}</button>
      ))}
    </>
  )
}
