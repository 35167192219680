import { useCallback } from 'react'
import { ExerciseType } from '../../../../../api/gameTypes'
import { AddSubtaskItem } from './AddSubtaskItem'
import { getIconNameForTaskType, getTitleForTaskType } from './helpers'
import styles from './TaskModal.module.css'

type AddSubtaskSectionProps = {
  onClick: (type: ExerciseType) => void
}

export const AddSubtaskSection: React.FC<AddSubtaskSectionProps> = ({
  onClick,
}) => {

  const onClickInternal = useCallback((type: ExerciseType) => () => {
    onClick(type)
  }, [onClick])

  return (
    <>
      <h5 className={styles.addSubtaskTitle}>Add tasks</h5>
      <div className={styles.addSubtaskSection}>
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.CreativeExercise)}
          description='Selection description'
          onClick={onClickInternal(ExerciseType.CreativeExercise)}
          icon={getIconNameForTaskType(ExerciseType.CreativeExercise)}
          tooltipContent='Descriptive info about creative task'
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.MultichoiceExercise)}
          description='Selection description'
          onClick={onClickInternal(ExerciseType.MultichoiceExercise)}
          icon={getIconNameForTaskType(ExerciseType.MultichoiceExercise)}
          tooltipContent='Descriptive info about multiple choice task'
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.PollExercise)}
          description='Selection description'
          onClick={onClickInternal(ExerciseType.PollExercise)}
          icon={getIconNameForTaskType(ExerciseType.PollExercise)}
          tooltipContent='Descriptive info about checkbox task'
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.MissingWordExercise)}
          description='Selection description'
          onClick={onClickInternal(ExerciseType.MissingWordExercise)}
          icon={getIconNameForTaskType(ExerciseType.MissingWordExercise)}
          tooltipContent='Descriptive info about missing word task'
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.CombineExercise)}
          description='Selection description'
          onClick={onClickInternal(ExerciseType.CombineExercise)}
          icon={getIconNameForTaskType(ExerciseType.CombineExercise)}
          tooltipContent='Descriptive info about match pairs task'
        />
        <AddSubtaskItem
          title={getTitleForTaskType(ExerciseType.CollaborationExercise)}
          description='Selection description'
          onClick={onClickInternal(ExerciseType.CollaborationExercise)}
          icon={getIconNameForTaskType(ExerciseType.CollaborationExercise)}
          tooltipContent='Descriptive info about create and comment task'
        />
      </div>
    </>
  )
}
