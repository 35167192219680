// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RootLayout_container__arUtK {\n  background-color: var(--grey-250);\n  min-height: calc(100vh - var(--global-notification-height));\n  width: 100%;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/navigation/RootLayout.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,2DAA2D;EAC3D,WAAW;EACX,aAAa;AACf","sourcesContent":[".container {\n  background-color: var(--grey-250);\n  min-height: calc(100vh - var(--global-notification-height));\n  width: 100%;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RootLayout_container__arUtK"
};
export default ___CSS_LOADER_EXPORT___;
