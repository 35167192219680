// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationInfo_container__FFDtm {\n  display: flex;\n  gap: 40px;\n}\n\n.OrganizationInfo_organizationIconContainer__cFxXj {\n  background-color: var(--primary-light);\n  height: 9.3rem;\n  width: 9.3rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n}\n\n.OrganizationInfo_organizationIconContainer__cFxXj > span {\n  height: 4.375rem;\n  font-size: 4.375rem;\n  color: var(--primary-normal);\n}\n\n.OrganizationInfo_orgInfoList__\\+iMey {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5625rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/settings/organization/components/OrganizationInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,sCAAsC;EACtC,cAAc;EACd,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  gap: 40px;\n}\n\n.organizationIconContainer {\n  background-color: var(--primary-light);\n  height: 9.3rem;\n  width: 9.3rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n}\n\n.organizationIconContainer > span {\n  height: 4.375rem;\n  font-size: 4.375rem;\n  color: var(--primary-normal);\n}\n\n.orgInfoList {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OrganizationInfo_container__FFDtm",
	"organizationIconContainer": "OrganizationInfo_organizationIconContainer__cFxXj",
	"orgInfoList": "OrganizationInfo_orgInfoList__+iMey"
};
export default ___CSS_LOADER_EXPORT___;
