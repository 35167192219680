import { useCallback, useEffect, useState } from 'react'
import { createNewGame, getGame } from '../api/gameApiService'
import { parseGameResponseToGameVm } from '../api/typeConverters'
import { Game } from '../types/commonTypes'
import { GameForm } from '../pages/GameEditor/types'
import { BaseGameSubset, MapType } from '../api/gameTypes'
import { safeIsNullOrEmpty } from '../util/string'
import { useGameData } from '../contexts/OwnGamesContextProvider'

const getGameParams = (data: GameForm): BaseGameSubset => {
  return {
    name: data.name,
    description: data.description,
    map_type: data.gameBoardSettings.gameBoardType,
    ...(data.gameBoardSettings.gameBoardType === MapType.LIVE && {
      longitude: data.gameBoardSettings.liveMap.center[0],
      latitude: data.gameBoardSettings.liveMap.center[1],
      zoom: data.gameBoardSettings.liveMap.zoom,
    }),
    maps: data.gameBoardSettings.gameBoards.map((map, ind) => {
      return {
        map_id: ind - 1,
        name: map.name,
        map_url: map.url,
        map_dimensions: { x: 0, y: 0 },
        location: '',
      }
    }),
    ...(!safeIsNullOrEmpty(data.keywords) && { keywords: data.keywords!.join(',') }),
    ages: data.ages,
    subjects: data.topics,
    language: data.language,
    notification_settings: {
      messages: data.messages ? Number(data.messages) : 0,
      answers: data.answers ? Number(data.answers) : 0,
      instant_enabled: data.instantEnabled ?? false,
      summary_enabled: data.summaryEnabled ?? false,
      emails: data.emails ?? '',
    },
    branch_type: data.allowBranching ? 'TREE' : null,
    happy_or_not: data.happyOrNot,
    hide_scoreboard: data.hideScoreboard,
    levels_enabled: data.levelsEnabled,
    no_chat: !data.chatEnabled,
    no_gps: !data.gpsEnabled,
    no_points_game: data.noPointsGame,
    player_continues_sent_ans_enabled: data.playerContinuesSentAnsEnabled,
  }
}

type UseManageGame = {
  gameData: Game | undefined
  loadingGetGame: boolean
  errorGetGame: string | undefined
  createGame: (data: GameForm) => Promise<boolean>
  loadingCreateGame: boolean
  errorCreateGame: string | undefined
  updateGame: (data: GameForm) => Promise<boolean>
  loadingUpdateGame: boolean
  errorUpdateGame: string | undefined
}

export const useManageGame = (gameId: number | null): UseManageGame => {
  const [gameData, setGameData] = useState<Game>()
  const [loadingGetGame, setLoadingGetGame] = useState<boolean>(false)
  const [errorGetGame, setErrorGetGame] = useState<string>()
  const [loadingCreateGame, setLoadingCreateGame] = useState<boolean>(false)
  const [errorCreateGame, setErrorCreateGame] = useState<string>()
  const [loadingUpdateGame, setLoadingUpdateGame] = useState<boolean>(false)
  const [errorUpdateGame, setErrorUpdateGame] = useState<string>()

  const { refreshGames } = useGameData()

  useEffect(() => {
    if (gameId != null && gameData?.gameId !== gameId) {
      setErrorGetGame(undefined)
      setLoadingGetGame(true)
      getGame({ gameId })
        .then((result) => {
          if (result.success) {
            setGameData(parseGameResponseToGameVm(result.value))
          } else {
            setErrorGetGame(result.error.message)
          }
        })
        .catch((e: any) => {
          setErrorGetGame(e?.message ?? 'An error occurred')
        })
        .finally(() => {
          setLoadingGetGame(false)
        })
    }
  }, [gameId, gameData?.gameId])

  const createGame = useCallback(
    async (data: GameForm) => {
      setErrorCreateGame(undefined)
      setLoadingCreateGame(true)
      return createNewGame({ game: getGameParams(data) })
        .then((result) => {
          if (result.success) {
            setGameData(parseGameResponseToGameVm(result.value))
            refreshGames()
            return true
          } else {
            setErrorCreateGame(result.error.message)
            return false
          }
        })
        .catch((e: any) => {
          setErrorCreateGame(e?.message ?? 'An error occurred')
          return false
        })
        .finally(() => {
          setLoadingCreateGame(false)
        })
    },
    [refreshGames],
  )

  const updateGame = useCallback(async (data: GameForm) => {
    setErrorUpdateGame(undefined)
    setLoadingUpdateGame(true)
    await new Promise((resolve) => setTimeout(resolve, 500))
    const params = getGameParams(data)
    alert('Edit not implemented yet\n' + JSON.stringify(params, undefined, 2))
    setLoadingUpdateGame(false)
    return true
  }, [])

  return {
    gameData,
    loadingGetGame,
    errorGetGame,
    createGame,
    loadingCreateGame,
    errorCreateGame,
    updateGame,
    loadingUpdateGame,
    errorUpdateGame,
  }
}
