import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { ExerciseType } from '../../../../../api/gameTypes'
import { Button } from '../../../../../common/components/button/Button'
import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { FormErrorType, Task } from '../../../../../types/commonTypes'
import { isNullOrZero } from '../../../../../util/number'
import { AddSubtaskSection } from './AddSubtaskSection'
import { SubtaskField } from './SubtaskField'
import styles from './TaskModal.module.css'

type TaskFormProps = {
  initialValues: Partial<Task>
  onSubmit: (values: Task) => void
  onCancel: () => void
  validate: (values: Partial<Task>) => FormErrorType<Task>
}

export const TaskForm: React.FC<TaskFormProps> = ({ initialValues, onSubmit, onCancel, validate }) => {
  const handleLibraryTaskClick = useCallback(() => {
    alert('To be implemented in a separate task')
  }, [])

  // action counter is part of the editor field key - a hack to force tinymce
  // to rerender with new initial value in case of reordering and deleting
  const [actionCounter, setActionCounter] = useState<number>(0)
  const withActionCounter = useCallback((action: () => void) => () => {
    action()
    setActionCounter((prev) => prev + 1)
  }, [])

  return (
    <Form<Task>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({
        handleSubmit,
        values,
        submitError,
        modifiedSinceLastSubmit,
        form: {
          mutators: { push },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={classNames(styles.taskForm, styles.taskModalMainSection)}>
            <div className={styles.taskFormSection}>
              <h4>Task information</h4>
              <InputFormField
                name='name'
                label='Name'
              />
            </div>
            <div className={classNames(styles.taskFormSection, styles.taskFormTasksContainer)}>
              <h4>Tasks</h4>
              <FieldArray name='subtasks'>
                {({ fields }) => {
                  return !isNullOrZero(fields?.length) ? (
                    fields.map((name, index) => (
                      <SubtaskField
                        key={`subtask:${name}:${actionCounter}`}
                        name={name}
                        value={values.subtasks[index]}
                        onDelete={withActionCounter(() => fields.remove(index))}
                        onMoveDown={index < (fields?.length ?? 0) - 1 ? withActionCounter(() => fields.move(index, index + 1)) : undefined}
                        onMoveUp={index > 0 ? withActionCounter(() => fields.move(index, index - 1)) : undefined}
                      />
                    ))
                  ) : (
                    <div style={{ textAlign: 'center', padding: '1rem' }}>
                      No tasks yet
                    </div>
                  )
                }}
              </FieldArray>
              <AddSubtaskSection
                onClick={(type: ExerciseType) => push('subtasks', { type })}
              />
              <div className={styles.addFromLibraryContainer}>
                <Button
                  type='button'
                  label='Add from library'
                  iconLeft='search'
                  styleVariant='tertiary'
                  sizeVariant='small'
                  onClick={handleLibraryTaskClick}
                />
              </div>
            </div>
            <div className={styles.taskFormSection}>
              <h4>Advanced</h4>
              <div>another task</div>
            </div>
            <div className={styles.taskFormSection}>
              <h4>Current form values</h4>
              <pre style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{JSON.stringify(values, undefined, 2)}</pre>
            </div>
          </div>
          <div className={classNames(styles.taskModalFooter, styles.taskModalSection)}>
            <Button type='button' label='Cancel' sizeVariant='small' styleVariant='tertiary' onClick={onCancel}/>
            <Button type='submit' label='Save' sizeVariant='small'/>
          </div>
          {submitError && !modifiedSinceLastSubmit && <div style={{ color: 'red' }}>{submitError}</div>}
        </form>
      )}
    </Form>
  )
}
