import { TFunction } from 'i18next'
import { SelectOption } from '../../../common/components/Select/Select'
import { GameFilters } from '../../../contexts/OwnGamesContextProvider'

export const ALL_GAME_FILTER_KEYS: Array<keyof GameFilters> = [
  'age',
  'approvedBySeppo',
  'language',
  'subject',
  'updatedAt',
  'search',
  'source',
]

export const ALL_SELECT_OPTION_VALUE = 'ALL' as const

const getDevAgeOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('search_page.age_filter_options.all', 'All ages'),
    value: ALL_SELECT_OPTION_VALUE,
  },
  {
    label: t('search_page.age_filter_options.0_8', '0-8'),
    value: '0-8',
  },
  {
    label: t('search_page.age_filter_options.2_3', '2-3'),
    value: '2-3',
  },
  {
    label: t('search_page.age_filter_options.4_6', '4-6'),
    value: '4-6',
  },
  {
    label: t('search_page.age_filter_options.6_8', '6-8'),
    value: '6-8',
  },
  {
    label: t('search_page.age_filter_options.7_9', '7-9'),
    value: '7-9',
  },
  {
    label: t('search_page.age_filter_options.10_12', '10-12'),
    value: '10-12',
  },
  {
    label: t('search_page.age_filter_options.13_15', '13-15'),
    value: '13-15',
  },
  {
    label: t('search_page.age_filter_options.16_19', '16-19'),
    value: '16-19',
  },
  {
    label: t('search_page.age_filter_options.20_29', '20-29'),
    value: '20-29',
  },
  {
    label: t('search_page.age_filter_options.30_above', '30-'),
    value: '30-',
  },
]

const getProdAgeOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('search_page.age_filter_options.all', 'All ages'),
    value: ALL_SELECT_OPTION_VALUE,
  },
  {
    label: t('search_page.age_filter_options.4_6', '4-6'),
    value: '4-6',
  },
  {
    label: t('search_page.age_filter_options.7_9', '7-9'),
    value: '7-9',
  },
  {
    label: t('search_page.age_filter_options.10_12', '10-12'),
    value: '10-12',
  },
  {
    label: t('search_page.age_filter_options.13_15', '13-15'),
    value: '13-15',
  },
  {
    label: t('search_page.age_filter_options.16_19', '16-19'),
    value: '16-19',
  },
  {
    label: t('search_page.age_filter_options.20_29', '20-29'),
    value: '20-29',
  },
  {
    label: t('search_page.age_filter_options.30_above', '30-'),
    value: '30-',
  },
]

export const getAgeOptions = (t: TFunction, isDev?: boolean): SelectOption[] => {
  return isDev ? getDevAgeOptions(t) : getProdAgeOptions(t)
}

const getDevLanguageOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('search_page.language_filter_options.chinese', 'Chinese'),
    value: 'Chinese',
  },
  {
    label: t('search_page.language_filter_options.deutsch', 'Deutsch'),
    value: 'Deutsch',
  },
  {
    label: t('search_page.language_filter_options.english', 'English'),
    value: 'English',
  },
  {
    label: t('search_page.language_filter_options.italiano', 'Italiano'),
    value: 'Italiano',
  },
  {
    label: t('search_page.language_filter_options.nederlands', 'Nederlands'),
    value: 'Nederlands',
  },
  {
    label: t('search_page.language_filter_options.norsk', 'Norsk'),
    value: 'Norsk',
  },
  {
    label: t('search_page.language_filter_options.old_norsk', 'Old Norsk'),
    value: 'OldNorsk',
  },
  {
    label: t('search_page.language_filter_options.portuguese', 'Portuguese'),
    value: 'Portuguese',
  },
  {
    label: t('search_page.language_filter_options.rwanda', 'Rwanda'),
    value: 'Rwanda',
  },
  {
    label: t('search_page.language_filter_options.spanish', 'Spanish'),
    value: 'Spanish',
  },
  {
    label: t('search_page.language_filter_options.suomi', 'Suomi'),
    value: 'Suomi',
  },
  {
    label: t('search_page.language_filter_options.svenska', 'Svenska'),
    value: 'Svenska',
  },
]

const getProdLanguageOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('search_page.language_filter_options.all', 'All languages'),
    value: ALL_SELECT_OPTION_VALUE,
  },
  {
    label: t('search_page.language_filter_options.arabic', 'Arabic'),
    value: 'Arabic',
  },
  {
    label: t('search_page.language_filter_options.chinese', 'Chinese'),
    value: 'Chinese',
  },
  {
    label: t('search_page.language_filter_options.deutsch', 'Deutsch'),
    value: 'Deutsch',
  },
  {
    label: t('search_page.language_filter_options.english', 'English'),
    value: 'English',
  },
  {
    label: t('search_page.language_filter_options.italiano', 'Italiano'),
    value: 'Italiano',
  },
  {
    label: t('search_page.language_filter_options.nederlands', 'Nederlands'),
    value: 'Nederlands',
  },
  {
    label: t('search_page.language_filter_options.norsk', 'Norsk'),
    value: 'Norsk',
  },
  {
    label: t('search_page.language_filter_options.other_language', 'Other'),
    value: 'Other',
  },
  {
    label: t('search_page.language_filter_options.portuguese', 'Portuguese'),
    value: 'Portuguese',
  },
  {
    label: t('search_page.language_filter_options.spanish', 'Spanish'),
    value: 'Spanish',
  },
  {
    label: t('search_page.language_filter_options.suomi', 'Suomi'),
    value: 'Suomi',
  },
  {
    label: t('search_page.language_filter_options.svenska', 'Svenska'),
    value: 'Svenska',
  },
]

export const getLanguageOptions = (t: TFunction, isDev?: boolean): SelectOption[] => {
  return isDev ? getDevLanguageOptions(t) : getProdLanguageOptions(t)
}

export const getDevSubjectOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('search_page.subject_filter_options.all', 'All subjects'),
    value: ALL_SELECT_OPTION_VALUE,
  },
  {
    label: t('search_page.subject_filter_options.arts', 'Arts'),
    value: 'Arts',
  },
  {
    label: t('search_page.subject_filter_options.chemistry', 'Chemistry'),
    value: 'Chemistry',
  },
  {
    label: t('search_page.subject_filter_options.crafts', 'Crafts'),
    value: 'Crafts',
  },
  {
    label: t('search_page.subject_filter_options.drama', 'Drama'),
    value: 'Drama',
  },
  {
    label: t('search_page.subject_filter_options.english', 'English'),
    value: 'English',
  },
  {
    label: t(
      'search_page.subject_filter_options.environmental_and_natural_studies',
      'Environmental and natural studies',
    ),
    value: 'Environmental-and-natural-studies',
  },
  {
    label: t('search_page.subject_filter_options.ethics', 'Ethics'),
    value: 'Ethics',
  },
  {
    label: t('search_page.subject_filter_options.finnish', 'Finnish'),
    value: 'Finnish',
  },
  {
    label: t('search_page.subject_filter_options.french', 'French'),
    value: 'French',
  },
  {
    label: t('search_page.subject_filter_options.geography', 'Geography'),
    value: 'Geography',
  },
  {
    label: t('search_page.subject_filter_options.health_education', 'Health education'),
    value: 'Health-education',
  },
  {
    label: t('search_page.subject_filter_options.higher_education_studies', 'Higher education studies'),
    value: 'Higher-education-studies',
  },
  {
    label: t('search_page.subject_filter_options.history', 'History'),
    value: 'History',
  },
  {
    label: t('search_page.subject_filter_options.home_economics', 'Home economics'),
    value: 'Home-economics',
  },
  {
    label: t('search_page.subject_filter_options.maths', 'Maths'),
    value: 'Maths',
  },
  {
    label: t('search_page.subject_filter_options.media_studies', 'Media studies'),
    value: 'Media-studies',
  },
  {
    label: t('search_page.subject_filter_options.music', 'Music'),
    value: 'Music',
  },
  {
    label: t('search_page.subject_filter_options.other_language', 'Other language'),
    value: 'Other-language',
  },
  {
    label: t('search_page.subject_filter_options.other_subject', 'Other'),
    value: 'Other',
  },
  {
    label: t('search_page.subject_filter_options.PE', 'PE'),
    value: 'PE',
  },
  {
    label: t('search_page.subject_filter_options.philosophy', 'Philosophy'),
    value: 'Philosophy',
  },
  {
    label: t('search_page.subject_filter_options.physics', 'Physics'),
    value: 'Physics',
  },
  {
    label: t('search_page.subject_filter_options.psychology', 'Psychology'),
    value: 'Psychology',
  },
  {
    label: t('search_page.subject_filter_options.religious_education', 'Religious education'),
    value: 'Religious-Education',
  },
  {
    label: t('search_page.subject_filter_options.robotics', 'Robotics'),
    value: 'Robotics',
  },
  {
    label: t('search_page.subject_filter_options.science', 'Science'),
    value: 'Science',
  },
  {
    label: t('search_page.subject_filter_options.second', 'Second'),
    value: 'second',
  },
  {
    label: t('search_page.subject_filter_options.social_studies', 'Social studies'),
    value: 'Social-studies',
  },
  {
    label: t('search_page.subject_filter_options.spanish', 'Spanish'),
    value: 'Spanish',
  },
  {
    label: t('search_page.subject_filter_options.student_counselling', 'Student counselling'),
    value: 'Student-counselling',
  },
  {
    label: t('search_page.subject_filter_options.swedish', 'Swedish'),
    value: 'Swedish',
  },
  {
    label: t('search_page.subject_filter_options.teambuilding', 'Teambuilding'),
    value: 'Teambuilding',
  },
  {
    label: t('search_page.subject_filter_options.vocational_studies', 'Vocational studies'),
    value: 'Vocational-studies',
  },
  {
    label: t('search_page.subject_filter_options.whynot', 'whynot'),
    value: 'whynot',
  },
]

export const getProdSubjectOptions = (t: TFunction): SelectOption[] => [
  {
    label: t('search_page.subject_filter_options.all', 'All subjects'),
    value: ALL_SELECT_OPTION_VALUE,
  },
  {
    label: t('search_page.subject_filter_options.arabic', 'Arabic'),
    value: 'Arabic',
  },
  {
    label: t('search_page.subject_filter_options.arts', 'Arts'),
    value: 'Arts',
  },
  {
    label: t('search_page.subject_filter_options.biology', 'Biology'),
    value: 'Biology',
  },
  {
    label: t('search_page.subject_filter_options.chemistry', 'Chemistry'),
    value: 'Chemistry',
  },
  {
    label: t('search_page.subject_filter_options.crafts', 'Crafts'),
    value: 'Crafts',
  },
  {
    label: t('search_page.subject_filter_options.drama', 'Drama'),
    value: 'Drama',
  },
  {
    label: t('search_page.subject_filter_options.english', 'English'),
    value: 'English',
  },
  {
    label: t(
      'search_page.subject_filter_options.environmental_and_natural_studies',
      'Environmental and natural studies',
    ),
    value: 'Environmental-and-natural-studies',
  },
  {
    label: t('search_page.subject_filter_options.ethics', 'Ethics'),
    value: 'Ethics',
  },
  {
    label: t('search_page.subject_filter_options.finnish', 'Finnish'),
    value: 'Finnish',
  },
  {
    label: t('search_page.subject_filter_options.french', 'French'),
    value: 'French',
  },
  {
    label: t('search_page.subject_filter_options.geography', 'Geography'),
    value: 'Geography',
  },
  {
    label: t('search_page.subject_filter_options.german', 'German'),
    value: 'German',
  },
  {
    label: t('search_page.subject_filter_options.health_education', 'Health education'),
    value: 'Health-education',
  },
  {
    label: t('search_page.subject_filter_options.higher_education_studies', 'Higher education studies'),
    value: 'higher-education-studies',
  },
  {
    label: t('search_page.subject_filter_options.history', 'History'),
    value: 'History',
  },
  {
    label: t('search_page.subject_filter_options.home_economics', 'Home economics'),
    value: 'Home-economics',
  },
  {
    label: t('search_page.subject_filter_options.maths', 'Maths'),
    value: 'Maths',
  },
  {
    label: t('search_page.subject_filter_options.media_studies', 'Media studies'),
    value: 'media-studies',
  },
  {
    label: t('search_page.subject_filter_options.music', 'Music'),
    value: 'Music',
  },
  {
    label: t('search_page.subject_filter_options.natural_sciences', 'Natural sciences'),
    value: 'natural_sciences',
  },
  {
    label: t('search_page.subject_filter_options.other', 'Other'),
    value: 'Other',
  },
  {
    label: t('search_page.subject_filter_options.other_language', 'Other language'),
    value: 'other-language',
  },
  {
    label: t('search_page.subject_filter_options.PE', 'PE'),
    value: 'PE',
  },
  {
    label: t('search_page.subject_filter_options.philosophy', 'Philosophy'),
    value: 'Philosophy',
  },
  {
    label: t('search_page.subject_filter_options.physics', 'Physics'),
    value: 'Physics',
  },
  {
    label: t('search_page.subject_filter_options.portuguese', 'Portuguese'),
    value: 'Portuguese',
  },
  {
    label: t('search_page.subject_filter_options.psychology', 'Psychology'),
    value: 'Psychology',
  },
  {
    label: t('search_page.subject_filter_options.religious_education', 'Religious education'),
    value: 'Religious-Education',
  },
  {
    label: t('search_page.subject_filter_options.robotics', 'Robotics'),
    value: 'Robotics',
  },
  {
    label: t('search_page.subject_filter_options.second', 'Second'),
    value: 'second',
  },
  {
    label: t('search_page.subject_filter_options.social_studies', 'Social studies'),
    value: 'Social-studies',
  },
  {
    label: t('search_page.subject_filter_options.spanish', 'Spanish'),
    value: 'Spanish',
  },
  {
    label: t('search_page.subject_filter_options.student_counselling', 'Student counselling'),
    value: 'student-counselling',
  },
  {
    label: t('search_page.subject_filter_options.swedish', 'Swedish'),
    value: 'Swedish',
  },
  {
    label: t('search_page.subject_filter_options.vocational_studies', 'Vocational studies'),
    value: 'vocational-studies',
  },
]

export const getSubjectOptions = (t: TFunction, isDev?: boolean): SelectOption[] => {
  return isDev ? getDevSubjectOptions(t) : getProdSubjectOptions(t)
}
