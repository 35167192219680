import { RoundButton } from '../button/RoundButton'
import { getIcon, IconName } from '../icons/utils'

export type InputElementProps = {
  iconName: IconName
  variant: 'icon' | 'button'
  elementStyles?: React.CSSProperties
  noHoverShadow?: boolean
  iconDirection?: 'right' | 'left' | 'up' | 'down'
  onButtonClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  className?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}

export const InputElement: React.FC<InputElementProps> = ({
  variant,
  iconName,
  elementStyles,
  iconDirection,
  noHoverShadow,
  onButtonClick,
  className,
  ...props
}) => {
  if (variant === 'icon') {
    return (
      <span className={className} style={elementStyles}>
        {getIcon(iconName)}
      </span>
    )
  }

  return (
    <span className={className}>
      <RoundButton
        buttonStyle={{
          fontSize: 18,
          height: 40,
          color: 'white',
          background: 'var(--gradient-primary-normal)',
          ...elementStyles,
        }}
        iconDirection={iconDirection}
        icon={iconName}
        noHoverShadow={noHoverShadow}
        onClick={onButtonClick}
        {...props}
      />
    </span>
  )
}
