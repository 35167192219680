import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext, UserContextType } from '../contexts/userContext'
import { routes } from '../routes'

export const Protected: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const { user, loginState } = useContext(UserContext) as UserContextType
  const [waitCounter, setWaitCounter] = useState(0)

  useEffect(() => {
    if (user == null) {
      if (loginState === 'PENDING') {
        if (waitCounter > 4) {
          navigate(routes.login)
        } else {
          setTimeout(() => {
            setWaitCounter(waitCounter + 1)
          }, 500)
        }
      } else if (loginState === 'LOGGED_OUT') {
        navigate(routes.login)
      }
    } else if (!user.termsAccepted) {
      navigate(routes.onboardingWizard)
    }
  }, [loginState, navigate, user, waitCounter])

  return <>{children}</>
}
