import React from 'react'

import { ToggleFormField } from '../../../../../common/components/Form/ToggleFormField/ToggleFormField'
import styles from './GameSettingsComponents.module.css'
import { useField } from 'react-final-form'
import classNames from 'classnames'

type GameSettingsToggleProps = {
  name: string
  label: string
  description: string
  disabled?: boolean
}

export const GameSettingsToggle: React.FC<GameSettingsToggleProps> = ({ name, label, description, disabled }) => {
  const { input } = useField(name)

  return (
    <div className={styles.toggleSwitchContainer}>
      <ToggleFormField
        name={name}
        disabled={disabled}
        label={
          <div>
            <span
              className={
                input.value
                  ? classNames(styles.toggleSwitchLabel, styles.toggleSwitchLabelEnabled)
                  : styles.toggleSwitchLabel
              }
            >
              {label}
            </span>
            <span className={styles.toggleSwitchDescription}>{description}</span>
          </div>
        }
      />
    </div>
  )
}
