import classNames from 'classnames'
import { useCallback } from 'react'
import { getIcon } from '../../../../../common/components/icons/utils'
import { ModalWrapper } from '../../../../../common/components/ModalWrapper/ModalWrapper'
import { useDisableBodyScroll } from '../../../../../hooks/useDisableBodyScroll'
import { useGetTask } from '../../../../../hooks/useGetTask'
import { useTrapFocus } from '../../../../../hooks/useTrapFocus'
import { DeepPartial, FormErrorType, Task } from '../../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../../util/string'
import { TaskForm } from './TaskForm'
import styles from './TaskModal.module.css'
import { TaskLoaderSection } from './TaskLoaderSection'

type TaskModalProps = {
  gameId: number | null
  taskId: number | null
  onClose: () => void
}

export const TaskModal: React.FC<TaskModalProps> = ({ gameId, taskId, onClose }) => {
  const { loading, data } = useGetTask(gameId, taskId)

  const modalRef = useTrapFocus<HTMLDivElement>()
  useDisableBodyScroll()

  const onSubmit = useCallback((values: Task) => {
    alert(JSON.stringify(values, undefined, 2))
  }, [])

  const validate = useCallback((values: DeepPartial<Task>): FormErrorType<Task> => {
    const errors: FormErrorType<Task> = {
      subtasks: [],
    }
    if (safeIsNullOrEmpty(values.name)) {
      errors.name = 'Required'
    }
    values?.subtasks?.forEach((subtask) => {
      errors.subtasks?.push({
        description: safeIsNullOrEmpty(subtask?.description) ? 'Required' : undefined,
        creative: {
          answerOptions: !subtask?.creative?.answerOptions?.length ? 'Choose at least one' : undefined,
          points: safeIsNullOrEmpty(subtask?.creative?.points) ? 'Required' : undefined,
        },
      })
    })
    return errors
  }, [])

  return (
    <ModalWrapper>
      <div className={styles.backdrop} />
      <div className={styles.taskModalContainer} ref={modalRef}>
        <div className={classNames(styles.taskModalHeader, styles.taskModalSection)}>
          <div className={styles.taskModalHeaderTitle}>
            <span className={styles.taskModalHeaderIcon}>{getIcon('exercisePin')}</span>
            <span>Task settings</span>
          </div>
          <button className={styles.smallActionButton} onClick={onClose} autoFocus>
            {getIcon('close')}
          </button>
        </div>
        {loading ? (
          <TaskLoaderSection />
        ) : (
          <TaskForm initialValues={data ?? {}} onSubmit={onSubmit} onCancel={onClose} validate={validate} />
        )}
      </div>
    </ModalWrapper>
  )
}
