import classNames from 'classnames'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { Button } from '../../common/components/button/Button'
import { useTheme } from '../../contexts/ThemeContext'
import { useTrapFocus } from '../../hooks/useTrapFocus'
import styles from './Wizard.module.css'
import { StepProgress } from './components/StepProgress'

export type WizardProps = {
  mainButtonText?: string
  hasHeaderButton?: boolean
  hasBackButton?: boolean
  steps: JSX.Element[]
  stepIndex: number
  hideControls?: boolean
  backdropVisible?: boolean
  isLarge?: boolean
  loading?: boolean
  onClickContinue: () => void
  onClickBack?: () => void
  onClickSkip?: () => void
}

export const Wizard: React.FC<WizardProps> = ({
  mainButtonText,
  hasHeaderButton = true,
  hasBackButton = false,
  steps = [],
  stepIndex = 1,
  hideControls = false,
  backdropVisible = true,
  isLarge = false,
  loading,
  onClickContinue,
  onClickBack = () => {},
  onClickSkip = () => {},
}) => {
  const { t } = useTranslation()
  const modalRef = useTrapFocus<HTMLDivElement>()
  const { logoUrl } = useTheme()

  return (
    <>
      {backdropVisible && <div className={styles.backdrop} />}
      <div ref={modalRef} className={classNames(styles.container, isLarge && styles.largeContainer)}>
        {!hideControls && (
          <div className={styles.header}>
            <span style={{ display: 'flex', gap: '1rem' }}>
              {hasHeaderButton && stepIndex === 1 && (
                <Button
                  styleVariant={'borderless'}
                  label={t('wizard.skip', 'Skip')}
                  sizeVariant={'small'}
                  onClick={onClickSkip}
                />
              )}
              {hasBackButton && stepIndex > 1 && (
                <Button
                  styleVariant={'borderless'}
                  label={t('wizard.back', 'Back')}
                  sizeVariant={'small'}
                  onClick={onClickBack}
                />
              )}
            </span>
            <img
              src={logoUrl}
              alt='logo'
              className={classNames(styles.seppoLogo, !hasHeaderButton && styles.noHeaderButtonImageSpacing)}
            />
          </div>
        )}
        <div className={styles.content}>{steps[stepIndex - 1]}</div>
        {!hideControls && (
          <>
            <div className={styles.footer}>
              <Button
                styleVariant={'primary'}
                label={mainButtonText ?? t('wizard.continue', 'Continue')}
                sizeVariant={'large'}
                onClick={onClickContinue}
                disabled={loading}
              />
              <p className={'medium'}>
                {t('wizard.step', 'Step')} {stepIndex} / {steps.length}
              </p>
            </div>
            <span className={styles.progressbar}>
              <StepProgress percent={(1 / steps.length) * stepIndex} />
            </span>
          </>
        )}
      </div>
    </>
  )
}
