import { useCallback, useState } from 'react'
import { ModalWrapper } from '../../common/components/ModalWrapper/ModalWrapper'
import { ToggleSwitch } from '../../common/components/ToggleSwitch/ToggleSwitch'
import { Button } from '../../common/components/button/Button'
import { getIcon } from '../../common/components/icons/utils'
import { InputField } from '../../common/components/input/InputField'
import { useDebug } from '../../contexts/DebugContext'
import { DEFAULT_THEME_SETTINGS, useTheme } from '../../contexts/ThemeContext'
import { useUser } from '../../contexts/userContext'
import { TUser } from '../../types/commonTypes'
import { getRandomHex } from '../../util/colors'
import { getUserActiveDays } from '../WelcomeBlock/helpers'
import styles from './Debug.module.css'

type DebugModalProps = {
  onClose: () => void
}

export const DebugModal: React.FC<DebugModalProps> = ({ onClose }) => {
  const { colorPrimary, colorSecondary, updateThemeSettings } = useTheme()
  const { user, DEVupdateUser, refreshUser } = useUser()
  const { useNewEditor, toggleUseNewEditor } = useDebug()

  const [customUrl, setCustomUrl] = useState<string>('')
  const [daysActiveInput, setDaysActiveInput] = useState<string>(
    getUserActiveDays(user?.createdAt ?? new Date().toISOString()).toFixed(2),
  )

  const handleCustomUrlInputChange = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
    setCustomUrl(e.currentTarget.value)
  }, [])

  const handleCustomUrlSubmit = useCallback(() => {
    updateThemeSettings({ logoUrl: customUrl })
    setCustomUrl('')
  }, [updateThemeSettings, customUrl])

  const handleChangeCreatedAt = () => {
    const daysActiveNumber = Number.parseFloat(Number.parseFloat(daysActiveInput).toFixed(2))
    if (isNaN(daysActiveNumber)) {
      alert('Days active should be a nice number!')
    } else {
      const daysActiveMs = daysActiveNumber * 24 * 60 * 60 * 1000
      const newCreatedAtMs = new Date().getTime() - daysActiveMs
      DEVupdateUser({ createdAt: new Date(newCreatedAtMs).toISOString() })
    }
  }

  const handleChangeUserAttribute = (key: keyof TUser) => () => {
    if (user != null) {
      DEVupdateUser({ [key]: !user[key] })
    }
  }

  return (
    <ModalWrapper>
      <div className={styles.backdrop} />
      <div className={styles.modalContainer}>
        <div className={styles.col}>
          <button className={styles.closeButton} onClick={onClose}>
            {getIcon('close')}
          </button>

          <h5>Theme primary color</h5>
          <div className={styles.colorPickingContainer}>
            <div>
              <input
                type='color'
                id='customColorPrimary'
                name='customColorPrimary'
                value={colorPrimary}
                onChange={(e: any) => updateThemeSettings({ colorPrimary: e.target.value })}
              />
              <label htmlFor='customColorPrimary' style={{ color: 'var(--primary-normal)' }}>
                pick
              </label>
            </div>
            <div className={styles.colorPreviewContainer}>
              <div style={{ backgroundColor: 'var(--primary-background)' }} />
              <div style={{ backgroundColor: 'var(--primary-light)' }} />
              <div style={{ backgroundColor: 'var(--primary-normal)' }} />
              <div style={{ backgroundColor: 'var(--primary-dark)' }} />
            </div>
          </div>
          <Button
            styleVariant='secondary'
            label='random color'
            onClick={() => updateThemeSettings({ colorPrimary: getRandomHex() })}
            sizeVariant='tiny'
          />
          <Button
            styleVariant='tertiary'
            label='reset color'
            onClick={() =>
              updateThemeSettings({ colorPrimary: user?.theme?.themeMainColor || DEFAULT_THEME_SETTINGS.colorPrimary })
            }
            sizeVariant='tiny'
          />

          <h5>Theme secondary color</h5>
          <div className={styles.colorPickingContainer}>
            <div>
              <input
                type='color'
                id='customColorSecondary'
                name='customColorSecondary'
                value={colorSecondary}
                onChange={(e: any) => updateThemeSettings({ colorSecondary: e.target.value })}
              />
              <label htmlFor='customColorSecondary' style={{ color: 'var(--secondary-normal)' }}>
                pick
              </label>
            </div>
            <div className={styles.colorPreviewContainer}>
              <div style={{ backgroundColor: 'var(--secondary-background)' }} />
              <div style={{ backgroundColor: 'var(--secondary-light)' }} />
              <div style={{ backgroundColor: 'var(--secondary-normal)' }} />
              <div style={{ backgroundColor: 'var(--secondary-dark)' }} />
            </div>
          </div>
          <Button
            styleVariant='community'
            label='random color'
            onClick={() => updateThemeSettings({ colorSecondary: getRandomHex() })}
            sizeVariant='tiny'
          />
          <Button
            styleVariant='community-outline'
            label='reset color'
            onClick={() =>
              updateThemeSettings({
                colorSecondary: user?.theme?.themeSecondaryColor || DEFAULT_THEME_SETTINGS.colorSecondary,
              })
            }
            sizeVariant='tiny'
          />

          <h5>Theme logo</h5>
          <InputField
            placeholder='custom logo url'
            value={customUrl}
            onChange={handleCustomUrlInputChange}
            rightElement={{
              variant: 'button',
              iconName: 'arrowRight',
              onButtonClick: handleCustomUrlSubmit,
            }}
          />
          <Button
            styleVariant='secondary'
            label='random image logo'
            onClick={() => updateThemeSettings({ logoUrl: 'https://picsum.photos/200/100' })}
            sizeVariant='tiny'
          />
          <Button
            styleVariant='secondary'
            label='place kitten logo'
            onClick={() => updateThemeSettings({ logoUrl: 'https://placekitten.com/150/50' })}
            sizeVariant='tiny'
          />
          <Button
            styleVariant='tertiary'
            label='local seppo logo'
            onClick={() =>
              updateThemeSettings({ logoUrl: user?.theme?.themeLogoUrl || DEFAULT_THEME_SETTINGS.logoUrl })
            }
            sizeVariant='tiny'
          />

          <h5>Theme favicon</h5>
          <Button
            styleVariant='secondary'
            label='random image favicon'
            onClick={() => updateThemeSettings({ faviconUrl: 'https://picsum.photos/20' })}
            sizeVariant='tiny'
          />
          <Button
            styleVariant='tertiary'
            label='local seppo favicon'
            onClick={() => updateThemeSettings({ faviconUrl: DEFAULT_THEME_SETTINGS.faviconUrl })}
            sizeVariant='tiny'
          />
          <div style={{ minHeight: 20 }}/>
        </div>

        <div>
          <div
            style={{
              backgroundColor: 'white',
              padding: '1rem',
              borderRadius: 9,
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
            }}
          >
            <h4 style={{ marginBottom: '1rem' }}>Change user account age</h4>
            <p style={{ marginBottom: '2rem' }}>Set number of active days, decimals are allowed</p>
            <div style={{ display: 'flex', gap: 8 }}>
              <input
                style={{ width: '6rem', height: 40 }}
                type='number'
                value={daysActiveInput}
                min={0}
                onChange={(e) => setDaysActiveInput(e.target.value)}
              />
              <Button styleVariant='tertiary' label='Set days' onClick={handleChangeCreatedAt} sizeVariant='tiny' />
              <Button styleVariant='tertiary' label='Refresh user' onClick={refreshUser} sizeVariant='tiny' />
            </div>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <p>Paying</p>
              <ToggleSwitch checked={user?.isTrial} onChange={handleChangeUserAttribute('isTrial')} />
              <p>Trial</p>
            </div>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <p>Corporate</p>
              <ToggleSwitch checked={user?.isEducation} onChange={handleChangeUserAttribute('isEducation')} />
              <p>Education</p>
            </div>

            <h4 style={{ margin: '32px 0' }}>Do you want to use the new game editor?</h4>
            <ToggleSwitch checked={useNewEditor} onChange={toggleUseNewEditor} />
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}
