import classNames from 'classnames'
import DOMPurify from 'dompurify'
import React, { useState } from 'react'
import { getTitleForTaskType } from '../../../pages/GameEditor/components/Tasks/TaskModal/helpers'
import { TaskPreview } from '../../../types/commonTypes'
import { EMPTY_CONTENT_FALLBACK } from '../../constants'
import { getIcon } from '../icons/utils'
import styles from './ExerciseAccordionItem.module.css'
import { TypeAndPoints } from './TypeAndPoints'

type ExerciseAccordionItemProps = {
  task: TaskPreview
}

export const ExerciseAccordionItem: React.FC<ExerciseAccordionItemProps> = ({ task }) => {
  const { name, pointsSum, subtasks } = task
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenClose = () => {
    setIsOpen((prevState) => !prevState)
  }

  const isMultiple = subtasks.length > 1

  return (
    <div className={classNames(styles.accordionContainer, isOpen && styles.accordionContainer__open)}>
      <button className={classNames(styles.accordionHeader)} onClick={handleOpenClose}>
        <span className={styles.exercisePin}>{getIcon('exercisePin')}</span>
        <div className={styles.contentContainer}>
          <p className={styles.exerciseName}>{name}</p>
          <TypeAndPoints
            title={isMultiple ? 'Multiple' : getTitleForTaskType(subtasks[0].type ?? '')}
            points={pointsSum}
          />
        </div>
        <span className={classNames(styles.chevron, isOpen ? styles.chevronOpen : styles.chevronClosed)}>
          {getIcon('chevron')}
        </span>
      </button>
      {isOpen && (
        <div className={styles.accordionContent}>
          {subtasks.map((subtask, index) => (
            <div key={`subtask:${subtask.type}:${index}`} className={styles.subtaskContainer}>
              {isMultiple && index > 0 && <div className={styles.subtasksDivider} />}
              {isMultiple && (
                <TypeAndPoints
                  title={getTitleForTaskType(subtask.type ?? '')}
                  points={subtask.points ?? 0}
                  className={styles.multipleSubtasksItem}
                />
              )}
              <p
                className={styles.exerciseDescription}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(subtask.descriptionHtml || EMPTY_CONTENT_FALLBACK, {
                    FORBID_TAGS: ['button'],
                    KEEP_CONTENT: false,
                  }),
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
