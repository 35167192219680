import React from 'react'

import {
  CreatableMultiSelectFormField,
  SelectFormField,
} from '../../../../../common/components/Form/SelectFormField/SelectFormField'
import { getIcon } from '../../../../../common/components/icons/utils'
import styles from './GameSettingsComponents.module.css'
import { GameSettingsToggle } from './GameSettingsToggle'
import { useTranslation } from 'react-i18next'

export const Notifications: React.FC = () => {
  const { t } = useTranslation()

  const CHAT_MESSAGES_OPTIONS = [
    { value: '0', label: t('game_settings.notifications.options.messages.no_notifications', 'No notifications') },
    {
      value: '1',
      label: t('game_settings.notifications.options.messages.notify_instructors', 'Messages sent to instructors'),
    },
    { value: '2', label: t('game_settings.notifications.options.messages.all_messages', 'All messages') },
  ]

  const TASKS_NOTIFICATIONS_OPTIONS = [
    { value: '0', label: t('game_settings.notifications.options.tasks.no_notifications', 'No notifications') },
    { value: '1', label: t('game_settings.notifications.options.tasks.waiting_grading', 'Tasks waiting grading') },
    { value: '2', label: t('game_settings.notifications.options.tasks.all_tasks', 'All tasks') },
  ]

  return (
    <>
      <span className={styles.toggleSwitchDescription}>
        {t(
          'game_settings.notifications.description',
          'Enable email notifications. You and your collaborators will be notified about events occurring in the game.',
        )}
      </span>
      <div className={styles.dropdownContainer}>
        <div className={styles.dropdownColumn}>
          <div style={{ minWidth: '20rem' }}>
            <SelectFormField
              name='messages'
              label={
                <label className={styles.dropdownLabel}>
                  <span className={styles.dropdownLabelIcon}>{getIcon('chatUnread')}</span>
                  {t('game_settings.notifications.chat_messages', 'Chat messages')}
                </label>
              }
              placeholder={t('game_settings.notifications.set_chat_notifications', 'Set chat notifications')}
              options={CHAT_MESSAGES_OPTIONS}
            />
          </div>
        </div>

        <div className={styles.dropdownColumn}>
          <div style={{ minWidth: '20rem' }}>
            <SelectFormField
              name='answers'
              label={
                <label className={styles.dropdownLabel}>
                  <span className={styles.dropdownLabelIcon}>{getIcon('pinFilled')}</span>
                  {t('game_settings.notifications.tasks', 'Tasks')}
                </label>
              }
              placeholder={t('game_settings.notifications.set_task_notifications', 'Set task notifications')}
              options={TASKS_NOTIFICATIONS_OPTIONS}
            />
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>{t('game_settings.notifications.frequency', 'Frequency')}</span>
        <GameSettingsToggle
          name='instantEnabled'
          label={t(
            'game_settings.notifications.all_messages_and_answers',
            'Send notification for every message and answer',
          )}
          description={t(
            'game_settings.notifications.all_messages_and_answers_desc',
            'You will be notified of every submission. This might cause a lot of emails.',
          )}
        />
        <GameSettingsToggle
          name='summaryEnabled'
          label={t('game_settings.notifications.daily_summary', 'Send a daily summary')}
          description={t(
            'game_settings.notifications.daily_summary_desc',
            'This will generate a daily email that reports what players have submitted.',
          )}
        />
      </div>
      <div className={styles.inputContainer}>
        <CreatableMultiSelectFormField
          label={
            <>
              <span className={styles.sectionTitle}>{t('game_settings.notifications.recipients', 'Recipients')}</span>
              <span className={styles.sectionDescription}>
                {t('game_settings.notifications.recipients_desc', 'Separate addresses with a coma')}
              </span>
            </>
          }
          name='emails'
          placeholder={t('game_settings.notifications.type_email_here', 'Type email addresses here')}
        />
      </div>
    </>
  )
}
