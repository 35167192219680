// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FadeAnimations_initial__qtVt5 {\n  opacity: 0;\n}\n\n.FadeAnimations_fadeIn__yumOA {\n  opacity: 1;\n  transition: opacity 0.35s;\n}\n\n.FadeAnimations_fadeOut__22uur {\n  opacity: 0;\n  transition: opacity 0.35s;\n}\n\n.FadeAnimations_fadeOutPartial__PgD3N {\n  opacity: 0.3;\n  transition: opacity 0.35s;\n}\n", "",{"version":3,"sources":["webpack://./src/hooks/FadeAnimation/FadeAnimations.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".initial {\n  opacity: 0;\n}\n\n.fadeIn {\n  opacity: 1;\n  transition: opacity 0.35s;\n}\n\n.fadeOut {\n  opacity: 0;\n  transition: opacity 0.35s;\n}\n\n.fadeOutPartial {\n  opacity: 0.3;\n  transition: opacity 0.35s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initial": "FadeAnimations_initial__qtVt5",
	"fadeIn": "FadeAnimations_fadeIn__yumOA",
	"fadeOut": "FadeAnimations_fadeOut__22uur",
	"fadeOutPartial": "FadeAnimations_fadeOutPartial__PgD3N"
};
export default ___CSS_LOADER_EXPORT___;
