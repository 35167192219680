import classNames from 'classnames'
import React from 'react'

import { getIcon, IconName } from '../icons/utils'
import styles from './Button.module.css'

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  /**
   * Is this the principal call to action on the page?
   */
  styleVariant?: 'primary' | 'secondary' | 'tertiary' | 'borderless' | 'community' | 'community-outline'
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * How large should the button be?
   */
  sizeVariant?: 'tiny' | 'small' | 'medium' | 'large'
  /**
   * Full width button
   */
  wide?: boolean
  /**
   * Button contents
   */
  label: string
  /**
   * Icon displayed before the label
   */
  iconLeft?: IconName
  /**
   * Icon displayed after the label
   */
  iconRight?: IconName
  /**
   * Container styles of iconLeft component
   */
  iconLeftContainerStyle?: React.CSSProperties
  /**
   * Container styles of iconRight component
   */
  iconRightContainerStyle?: React.CSSProperties
  /**
   * Button style overrides
   */
  buttonStyles?: React.CSSProperties
  /**
   * Disables hover background change
   */
  noHoverBg?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      styleVariant = 'primary',
      sizeVariant = 'medium',
      backgroundColor = '',
      label,
      wide = false,
      iconLeft,
      iconRight,
      disabled = false,
      noHoverBg = false,
      iconLeftContainerStyle,
      iconRightContainerStyle,
      buttonStyles = {},
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const iconLeftSelector = React.useMemo(() => {
      if (iconLeft == null) {
        return null
      }
      return getIcon(iconLeft)
    }, [iconLeft])
    const iconRightSelector = React.useMemo(() => {
      if (iconRight == null) {
        return null
      }
      return getIcon(iconRight)
    }, [iconRight])

    return (
      <button
        ref={ref}
        disabled={disabled}
        className={classNames(
          styles.button,
          styles[`button_${sizeVariant}`],
          styles[`button_${styleVariant}`],
          wide ? styles.button_fullwidth : '',
          disabled ? styles[`button_${styleVariant}_disabled`] : '',
          noHoverBg && styles.noHoverBg,
        )}
        style={{ backgroundColor, ...buttonStyles }}
        type={type as 'button' | 'reset' | 'submit' | undefined}
        {...props}
      >
        {!!iconLeft ? (
          <span className={styles.button_icon_left} style={iconLeftContainerStyle}>
            {iconLeftSelector}
          </span>
        ) : null}
        {label}
        {!!iconRight ? (
          <span className={styles.button_icon_right} style={iconRightContainerStyle}>
            {iconRightSelector}
          </span>
        ) : null}
      </button>
    )
  },
)
