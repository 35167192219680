import classNames from 'classnames'
import React from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { LibrarySource } from '../../../api/gameTypes'
import { StatusPill } from '../../../common/components/StatusPill/StatusPill'
import { GameInfo } from '../../../common/components/card/components/GameInfo'
import { MoreIcon, PeopleIcon, RecentsIcon } from '../../../common/components/icons'
import shared from '../../../common/styles/shared.module.css'
import { ContextMenu } from '../../../composites/ContextMenu/ContextMenu'
import { usePositionedMenu } from '../../../hooks/usePositionedMenu'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import { getDisplayGameOwnersLines } from '../../../util/game'
import { getFocusableGameItemId } from '../helpers'
import styles from './GamesListItem.module.css'

type ListItemProps = {
  game: TGameCard
  onGameAction: (action: GameAction, game: TGameCard) => void
}

export const GameListItem: React.FC<ListItemProps> = ({ game, onGameAction }) => {
  const {
    id,
    modifiedAt,
    gameName,
    gameOwners,
    status,
    exercisesCount,
    secondaryStatus,
    playersCount,
    mapsCount,
    archivedAt,
  } = game
  const [isContextMenuOpen, setIsContextMenuOpen] = React.useState(false)

  const menuButtonId = getFocusableGameItemId(id)

  const { anchorElementRef, isMenuPositioned, menuCoordinates, menuElementRef } =
    usePositionedMenu<HTMLDivElement>(isContextMenuOpen)

  useOnClickOutside(menuElementRef, (e) => {
    if (
      (e.target as EventTarget & { getAttribute: (attr: string) => string })?.getAttribute?.('id') === menuButtonId ||
      anchorElementRef.current?.contains(e.target as any)
    ) {
      return
    }
    setIsContextMenuOpen(false)
  })

  const toggleMenu = () => {
    setIsContextMenuOpen((prev) => !prev)
  }

  const handleMenuItemClick = (action: GameAction) => {
    setIsContextMenuOpen(false)
    onGameAction(action, game)
  }

  return (
    <tr>
      <td>
        <h5 className={styles.gameName}>{gameName}</h5>
      </td>
      <td>
        <StatusPill status={status} secondaryStatus={secondaryStatus} />
      </td>
      <td>
        <div className={styles.usersContainer}>
          <PeopleIcon fontSize={16} />
          <p className={classNames('tiny', shared.ml_075)}>
            {getDisplayGameOwnersLines(gameOwners).map((owner, i) => (
              <span key={`game-owner_${owner}:${i}`}>{owner}</span>
            ))}
          </p>
        </div>
      </td>
      <td>
        <div className={styles.lastModifiedContainer}>
          <RecentsIcon fontSize={16} />
          <p className={classNames('tiny', shared.ml_075)}>{modifiedAt}</p>
        </div>
      </td>
      <td>
        <div className={styles.infoContainer}>
          <span className={styles.infoItem}>
            <GameInfo
              status={status}
              showModifiedAt={false}
              exercisesNumber={exercisesCount}
              mapsNumber={mapsCount}
              playersCount={playersCount}
              archivedAt={archivedAt}
            />
          </span>
        </div>
      </td>
      <td>
        <div ref={anchorElementRef} className={classNames(styles.optionsContainer, styles.anchorRelative)}>
          <button id={menuButtonId} type='button' className={styles.contextMenuButton} onClick={toggleMenu}>
            <MoreIcon />
          </button>
          <ContextMenu
            ref={menuElementRef}
            anchorX={menuCoordinates?.x}
            anchorY={menuCoordinates?.y}
            isOpen={isContextMenuOpen}
            isPositioned={isMenuPositioned}
            onMenuItemClick={handleMenuItemClick}
            libraryMode={game.librarySource !== LibrarySource.OWN}
          />
        </div>
      </td>
    </tr>
  )
}
