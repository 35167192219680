// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoPill_infoPill__z1Y6A {\n  border-radius: 100px;\n  padding: 2px 8px;\n  text-transform: uppercase;\n  font-weight: var(--bold);\n  transition: all 0.2s;\n  white-space: nowrap;\n}\n\n.InfoPill_infoPill_primary__Ur2dM {\n  background-color: var(--primary-background);\n}\n.InfoPill_infoPill_primary__Ur2dM:hover {\n  background-color: var(--primary-normal);\n  color: var(--primary-background);\n}\n\n.InfoPill_infoPill_community__oy-ZJ {\n  background-color: var(--secondary-background);\n}\n.InfoPill_infoPill_community__oy-ZJ:hover {\n  background-color: var(--secondary-normal);\n  color: var(--secondary-background);\n}\n", "",{"version":3,"sources":["webpack://./src/composites/GamePreviewModal/InfoPill.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,yBAAyB;EACzB,wBAAwB;EACxB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;AAC7C;AACA;EACE,uCAAuC;EACvC,gCAAgC;AAClC;;AAEA;EACE,6CAA6C;AAC/C;AACA;EACE,yCAAyC;EACzC,kCAAkC;AACpC","sourcesContent":[".infoPill {\n  border-radius: 100px;\n  padding: 2px 8px;\n  text-transform: uppercase;\n  font-weight: var(--bold);\n  transition: all 0.2s;\n  white-space: nowrap;\n}\n\n.infoPill_primary {\n  background-color: var(--primary-background);\n}\n.infoPill_primary:hover {\n  background-color: var(--primary-normal);\n  color: var(--primary-background);\n}\n\n.infoPill_community {\n  background-color: var(--secondary-background);\n}\n.infoPill_community:hover {\n  background-color: var(--secondary-normal);\n  color: var(--secondary-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoPill": "InfoPill_infoPill__z1Y6A",
	"infoPill_primary": "InfoPill_infoPill_primary__Ur2dM",
	"infoPill_community": "InfoPill_infoPill_community__oy-ZJ"
};
export default ___CSS_LOADER_EXPORT___;
