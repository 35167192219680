export const pluralize = (count: number, singular: string, plural: string): string => {
  return `${count} ${count === 1 ? singular : plural}`
}

export const isNullOrEmpty = (value: string): boolean => {
  return value == null || value?.trim()?.length === 0
}

export const safeIsNullOrEmpty = (value: unknown): boolean => {
  return typeof value === 'string' ? isNullOrEmpty(value) : value == null
}

export const capitalizeFirstLetter = (text: string): string => {
  if (safeIsNullOrEmpty(text)) {
    return ''
  }

  return text[0].toUpperCase() + text.slice(1).toLowerCase()
}

export const padNumWithZero = (num: number, length: number) => {
  // optional chaining + fallback for browsers that don't support padStart
  return num.toString()?.padStart?.(length, '0') ?? num.toString()
}

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)

export const dateTimeToLocalDateString = (dateTime: string | undefined, locale: string = 'fi-FI'): string => {
  if (dateTime == null) {
    return ''
  }
  return new Date(dateTime).toLocaleDateString(locale)
}
