import { Business, TUser } from '../../types/commonTypes'

export const ifUserHasMultipleBusinesses = (businesses: Business[] | undefined): boolean => {
  if (!businesses) {
    return false
  }

  return businesses.length > 1
}

export const generateUserInitials = (name: string | undefined): string => {
  const EMAIL_WITH_DOT_DELIMITER = /^([A-Za-z]+)\.([A-Za-z]+)@.*/

  if (!name) {
    return ''
  }

  if (name.includes('@')) {
    const match = name.match(EMAIL_WITH_DOT_DELIMITER)

    if (match) {
      return (match[1][0] + match[2][0]).toLocaleUpperCase()
    }

    return name![0].toLocaleUpperCase()
  }

  const nameElements = name!.split(' ').map((value) => value[0])
  return (nameElements[0] + nameElements.slice(-1)[0]).toLocaleUpperCase()
}

export const getActiveBusiness = (user: TUser): Business => {
  const activeBusinessId = getActiveBusinessId(user)

  if (!activeBusinessId) {
    return user.businesses[0]
  }

  const matchingBusinesses = user.businesses.filter((business) => {
    return business.id === activeBusinessId
  })

  return matchingBusinesses[0] ?? null
}

export const getActiveBusinessId = (user: TUser): number => {
  // TODO: Had to do this because of the data that is received from the backend:
  // once it is the entire business object and after the update it is only the businessId string
  return user?.activeBusiness.id ? user?.activeBusiness.id : Number(user?.activeBusiness)
}
