import { IconName } from '../../common/components/icons/utils'
import { routes } from '../../routes'
import { TUser } from '../../types/commonTypes'
import { openNinchatForm } from '../../util/ninchat'
import { CorporatePaying, CorporateTrial, EducationPaying, EducationTrial } from './constants'

export type EntityMessages = {
  id: string
  title: string
  body: string
  primaryButton?: string
  secondaryButton?: string
}

const oneDayInMs = 86400000

export const getUserActiveDays = (createdAt: string) => {
  const createdAtTimeInMs = new Date(createdAt.toString()).getTime()
  const currentTimeInMs = new Date().getTime()
  return (currentTimeInMs - createdAtTimeInMs) / oneDayInMs
}

export const hasTrialPeriodExpired = (createdAt: string) => {
  return getUserActiveDays(createdAt) >= 15
}

export const messageSelector = ({ createdAt, isEducation, isTrial }: TUser) => {
  // If industry type is NOT Education - show Corporate
  // (there is a different industry type - show as corporate)
  // If user is NOT Trial - show Paying
  // (there is a DEMO user type - we show it as paying)
  const messagesEntity = isTrial
    ? isEducation
      ? EducationTrial
      : CorporateTrial
    : isEducation
    ? EducationPaying
    : CorporatePaying

  const activeDays = getUserActiveDays(createdAt)
  if (activeDays < 2) {
    return messagesEntity[1]
  } else if (activeDays < 4) {
    return messagesEntity[2]
  } else if (activeDays < 6) {
    return messagesEntity[4]
  } else if (activeDays < 8) {
    return messagesEntity[6]
  } else if (activeDays < 10) {
    return messagesEntity[8]
  } else if (activeDays < 12) {
    return messagesEntity[10]
  } else if (activeDays < 14 || (!isTrial && activeDays < 22)) {
    // Paying users see message from day 12 for 10 days
    return messagesEntity[12]
  } else if (isTrial && activeDays < 15) {
    return messagesEntity[14]
  } else if (isTrial) {
    return messagesEntity[15]
  }
}

export const isMessageHidden = (id: string, stored: string[]) => {
  return stored.some((el) => el === id)
}

export const hideDistinctMessage = (
  id: string,
  storedValue: string[] | null,
  storeValue: (value: string[]) => void,
) => {
  let stored: string[] = []
  if (storedValue != null) {
    stored = [...storedValue, id]
    storeValue(stored)
  } else {
    storeValue([id])
  }
}

export const getPrimaryLink = ({ createdAt, language }: TUser) => {
  const activeDays = getUserActiveDays(createdAt)
  if (activeDays >= 4 && activeDays < 6) {
    return language === 'fi'
      ? 'https://seppo.io/fi/blogi/miten-antaa-palautetta-oppimispeleissa/'
      : 'https://seppo.io/blog/giving-feedback/'
  } else if (activeDays < 8) {
    return 'https://seppo.pipedrive.com/scheduler/PyPPktM/meet-seppo'
  } else if (activeDays >= 12 && activeDays < 14) {
    return language === 'fi' ? 'https://seppo.io/fi/asiakkaiden-tarinat/' : 'https://seppo.io/customer-references/'
  } else if (activeDays < 15) {
    return 'https://play.seppo.io/shop'
  }
}

export const pinkBubbleIconSelector = ({ createdAt }: TUser): IconName[] => {
  const activeDays = getUserActiveDays(createdAt)
  if (activeDays < 2) {
    return ['communityGradient']
  } else if (activeDays < 4) {
    return ['taskMultipleChoiceGradient', 'taskCreativeGradient']
  } else if (activeDays < 6) {
    return ['circleCheckmarkGradient']
  } else if (activeDays < 8) {
    return ['communityGradient']
  } else if (activeDays < 10) {
    return ['reportsGradient']
  } else if (activeDays < 12) {
    return ['levelGradient']
  } else if (activeDays < 14) {
    return ['receiptGradient']
  } else if (activeDays >= 14) {
    return ['chatUnreadGradient']
  }
  return []
}

export const primaryActionSelector = ({ createdAt, isCorporate, isEducation, isTrial }: TUser): string => {
  const activeDays = getUserActiveDays(createdAt)
  const isCorpPaying = isCorporate && !isTrial
  if (activeDays < 2) {
    if (isEducation) {
      return routes.community
    }
    if (isCorporate) {
      return routes.templates
    }
  } else if (activeDays < 4) {
    return 'help-menu'
  } else if (activeDays >= 6 && activeDays < 8) {
    if (isEducation) {
      return routes.community
    }
    if (isCorpPaying) {
      return routes.templates
    }
  }
  return ''
}

export const secondaryActionSelector = ({ createdAt, isTrial, isCorporate }: TUser) => {
  const isCorpTrial = isCorporate && isTrial
  const userActiveDays = getUserActiveDays(createdAt)
  if (isCorpTrial) {
    if ((userActiveDays >= 6 && userActiveDays < 8) || userActiveDays >= 14) {
      openNinchatForm()
    }
  }
}
