import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTaskMultipleChoiceGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <linearGradient
      id='task_multiple_choice_gradient_icon_svg__b'
      x1={16.001}
      x2={16.001}
      y1={0}
      y2={32}
      gradientUnits='userSpaceOnUse'
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <clipPath id='task_multiple_choice_gradient_icon_svg__a'>
      <path d='M0 0h32v32H0z' />
    </clipPath>
    <g clipPath='url(#task_multiple_choice_gradient_icon_svg__a)'>
      <path
        fill='currentColor'
        d='M21.814 4.58a1 1 0 1 0-1.628-1.163l-4.314 6.04-3.165-3.165a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.52-.126z'
      />
      <path
        fill='url(#task_multiple_choice_gradient_icon_svg__b)'
        fillOpacity={0.25}
        d='M21.814 4.58a1 1 0 1 0-1.628-1.163l-4.314 6.04-3.165-3.165a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.52-.126z'
      />
      <g fillRule='evenodd' clipRule='evenodd'>
        <path
          fill='currentColor'
          d='M7.498 0h17.006a7.52 7.52 0 0 1 7.497 7.497v.006A7.52 7.52 0 0 1 24.504 15H7.5A7.518 7.518 0 0 1 0 7.503v-.006A7.52 7.52 0 0 1 7.498 0zM24.5 2H7.503A5.52 5.52 0 0 0 2 7.5 5.518 5.518 0 0 0 7.503 13H24.5A5.52 5.52 0 0 0 30 7.5 5.52 5.52 0 0 0 24.5 2z'
        />
        <path
          fill='url(#task_multiple_choice_gradient_icon_svg__b)'
          fillOpacity={0.25}
          d='M7.498 0h17.006a7.52 7.52 0 0 1 7.497 7.497v.006A7.52 7.52 0 0 1 24.504 15H7.5A7.518 7.518 0 0 1 0 7.503v-.006A7.52 7.52 0 0 1 7.498 0zM24.5 2H7.503A5.52 5.52 0 0 0 2 7.5 5.518 5.518 0 0 0 7.503 13H24.5A5.52 5.52 0 0 0 30 7.5 5.52 5.52 0 0 0 24.5 2z'
        />
        <path
          fill='currentColor'
          d='M7.498 17h17.006a7.52 7.52 0 0 1 7.497 7.497v.006A7.52 7.52 0 0 1 24.504 32H7.5A7.518 7.518 0 0 1 0 24.503v-.006A7.52 7.52 0 0 1 7.498 17zM24.5 19H7.503A5.52 5.52 0 0 0 2 24.5 5.518 5.518 0 0 0 7.503 30H24.5A5.52 5.52 0 0 0 30 24.5 5.52 5.52 0 0 0 24.5 19z'
        />
        <path
          fill='url(#task_multiple_choice_gradient_icon_svg__b)'
          fillOpacity={0.25}
          d='M7.498 17h17.006a7.52 7.52 0 0 1 7.497 7.497v.006A7.52 7.52 0 0 1 24.504 32H7.5A7.518 7.518 0 0 1 0 24.503v-.006A7.52 7.52 0 0 1 7.498 17zM24.5 19H7.503A5.52 5.52 0 0 0 2 24.5 5.518 5.518 0 0 0 7.503 30H24.5A5.52 5.52 0 0 0 30 24.5 5.52 5.52 0 0 0 24.5 19z'
        />
      </g>
    </g>
  </svg>
)
export default SvgTaskMultipleChoiceGradientIcon
