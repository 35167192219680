import classNames from 'classnames'
import { useField } from 'react-final-form'
import { MultiValue, SingleValue } from 'react-select'
import { FormFieldProps } from '../../../../types/commonTypes'
import { getFieldError } from '../../../../util/form'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { CreatableMultiSelect, MultiSelect, Select, SelectOption, SelectProps } from '../../Select/Select'
import { FormFieldError } from '../FormFieldError/FormFieldError'
import styles from '../FormFields.module.css'

export const SelectFormField: React.FC<FormFieldProps<SelectProps>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  options,
  inline,
  fieldContainerClassName,
  reserveErrorSpace,
  validateOnlyIfDirty,
  ...rest
}) => {
  const { input, meta } = useField(name)
  const getValue = () => options.find((option) => option.value === input.value) || undefined

  return (
    <div className={classNames(inline && styles.inlineField, fieldContainerClassName)}>
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', styles.label)}>
        {label}
      </label>
      <Select
        {...input}
        {...rest}
        hasError={!safeIsNullOrEmpty(getFieldError(meta, validateOnlyIfDirty))}
        id={id ?? name}
        onChange={(option: SingleValue<SelectOption>) => input.onChange(option?.value as any)}
        value={getValue()}
        options={options}
      />
      <FormFieldError meta={meta} reserveErrorSpace={reserveErrorSpace} validateOnlyIfDirty={validateOnlyIfDirty} />
    </div>
  )
}

export const MultiSelectFormField: React.FC<FormFieldProps<SelectProps>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  options,
  inline,
  fieldContainerClassName,
  reserveErrorSpace,
  validateOnlyIfDirty,
  ...rest
}) => {
  const { input, meta } = useField(name)
  const getValue = () =>
    options.filter((option) => ((input.value as Array<string | number>) || []).includes(option.value))

  return (
    <div className={classNames(inline && styles.inlineField, fieldContainerClassName)}>
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', styles.label)}>
        {label}
      </label>
      <MultiSelect
        {...input}
        {...rest}
        hasError={!safeIsNullOrEmpty(getFieldError(meta, validateOnlyIfDirty))}
        id={id ?? name}
        onChange={(selectedOptions: MultiValue<SelectOption>) =>
          input.onChange(selectedOptions.map((option) => option.value))
        }
        value={getValue()}
        options={options}
      />
      <FormFieldError meta={meta} reserveErrorSpace={reserveErrorSpace} validateOnlyIfDirty={validateOnlyIfDirty} />
    </div>
  )
}

export const CreatableMultiSelectFormField: React.FC<FormFieldProps<Omit<SelectProps, 'options'>>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  inline,
  fieldContainerClassName,
  reserveErrorSpace,
  validateOnlyIfDirty,
  ...rest
}) => {
  const { input, meta } = useField(name)
  const getValue = () =>
    input.value?.map?.((item: string, index: number) => ({ label: item, value: `${item}:${index}` }))

  return (
    <div className={classNames(inline && styles.inlineField, fieldContainerClassName)}>
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', styles.label)}>
        {label}
      </label>
      <CreatableMultiSelect
        {...input}
        {...(rest as any)}
        hasError={!safeIsNullOrEmpty(getFieldError(meta, validateOnlyIfDirty))}
        id={id ?? name}
        onChange={(options: MultiValue<SelectOption>) =>
          input.onChange(options.map((option) => option.value.split(':')[0]))
        }
        value={getValue()}
      />
      <FormFieldError meta={meta} reserveErrorSpace={reserveErrorSpace} validateOnlyIfDirty={validateOnlyIfDirty} />
    </div>
  )
}
