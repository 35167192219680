import classNames from 'classnames'
import styles from './TaskModal.module.css'
import { ThreeDots } from '../../../../../common/components/loaders/ThreeDots/ThreeDots'

export const TaskLoaderSection: React.FC = () => {
  return (
    <div>
      <div className={classNames(styles.taskModalMainSection, styles.taskModalSection, styles.loaderContainer)}>
        <ThreeDots />
      </div>
      <div className={classNames(styles.taskModalFooter, styles.taskModalSection)} />
    </div>
  )
}
