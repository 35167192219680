import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTaskCreativeGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <linearGradient
      id='task_creative_gradient_icon_svg__b'
      x1={16}
      x2={16}
      y1={0}
      y2={32}
      gradientUnits='userSpaceOnUse'
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <clipPath id='task_creative_gradient_icon_svg__a'>
      <path d='M0 0h32v32H0z' />
    </clipPath>
    <g fillRule='evenodd' clipPath='url(#task_creative_gradient_icon_svg__a)' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M17 7.874a4 4 0 1 0-2 0V15H7.874a4 4 0 1 0 0 2H15v7.126a4.001 4.001 0 1 0 2 0V17h7.126a4.001 4.001 0 1 0 0-2H17zM14 4a2 2 0 1 1 3.999-.001A2 2 0 0 1 14 4zm2 22a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 26zM2 16a2 2 0 1 1 3.999-.001A2 2 0 0 1 2 16zm26 2a2 2 0 1 1-.001-3.999A2 2 0 0 1 28 18z'
      />
      <path
        fill='url(#task_creative_gradient_icon_svg__b)'
        fillOpacity={0.25}
        d='M17 7.874a4 4 0 1 0-2 0V15H7.874a4 4 0 1 0 0 2H15v7.126a4.001 4.001 0 1 0 2 0V17h7.126a4.001 4.001 0 1 0 0-2H17zM14 4a2 2 0 1 1 3.999-.001A2 2 0 0 1 14 4zm2 22a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 26zM2 16a2 2 0 1 1 3.999-.001A2 2 0 0 1 2 16zm26 2a2 2 0 1 1-.001-3.999A2 2 0 0 1 28 18z'
      />
      <path
        fill='currentColor'
        d='M10.961 9.547a4 4 0 1 0-7.446-2.032 4 4 0 0 0 6.032 3.446l2.746 2.746a1 1 0 0 0 1.414-1.414zM7.515 5.515a2 2 0 1 0 1.401 3.427l.013-.013.013-.013a2 2 0 0 0-1.427-3.401z'
      />
      <path
        fill='url(#task_creative_gradient_icon_svg__b)'
        fillOpacity={0.25}
        d='M10.961 9.547a4 4 0 1 0-7.446-2.032 4 4 0 0 0 6.032 3.446l2.746 2.746a1 1 0 0 0 1.414-1.414zM7.515 5.515a2 2 0 1 0 1.401 3.427l.013-.013.013-.013a2 2 0 0 0-1.427-3.401z'
      />
      <path
        fill='currentColor'
        d='M20.485 24.485c0-.742.202-1.436.554-2.032l-2.746-2.746a1 1 0 0 1 1.414-1.414l2.746 2.746a4 4 0 1 1 2.032 7.446 4 4 0 0 1-4-4zm2 0c0-.546.219-1.04.573-1.401l.013-.013.013-.013a2 2 0 1 1-.599 1.427z'
      />
      <path
        fill='url(#task_creative_gradient_icon_svg__b)'
        fillOpacity={0.25}
        d='M20.485 24.485c0-.742.202-1.436.554-2.032l-2.746-2.746a1 1 0 0 1 1.414-1.414l2.746 2.746a4 4 0 1 1 2.032 7.446 4 4 0 0 1-4-4zm2 0c0-.546.219-1.04.573-1.401l.013-.013.013-.013a2 2 0 1 1-.599 1.427z'
      />
      <path
        fill='currentColor'
        d='M22.453 10.961a4 4 0 1 0 2.032-7.446 4 4 0 0 0-3.446 6.032l-2.746 2.746a1 1 0 0 0 1.414 1.414zm2.032-5.446a2 2 0 1 0 0 3.999 2 2 0 0 0 0-4z'
      />
      <path
        fill='url(#task_creative_gradient_icon_svg__b)'
        fillOpacity={0.25}
        d='M22.453 10.961a4 4 0 1 0 2.032-7.446 4 4 0 0 0-3.446 6.032l-2.746 2.746a1 1 0 0 0 1.414 1.414zm2.032-5.446a2 2 0 1 0 0 3.999 2 2 0 0 0 0-4z'
      />
      <path
        fill='currentColor'
        d='M7.515 20.485c.742 0 1.436.202 2.032.554l2.746-2.746a1 1 0 0 1 1.414 1.414l-2.746 2.746a4 4 0 1 1-7.446 2.032 4 4 0 0 1 4-4zm-2 4a2 2 0 0 1 3.401-1.427l.013.013.013.013a2 2 0 1 1-3.427 1.401z'
      />
      <path
        fill='url(#task_creative_gradient_icon_svg__b)'
        fillOpacity={0.25}
        d='M7.515 20.485c.742 0 1.436.202 2.032.554l2.746-2.746a1 1 0 0 1 1.414 1.414l-2.746 2.746a4 4 0 1 1-7.446 2.032 4 4 0 0 1 4-4zm-2 4a2 2 0 0 1 3.401-1.427l.013.013.013.013a2 2 0 1 1-3.427 1.401z'
      />
    </g>
  </svg>
)
export default SvgTaskCreativeGradientIcon
