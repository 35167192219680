import classNames from 'classnames'
import { useField } from 'react-final-form'
import { FormFieldProps } from '../../../../types/commonTypes'
import { getFieldError } from '../../../../util/form'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { InputField, InputFieldProps } from '../../input/InputField'
import { FormFieldError } from '../FormFieldError/FormFieldError'
import styles from '../FormFields.module.css'

export const InputFormField: React.FC<FormFieldProps<Partial<InputFieldProps>>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  fieldContainerClassName,
  inline,
  reserveErrorSpace,
  validateOnlyIfDirty,
  type = 'text',
  ...rest
}) => {
  const { input, meta } = useField(name, { type })

  return (
    <div className={classNames(inline && styles.inlineField, fieldContainerClassName)}>
      <label htmlFor={id ?? name} className={classNames(srOnlyLabel && 'sr-only', styles.label)}>
        {label}
      </label>
      <InputField
        {...rest}
        {...input}
        hasError={!safeIsNullOrEmpty(getFieldError(meta, validateOnlyIfDirty))}
        id={id ?? name}
        type={type}
      />
      <FormFieldError validateOnlyIfDirty={validateOnlyIfDirty} meta={meta} reserveErrorSpace={reserveErrorSpace} />
    </div>
  )
}
