import { PropsWithChildren } from 'react'
import { getIcon, IconName } from '../../../common/components/icons/utils'
import styles from './LoginCard.module.css'

type LoginCardProps = {
  icon: IconName
  title: string
}
export const LoginCard: React.FC<PropsWithChildren<LoginCardProps>> = ({ children, icon, title }) => {
  return (
    <div className={styles.cardContainer}>
      <div>
        <span className={styles.icon}>{getIcon(icon)}</span>
        <h1>{title}</h1>
      </div>
      {children}
    </div>
  )
}
