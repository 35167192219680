import { MapType } from '../../api/gameTypes'
import { DEFAULT_MAP_OPTIONS } from '../../common/constants'

export type GameBoard = {
  fileName: string
  name: string
  url: string
}

export type MapOptions = {
  center: [longitude: number, latitude: number]
  zoom: number
}

export type NotificationSettings = {
  messages: number
  answers: number
  instantEnabled: boolean
  summaryEnabled: boolean
  emails: string
}

export type GameBoardSettings = {
  gameBoardType: MapType
  liveMap: MapOptions
  gameBoards: GameBoard[]
}

export type GameFormBase = {
  name: string
  description: string
  gameBoardSettings: GameBoardSettings
}

export type GameForm = GameFormBase & {
  language?: string
  ages?: string[]
  topics?: string[]
  keywords?: string[]
  messages?: number
  answers?: number
  instantEnabled?: boolean
  summaryEnabled?: boolean
  emails?: string
  chatEnabled?: boolean
  gpsEnabled?: boolean
  explorationMode?: boolean
  levelsEnabled?: boolean
  allowBranching?: boolean
  playerContinuesSentAnsEnabled?: boolean
  noPointsGame?: boolean
  hideScoreboard?: boolean
  enableBadges?: boolean
  happyOrNot?: boolean
}

export const DEFAULT_GAME_BOARD_SETTINGS: GameBoardSettings = {
  gameBoardType: MapType.STATIC,
  gameBoards: [],
  liveMap: DEFAULT_MAP_OPTIONS,
}

export enum CreateGameOption {
  QUICK_START = 'QUICK_START',
  SEARCH_COMMUNITY = 'SEARCH_COMMUNITY',
  GAME_SETTINGS = 'GAME_SETTINGS',
}

export enum TabType {
  BUILD = 'Build',
  TEST = 'Test',
  PLAY = 'Play',
  REPORT = 'Report',
}
