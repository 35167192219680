import { useTranslation } from 'react-i18next'
import styles from './LoginMethod.module.css'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import classNames from 'classnames'

export const PasswordOrCode: React.FC<{ isGuestInstructor: boolean }> = ({ isGuestInstructor = false }) => {
  const { t } = useTranslation()
  const { fadeTransition } = useFadeAnimation()

  return (
    <div className={classNames(styles.mainContentWrapper, fadeTransition)}>
      <h1 className={styles.title}>{t('login.instructor.right.normal.first_step.title', "Let's start our engines")}</h1>
      <div className={styles.paragraphContainerSecondStep}>
        {isGuestInstructor ? (
          <>
            <p>
              {t(
                'login.instructor.right.guest_instructor.second_step.body.paragraph_one',
                'We sent you an email to the address you provided that has a link to instantly continue, as well as a code you can enter below.',
              )}
            </p>
            <p>
              {t(
                'login.instructor.right.guest_instructor.second_step.body.paragraph_two',
                'If you have an account associated with your e-mail, you can write it to instantly continue.',
              )}
            </p>
          </>
        ) : (
          <>
            <p>
              {t(
                'login.instructor.right.normal.second_step.body.paragraph_one',
                'We sent you an email to the address you provided that has a link to instantly continue, as well as a code you can enter below.',
              )}
            </p>
            {/* <p>
              {t(
                'login.instructor.right.normal.second_step.body.paragraph_two',
                'If you have an account associated with your e-mail, you can write it to instantly continue.',
              )}
            </p> */}
          </>
        )}
      </div>
      <InputFormField
        label='Password'
        placeholder={t(
          'login.instructor.right.normal.second_step.password_placeholder',
          'Code from e-mail or your password',
        )}
        srOnlyLabel
        name='password'
        type='password'
        reserveErrorSpace
      />
    </div>
  )
}
