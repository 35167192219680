// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpMenuItem_helpMenuItem__H6mMF {\n  display: flex;\n  padding: 16px 20px;\n  cursor: pointer;\n}\n\n.HelpMenuItem_helpMenuItem__H6mMF:hover {\n  background: var(--gradient-primary-background-horizontal);\n}\n\n.HelpMenuItem_icon__cKKiN {\n  font-size: 1.3125rem;\n  margin-right: 16px;\n  color: var(--grey-600);\n}\n\n.HelpMenuItem_label__4c1oc {\n  display: flex;\n  flex-direction: column;\n  font-weight: 500;\n  line-height: 1.5rem;\n  font-size: 1rem;\n  color: var(--grey-900);\n}\n\n.HelpMenuItem_subLabel__LI9nL {\n  font-weight: 500;\n  line-height: 1rem;\n  font-size: 0.75rem;\n  color: var(--grey-700);\n}\n", "",{"version":3,"sources":["webpack://./src/composites/HelpMenu/components/HelpMenuItem/HelpMenuItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yDAAyD;AAC3D;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".helpMenuItem {\n  display: flex;\n  padding: 16px 20px;\n  cursor: pointer;\n}\n\n.helpMenuItem:hover {\n  background: var(--gradient-primary-background-horizontal);\n}\n\n.icon {\n  font-size: 1.3125rem;\n  margin-right: 16px;\n  color: var(--grey-600);\n}\n\n.label {\n  display: flex;\n  flex-direction: column;\n  font-weight: 500;\n  line-height: 1.5rem;\n  font-size: 1rem;\n  color: var(--grey-900);\n}\n\n.subLabel {\n  font-weight: 500;\n  line-height: 1rem;\n  font-size: 0.75rem;\n  color: var(--grey-700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpMenuItem": "HelpMenuItem_helpMenuItem__H6mMF",
	"icon": "HelpMenuItem_icon__cKKiN",
	"label": "HelpMenuItem_label__4c1oc",
	"subLabel": "HelpMenuItem_subLabel__LI9nL"
};
export default ___CSS_LOADER_EXPORT___;
