import React from 'react'
import { Card } from '../../../common/components/card/Card'
import { CreateGameCard } from '../../../common/components/card/CreateGameCard'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import styles from '../GamesOverview.module.css'
import classNames from 'classnames'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'

type GamesGridProps = {
  games: TGameCard[]
  onGameAction: (action: GameAction, game: TGameCard) => void
  libraryMode?: boolean
  showCreateGameCard?: boolean
  onClickCreateGame?: () => void
  loading: boolean
}

export const GamesGrid: React.FC<GamesGridProps> = ({
  games,
  onGameAction,
  showCreateGameCard,
  onClickCreateGame,
  libraryMode,
  loading,
}) => {
  const { fadeTransition } = useFadeAnimation(loading, true)

  return (
    <>
      <div className={classNames(styles.gridContainer, fadeTransition)}>
        {showCreateGameCard && onClickCreateGame != null && <CreateGameCard onClick={onClickCreateGame} />}
        {games.map((game, index) => {
          return <Card key={`${game.id}${index}`} game={game} onGameAction={onGameAction} libraryMode={libraryMode} />
        })}
      </div>
    </>
  )
}
