// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Community_container__y59oE {\n  position: relative;\n  height: 100%;\n}\n\n.Community_EmptyContent__X-K3M {\n  padding-top: 3rem;\n  display: flex;\n  flex-direction: column;\n  color: var(--grey-800);\n  text-align: center;\n  line-height: 5rem;\n}\n\n.Community_noContentButtons__MuKsB {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/community/Community.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".container {\n  position: relative;\n  height: 100%;\n}\n\n.EmptyContent {\n  padding-top: 3rem;\n  display: flex;\n  flex-direction: column;\n  color: var(--grey-800);\n  text-align: center;\n  line-height: 5rem;\n}\n\n.noContentButtons {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Community_container__y59oE",
	"EmptyContent": "Community_EmptyContent__X-K3M",
	"noContentButtons": "Community_noContentButtons__MuKsB"
};
export default ___CSS_LOADER_EXPORT___;
