import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../common/components/button/Button'
import { useGameEditorNavigation } from '../../../hooks/useGameEditorNavigation'
import styles from '../GamesOverview.module.css'

export type EmptyContentBrowseButton = {
  label: string
  route: string
}

type RefreshButton = {
  label: string
}

export type EmptyContentProps = {
  title: string
  subtitle: string
  browseButton?: EmptyContentBrowseButton
  refreshButton?: RefreshButton
}

export const EmptyContent: React.FC<EmptyContentProps> = ({ title, subtitle, browseButton, refreshButton }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { goToCreateGame } = useGameEditorNavigation()

  const handleClickCreateGame = async () => {
    await goToCreateGame()
  }

  const handleClickBrowse = (route: string) => () => {
    navigate(route)
  }

  const handleClickRefresh = () => {
    window.location.reload()
  }

  return (
    <>
      <div className={styles.EmptyContent}>
        <h2 className={styles.noGamesHeading}>{title}</h2>
        <p className={styles.noGamesText}>{subtitle}</p>
      </div>
      <div className={styles.noContentButtons}>
        <Button
          styleVariant='secondary'
          sizeVariant='small'
          iconLeft='plus'
          label={t('dashboard.empty.new_game_button', 'New game')}
          onClick={handleClickCreateGame}
          style={{ boxShadow: 'none' }}
        />
        {browseButton != null && (
          <Button
            styleVariant='tertiary'
            sizeVariant='small'
            iconLeft='community'
            label={browseButton.label}
            onClick={handleClickBrowse(browseButton.route)}
            style={{ boxShadow: 'none' }}
          />
        )}
        {refreshButton != null && (
          <Button
            styleVariant='tertiary'
            sizeVariant='small'
            label={refreshButton.label}
            onClick={handleClickRefresh}
            style={{ boxShadow: 'none' }}
          />
        )}
      </div>
    </>
  )
}
