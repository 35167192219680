import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import mapboxgl from 'mapbox-gl'
import { useRef, useLayoutEffect, useEffect, useMemo } from 'react'
import { DEFAULT_MAP_OPTIONS } from '../common/constants'

// Vjeko dev token - if maps stop initializing, we probably hit the init threshold and will need a new token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_DEV_ACCESS_TOKEN!

export enum MapInstanceID {
  MODAL = 'modal',
  EDITOR = 'editor',
}

let mapInstances: { [id: string]: { container: HTMLElement; map: mapboxgl.Map; geocoder: MapboxGeocoder } } = {}

const mapRoot = document.getElementById('map-root')!

const getMapInstance = (id: MapInstanceID) => {
  if (mapInstances[id] == null) {
    const mapContainer = document.createElement('div')
    mapContainer.style.width = '100%'
    mapContainer.style.height = '100%'
    mapRoot.appendChild(mapContainer)
    const map = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/mapbox/streets-v12',
      trackResize: true,
      zoom: DEFAULT_MAP_OPTIONS.zoom,
      center: [...DEFAULT_MAP_OPTIONS.center],
    })
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-left')
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    })
    map.addControl(geocoder, 'top-right')
    mapInstances[id] = { container: mapContainer, map, geocoder }
  }
  return mapInstances[id]
}

export const tryMoveMapTo = (id: MapInstanceID, element: HTMLElement) => {
  if (mapInstances[id] != null) {
    element.appendChild(mapInstances[id].container)
    getMapInstance(id).geocoder.clear()
  }
}

// IMOPORTANT: when unmounting any component that has the map in it, we MUST first move
// the map back to it's original postion - otherwise we lose the map
export const tryResetMapInstance = (id: MapInstanceID) => {
  tryMoveMapTo(id, mapRoot)
}

// NOTE: Only use inside MapboxContainer component
export const useMap = (id: MapInstanceID) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Ensuring map exists
    getMapInstance(id)
  }, [id])

  useLayoutEffect(() => {
    if (mapContainerRef.current != null) {
      tryMoveMapTo(id, mapContainerRef.current)
      getMapInstance(id).map.resize()
    }
    return () => tryResetMapInstance(id)
  }, [id])

  // Cache return object to avoid unnecessary re-renders
  const ret = useMemo(
    () => ({
      elementRef: mapContainerRef,
      api: getMapInstance(id).map,
      geocoder: getMapInstance(id).geocoder,
    }),
    [id],
  )

  return ret
}

// NOTE: for use outside of MapboxContainer component
export const useMapApi = getMapInstance
