// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BoardSection_container__\\+\\+uhv {\n  height: calc(100vh - 5.25rem);\n  position: relative;\n  width: 100%;\n  overflow: hidden;\n  cursor: grab;\n  /* Used for the currently hardcoded live map \"preview\" */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.BoardSection_transformComponent__kF5Hz {\n  width: 100%;\n}\n\n.BoardSection_zoomButtons__-9LgK {\n  position: fixed;\n  right: 2rem;\n  bottom: 8.875rem;\n  z-index: 100;\n}\n\n.BoardSection_mapboxContainer__53KM6 {\n  width: 100%;\n  height: calc(100vh - 5.25rem);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GameEditor/components/BoardSection/BoardSection.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,wDAAwD;EACxD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,6BAA6B;AAC/B","sourcesContent":[".container {\n  height: calc(100vh - 5.25rem);\n  position: relative;\n  width: 100%;\n  overflow: hidden;\n  cursor: grab;\n  /* Used for the currently hardcoded live map \"preview\" */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n}\n\n.transformComponent {\n  width: 100%;\n}\n\n.zoomButtons {\n  position: fixed;\n  right: 2rem;\n  bottom: 8.875rem;\n  z-index: 100;\n}\n\n.mapboxContainer {\n  width: 100%;\n  height: calc(100vh - 5.25rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BoardSection_container__++uhv",
	"transformComponent": "BoardSection_transformComponent__kF5Hz",
	"zoomButtons": "BoardSection_zoomButtons__-9LgK",
	"mapboxContainer": "BoardSection_mapboxContainer__53KM6"
};
export default ___CSS_LOADER_EXPORT___;
