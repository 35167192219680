import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../common/components/button/Button'
import { HELP_MENU_CONTROL_ID, useHelpMenu } from '../../contexts/HelpMenuContextProvider'
import { useTheme } from '../../contexts/ThemeContext'
import { useUser } from '../../contexts/userContext'
import { useGameEditorNavigation } from '../../hooks/useGameEditorNavigation'
import { routes } from '../../routes'
import { SidebarLink } from './components/SidebarLink'
import { SidebarSubLink, SidebarSubLinks } from './components/SidebarSubLinks'

import styles from './Sidebar.module.css'
import shared from '../../common/styles/shared.module.css'

const buttonSidebarStyles = {
  buttonStyle: {
    minHeight: 'unset',
    minWidth: 'unset',
    justifyContent: 'start',
    padding: '0',
    fontSize: '1.125rem',
    color: 'var(--grey-700)',
  },
  iconLeftStyle: {
    color: 'var(--grey-600)',
    fontSize: '1.75rem',
    marginRight: '0.9375rem',
  },
}

export const Sidebar: React.FC = () => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { toggleDisplayMenu } = useHelpMenu()
  const navigate = useNavigate()
  const { logoUrl } = useTheme()
  const { goToCreateGame } = useGameEditorNavigation()

  const handleClickCreateGame = async () => {
    await goToCreateGame()
  }

  const settingsSubLinks: SidebarSubLink[] = [
    { title: t('side_bar.settings_general', 'General'), to: routes.generalSettings },
    // NOTE: to be revived soon, once the new webshop goes live
    // { title: t('side_bar.settings_billing', 'Billing'), to: routes.billingSettings },
    { title: t('side_bar.settings_organization', 'Organisation'), to: routes.organizationSettings },
  ]

  return (
    <nav className={styles.container}>
      <div className={styles.stickyContainer}>
        <div className={styles.topBorderContainer}>
          <div className={styles.topBorder} />
        </div>
        <div className={styles.sidebarContent}>
          <div className={styles.seppoImg} onClick={() => navigate(routes.homepage)}>
            <img width={90} height={26} src={logoUrl} alt='logo' />
          </div>
          {user && user.termsAccepted && (
            <div className={classNames(shared.flex, shared.mAxisC)}>
              <Button
                styleVariant='tertiary'
                sizeVariant='tiny'
                iconLeft='plus'
                label={t('new_game_button', 'New game')}
                onClick={handleClickCreateGame}
                style={{ boxShadow: 'none' }}
              />
            </div>
          )}
          <div className={styles.sidebarLinksContainer}>
            <ul className={styles.linksList}>
              {user && user.termsAccepted && (
                <>
                  <SidebarLink to={routes.homepage} title={t('side_bar.dashboard', 'Dashboard')} icon={'exercisePin'} />
                  <SidebarLink to={routes.reports} title={t('side_bar.reports', 'Reports')} icon={'reports'} />
                  {user.hasOrgLibrary && (
                    <SidebarLink
                      to={routes.orgLibrary}
                      title={t('side_bar.org_library', 'Org Library')}
                      icon={'orgLibrary'}
                    />
                  )}
                  {user.hasCommunity && (
                    <SidebarLink
                      to={routes.community}
                      title={t('side_bar.community', 'Community')}
                      icon={'community'}
                    />
                  )}
                  {user.hasTemplates && (
                    <SidebarLink
                      to={routes.templates}
                      title={t('side_bar.templates', 'Templates')}
                      icon={'community'}
                    />
                  )}
                  <SidebarSubLinks
                    icon='settings'
                    to={routes.generalSettings}
                    title={t('side_bar.settings', 'Settings')}
                    subLinks={settingsSubLinks}
                  />
                  <li>
                    <Button
                      label={t('side_bar.help', 'Help')}
                      iconLeft='help'
                      styleVariant='borderless'
                      sizeVariant='large'
                      buttonStyles={buttonSidebarStyles.buttonStyle}
                      iconLeftContainerStyle={buttonSidebarStyles.iconLeftStyle}
                      onClick={toggleDisplayMenu}
                      id={`${HELP_MENU_CONTROL_ID}_sidebarButton`}
                    />
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}
