import classNames from 'classnames'
import { FieldMetaState } from 'react-final-form'
import { getFieldError } from '../../../../util/form'
import { safeIsNullOrEmpty } from '../../../../util/string'
import styles from './FormError.module.css'

export type FormFieldErrorProps = {
  meta: FieldMetaState<any>
  className?: string
  reserveErrorSpace?: boolean
  validateOnlyIfDirty?: boolean
}

export const FormFieldError: React.FC<FormFieldErrorProps> = ({
  meta,
  className,
  reserveErrorSpace,
  validateOnlyIfDirty,
}) => {
  const error = getFieldError(meta, validateOnlyIfDirty)

  return !safeIsNullOrEmpty(error) || reserveErrorSpace ? (
    <div className={classNames(styles.fieldErrorContainer, !safeIsNullOrEmpty(error) && styles.withError, className)}>
      {error}
    </div>
  ) : null
}
