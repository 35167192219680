import { useTranslation } from 'react-i18next'
import { Button } from '../../../common/components/button/Button'
import { CloseButton } from '../../../common/components/button/CloseButton'
import { useStorage } from '../../../hooks/useStorage'
import { HIDDEN_END_MESSAGES } from '../constants'
import { EntityMessages, hideDistinctMessage, isMessageHidden } from '../helpers'
import styles from '../WelcomeBlock.module.css'
import { submitTrialFeedback } from '../../../api/userApiService'
import { useNotification } from '../../../contexts/NotificationContext'
import { useState } from 'react'
import { openNinchatForm } from '../../../util/ninchat'
import { LinkButton } from '../../../common/components/button/LinkButton'

type TrialEndProps = {
  messages: EntityMessages
}

export const TrialEndBanner: React.FC<TrialEndProps> = ({
  messages: { id, title, body, primaryButton, secondaryButton },
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { t } = useTranslation()
  const { storedValue, storeValue } = useStorage<string[] | null>(HIDDEN_END_MESSAGES, null)
  const { notifySuccess, notifyError } = useNotification()

  const handleClickClose = () => {
    hideDistinctMessage(id, storedValue, storeValue)
  }

  if (storedValue != null && isMessageHidden(id, storedValue)) {
    return null
  }

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const feedback = formData.get('feedback')

    if (feedback != null) {
      setIsSubmitting(true)
      submitTrialFeedback({ feedbackMessage: feedback as string })
        .then((res) => {
          if (res.success) {
            notifySuccess({
              title: t('trial_end.feedback_response.success.title', 'Feedback sent'),
              content: t('trial_end.feedback_response.success.content', 'Your feedback message was sent successfully'),
            })
          } else {
            notifyError({
              title: t('trial_end.feedback_response.success.title', 'Error'),
              content: t(
                'trial_end.feedback_response.success.content',
                'Failed to send feedback, please try again later or contact support.',
              ),
            })
          }
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <div className={styles.trialBannerContainer}>
      <div className={styles.bannerContainerLeftSide}>
        <div className={styles.title}>
          <p>{t(title)}</p>
        </div>
        <div className={styles.welcomeText}>
          <p className='normal'>{t(body)}</p>
        </div>
        <div className={styles.buttonsColumn}>
          {primaryButton != null && (
            <LinkButton
              label={t('trial_end.upgrade_account', 'Upgrade your account')}
              iconName='transparentStar'
              href='https://play.seppo.io/shop'
              target='_blank'
              rel='noreferrer'
            />
          )}
          {secondaryButton != null && (
            <Button
              buttonStyles={{
                background: 'white',
                lineHeight: '1.4375rem',
                fontWeight: '500',
                letterSpacing: '0.06em',
                marginBottom: '1.5rem',
                width: 'fit-content',
              }}
              label={t(secondaryButton)}
              styleVariant='tertiary'
              sizeVariant='small'
              onClick={() => openNinchatForm()}
            />
          )}
        </div>
      </div>

      <div className={styles.bannerContainerRightSide}>
        <div className={styles.rightSideHeader}>
          <div>
            {t('trial_end.feedback', 'If you did not sign up with Seppo at this time, could you please tell us why?')}
          </div>
          <div className={styles.closeButton}>
            <CloseButton buttonStyle={{ color: 'white' }} onClick={handleClickClose} />
          </div>
        </div>
        <div className={styles.feedbackContainer}>
          <form onSubmit={handleSubmit}>
            <textarea
              required
              name='feedback'
              className={styles.feedback}
              placeholder={t('trial_end.feedback_input_placeholder', 'Type here')}
            />
            <Button
              buttonStyles={{
                letterSpacing: '0.06em',
                width: '6.1875rem',
              }}
              type='submit'
              label={t('trial_end.send_button', 'Send')}
              styleVariant='secondary'
              sizeVariant='small'
              disabled={isSubmitting}
            />
          </form>
        </div>
      </div>
    </div>
  )
}
