// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GameEditor_container__l2sZ4 {\n  position: relative;\n  height: 100vh;\n  width: 100%;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GameEditor/GameEditor.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".container {\n  position: relative;\n  height: 100vh;\n  width: 100%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GameEditor_container__l2sZ4"
};
export default ___CSS_LOADER_EXPORT___;
