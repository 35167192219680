import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from '../../../../common/components/Select/Select'
import { Button } from '../../../../common/components/button/Button'
import { getIcon } from '../../../../common/components/icons/utils'
import { MemberRole, TMember } from '../../../../types/commonTypes'
import { getMemberRoleOptions } from '../helpers'
import styles from './MembersTable.module.css'

type MemberTableRowProps = {
  member: TMember
  onChangeMemberRole: (userName: string) => Promise<boolean>
  onRemoveMember: (userName: string) => Promise<boolean>
}

export const MemberTableRow: React.FC<MemberTableRowProps> = ({ member, onRemoveMember, onChangeMemberRole }) => {
  const { t } = useTranslation()
  const [isEditingRole, setIsEditingRole] = useState<boolean>(false)
  const [isRemovingMember, setIsRemovingMember] = useState<boolean>(false)
  const [isSubmittingRoleChange, setIsSubmittingRoleChange] = useState<boolean>(false)
  const [newRole, setNewRole] = useState<MemberRole>(member.role)

  const onChangeMemberRoleInternal = async () => {
    if (member.role === newRole) {
      setIsEditingRole(false)
      return
    }
    setIsSubmittingRoleChange(true)
    await onChangeMemberRole(member.email)
    setIsSubmittingRoleChange(false)
    setIsEditingRole(false)
  }

  const onRemoveMemberInternal = async () => {
    setIsRemovingMember(true)
    await onRemoveMember(member.email)
    setIsRemovingMember(false)
  }

  const memberRoleOptions = getMemberRoleOptions(t)

  return (
    <tr>
      <td className='normal bold'>{member.name}</td>
      <td>{member.email}</td>
      <td>
        {isEditingRole ? (
          <Select
            options={memberRoleOptions}
            value={memberRoleOptions.find((option) => option.value === newRole)}
            onChange={(option) => setNewRole((option?.value as MemberRole) || member.role)}
          />
        ) : member.role === MemberRole.ADMIN ? (
          t('settings_organization.members.role_label.admin', 'Admin')
        ) : (
          t('settings_organization.members.role_label.instructor', 'Instructor')
        )}
      </td>
      <td>
        {isEditingRole ? (
          <div className={styles.memberEditingRoleActionsContainer}>
            <Button
              styleVariant='tertiary'
              sizeVariant='tiny'
              label={t('settings_organization.members.buttons_text.cancel', 'Cancel')}
              onClick={() => setIsEditingRole(false)}
              disabled={isSubmittingRoleChange}
            />
            <Button
              styleVariant='secondary'
              sizeVariant='tiny'
              label={t('settings_organization.members.buttons_text.submit_role_update', 'Ok')}
              disabled={isSubmittingRoleChange}
              onClick={onChangeMemberRoleInternal}
            />
          </div>
        ) : (
          <div className={styles.memberActionsContainer}>
            <button onClick={() => setIsEditingRole(true)}>
              <span>{getIcon('text')}</span>
              <span>{t('settings_organization.members.action_text.change_role', 'Change role')}</span>
            </button>
            <button onClick={onRemoveMemberInternal} disabled={isRemovingMember}>
              <span>{getIcon('trash')}</span>
              <span>{t('settings_organization.members.action_text.remove_member', 'Remove member')}</span>
            </button>
          </div>
        )}
      </td>
    </tr>
  )
}
