import BackIcon from './BackIcon'
import {
  AddFolderIcon,
  AlertIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  AudioIcon,
  BellIcon,
  CalendarIcon,
  CameraIcon,
  ChatNormalIcon,
  ChatUnreadIcon,
  ChevronIcon,
  CircleCheckmarkIcon,
  CircleWithIcons,
  CloseIcon,
  CommentIcon,
  CommunityIcon,
  DebugIcon,
  DoorIcon,
  DownloadsOutlineIcon,
  DuplicateIcon,
  EmailIcon,
  ExercisePinIcon,
  ExercisesIcon,
  FbIcon,
  FilterIcon,
  FolderMoveIcon,
  GoogleIcon,
  GridIcon,
  HelpIcon,
  IgIcon,
  InfoIcon,
  JSIcon,
  LanguageIcon,
  LinkIcon,
  ListIcon,
  LogoutIcon,
  LongerArrowDownIcon,
  LongerArrowUpIcon,
  MapWithPinIcon,
  MessageFailIcon,
  MinusIcon,
  MoreIcon,
  MultipleUsersIcon,
  OrganizationGradientIcon,
  OrgLibraryIcon,
  PeopleIcon,
  PhotoIcon,
  PinAddIcon,
  PinFilledIcon,
  PinMultipleIcon,
  PlayIcon,
  PlusIcon,
  ProfileCircledIcon,
  ProfileIcon,
  RadarIcon,
  ReceiptIcon,
  RecentsIcon,
  ReportIcon,
  ScoreHeaderIcon,
  ScoreIcon,
  SearchIcon,
  SelectMapIcon,
  SettingsGradientIcon,
  SettingsIcon,
  StarIcon,
  StarOutlineIcon,
  TaskCreativeIcon,
  TaskMatchPairsIcon,
  TaskMissingWordIcon,
  TaskMultipleChoiceIcon,
  TextFileIcon,
  TextIcon,
  TransparentStarIcon,
  TrashIcon,
  TwitterIcon,
  UserIdleIcon,
  VideoIcon,
  ViewIcon,
  LevelGenericIcon,
  CommunityGradientIcon,
  ReceiptGradientIcon,
  ChatUnreadGradientIcon,
  LevelGradientIcon,
  ReportGradientIcon,
  CircleCheckmarkGradientIcon,
  TaskMultipleChoiceGradientIcon,
  TaskCreativeGradientIcon,
} from './index'

const ICON_MAP = {
  addFolder: <AddFolderIcon color='currentColor' />,
  alert: <AlertIcon />,
  arrowDown: <ArrowDownIcon color='currentColor' />,
  arrowRight: <ArrowRightIcon color='currentColor' />,
  audio: <AudioIcon />,
  backIcon: <BackIcon color='currentColor' />,
  bell: <BellIcon color='currentColor' />,
  calendar: <CalendarIcon color='currentColor' />,
  camera: <CameraIcon />,
  chatNormal: <ChatNormalIcon color='currentColor' />,
  chatUnread: <ChatUnreadIcon color='currentColor' />,
  chatUnreadGradient: <ChatUnreadGradientIcon />,
  chevron: <ChevronIcon color='currentColor' />,
  circleCheckmark: <CircleCheckmarkIcon color='currentColor' />,
  circleCheckmarkGradient: <CircleCheckmarkGradientIcon />,
  CircleWithIcons: <CircleWithIcons />,
  close: <CloseIcon color='currentColor' />,
  comment: <CommentIcon color='currentColor' />,
  community: <CommunityIcon color='currentColor' />,
  communityGradient: <CommunityGradientIcon />,
  debug: <DebugIcon />,
  door: <DoorIcon />,
  downloadsOutline: <DownloadsOutlineIcon color='currentColor' />,
  duplicate: <DuplicateIcon color='currentColor' />,
  email: <EmailIcon color='currentColor' />,
  exercisePin: <ExercisePinIcon color='currentColor' />,
  exercises: <ExercisesIcon color='currentColor' />,
  fb: <FbIcon color='currentColor' />,
  filter: <FilterIcon color='currentColor' />,
  folderMove: <FolderMoveIcon color='currentColor' />,
  google: <GoogleIcon />,
  grid: <GridIcon color='currentColor' />,
  help: <HelpIcon color='currentColor' />,
  ig: <IgIcon color='currentColor' />,
  info: <InfoIcon color='currentColor' />,
  js: <JSIcon color='currentColor' />,
  language: <LanguageIcon color='currentColor' />,
  levelGeneric: <LevelGenericIcon />,
  levelGradient: <LevelGradientIcon />,
  link: <LinkIcon color='currentColor' />,
  list: <ListIcon color='currentColor' />,
  logout: <LogoutIcon color='currentColor' />,
  longerArrowDown: <LongerArrowDownIcon />,
  longerArrowUp: <LongerArrowUpIcon />,
  mapWithPin: <MapWithPinIcon color='currentColor' />,
  messageFail: <MessageFailIcon color='currentColor' />,
  minus: <MinusIcon color='currentColor' />,
  more: <MoreIcon color='currentColor' />,
  multipleUsers: <MultipleUsersIcon color='currentColor' />,
  organizationGradient: <OrganizationGradientIcon color='currentColor' />,
  orgLibrary: <OrgLibraryIcon color='currentColor' />,
  people: <PeopleIcon color='currentColor' />,
  photo: <PhotoIcon color='currentColor' />,
  pinAdd: <PinAddIcon color='currentColor' />,
  pinFilled: <PinFilledIcon color='currentColor' />,
  pinMultiple: <PinMultipleIcon color='currentColor' />,
  play: <PlayIcon color='currentColor' />,
  plus: <PlusIcon color='currentColor' />,
  profile: <ProfileIcon color='currentColor' />,
  profileCircled: <ProfileCircledIcon color='currentColor' />,
  radar: <RadarIcon color='currentColor' />,
  receipt: <ReceiptIcon color='currentColor' />,
  receiptGradient: <ReceiptGradientIcon />,
  recents: <RecentsIcon color='currentColor' />,
  reports: <ReportIcon color='currentColor' />,
  reportsGradient: <ReportGradientIcon />,
  scoreHeader: <ScoreHeaderIcon color='currentColor' />,
  scoreIcon: <ScoreIcon color='currentColor' />,
  search: <SearchIcon color='currentColor' />,
  selectMap: <SelectMapIcon color='currentColor' />,
  settings: <SettingsIcon color='currentColor' />,
  settingsGradient: <SettingsGradientIcon />,
  star: <StarIcon color='currentColor' />,
  starOutline: <StarOutlineIcon color='currentColor' />,
  taskCreative: <TaskCreativeIcon />,
  taskCreativeGradient: <TaskCreativeGradientIcon />,
  taskMatchPairs: <TaskMatchPairsIcon />,
  taskMissingWord: <TaskMissingWordIcon />,
  taskMultipleChoice: <TaskMultipleChoiceIcon />,
  taskMultipleChoiceGradient: <TaskMultipleChoiceGradientIcon />,
  text: <TextIcon color='currentColor' />,
  textFile: <TextFileIcon color='currentColor' />,
  transparentStar: <TransparentStarIcon color='currentColor' />,
  trash: <TrashIcon color='currentColor' />,
  twitter: <TwitterIcon color='currentColor' />,
  userIdle: <UserIdleIcon color='currentColor' />,
  video: <VideoIcon />,
  view: <ViewIcon color='currentColor' />,
} as const

export type IconName = keyof typeof ICON_MAP

export const getIcon = (iconName: IconName) => {
  return ICON_MAP[iconName]
}
