import { InputFormField } from '../../../../../../../common/components/Form/InputFormField/InputFormField'
import { getIcon } from '../../../../../../../common/components/icons/utils'
import { safeIsNullOrEmpty } from '../../../../../../../util/string'
import styles from './PointsInput.module.css'

type PointsInputProps = {
  name: string
  description: string
}

export const PointsInput: React.FC<PointsInputProps> = ({ name, description }) => {
  return (
    <>
      {!safeIsNullOrEmpty(description) && (
        <p className={styles.scoreItem}>
          <span className={styles.scoreIcon}>{getIcon('scoreHeader')}</span>
          <span>{description}</span>
        </p>
      )}
      <InputFormField
        name={name}
        label={description}
        srOnlyLabel
        type='number'
        leftElement={{
          iconName: 'scoreHeader',
          elementStyles: { color: 'var(--secondary-normal)', fontSize: '2rem' },
          variant: 'icon',
        }}
        inputStyles={{
          maxWidth: '10rem',
          height: '3rem',
          borderRadius: 50,
        }}
      />
    </>
  )
}
