import { Trans, useTranslation } from 'react-i18next'
import { getOldUIRoutes } from '../../routes'
import styles from './GlobalNotification.module.css'

// NOTE: hardcoded to dashboard message for now, can be made configurable later
export const GlobalNotification: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Trans i18nKey={'global_notification.new_ui_info.message'}>
        This is the new version of Seppo for instructors (BETA).
        <a
          aria-label={t('global_notification.new_ui_info.link_to_old_ui_aria_label', 'Go to the old version')}
          href={getOldUIRoutes.oldDashboard()}
        >
          Go to the old version.
        </a>
      </Trans>
    </div>
  )
}
