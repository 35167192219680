import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { debounce } from '../util/functional'

export const usePositionedMenu = <T extends HTMLElement>(isMenuOpen: boolean) => {
  const anchorElementRef = useRef<T>(null)
  const menuElementRef = useRef<HTMLDivElement>(null)

  const [menuCoordinates, setMenuCoordinates] = useState<{ x: string; y: string } | null>(null)
  const [sizeHash, setSizeHash] = useState(`${window.innerWidth}:${window.innerHeight}`)
  const [scrollValue, setScrollValue] = useState(0)
  const [menuWidth, setMenuWidth] = useState(0)
  const [menuHeight, setMenuHeight] = useState(0)
  const [isMenuPositioned, setIsMenuPositioned] = useState(false)

  useEffect(() => {
    if (isMenuOpen) {
      const menuBounds = menuElementRef.current?.getBoundingClientRect()
      if (menuBounds != null) {
        setMenuWidth(menuBounds.width)
        setMenuHeight(menuBounds.height + 8)
      }
    }
  }, [isMenuOpen])

  useEffect(() => {
    const onResize = debounce(() => {
      setSizeHash(`${window.innerWidth}:${window.innerHeight}`)
    }, 50)
    window.addEventListener('resize', onResize, { passive: true })
    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    const onStartScrolling = debounce(() => {
      setScrollValue(window.scrollY)
    }, 50)
    window.addEventListener('scroll', onStartScrolling, { passive: true })
    return () => window.removeEventListener('scroll', onStartScrolling)
  }, [])

  useLayoutEffect(() => {
    const elementAnchor = anchorElementRef.current?.getBoundingClientRect()
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    if (elementAnchor != null) {
      setIsMenuPositioned(false)
      const anchorToRightOffset = menuWidth - elementAnchor?.width
      const anchorToBottomOffset = elementAnchor?.height + 8
      const anchorToTopOffset = menuHeight
      // Check if gap between anchor and right side of screen is wide enough to fit ContextMenu
      if (windowWidth - (menuWidth + elementAnchor.width + 8) > elementAnchor.left) {
        // Check if gap between anchor and bottom of screen is high enough to fit ContextMenu
        if (windowHeight - elementAnchor.bottom > menuHeight) {
          setMenuCoordinates({
            x: `-${anchorToRightOffset}px`,
            y: `${anchorToBottomOffset}px`,
          })
        } else {
          setMenuCoordinates({
            x: `-${anchorToRightOffset}px`,
            y: `-${anchorToTopOffset}px`,
          })
        }
      } else {
        // Gap between anchor and right side of screen is not wide enough
        // Check if gap between anchor and bottom of screen is high enough to fit ContextMenu
        if (windowHeight - elementAnchor.bottom < menuHeight) {
          setMenuCoordinates({
            x: '0px',
            y: `-${anchorToTopOffset}px`,
          })
        } else {
          setMenuCoordinates({
            x: '0px',
            y: `${anchorToBottomOffset}px`,
          })
        }
      }
      setIsMenuPositioned(menuHeight !== 0 && menuWidth !== 0)
    }
  }, [sizeHash, scrollValue, isMenuOpen, menuHeight, menuWidth])

  return { menuCoordinates, isMenuPositioned, anchorElementRef, menuElementRef }
}
