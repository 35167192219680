import { ReactElement } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { BusinessSettingsWrapper } from './composites/layout/BusinessSettingsWrapper'
import { ThemeProvider } from './contexts/ThemeContext'
import { UserProvider } from './contexts/userContext'
import { NoNavbarLayout } from './navigation/NoNavbarLayout'
import { Protected } from './navigation/Protected'
import { RootLayout } from './navigation/RootLayout'
import { Community } from './pages/community/Community'
import { Templates } from './pages/templates/Templates'
import { Dashboard } from './pages/dashboard/Dashboard'
import { GameEditorPage } from './pages/GameEditor/GameEditorPage'
import { Login } from './pages/login/Login'
import { OnboardingWizard } from './pages/OnboardingWizard/OnboardingWizard'
import { OrgLibrary } from './pages/OrgLibrary/OrgLibrary'
import { Reports } from './pages/reports/Reports'
import { Search } from './pages/search/Search'
import { GeneralSettings } from './pages/settings/general/GeneralSettings'
import { MembersSettings } from './pages/settings/organization/MembersSettings'
import { OrganizationSettings } from './pages/settings/organization/OrganizationSettings'
import { SponsoredGames } from './pages/SponsoredGames/SponsoredGames'
import { routes } from './routes'
import { CombineComponents } from './util/combineComponents'
import { HelpMenuProvider } from './contexts/HelpMenuContextProvider'

const protect = (component: ReactElement) => <Protected>{component}</Protected>

const router = createBrowserRouter([
  {
    path: '/',
    // TODO: we can add a generic error page here
    // NOTE: additionally, each route can hav it's own errorElement
    errorElement: null,
    children: [
      {
        element: protect(<NoNavbarLayout />),
        children: [
          {
            element: <RootLayout />,
            children: [
              { index: true, element: <Dashboard /> },
              { path: routes.reports, element: <Reports /> },
              { path: routes.orgLibrary, element: <OrgLibrary /> },
              { path: routes.community, element: <Community /> },
              { path: routes.templates, element: <Templates /> },
              { path: routes.search, element: <Search /> },
              { path: routes.generalSettings, element: <GeneralSettings /> },
              {
                element: <BusinessSettingsWrapper />,
                children: [
                  // NOTE: to be revived soon, once the new webshop goes live
                  // { path: routes.billingSettings, element: <BillingSettings /> },
                  { path: routes.organizationSettings, element: <OrganizationSettings /> },
                  { path: routes.membersSettings, element: <MembersSettings /> },
                  // NOTE: to be revived soon, once we define rules for who gets to see player settings
                  // { path: routes.playersSettings, element: <PlayersSettings /> },
                ],
              },
            ],
          },
          { path: routes.onboardingWizard, element: <OnboardingWizard /> },
          { path: routes.gameEditor, element: <GameEditorPage /> },
          { path: routes.gameEditorWithId, element: <GameEditorPage /> },
          { path: routes.sponsoredGames, element: <SponsoredGames /> },
        ],
      },
    ],
  },
  { path: routes.login, element: <Login /> },
])

function App() {
  return (
    <CombineComponents components={[ThemeProvider, UserProvider, HelpMenuProvider]}>
      <RouterProvider router={router} />
    </CombineComponents>
  )
}

export default App
