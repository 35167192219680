import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../../../common/components/ModalWrapper/ModalWrapper'
import { Button } from '../../../../common/components/button/Button'
import { useTrapFocus } from '../../../../hooks/useTrapFocus'
import { GameBoard } from '../../types'
import styles from './GameBoardsPicker.module.css'
import { UploadGameBoard } from './UploadGameBoard'

type AddGameBoardsModalProps = {
  previouslySelectedBoards: GameBoard[]
  allGameBoards: GameBoard[]
  onClickAddSelection: (selectedGameBoards: GameBoard[]) => void
  onClickBack: () => void
  onUploadGameBoard: (newBoard: GameBoard) => void
}

export const AddGameBoardsModal: React.FC<AddGameBoardsModalProps> = ({
  allGameBoards,
  previouslySelectedBoards,
  onClickAddSelection,
  onClickBack,
  onUploadGameBoard,
}) => {
  const { t } = useTranslation()
  const modalRef = useTrapFocus<HTMLDivElement>()
  const [selectedGameBoards, setSelectedGameBoards] = useState<GameBoard[]>(previouslySelectedBoards)

  const handleChangeSelectedBoard = (gameBoard: GameBoard) => (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    if (checked) {
      setSelectedGameBoards((prevState) => [...prevState, gameBoard])
    } else {
      setSelectedGameBoards((prevState) => prevState.filter(({ url }) => url !== gameBoard.url))
    }
  }

  const isBoardSelected = useCallback(
    (boardUrl: string) => {
      return selectedGameBoards.some(({ url }) => url === boardUrl)
    },
    [selectedGameBoards],
  )

  const onClickAddSelectionInternal = () => {
    onClickAddSelection(selectedGameBoards)
  }

  const onUploadGameBoardInternal = useCallback(
    (newBoard: GameBoard) => {
      onUploadGameBoard(newBoard)
      setSelectedGameBoards((prev) => [...prev, newBoard])
    },
    [onUploadGameBoard],
  )

  return (
    <ModalWrapper>
      <div className={styles.backdrop} />
      <div ref={modalRef} className={styles.addGameBoardsModal}>
        <Button
          styleVariant='borderless'
          label={t('game_editor.game_boards.back_button', 'Back')}
          sizeVariant='small'
          onClick={onClickBack}
        />
        <h1>{t('game_editor.game_boards.add_board_title', 'Add game boards')}</h1>
        <h4>{t('game_editor.game_boards.add_board_subtitle', 'Choose a premade board or upload your own')}</h4>
        <div>
          <UploadGameBoard onUploadSuccessful={onUploadGameBoardInternal} disabled={selectedGameBoards.length >= 4} />
          <div className={styles.boardsGrid}>
            {allGameBoards.map((board) => {
              const isSelected = isBoardSelected(board.url)
              return (
                <label className={classNames(styles.selectableBoard)} key={board.fileName + board.name}>
                  {selectedGameBoards.length >= 4 && !isSelected && <div className={styles.dimOverlay} />}
                  <div>
                    <img alt={board.name} src={board.url} />
                  </div>
                  <input
                    className={styles.originalCheckbox}
                    id={board.url}
                    name={board.name}
                    type='checkbox'
                    onChange={handleChangeSelectedBoard(board)}
                    checked={isSelected}
                    disabled={selectedGameBoards.length >= 4 && !isSelected}
                  />
                  <span style={{ margin: 'auto' }}>{board.name}</span>
                </label>
              )
            })}
          </div>
          <Button
            styleVariant='primary'
            sizeVariant='large'
            label={t('game_editor.game_boards.apply_selection', 'Apply selection')}
            disabled={!previouslySelectedBoards.length && !selectedGameBoards.length}
            onClick={onClickAddSelectionInternal}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}
