import React, { useCallback } from 'react'
import { InputField } from '../../../../../common/components/input/InputField'
import { useTranslation } from 'react-i18next'
import styles from './Steps.module.css'

type GameInfoStepProps = {
  gameName: string
  description: string
  onChangeInput: (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, stepValueKey: string) => void
}

export const GameInfoStep: React.FC<GameInfoStepProps> = ({ gameName, description, onChangeInput }) => {
  const { t } = useTranslation()
  const onChangeInputInternal = useCallback(
    (stepValueKey: string) => (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChangeInput(e, stepValueKey)
    },
    [onChangeInput],
  )

  return (
    <div className={styles.container}>
      <h1>{t('game_editor.wizard.game_info_step.title', 'Name the game')}</h1>
      <h4>{t('game_editor.wizard.game_info_step.subtitle', 'What will we call it?')}</h4>
      <div className={styles.row}>
        <div className={styles.labelInputWrapper}>
          <label>{t('game_editor.wizard.game_info_step.name_label', 'Name')}</label>
          <InputField
            name={'gameName'}
            placeholder={t('game_editor.wizard.game_info_step.name_placeholder', 'Game name')}
            style={{ maxWidth: '20rem' }}
            onChange={onChangeInputInternal('name')}
            value={gameName}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.labelInputWrapper}>
          <label>{t('game_editor.wizard.game_info_step.description_label', 'Description')}</label>
          <textarea
            name={'description'}
            placeholder={t(
              'game_editor.wizard.game_info_step.description_placeholder',
              'Write a general description of the game',
            )}
            onChange={onChangeInputInternal('description')}
            value={description}
          />
        </div>
      </div>
    </div>
  )
}
