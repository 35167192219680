import classNames from 'classnames'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import { LibrarySource } from '../../api/gameTypes'
import { parseLibraryGamesResponseToGameCards } from '../../api/typeConverters'
import { startNewTrial } from '../../api/userApiService'
import { ValidationNotificationSpy } from '../../common/components/Form/ValidationNotificationSpy/ValidationNotificationSpy'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { Button } from '../../common/components/button/Button'
import {
  COUNTRY_SELECT_DEFAULT_OPTION,
  LANGUAGE_SELECT_DEFAULT_OPTION,
  LANGUAGE_SELECT_OPTIONS,
} from '../../common/constants'
import shared from '../../common/styles/shared.module.css'
import { useNotification } from '../../contexts/NotificationContext'
import { useTheme } from '../../contexts/ThemeContext'
import { useUser } from '../../contexts/userContext'
import { routes } from '../../routes'
import { TGameCard } from '../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../util/string'
import styles from './OnboardingWizard.module.css'
import { FORM_STEPS, parseFormValuesToSubmitData } from './helpers'
import { SuggestionStep } from './steps/SuggestionStep'
import { OnboardingForm } from './types'

const TOTAL_STEPS_COUNT = FORM_STEPS.length + 1

export const OnboardingWizard = () => {
  const navigate = useNavigate()
  const { user, updateUser } = useUser()
  const { logoUrl } = useTheme()
  const { t, i18n } = useTranslation()
  const { notifyError } = useNotification()

  const [stepIndex, setStepIndex] = useState<number>(0)
  const [suggestedGames, setSuggestedGames] = useState<TGameCard[]>([])
  const [initialValues] = useState<Partial<OnboardingForm>>(() => {
    const userIndustry = user?.activeBusiness?.industry
    return {
      language: LANGUAGE_SELECT_DEFAULT_OPTION.value,
      country: COUNTRY_SELECT_DEFAULT_OPTION.value,
      ...(!safeIsNullOrEmpty(userIndustry) && {
        organisationType: userIndustry === 'Corporate' ? 'Business' : userIndustry,
      }),
      termsOfService: false,
    }
  })

  const previous = () => setStepIndex((prev) => Math.max(prev - 1, 0))

  const next = () => setStepIndex((prev) => Math.min(prev + 1, TOTAL_STEPS_COUNT - 1))

  const validate = (values: Partial<OnboardingForm>) => {
    return FORM_STEPS?.[stepIndex]?.validateCreator(t)(values) ?? {}
  }

  const onSubmit = (values: OnboardingForm) => {
    if (stepIndex === FORM_STEPS.length - 1) {
      updateUser({ key: 'termsAccepted', value: true }, true)
      return startNewTrial(parseFormValuesToSubmitData(values)).then((communityGamesResponse) => {
        if (communityGamesResponse.success) {
          setSuggestedGames(
            parseLibraryGamesResponseToGameCards(communityGamesResponse.value, LibrarySource.COMMUNITY).slice(0, 2),
          )
          next()
        } else {
          notifyError({
            title: t('onboarding_wizard.submit_error_notification.title', 'Failed to suggest games'),
            content: t(
              'onboarding_wizard.submit_error_notification.content',
              'Please try again or contact us for support',
            ),
          })
        }
      })
    } else {
      next()
    }
  }

  const handleCreateNewGame = () => {
    navigate(routes.gameEditor)
  }

  const handleToDashboard = () => {
    navigate(routes.homepage)
  }

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value)
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <div className={styles.language}>
          {t('onboarding_wizard.language', 'Language')}
          {': '}
          <Select
            options={LANGUAGE_SELECT_OPTIONS}
            defaultValue={LANGUAGE_SELECT_DEFAULT_OPTION}
            onChange={handleChangeLanguage}
            className={styles.languageSelect}
          />
        </div>

        <div className={styles.stepContentWrapper}>
          <div className={classNames(styles.wizardHeader)}>
            {stepIndex > 0 && (
              <Button
                label={t('onboarding_wizard.buttons_text.back', 'Back')}
                onClick={previous}
                sizeVariant='small'
                styleVariant='borderless'
              />
            )}
            <img
              src={logoUrl}
              alt='logo'
              height={26}
              className={classNames(styles.seppoLogo, stepIndex === 0 && styles.firstStepImageSpacing)}
            />
          </div>

          <Form<OnboardingForm> onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({ handleSubmit, submitting, values }) => {
              return stepIndex <= FORM_STEPS.length - 1 ? (
                <form onSubmit={handleSubmit} className={styles.onboardingForm}>
                  {FORM_STEPS[stepIndex].component}
                  <div className={classNames(styles.buttonsContainer, shared.mAxisSA)}>
                    <Button
                      label={
                        stepIndex === FORM_STEPS.length - 1
                          ? submitting
                            ? t('onboarding_wizard.buttons_text.loading', 'Loading...')
                            : t('onboarding_wizard.buttons_text.submit', 'Submit')
                          : t('onboarding_wizard.buttons_text.continue', 'Continue')
                      }
                      styleVariant='primary'
                      sizeVariant='large'
                      type='submit'
                      disabled={stepIndex === FORM_STEPS.length - 1 && submitting}
                    />
                  </div>
                  <ValidationNotificationSpy />
                </form>
              ) : (
                <>
                  <SuggestionStep games={suggestedGames} hasTemplates={values.organisationType === 'Business'} />
                  <div className={classNames(styles.buttonsContainer, shared.mAxisSA)}>
                    <Button
                      label={t('onboarding_wizard.suggestion_step.create_new_game', 'Create new game from scratch')}
                      onClick={handleCreateNewGame}
                      styleVariant='tertiary'
                      iconLeft='plus'
                      sizeVariant='tiny'
                    />
                  </div>
                  <div className={classNames(styles.buttonsContainer, shared.mAxisSA)}>
                    <Button
                      buttonStyles={{
                        textTransform: 'uppercase',
                        color: 'var(--grey-700)',
                        textDecoration: 'underline',
                      }}
                      label={t('onboarding_wizard.suggestion_step.go_to_dashboard', 'Take me to the dashboard')}
                      onClick={handleToDashboard}
                      styleVariant='borderless'
                      sizeVariant='small'
                    />
                  </div>
                </>
              )
            }}
          </Form>

          <p>
            {t('onboarding_wizard.step', 'Step')} {stepIndex + 1} / {TOTAL_STEPS_COUNT}
          </p>
        </div>
      </div>
    </div>
  )
}
