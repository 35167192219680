import classNames from 'classnames'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { ArrowRightIcon, MoreIcon } from '../../../../../common/components/icons'
import styles from './Accordion.module.css'

type AccordionProps = {
  title: string
  isOpen?: boolean
}

export const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  children,
  title = 'Title',
  isOpen = false,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleClickAccordionMenu = useCallback(() => setIsMenuOpen((prevState) => !prevState), [])
  const handleClickAccordion = useCallback(() => setIsAccordionOpen((prevState) => !prevState), [])

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <button type='button' className={styles.accordionButton} onClick={handleClickAccordion}>
          <span className={classNames(styles.accordionChevronWrapper, isAccordionOpen && styles.accordionOpen)}>
            <ArrowRightIcon color={'currentColor'} />
          </span>
          <h4 className={'normal'}>{title}</h4>
        </button>
        <button type='button' className={styles.actionsMenuButton} onClick={handleClickAccordionMenu}>
          <MoreIcon />
        </button>
        {isMenuOpen && <div>actions menu</div>}
      </div>
      {isAccordionOpen && children}
    </div>
  )
}
