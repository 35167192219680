// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SponsoredGames_container__cW5Ib {\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n  padding: 28px 32px 28px 43px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SponsoredGames/SponsoredGames.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,4BAA4B;AAC9B","sourcesContent":[".container {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  padding: 28px 32px 28px 43px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SponsoredGames_container__cW5Ib"
};
export default ___CSS_LOADER_EXPORT___;
