import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside'
import { Button } from '../../../common/components/button/Button'
import { GameAction } from '../../../types/commonTypes'
import styles from './GamePreviewModalSidebar.module.css'
import { SidebarMoreMenu } from './SidebarMoreMenu'

export type SidebarActionsProps = {
  isLibrary: boolean
  onGameAction: (action: GameAction) => void
}

const buttonId = 'more-button-id'
const buttonStyles = { height: '44px', width: 'fit-content' }

export const SidebarActions: React.FC<SidebarActionsProps> = ({ isLibrary, onGameAction }) => {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false)
  const { t } = useTranslation()

  const handleMenuItemClick = (action: GameAction) => {
    setIsMoreMenuOpen(false)
    onGameAction(action)
  }

  const menuRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(menuRef, (e) => {
    if ((e.target as EventTarget & { getAttribute: (attr: string) => string })?.getAttribute?.('id') !== buttonId) {
      setIsMoreMenuOpen(false)
    }
  })

  return (
    <div className={styles.sidebarActionsContainer}>
      {isLibrary ? (
        <Button
          sizeVariant='small'
          style={buttonStyles}
          label={t('game_preview_modal.actions_text.import', 'Import')}
          iconLeft='plus'
          styleVariant='community'
          onClick={() => onGameAction(GameAction.IMPORT)}
        />
      ) : (
        <>
          <Button
            sizeVariant='small'
            style={buttonStyles}
            label={t('game_preview_modal.actions_text.edit', 'Edit game')}
            iconLeft='duplicate'
            styleVariant='primary'
            onClick={() => onGameAction(GameAction.EDIT)}
          />
          <div>
            <Button
              sizeVariant='small'
              label={t('game_preview_modal.actions_text.more', 'More')}
              iconLeft='more'
              styleVariant='tertiary'
              onClick={() => setIsMoreMenuOpen((prev) => !prev)}
              buttonStyles={{ minWidth: 'auto' }}
              id={buttonId}
            />
            {isMoreMenuOpen && <SidebarMoreMenu ref={menuRef} onMenuItemClick={handleMenuItemClick} />}
          </div>
        </>
      )}
    </div>
  )
}
