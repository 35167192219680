import React, { useCallback, useState } from 'react'
import { Wizard } from '../../../../composites/wizard/Wizard'
import { CreateGameOption, DEFAULT_GAME_BOARD_SETTINGS, GameBoardSettings, GameForm } from '../../types'
import { GameBoardStep } from './steps/GameBoardStep'
import { GameInfoStep } from './steps/GameInfoStep'
import { NewGameStep } from './steps/NewGameStep'
import { extendGameDataWithLiveMap } from '../../helpers'
import { MapInstanceID, useMapApi } from '../../../../util/map'

type QuickStartWizardProps = {
  loading: boolean
  onSubmit: (data: GameForm) => void
  onOpenGameSettings: () => void
  onClose: () => void
}

export const QuickStartWizard: React.FC<QuickStartWizardProps> = ({
  loading,
  onSubmit,
  onOpenGameSettings,
  onClose,
}) => {
  const [createOption, setCreateOption] = useState<CreateGameOption>(CreateGameOption.QUICK_START)
  const [gameFormState, setGameFormState] = useState<GameForm>({
    gameBoardSettings: DEFAULT_GAME_BOARD_SETTINGS,
    description: '',
    name: '',
  })
  const [stepIndex, setStepIndex] = useState(1)
  const { map } = useMapApi(MapInstanceID.MODAL)

  const handleClickBack = useCallback(() => {
    setStepIndex((currentIndex) => Math.max(1, currentIndex - 1))
  }, [])

  const onClickContinueInternal = useCallback(() => {
    if (stepIndex === 1 && createOption === CreateGameOption.GAME_SETTINGS) {
      onOpenGameSettings()
    } else if (stepIndex === 1 && createOption === CreateGameOption.SEARCH_COMMUNITY)
      alert('Not implemented yet!')
    else if (stepIndex === 3) {
      const submitValues = extendGameDataWithLiveMap(gameFormState, map)
      onSubmit(submitValues)
    } else {
      setStepIndex((prev) => Math.min(prev + 1, 3))
    }
  }, [stepIndex, createOption, onOpenGameSettings, onSubmit, gameFormState, map])

  const handleChangeOption = useCallback((option: CreateGameOption) => {
    setCreateOption(option)
  }, [])

  const handleChangeInput = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, stepValueKey: string) => {
      const { value } = e.currentTarget
      setGameFormState((prevState) => ({
        ...prevState,
        [stepValueKey]: value,
      }))
    },
    [],
  )

  const handleChangeGameBoardSettings = useCallback((settings: GameBoardSettings) => {
    setGameFormState((prev) => {
      return {
        ...prev,
        gameBoardSettings: settings,
      }
    })
  }, [])

  return (
    <Wizard
      stepIndex={stepIndex}
      hasBackButton
      onClickContinue={onClickContinueInternal}
      onClickSkip={onClose}
      onClickBack={handleClickBack}
      loading={loading}
      steps={[
        <NewGameStep createGameOption={createOption} onChangeOption={handleChangeOption} />,
        <GameInfoStep gameName={gameFormState.name} description={gameFormState.description} onChangeInput={handleChangeInput} />,
        <GameBoardStep initialSettings={gameFormState.gameBoardSettings} onChange={handleChangeGameBoardSettings}/>,
      ]}
    />
  )
}
