// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToggleFormField_controlWithLabelContainer__DCLF7 {\n  display: flex;\n  align-items: center;\n  padding: 8px;\n}\n\n.ToggleFormField_labelContainer__8CPL\\+ {\n  cursor: pointer;\n  padding-left: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n\n.ToggleFormField_labelSubtext__oWL3O {\n  font-size: 0.75rem;\n  color: var(--grey-700);\n}\n\n.ToggleFormField_withError__HY2\\+H {\n  border-radius: 8px;\n  outline: 0.125rem solid var(--validation-error);\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/Form/ToggleFormField/ToggleFormField.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,+CAA+C;AACjD","sourcesContent":[".controlWithLabelContainer {\n  display: flex;\n  align-items: center;\n  padding: 8px;\n}\n\n.labelContainer {\n  cursor: pointer;\n  padding-left: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n\n.labelSubtext {\n  font-size: 0.75rem;\n  color: var(--grey-700);\n}\n\n.withError {\n  border-radius: 8px;\n  outline: 0.125rem solid var(--validation-error);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlWithLabelContainer": "ToggleFormField_controlWithLabelContainer__DCLF7",
	"labelContainer": "ToggleFormField_labelContainer__8CPL+",
	"labelSubtext": "ToggleFormField_labelSubtext__oWL3O",
	"withError": "ToggleFormField_withError__HY2+H"
};
export default ___CSS_LOADER_EXPORT___;
