import { useTranslation } from 'react-i18next'

import { ModalWrapper } from '../../common/components/ModalWrapper/ModalWrapper'
import { Button } from '../../common/components/button/Button'
import { getIcon } from '../../common/components/icons/utils'
import { useOnEscPress } from '../../hooks/useOnEscPress'
import { useTrapFocus } from '../../hooks/useTrapFocus'
import styles from './LicenseModal.module.css'
import { LinkButton } from '../../common/components/button/LinkButton'
import { openNinchat } from '../../util/ninchat'
import { useFadeAnimation } from '../../hooks/FadeAnimation/useFadeAnimation'
import classNames from 'classnames'

type LicenseModalProps = {
  trialDaysLeft: number
  // TODO: check if we use more than one starting price (different by country?)
  startingPricePerMonth: number
  // TODO: check if we use more than one currency
  currency?: string
  onClickClose: () => void
}

export const LicenseModal: React.FC<LicenseModalProps> = ({
  trialDaysLeft,
  startingPricePerMonth,
  currency,
  onClickClose,
}) => {
  const { t } = useTranslation()
  const modalRef = useTrapFocus<HTMLDivElement>()
  useOnEscPress(onClickClose)
  const { fadeTransition, closeWithDelay } = useFadeAnimation()

  const onClickContactSupport = () => {
    closeWithDelay(350, onClickClose)
    openNinchat()
  }

  const onClickCloseInternal = () => {
    closeWithDelay(350, onClickClose)
  }

  return (
    <ModalWrapper>
      <div className={styles.backdrop} />
      <div ref={modalRef} className={classNames(styles.container, fadeTransition)}>
        <button autoFocus className={styles.closeButton} onClick={onClickCloseInternal}>
          {getIcon('close')}
        </button>
        <span className={styles.circleWithIcons}>{getIcon('CircleWithIcons')}</span>
        <div className={styles.middleContainer}>
          <h1 className={styles.title}>{t('top_bar.license_modal.title', 'Get your license to stay creating')}</h1>
          <p className={styles.boldText}>
            {t('top_bar.license_modal.expiration.trial_days', {
              count: trialDaysLeft,
              defaultValue: 'In %{count} days you will lose access to creating new games',
            })}
          </p>
          <p className={styles.middleTextSpacing}>{t('top_bar.license_modal.body', '')}</p>
          <p className={styles.boldText}>
            {t('top_bar.license_modal.billing', {
              startingPricePerMonth,
              currency,
              defaultValue: 'Prices starting at %{startingPricePerMonth} %{currency} / player / month',
            })}
          </p>
        </div>
        <div className={styles.buttonsContainer}>
          <LinkButton
            label={t('top_bar.license_modal.buttons.choose_license', 'Choose your licence')}
            href='https://play.seppo.io/shop'
            target='_blank'
            rel='noreferrer'
            anchorStyle={{ fontSize: '0.8rem', textTransform: 'uppercase' }}
          />
          <Button
            styleVariant='tertiary'
            sizeVariant='tiny'
            label={t('top_bar.license_modal.buttons.contact_support', 'Contact support')}
            onClick={onClickContactSupport}
          />
          <Button
            styleVariant='borderless'
            sizeVariant='tiny'
            label={t('top_bar.license_modal.buttons.continue_trial', 'Continue with trial')}
            onClick={onClickCloseInternal}
            buttonStyles={{
              color: 'var(--grey-600)',
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}
