import { EntityMessages } from './helpers'

type EntityMessagesIndex = { [key: number]: EntityMessages }

export const HIDDEN_MESSAGES = 'hidden-messages'
export const HIDDEN_END_MESSAGES = 'hidden-end-messages'
export const TRIAL_PERIOD_LENGTH = 14

export const CorporateTrial: EntityMessagesIndex = {
  1: {
    id: 'trial:1',
    // t('onboarding_message.corporate.trial.day1.title'),
    title: 'onboarding_message.corporate.trial.day1.title',
    // t('onboarding_message.corporate.trial.day1.body')
    body: 'onboarding_message.corporate.trial.day1.body',
    // t('onboarding_message.corporate.trial.day1.primary_button')
    primaryButton: 'onboarding_message.corporate.trial.day1.primary_button',
  },
  2: {
    id: 'trial:2',
    // t('onboarding_message.corporate.trial.day2.title')
    title: 'onboarding_message.corporate.trial.day2.title',
    // t('onboarding_message.corporate.trial.day2.body')
    body: 'onboarding_message.corporate.trial.day2.body',
    // t('onboarding_message.corporate.trial.day2.primary_button')
    primaryButton: 'onboarding_message.corporate.trial.day2.primary_button',
  },
  4: {
    id: 'trial:4',
    // t('onboarding_message.corporate.trial.day4.title')
    title: 'onboarding_message.corporate.trial.day4.title',
    // t('onboarding_message.corporate.trial.day4.body')
    body: 'onboarding_message.corporate.trial.day4.body',
    // t('onboarding_message.corporate.trial.day4.primary_button')
    primaryButton: 'onboarding_message.corporate.trial.day4.primary_button',
  },
  6: {
    id: 'trial:6',
    // t('onboarding_message.corporate.trial.day6.title')
    title: 'onboarding_message.corporate.trial.day6.title',
    // t('onboarding_message.corporate.trial.day6.body')
    body: 'onboarding_message.corporate.trial.day6.body',
    // t('onboarding_message.corporate.trial.day6.primary_button')
    primaryButton: 'onboarding_message.corporate.trial.day6.primary_button',
    // t('onboarding_message.corporate.trial.day6.secondary_button')
    secondaryButton: 'onboarding_message.corporate.trial.day6.secondary_button',
  },
  8: {
    id: 'trial:8',
    // t('onboarding_message.corporate.trial.day8.title')
    title: 'onboarding_message.corporate.trial.day8.title',
    // t('onboarding_message.corporate.trial.day8.body')
    body: 'onboarding_message.corporate.trial.day8.body',
  },
  10: {
    id: 'trial:10',
    // t('onboarding_message.corporate.trial.day10.title')
    title: 'onboarding_message.corporate.trial.day10.title',
    // t('onboarding_message.corporate.trial.day10.body')
    body: 'onboarding_message.corporate.trial.day10.body',
  },
  12: {
    id: 'trial:12',
    // t('onboarding_message.corporate.trial.day12.title')
    title: 'onboarding_message.corporate.trial.day12.title',
    // t('onboarding_message.corporate.trial.day12.body')
    body: 'onboarding_message.corporate.trial.day12.body',
    // t('onboarding_message.corporate.trial.day12.primary_button')
    primaryButton: 'onboarding_message.corporate.trial.day12.primary_button',
  },
  14: {
    id: 'trial:14',
    // t('onboarding_message.corporate.trial.day14.title')
    title: 'onboarding_message.corporate.trial.day14.title',
    // t('onboarding_message.corporate.trial.day14.body')
    body: 'onboarding_message.corporate.trial.day14.body',
    // t('onboarding_message.corporate.trial.day14.primary_button')
    primaryButton: 'onboarding_message.corporate.trial.day14.primary_button',
    // t('onboarding_message.corporate.trial.day14.secondary_button')
    secondaryButton: 'onboarding_message.corporate.trial.day14.secondary_button',
  },
  15: {
    id: 'trial:15',
    // t('onboarding_message.corporate.trial.day15.title')
    title: 'onboarding_message.corporate.trial.day15.title',
    // t('onboarding_message.corporate.trial.day15.body')
    body: 'onboarding_message.corporate.trial.day15.body',
    // t('onboarding_message.corporate.trial.day15.primary_button')
    primaryButton: 'onboarding_message.corporate.trial.day15.primary_button',
    // t('onboarding_message.corporate.trial.day15.secondary_button')
    secondaryButton: 'onboarding_message.corporate.trial.day15.secondary_button',
  },
}

export const CorporatePaying: EntityMessagesIndex = {
  1: {
    id: 'paying:1',
    // t('onboarding_message.corporate.paying.day1.title')
    title: 'onboarding_message.corporate.paying.day1.title',
    // t('onboarding_message.corporate.paying.day1.body')
    body: 'onboarding_message.corporate.paying.day1.body',
    // t('onboarding_message.corporate.paying.day1.primary_button')
    primaryButton: 'onboarding_message.corporate.paying.day1.primary_button',
  },
  2: {
    id: 'paying:2',
    // t('onboarding_message.corporate.paying.day2.title')
    title: 'onboarding_message.corporate.paying.day2.title',
    // t('onboarding_message.corporate.paying.day2.body')
    body: 'onboarding_message.corporate.paying.day2.body',
    // t('onboarding_message.corporate.paying.day2.primary_button')
    primaryButton: 'onboarding_message.corporate.paying.day2.primary_button',
  },
  4: {
    id: 'paying:4',
    // t('onboarding_message.corporate.paying.day4.title')
    title: 'onboarding_message.corporate.paying.day4.title',
    // t('onboarding_message.corporate.paying.day4.body')
    body: 'onboarding_message.corporate.paying.day4.body',
    // t('onboarding_message.corporate.paying.day4.primary_button')
    primaryButton: 'onboarding_message.corporate.paying.day4.primary_button',
  },
  6: {
    id: 'paying:6',
    // t('onboarding_message.corporate.paying.day6.title')
    title: 'onboarding_message.corporate.paying.day6.title',
    // t('onboarding_message.corporate.paying.day6.body')
    body: 'onboarding_message.corporate.paying.day6.body',
    // t('onboarding_message.corporate.paying.day6.primary_button')
    primaryButton: 'onboarding_message.corporate.paying.day6.primary_button',
  },
  8: {
    id: 'paying:8',
    // t('onboarding_message.corporate.paying.day8.title')
    title: 'onboarding_message.corporate.paying.day8.title',
    // t('onboarding_message.corporate.paying.day8.body')
    body: 'onboarding_message.corporate.paying.day8.body',
  },
  10: {
    id: 'paying:10',
    // t('onboarding_message.corporate.paying.day10.title')
    title: 'onboarding_message.corporate.paying.day10.title',
    // t('onboarding_message.corporate.paying.day10.body')
    body: 'onboarding_message.corporate.paying.day10.body',
  },
  12: {
    id: 'paying:12',
    // t('onboarding_message.corporate.paying.day12.title')
    title: 'onboarding_message.corporate.paying.day12.title',
    // t('onboarding_message.corporate.paying.day12.body')
    body: 'onboarding_message.corporate.paying.day12.body',
    // t('onboarding_message.corporate.paying.day12.primary_button')
    primaryButton: 'onboarding_message.corporate.paying.day12.primary_button',
  },
}

export const EducationTrial: EntityMessagesIndex = {
  1: {
    id: 'trial:1',
    // t('onboarding_message.education.trial.day1.title')
    title: 'onboarding_message.education.trial.day1.title',
    // t('onboarding_message.education.trial.day1.body')
    body: 'onboarding_message.education.trial.day1.body',
    // t('onboarding_message.education.trial.day1.primary_button')
    primaryButton: 'onboarding_message.education.trial.day1.primary_button',
  },
  2: {
    id: 'trial:2',
    // t('onboarding_message.education.trial.day2.title')
    title: 'onboarding_message.education.trial.day2.title',
    // t('onboarding_message.education.trial.day2.body')
    body: 'onboarding_message.education.trial.day2.body',
    // t('onboarding_message.education.trial.day2.primary_button')
    primaryButton: 'onboarding_message.education.trial.day2.primary_button',
  },
  4: {
    id: 'trial:4',
    // t('onboarding_message.education.trial.day4.title')
    title: 'onboarding_message.education.trial.day4.title',
    // t('onboarding_message.education.trial.day4.body')
    body: 'onboarding_message.education.trial.day4.body',
    // t('onboarding_message.education.trial.day4.primary_button')
    primaryButton: 'onboarding_message.education.trial.day4.primary_button',
  },
  6: {
    id: 'trial:6',
    // t('onboarding_message.education.trial.day6.title')
    title: 'onboarding_message.education.trial.day6.title',
    // t('onboarding_message.education.trial.day6.body')
    body: 'onboarding_message.education.trial.day6.body',
    // t('onboarding_message.education.trial.day6.primary_button')
    primaryButton: 'onboarding_message.education.trial.day6.primary_button',
  },
  8: {
    id: 'trial:8',
    // t('onboarding_message.education.trial.day8.title')
    title: 'onboarding_message.education.trial.day8.title',
    // t('onboarding_message.education.trial.day8.body')
    body: 'onboarding_message.education.trial.day8.body',
  },
  10: {
    id: 'trial:10',
    // t('onboarding_message.education.trial.day10.title')
    title: 'onboarding_message.education.trial.day10.title',
    // t('onboarding_message.education.trial.day10.body')
    body: 'onboarding_message.education.trial.day10.body',
  },
  12: {
    id: 'trial:12',
    // t('onboarding_message.education.trial.day12.title')
    title: 'onboarding_message.education.trial.day12.title',
    // t('onboarding_message.education.trial.day12.body')
    body: 'onboarding_message.education.trial.day12.body',
    // t('onboarding_message.education.trial.day12.primary_button')
    primaryButton: 'onboarding_message.education.trial.day12.primary_button',
  },
  14: {
    id: 'trial:14',
    // t('onboarding_message.education.trial.day14.title')
    title: 'onboarding_message.education.trial.day14.title',
    // t('onboarding_message.education.trial.day14.body')
    body: 'onboarding_message.education.trial.day14.body',
    // t('onboarding_message.education.trial.day14.primary_button')
    primaryButton: 'onboarding_message.education.trial.day14.primary_button',
  },
  15: {
    id: 'trial:15',
    // t('onboarding_message.education.trial.day15.title')
    title: 'onboarding_message.education.trial.day15.title',
    // t('onboarding_message.education.trial.day15.body')
    body: 'onboarding_message.education.trial.day15.body',
    // t('onboarding_message.education.trial.day15.primary_button')
    primaryButton: 'onboarding_message.education.trial.day15.primary_button',
  },
}

export const EducationPaying: EntityMessagesIndex = {
  1: {
    id: 'paying:1',
    // t('onboarding_message.education.paying.day1.title')
    title: 'onboarding_message.education.paying.day1.title',
    // t('onboarding_message.education.paying.day1.body')
    body: 'onboarding_message.education.paying.day1.body',
    // t('onboarding_message.education.paying.day1.primary_button')
    primaryButton: 'onboarding_message.education.paying.day1.primary_button',
  },
  2: {
    id: 'paying:2',
    // t('onboarding_message.education.paying.day2.title')
    title: 'onboarding_message.education.paying.day2.title',
    // t('onboarding_message.education.paying.day2.body')
    body: 'onboarding_message.education.paying.day2.body',
    // t('onboarding_message.education.paying.day2.primary_button')
    primaryButton: 'onboarding_message.education.paying.day2.primary_button',
    // t('onboarding_message.education.paying.day2.secondary_button')
    secondaryButton: 'onboarding_message.education.paying.day2.secondary_button',
  },
  4: {
    id: 'paying:4',
    // t('onboarding_message.education.paying.day4.title')
    title: 'onboarding_message.education.paying.day4.title',
    // t('onboarding_message.education.paying.day4.body')
    body: 'onboarding_message.education.paying.day4.body',
    // t('onboarding_message.education.paying.day4.primary_button')
    primaryButton: 'onboarding_message.education.paying.day4.primary_button',
  },
  6: {
    id: 'paying:6',
    // t('onboarding_message.education.paying.day6.title')
    title: 'onboarding_message.education.paying.day6.title',
    // t('onboarding_message.education.paying.day6.body')
    body: 'onboarding_message.education.paying.day6.body',
    // t('onboarding_message.education.paying.day6.primary_button')
    primaryButton: 'onboarding_message.education.paying.day6.primary_button',
  },
  8: {
    id: 'paying:8',
    // t('onboarding_message.education.paying.day8.title')
    title: 'onboarding_message.education.paying.day8.title',
    // t('onboarding_message.education.paying.day8.body')
    body: 'onboarding_message.education.paying.day8.body',
  },
  10: {
    id: 'paying:10',
    // t('onboarding_message.education.paying.day10.title')
    title: 'onboarding_message.education.paying.day10.title',
    // t('onboarding_message.education.paying.day10.body')
    body: 'onboarding_message.education.paying.day10.body',
  },
  12: {
    id: 'paying:12',
    // t('onboarding_message.education.paying.day12.title')
    title: 'onboarding_message.education.paying.day12.title',
    // t('onboarding_message.education.paying.day12.body')
    body: 'onboarding_message.education.paying.day12.body',
    // t('onboarding_message.education.paying.day12.primary_button')
    primaryButton: 'onboarding_message.education.paying.day12.primary_button',
  },
}
