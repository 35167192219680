import axios, { AxiosInstance, AxiosError } from 'axios'
import { config as appConfig } from '../config'

const DEBUG = false

export function createAxios(authorization: string | null = null): AxiosInstance {
  let _auth = authorization
  if (!_auth) {
    _auth = localStorage.getItem('sessionKey') as string
  }
  const instance = axios.create({
    baseURL: appConfig.api.url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (_auth) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${encodeURIComponent(_auth)}`
  }

  instance.interceptors.response.use(
    (response) => {
      const { status, data, config } = response

      if (DEBUG) console.log([status, data, config].join(' '))

      return response
    },
    (error) => {
      const { response }: AxiosError = error

      if (response) {
        console.log(response)
        if ((response?.data as any).reason === 'NOT_AUTHENTICATED' && window.location.href.indexOf('login') < 0) {
          console.log('Need to re-login')
          window.location.href = '/login'
        }
      } else {
        console.log('Request failed')
        console.log(`${error.name}: ${error.message}`)
      }

      throw error
    },
  )

  return instance
}
