import React, { useLayoutEffect } from 'react'
import { MapType } from '../../../../api/gameTypes'

import { MapInstanceID, useMap } from '../../../../util/map'
import { MapOptions } from '../../types'

type MapboxContainerProps = {
  boardType: MapType
  showMapbox: boolean
  mapInstanceId: MapInstanceID
  mapOptions: MapOptions
}

export const MapboxContainer: React.FC<MapboxContainerProps> = ({
  showMapbox,
  mapInstanceId,
  mapOptions: { center, zoom },
}) => {
  const map = useMap(mapInstanceId)

  useLayoutEffect(() => {
    map.api.jumpTo({ center: [...center], zoom })
  }, [center, zoom, map])

  return (
    <div
      ref={map.elementRef}
      style={{
        width: showMapbox ? '100%' : 0,
        height: showMapbox ? '100%' : 0,
      }}
    />
  )
}
