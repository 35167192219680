import { useCallback, useState } from 'react'
import { Button } from '../../../../../common/components/button/Button'
import { RoundButton } from '../../../../../common/components/button/RoundButton'
import { Game } from '../../../../../types/commonTypes'
import { safeParseInt } from '../../../../../util/number'
import { TaskModal } from '../TaskModal/TaskModal'
import styles from './TaskSidebar.module.css'

type TaskSidebarProps = {
  game: Game | undefined
}

export const TaskSidebar: React.FC<TaskSidebarProps> = ({ game }) => {
  const [editTaskId, setEditTaskId] = useState<number | null>(null)
  const [showTaskModel, setShowTaskModal] = useState<boolean>(false)

  const handleTaskEditClick = useCallback(
    (taskId: string | null) => () => {
      setEditTaskId(taskId == null ? null : safeParseInt(taskId))
      setShowTaskModal(true)
    },
    [],
  )

  const handleCloseTaskModal = useCallback(() => {
    setShowTaskModal(false)
    setEditTaskId(null)
  }, [])

  const handleNewTaskClick = useCallback(() => {
    setShowTaskModal(true)
  }, [])

  if (game != null) {
    return (
      <div className={styles.sidebarContainer}>
        <h4 style={{ textAlign: 'left', margin: '2rem 0' }}>Game tasks:</h4>
        {game.tasks?.length > 0 ? (
          game.tasks.map((task) => (
            <div key={`gameEditorTask_${task.id}`} className={styles.gameEditorBtnWrapper}>
              <RoundButton onClick={handleTaskEditClick(task.id)} icon='text' />
              <span>{task.name}</span>
            </div>
          ))
        ) : (
          <div>no tasks yet</div>
        )}
        <Button
          onClick={handleNewTaskClick}
          label='Create new task!'
          styleVariant='tertiary'
          sizeVariant='tiny'
          buttonStyles={{ margin: '32px 0', maxWidth: '250px', whiteSpace: 'nowrap' }}
          iconLeft='plus'
        />
        {showTaskModel && <TaskModal gameId={game.gameId} taskId={editTaskId} onClose={handleCloseTaskModal} />}
      </div>
    )
  }

  return null
}
