import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExerciseAccordionItem } from '../../../common/components/ExerciseAccordionItem/ExerciseAccordionItem'
import { TaskPreview } from '../../../types/commonTypes'
import styles from './GamePreview.module.css'

type ExercisesPreviewProps = {
  exercises: TaskPreview[]
}

export const ExercisesPreview: React.FC<ExercisesPreviewProps> = ({ exercises }) => {
  const { t } = useTranslation()

  if (exercises.length === 0) {
    return (
      <div className={classNames(styles.exercisePreviewContainer, styles.centeredContainer)}>
        {t('game_preview_modal.no_tasks_text', 'No tasks added')}
      </div>
    )
  }

  return (
    <div className={styles.exercisePreviewContainer}>
      <ul className={styles.exercisesList}>
        {exercises.map((exercise) => (
          <li key={exercise.name}>
            <ExerciseAccordionItem task={exercise} />
          </li>
        ))}
      </ul>
      {/* NOTE: not a useless div, but a padding at the bottom of a scroll container hack */}
      <div style={{ height: 20 }} />
    </div>
  )
}
