import { useField } from 'react-final-form'
import { FormFieldProps } from '../../../../types/commonTypes'
import { ToggleSwitch, ToggleSwitchProps } from '../../ToggleSwitch/ToggleSwitch'
import { FormFieldError } from '../FormFieldError/FormFieldError'
import styles from './ToggleFormField.module.css'
import classNames from 'classnames'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { getFieldError } from '../../../../util/form'

export const ToggleFormField: React.FC<FormFieldProps<Partial<ToggleSwitchProps>>> = ({
  name,
  label,
  srOnlyLabel,
  id,
  fieldContainerClassName,
  reserveErrorSpace,
  validateOnlyIfDirty,
  labelSubtext,
  errorClassName,
  controlWithLabelClassName,
  ...rest
}) => {
  const { input, meta } = useField(name, { type: 'checkbox' })

  return (
    <div className={fieldContainerClassName}>
      <div
        className={classNames(
          !safeIsNullOrEmpty(getFieldError(meta)) && styles.withError,
          styles.controlWithLabelContainer,
          controlWithLabelClassName,
        )}
      >
        <ToggleSwitch {...input} {...rest} id={id ?? name} />
        <label htmlFor={id ?? name} className={srOnlyLabel ? 'sr-only' : styles.labelContainer}>
          {label}
          <span className={styles.labelSubtext}>{labelSubtext}</span>
        </label>
      </div>
      <FormFieldError
        className={errorClassName}
        meta={meta}
        reserveErrorSpace={reserveErrorSpace}
        validateOnlyIfDirty={validateOnlyIfDirty}
      />
    </div>
  )
}
