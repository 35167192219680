import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { RoundButton } from '../../common/components/button/RoundButton'
import { useDebug } from '../../contexts/DebugContext'
import styles from './Debug.module.css'

type DebugButtonProps = {
  onClick: () => void
}

export const DebugButton: React.FC<DebugButtonProps> = ({ onClick }) => {
  const { useNewEditor } = useDebug()
  const { pathname } = useLocation()

  return (
    <span
      className={classNames(
        !useNewEditor && pathname.includes('game-editor')
          ? styles.debugButtonWrapperTempLegacyEditor
          : styles.debugButtonWrapper,
      )}
    >
      <RoundButton icon='debug' onClick={onClick} className={styles.debugButton} />
    </span>
  )
}
