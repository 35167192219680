import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '../../common/components/ModalWrapper/ModalWrapper'
import { Button } from '../../common/components/button/Button'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../hooks/useTrapFocus'
import styles from './ConfirmationDialog.module.css'

export type ConfirmationProps = {
  title: string | JSX.Element
  text?: string | JSX.Element
  confirmActionText?: string
  cancelActionText?: string
}

type ConfirmationDialogProps = {
  show: boolean
  confirmationProps: ConfirmationProps
  resolveConfirmation: (answer: boolean) => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  show,
  confirmationProps,
  resolveConfirmation,
}) => {
  const { t } = useTranslation()

  const resolveConfirmationInternalCreator = useCallback(
    (answer: boolean) => () => {
      resolveConfirmation(answer)
    },
    [resolveConfirmation],
  )

  const modalRef = useTrapFocus<HTMLDivElement>(show)
  useDisableBodyScroll(show)

  if (!show) {
    return null
  }

  return (
    <ModalWrapper>
      <div className={styles.backdrop} />
      <div ref={modalRef} className={styles.modalContainer}>
        <h3>{confirmationProps.title}</h3>
        {confirmationProps.text && (
          <div className={classNames(styles.textContainer, 'light')}>{confirmationProps.text}</div>
        )}
        <div className={styles.actionsContainer}>
          <Button
            styleVariant='tertiary'
            label={confirmationProps.cancelActionText ?? t('confirmation_dialog.default_cancel', 'Cancel')}
            onClick={resolveConfirmationInternalCreator(false)}
            autoFocus
            sizeVariant='small'
            iconLeft='close'
          />
          <Button
            styleVariant='secondary'
            label={confirmationProps.confirmActionText ?? t('confirmation_dialog.default_confirm', 'Confirm')}
            onClick={resolveConfirmationInternalCreator(true)}
            sizeVariant='small'
          />
        </div>
      </div>
    </ModalWrapper>
  )
}
