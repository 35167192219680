import React, { useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import styles from '../OnboardingWizard.module.css'

export type OtherInputProps = {
  onConfirmInput: () => void
  placeholder: string
  name: string
}

export const OtherInput: React.FC<OtherInputProps> = ({ onConfirmInput, name, placeholder }) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, (e) => {
    if ((e.target as EventTarget & { getAttribute: (attr: string) => string })?.getAttribute?.('type') !== 'submit') {
      onConfirmInput()
    }
  })

  return (
    <div ref={ref} className={styles.otherInputContainer}>
      <InputFormField
        name={name}
        label={placeholder}
        srOnlyLabel
        placeholder={placeholder}
        autoFocus
        validateOnlyIfDirty
        leftElement={{
          iconName: 'arrowRight',
          variant: 'button',
          elementStyles: {
            background: 'none',
            color: 'var(--primary-normal)',
            transform: 'rotate(180deg)',
          },
          noHoverShadow: true,
          onButtonClick: onConfirmInput,
        }}
      />
    </div>
  )
}
