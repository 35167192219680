import * as React from 'react'
import type { SVGProps } from 'react'
const SvgReportGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <linearGradient id='report_gradient_icon_svg__a' x1={16} x2={16} y1={3} y2={28.6} gradientUnits='userSpaceOnUse'>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g fillRule='evenodd' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M6.4 4.6a.8.8 0 0 0-.8.8v20.8a.8.8 0 0 0 .8.8h19.2a.8.8 0 0 0 .8-.8l-.002-15.952h-3.662A1.867 1.867 0 0 1 20.87 8.38V4.6zm16.07.003v3.778c0 .147.119.267.266.267h3.662v-.173a.8.8 0 0 0-.24-.57l-.002-.003-3.2-3.075a.8.8 0 0 0-.487-.224zm-17.767-.9A2.4 2.4 0 0 1 6.4 3h16.001a2.4 2.4 0 0 1 1.67.68l.002.002 3.2 3.075a2.4 2.4 0 0 1 .725 1.717L28 26.2a2.4 2.4 0 0 1-2.4 2.4H6.4A2.4 2.4 0 0 1 4 26.2V5.4a2.4 2.4 0 0 1 .703-1.697zM9.6 10.2a.8.8 0 0 1 .8.8v11.2H12v-4.87c0-.491.3-.844.601-1.038a1.86 1.86 0 0 1 .999-.278h1.6c.336 0 .697.084.999.278.3.194.601.547.601 1.037V22.2h1.6v-7.6c0-.448.117-.914.369-1.292.252-.379.678-.708 1.231-.708h1.6c.553 0 .979.329 1.231.708.253.378.369.844.369 1.292v7.6h.8a.8.8 0 0 1 0 1.6H9.6a.8.8 0 0 1-.8-.8V11a.8.8 0 0 1 .8-.8zm10.4 12h1.6v-7.6a.776.776 0 0 0-.097-.4h-1.406a.776.776 0 0 0-.097.4zm-4.8 0h-1.6v-4.586h1.6z'
      />
      <path
        fill='url(#report_gradient_icon_svg__a)'
        fillOpacity={0.25}
        d='M6.4 4.6a.8.8 0 0 0-.8.8v20.8a.8.8 0 0 0 .8.8h19.2a.8.8 0 0 0 .8-.8l-.002-15.952h-3.662A1.867 1.867 0 0 1 20.87 8.38V4.6zm16.07.003v3.778c0 .147.119.267.266.267h3.662v-.173a.8.8 0 0 0-.24-.57l-.002-.003-3.2-3.075a.8.8 0 0 0-.487-.224zm-17.767-.9A2.4 2.4 0 0 1 6.4 3h16.001a2.4 2.4 0 0 1 1.67.68l.002.002 3.2 3.075a2.4 2.4 0 0 1 .725 1.717L28 26.2a2.4 2.4 0 0 1-2.4 2.4H6.4A2.4 2.4 0 0 1 4 26.2V5.4a2.4 2.4 0 0 1 .703-1.697zM9.6 10.2a.8.8 0 0 1 .8.8v11.2H12v-4.87c0-.491.3-.844.601-1.038a1.86 1.86 0 0 1 .999-.278h1.6c.336 0 .697.084.999.278.3.194.601.547.601 1.037V22.2h1.6v-7.6c0-.448.117-.914.369-1.292.252-.379.678-.708 1.231-.708h1.6c.553 0 .979.329 1.231.708.253.378.369.844.369 1.292v7.6h.8a.8.8 0 0 1 0 1.6H9.6a.8.8 0 0 1-.8-.8V11a.8.8 0 0 1 .8-.8zm10.4 12h1.6v-7.6a.776.776 0 0 0-.097-.4h-1.406a.776.776 0 0 0-.097.4zm-4.8 0h-1.6v-4.586h1.6z'
      />
    </g>
  </svg>
)
export default SvgReportGradientIcon
