import { TFunction } from 'i18next'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside'
import { Button } from '../../../common/components/button/Button'
import { getIcon } from '../../../common/components/icons/utils'
import styles from '../Search.module.css'
import { OptionalFilterKeys, OptionalFiltersVisibility } from './types'

type FilterOption = {
  label: string
  filterKey: keyof OptionalFilterKeys
}

const ADD_FILTER_BUTTON_ID = 'add-filter-button'

const getOptionalFilterOptions = (t: TFunction): FilterOption[] => [
  {
    filterKey: 'age',
    label: t('search_page.add_filters.age', 'Age'),
  },
  {
    filterKey: 'approvedBySeppo',
    label: t('search_page.add_filters.approved_by_seppo', 'Approved by Seppo'),
  },
  {
    filterKey: 'language',
    label: t('search_page.add_filters.language', 'Language'),
  },
]

type AddFilterProps = {
  optionsVisibility: OptionalFiltersVisibility
  onAddFilter: (filterKey: keyof OptionalFilterKeys) => void
}

export const AddFilter: React.FC<AddFilterProps> = ({ optionsVisibility, onAddFilter }) => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const toggleIsMenuOpen = useCallback(() => {
    setIsMenuOpen((prev) => !prev)
  }, [])

  useOnClickOutside(menuRef, (e: any) => {
    if (e?.target?.id !== ADD_FILTER_BUTTON_ID) {
      setIsMenuOpen(false)
    }
  })

  const onAddFilterInternal = useCallback(
    (option: keyof OptionalFilterKeys) => () => {
      onAddFilter(option)
      setIsMenuOpen(false)
    },
    [onAddFilter],
  )

  const options = useMemo<FilterOption[]>(() => {
    return getOptionalFilterOptions(t).filter((option) => !optionsVisibility[option.filterKey])
  }, [optionsVisibility, t])

  return (
    <div className={styles.addFilterContainer}>
      <Button
        id={ADD_FILTER_BUTTON_ID}
        label={t('search_page.add_filter_button', 'Add filter')}
        styleVariant='tertiary'
        sizeVariant='tiny'
        iconLeft='plus'
        onClick={toggleIsMenuOpen}
        disabled={options.length === 0}
        iconLeftContainerStyle={{ fontSize: '1rem' }}
        style={{ maxHeight: '2.5rem' }}
      />
      {isMenuOpen && (
        <div className={styles.addFilterMenu} ref={menuRef}>
          {options.map((option) => (
            <button key={`addFilterMenuItem_${option.filterKey}`} onClick={onAddFilterInternal(option.filterKey)}>
              <span>{getIcon('filter')}</span>
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
