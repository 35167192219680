import { Try, toFailure, toSuccess } from '../types/commonTypes'
import { createAxios } from './axios'
import {
  AddMembersParams,
  DeleteMemberParams,
  GetBusinessSettingsParams,
  GetBusinessSettingsResponse,
  UpdateMemberRoleParams,
} from './businessSettingsTypes'
import { TEST_USER_AUTHORIZATION } from './userApiService'

// eslint-disable-next-line quotes
const ADD_MEMBERS_MESSAGE = `"Hi,<br>Welcome to use Seppo. Seppo is an easy-to-use tool for gamifying education and training.<br><br>To start using Seppo, go to play.seppo.io -&gt; Instructor.<br><br>Username: [email]<br>Password: [password]<br>\n<br>You need to change your password when you login for the first time.<br><br>We recommend using Chrome, Safari or Firefox.<br><br>Once you have logged in you can <br>- Find some tutorial videos to get you started.<br>- Browse other users' games in the game library. Click 'See more' to explore a game or choose 'Download' to import it to your games.<br>- Find answers to the most common questions in Tips.<br>- Change your password using the menu on the top right corner: 'Change password'.<br><br>Enjoy the magic of games!<br><br>Best wishes,<br>Seppo team`

export async function getBusinessSettingsData({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
}: GetBusinessSettingsParams): Promise<Try<GetBusinessSettingsResponse>> {
  try {
    const url = `/admin/businesses/${businessId}.json?ux3=true`
    const { data } = await createAxios(authorization).get<GetBusinessSettingsResponse>(url)
    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function addMembersApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  members,
}: AddMembersParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member`
    await createAxios(authorization).post<undefined>(url, {
      business_id: businessId.toString(),
      members,
      message1: ADD_MEMBERS_MESSAGE,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function changeMemberRoleApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  userName,
}: UpdateMemberRoleParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member_toggle_admin`
    await createAxios(authorization).post<undefined>(url, {
      username: userName,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function removeMemberApi({
  authorization = TEST_USER_AUTHORIZATION,
  businessId,
  userName,
}: DeleteMemberParams): Promise<Try<undefined>> {
  try {
    const url = `/admin/businesses/${businessId}/member_remove`
    await createAxios(authorization).post<undefined>(url, {
      username: userName,
      remove: true,
    })
    return toSuccess(undefined)
  } catch (e: any) {
    return toFailure(e)
  }
}
