import { PropsWithChildren, useCallback, useState } from 'react'
import { DebugButton } from './DebugButton'
import { DebugModal } from './DebugDialog'

export const DebugDialogWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev)
  }, [])

  return process.env.REACT_APP_ENV === 'development' ? (
    <>
      {showModal && <DebugModal onClose={toggleModal} />}
      {children}
      <DebugButton onClick={toggleModal} />
    </>
  ) : (
    <>{children}</>
  )
}
