import { DateFilterProps } from './types'

import styles from '../Search.module.css'
import { DatePicker } from '../../../common/components/DatePicker/DatePicker'

export const DateFilter: React.FC<DateFilterProps> = ({
  onChange,
  initialValue,
  label,
}) => {
  return (
    <div className={styles.singleFilterContainer}>
      <DatePicker
        initialValue={initialValue != null ? new Date(initialValue) : null}
        onChangeDate={onChange}
        placeholderText={label}
        isClearable
      />
    </div>
  )
}
