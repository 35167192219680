import { TFunction } from 'i18next'
import { TUser } from '../types/commonTypes'
import { EmptyContentBrowseButton, EmptyContentProps } from '../composites/GamesOverview/components/EmptyContent'
import { routes } from '../routes'

export const getDisplayGameOwnersLines = (gameOwners: string[]): string[] => {
  return gameOwners?.length <= 2 ? gameOwners : [...gameOwners.slice(0, 2), `+ ${gameOwners.length - 2}`]
}

export const getDisplayGameOwnersString = (gameOwners: string[]): string => {
  return gameOwners?.length <= 2
    ? gameOwners.join(', ')
    : `${gameOwners.slice(0, 2).join(', ')} + ${gameOwners.length - 2}`
}

export const getDisplayExercisesAndMaps = (exercisesCount: number, mapsCount: number, t: TFunction) => {
  const exercises = t('game_info.tasks', { defaultValue: '%{count} tasks', count: exercisesCount })
  const maps = t('game_info.game_board', { defaultValue: '%{count} game boards', count: mapsCount })
  return `${exercises}, ${maps}`
}

export const getEmptyContentBrowseButton = (user: TUser | null, t: TFunction): EmptyContentBrowseButton | undefined => {
  return user?.hasCommunity
    ? { label: t('dashboard.empty.browse_community_button', 'Browse games in Community'), route: routes.community }
    : user?.hasTemplates
    ? { label: t('dashboard.empty.browse_templates_button', 'Browse games in Templates'), route: routes.templates }
    : undefined
}

export const getDashboardEmptyContent = (user: TUser | null, t: TFunction): EmptyContentProps => {
  const subtitle = user?.hasCommunity
    ? t('dashboard.empty.content.with_community', 'Create a new game or visit our community for inspiration')
    : user?.hasTemplates
    ? t('dashboard.empty.content.with_templates', 'Create a new game or check out our templates for inspiration')
    : t('dashboard.empty.content.without_libraries', 'Create a new game')

  return {
    title: t('dashboard.empty.title', 'You have no games yet'),
    subtitle,
    browseButton: getEmptyContentBrowseButton(user, t),
  }
}
