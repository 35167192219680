import { FORM_ERROR } from 'final-form'
import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'
import { Button } from '../../../common/components/button/Button'
import { useTheme } from '../../../contexts/ThemeContext'
import { FormErrorType } from '../../../types/commonTypes'
import styles from './InstructorOrPlayer.module.css'
import { LoginCard } from './LoginCard'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import classNames from 'classnames'
import { Select, SelectOption } from '../../../common/components/Select/Select'
import { LANGUAGE_SELECT_OPTIONS, LANGUAGE_SELECT_DEFAULT_OPTION } from '../../../common/constants'
import { SingleValue } from 'react-select'

type LoginFormValues = {
  gameCode: string
  email: string
  password: string
}

type PlayerCodeFormValue = {
  gameCode: string
}

type InstructorOrPlayerProps = {
  autofocusEnterCode: boolean
  onClickContinueWithSignup: () => void
}

// TODO: not sure if this will be needed so leaving it here for now
const validateLogin = ({ gameCode }: Partial<PlayerCodeFormValue>): FormErrorType<PlayerCodeFormValue> => {
  const errors: FormErrorType<PlayerCodeFormValue> = {}
  if (!gameCode) {
    errors.gameCode = 'Required'
  }
  return errors
}

export const InstructorOrPlayer: React.FC<InstructorOrPlayerProps> = ({
  autofocusEnterCode = false,
  onClickContinueWithSignup,
}) => {
  const { t, i18n } = useTranslation()
  const { logoUrl } = useTheme()
  const { fadeTransition } = useFadeAnimation()

  const handleOnSubmit = useCallback(
    async (values: LoginFormValues) => {
      if (values.gameCode != null && values.gameCode !== '') {
        window.location.replace(`${process.env.REACT_APP_OLD_UI_BASE_URL}/player?pin_code=${values.gameCode}&pa=true`)
      } else {
        return {
          [FORM_ERROR]: t(
            'login.login_failed_error_message',
            'Failed to log in. Please check that you are entering correct values and try again or contact us for support',
          ),
        }
      }
    },
    [t],
  )

  const handleClickGoToPlayerLogin = () => {
    window.location.replace(`${process.env.REACT_APP_OLD_UI_BASE_URL}/?pa=true`)
  }

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value)
  }

  return (
    <div className={classNames(styles.backgroundContainer, fadeTransition)}>
      <img src={logoUrl} alt='Seppo' height={26} className={styles.seppoLogo} />
      <Select
        options={LANGUAGE_SELECT_OPTIONS}
        defaultValue={LANGUAGE_SELECT_DEFAULT_OPTION}
        onChange={handleChangeLanguage}
        className={classNames(styles.languageSelect, styles.hideOnLargeScreen)}
      />
      <h1 className={styles.hideOnSmallScreen}>{t('login.title', 'What would you like to do?')}</h1>
      <div className={styles.cardsContainer}>
        <LoginCard icon='door' title={t('login.join_existing_game.title', 'Join an existing game')}>
          <p className={styles.cardBodyText}>{t('login.join_existing_game.input.label', 'Enter your code')}</p>
          <div className={styles.layoutAlignmentWrapper}>
            <Form<LoginFormValues>
              onSubmit={handleOnSubmit}
              // TODO: Should we show a warning message here instead of an error? Player code is not necessary here
              // since a user can click on the button LOGIN WITH PLAYER ID (not the clearest wording combined with actual
              // behaviour) to go to the player app without a code inputted on this side.
              // validate={validateLogin}
              className={styles.loginWithGameCode}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.loginWithGameCode}>
                  <InputFormField
                    label={t('login.join_existing_game.input.label', 'Enter your code')}
                    placeholder={t('login.join_existing_game.input.placeholder', 'Type code')}
                    srOnlyLabel
                    autoFocus={autofocusEnterCode}
                    name='gameCode'
                    type='text'
                    reserveErrorSpace
                    rightElement={{
                      variant: 'button',
                      iconName: 'arrowRight',
                      type: 'submit',
                    }}
                  />
                  <button type='button' className={styles.loginWithIdButton} onClick={handleClickGoToPlayerLogin}>
                    {t('login.join_existing_game.login_with_player_id', 'Log in with player id').toUpperCase()}
                  </button>
                </form>
              )}
            </Form>
          </div>
        </LoginCard>
        <LoginCard icon='text' title={t('login.create_games.title', 'Create games')}>
          <p className={classNames(styles.cardBodyText, styles.hideOnSmallScreen)}>
            {t('login.create_games.body', "Let's get signed up and make something awesome")}
          </p>
          <div className={styles.layoutAlignmentWrapper}>
            <p className={styles.hideOnLargeScreen}>
              {t(
                'login.instructor_small_device.first_paragraph',
                "Instructor user interface isn't supported on mobile phone devices.",
              )}
            </p>
            <p className={styles.hideOnLargeScreen}>
              {t(
                'login.instructor_small_device.first_paragraph',
                'To login as an instructor, please use Seppo on a larger device such as a laptop or a desktop PC.',
              )}
            </p>
            <span className={styles.hideOnSmallScreen}>
              <Button
                styleVariant='secondary'
                buttonStyles={{ borderRadius: '8px' }}
                label={t('login.create_games.button_text', 'Continue')}
                onClick={onClickContinueWithSignup}
              />
            </span>
          </div>
        </LoginCard>
      </div>
    </div>
  )
}
