// TODO: Extract these constants to enum

import { LibraryCountry, LibraryLanguage } from '../api/gameTypes'
import { MapOptions } from '../pages/GameEditor/types'
import { DeepMappedType } from '../types/commonTypes'
import { SelectOption } from './components/Select/Select'

// UserMenu related constants
export const USER_MENU_INITIAL = 'initialMenu'
export const USER_MENU_LANGUAGE = 'languageMenu'
export const USER_MENU_ACCOUNT = 'accountMenu'

// MenuItemTypes
export const MENU_ITEM_TYPE_DEFAULT = 'defaultItem'
export const MENU_ITEM_TYPE_SUBMENU = 'submenuItem'
export const MENU_ITEM_TYPE_BACK = 'backItem'

export const READY_STATIC_GAME_BOARDS = [
  {
    fileName: 'anatomy.jpg',
    name: 'Anatomy',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/anatomy.jpg',
  },
  {
    fileName: 'business.jpg',
    name: 'Business',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/business.jpg',
  },
  {
    fileName: 'city.png',
    name: 'City',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/city.png',
  },
  {
    fileName: 'school.jpg',
    name: 'School',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/school.jpg',
  },
  {
    // typo in fileName (from legacy app), probably not important, but making a NOTE just in case
    fileName: 'endagered_animals.jpg',
    name: 'Endangered animals',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/endagered_animals.jpg',
  },
  {
    fileName: 'dockyard.gif',
    name: 'Dockyard',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/dockyard.gif',
  },
  {
    fileName: 'naturepath.jpg',
    name: 'Nature path',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/naturepath.jpg',
  },
  {
    fileName: 'lobby.png',
    name: 'Lobby',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/lobby.png',
  },
  {
    fileName: 'office.png',
    name: 'Office',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/office.png',
  },
  {
    fileName: 'recreation.png',
    name: 'Recreation',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/recreation.png',
  },
  {
    fileName: 'warehouse.png',
    name: 'Warehouse',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/warehouse.png',
  },
]

export const READY_360_GAME_BOARDS = [
  {
    fileName: 'Pyramids',
    name: 'Pyramids',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Pyramids.jpg',
  },
  {
    fileName: 'Restaurant',
    name: 'Restaurant',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Restaurant.jpg',
  },
  {
    fileName: 'Park',
    name: 'Park',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Park.jpg',
  },
  {
    fileName: 'Laboratory',
    name: 'Laboratory',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/Lab.jpg',
  },
  {
    fileName: 'FutureCity',
    name: 'Future city',
    url: 'https://smartfeet-assets-production.s3-eu-west-1.amazonaws.com/ready_maps/FutureCity.jpg',
  },
]
export const MODALS_ROOT_ID = 'MODALS_ROOT'

export const LANGUAGE_FLAGS: DeepMappedType<typeof LibraryLanguage, string> = {
  en: '🇬🇧',
  fi: '🇫🇮',
  pt: '🇵🇹',
  sv: '🇸🇪',
  de: '🇩🇪',
  nl: '🇳🇱',
  nb: '🇳🇴',
  sp: '🇪🇸',
}

export const COUNTRY_FLAGS: DeepMappedType<typeof LibraryCountry, string> = {
  FI: '🇫🇮',
  SE: '🇸🇪',
  NL: '🇳🇱',
  US: '🇺🇸',
  CH: '🇨🇭',
  AE: '🇦🇪',
  QA: '🇶🇦',
  KW: '🇰🇼',
  HK: '🇭🇰',
  BR: '🇧🇷',
  GB: '🇬🇧',
  NO: '🇳🇴',
  DK: '🇩🇰',
  DE: '🇩🇪',
  PL: '🇵🇱',
  LT: '🇱🇹',
  LV: '🇱🇻',
  EE: '🇪🇪',
  SA: '🇸🇦',
  IN: '🇮🇳',
  HKE: '🇭🇰',
  HU: '🇭🇺',
  CO: '🇨🇴',
  MX: '🇲🇽',
  PE: '🇵🇪',
  CL: '🇨🇱',
  EC: '🇪🇨',
  PA: '🇵🇦',
  AR: '🇦🇷',
}

export const LANGUAGE_LABELS: DeepMappedType<typeof LibraryLanguage, string> = {
  en: 'English',
  fi: 'Suomi',
  pt: 'Português',
  sv: 'Svenska',
  de: 'Deutsch',
  nl: 'Nederlands',
  nb: 'Norsk Bokmål',
  sp: 'Español',
}

export const LANGUAGE_SELECT_OPTIONS: SelectOption[] = Object.keys(LibraryLanguage).map(
  (key): SelectOption => ({
    value: key,
    label: LANGUAGE_LABELS[key as keyof typeof LibraryLanguage],
    prefixElement: {
      type: 'rawContent',
      rawContent: LANGUAGE_FLAGS[key as keyof typeof LibraryLanguage],
    },
  }),
)

export const LANGUAGE_SELECT_DEFAULT_OPTION: SelectOption = LANGUAGE_SELECT_OPTIONS.find(
  (option) => option.value === 'en',
)!

export const COUNTRY_SELECT_OPTIONS: SelectOption[] = Object.keys(LibraryCountry)
  .map(
    (key): SelectOption => ({
      value: key,
      label: LibraryCountry[key as keyof typeof LibraryCountry],
      prefixElement: {
        type: 'rawContent',
        rawContent: COUNTRY_FLAGS[key as keyof typeof LibraryCountry],
      },
    }),
  )
  .sort((countryA, countryB) => (countryA.label < countryB.label ? -1 : 1))

export const COUNTRY_SELECT_DEFAULT_OPTION = COUNTRY_SELECT_OPTIONS.find((option) => option.value === 'FI')!

export const DEFAULT_MAP_OPTIONS: MapOptions = {
  // Helsinki coordinates
  center: [24.9384, 60.1699],
  zoom: 9,
}

export const BILLING_DEFAULT_PRICE = 3
export const EMPTY_CONTENT_FALLBACK = '-'
