import classNames from 'classnames'
import { forwardRef, useState } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { safeIsNullOrEmpty } from '../../../util/string'
import { getIcon } from '../icons/utils'
import styles from './DatePicker.module.css'

export type DatePickerProps = Partial<ReactDatePickerProps> & {
  onChangeDate: (newDateString: string | null) => void
  initialValue: Date | null
  isClearable?: boolean
}

const dateToString = (date: Date): string => {
  return date.toISOString().split('T')[0]
}

const onCustomInputKeyDownCreator = (onClick: () => void) => (e: any) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

export const DatePicker: React.FC<DatePickerProps> = ({
  onChangeDate,
  initialValue,
  isClearable,
  ...rest
}) => {

  const [value, setValue] = useState<Date | null>(initialValue ?? null)

  const onChangeInternal = (newValue: Date | null) => {
    if (newValue instanceof Date) {
      newValue.setHours(12, 0)
      newValue.setMilliseconds(0)
      newValue.setSeconds(0)
      setValue(newValue)
      onChangeDate(dateToString(newValue))
    } else {
      setValue(null)
      onChangeDate(null)
    }
  }

  const handleClearClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onChangeInternal(null)
  }

  const CustomInput = forwardRef<any, any>((props, ref) => {
    return (
      <div
        tabIndex={0}
        onKeyDown={onCustomInputKeyDownCreator(props.onClick)}
        onClick={props.onClick}
        ref={ref}
        className={styles.datePickerControlContainer}
      >
        <div className={styles.valueContainer}>
          <span className={classNames(styles.calendarIcon, !safeIsNullOrEmpty(props.value) && styles.calendarIconWithValue)}>
            { getIcon('calendar') }
          </span>
          <span className={safeIsNullOrEmpty(props.value) ? styles.placeholder : styles.value}>
            {props.value || props.placeholder}
          </span>
        </div>
        { isClearable && !safeIsNullOrEmpty(props.value) && (
          <button
            tabIndex={0}
            className={styles.clearButton}
            onClick={handleClearClick}
          >
            { getIcon('close') }
          </button>
        )}
      </div>
    )
  })

  return (
    <ReactDatePicker
      selected={value}
      onChange={onChangeInternal}
      maxDate={new Date()}
      customInput={<CustomInput />}
      {...rest}
    />
  )
}
