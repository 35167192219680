import classNames from 'classnames'
import React from 'react'
import styles from './Input.module.css'
import { InputElement, InputElementProps } from './InputElement'
import { isFirefox } from '../../../util/browserSpecifics'

export type InputFieldProps = React.HTMLProps<HTMLInputElement> & {
  value: string
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void
  leftElement?: InputElementProps
  rightElement?: InputElementProps
  inputStyles?: React.CSSProperties
  inputContainerClassName?: string
  disabled?: boolean
  hasError?: boolean
}

export const InputField: React.FC<InputFieldProps> = ({
  value,
  leftElement,
  rightElement,
  onChange,
  disabled,
  inputStyles,
  inputContainerClassName,
  hasError,
  className,
  ...restProps
}) => {
  return (
    <span className={classNames(styles.container, inputContainerClassName)}>
      {leftElement != null && (
        <InputElement {...leftElement} className={classNames(leftElement.className, styles.left_element)} />
      )}
      <input
        onChange={onChange}
        className={classNames(
          styles.input_field,
          isFirefox() && styles.isFirefox_outline,
          !!leftElement && [styles.with_left_element, styles.with_element],
          !!rightElement && [styles.with_right_element, styles.with_element],
          hasError && styles.hasError,
          className,
        )}
        value={value}
        disabled={disabled}
        style={inputStyles}
        {...restProps}
      />
      {rightElement != null && (
        <InputElement {...rightElement} className={classNames(rightElement.className, styles.right_element)} />
      )}
    </span>
  )
}
