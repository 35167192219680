import React, { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getIcon } from '../../../../common/components/icons/utils'
import { InputField } from '../../../../common/components/input/InputField'
import { HelpMenu } from '../../../../composites/HelpMenu/HelpMenu'
import { useTheme } from '../../../../contexts/ThemeContext'
import { TabGroup } from '../TabGroup/TabGroup'
import styles from './Header.module.css'
import { UserMenu } from './UserMenu'

type HeaderProps = {
  gameName?: string
  activeTab: string
  onClickTab: (id: string) => void
  onClickGameSettings: () => void
}

export const Header: React.FC<HeaderProps> = ({ gameName, activeTab, onClickTab, onClickGameSettings }) => {
  const navigate = useNavigate()
  const { logoUrl } = useTheme()

  const handleClickNavigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <header className={styles.container}>
      <button onClick={handleClickNavigateBack} className={styles.backButton}>
        <span>{getIcon('backIcon')}</span>
      </button>
      <div className={styles.seppoLogoContainer}>
        <Link to='/'>
          <img className={styles.seppoLogo} src={logoUrl} alt='logo' />
        </Link>
      </div>
      <div className={styles.gameSettingsContainer}>
        <InputField
          value={gameName || 'Please set game name'}
          disabled
          className={styles.gameSettings}
          onChange={() => {
            //
          }}
          rightElement={{
            iconName: 'settings',
            variant: 'button',
            elementStyles: {
              background: 'none',
              color: 'var(--grey-600)',
              width: '1.875rem',
              height: '1.875rem',
              fontSize: '2rem',
            },
            noHoverShadow: true,
            onButtonClick: onClickGameSettings,
          }}
        />
      </div>
      <div className={styles.tabOptions}>
        <TabGroup activeTab={activeTab} onClickTab={onClickTab} />
      </div>
      <div className={styles.iconsWrapper}>
        <button
          onClick={() => {
            console.log('Score icon clicked')
          }}
          className={styles.buttonDefault}
        >
          <span>{getIcon('scoreHeader')}</span>
        </button>
        <button
          onClick={() => {
            console.log('User icon clicked')
          }}
          className={styles.buttonDefault}
        >
          <span>{getIcon('multipleUsers')}</span>
        </button>
        <button
          onClick={() => {
            console.log('Chat icon clicked')
          }}
          className={styles.buttonDefault}
        >
          <span>{getIcon('chatNormal')}</span>
        </button>
      </div>
      <UserMenu />
      <HelpMenu />
    </header>
  )
}
