export const noop = () => {}

export const clamp = (min: number, preferred: number, max: number): number => {
  return Math.min(Math.max(min, preferred), max)
}

export const debounce = <T extends any[]>(func: (...args: T) => void, timeout = 300): ((...args: T) => void) => {
  let timer: any
  return (...args: T) => {
    window.clearTimeout(timer)
    timer = window.setTimeout(() => func(...args), timeout)
  }
}

export const getEnumKeyByValue = (value: string | number, myEnum: any): string => {
  const indexOfS = Object.values(myEnum).indexOf(value)

  const key = Object.keys(myEnum)[indexOfS]

  return key
}
