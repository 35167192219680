import React, { useCallback } from 'react'
import { SelectableIcon } from '../../../../../common/components/SelectableIcon/SelectableIcon'
import { useTranslation } from 'react-i18next'
import { CreateGameOption } from '../../../types'
import styles from './Steps.module.css'

type NewGameStepProps = {
  createGameOption: CreateGameOption
  onChangeOption: (option: CreateGameOption) => void
}

export const NewGameStep: React.FC<NewGameStepProps> = ({ onChangeOption, createGameOption }) => {
  const { t } = useTranslation()
  const onChangeOptionInternal = useCallback(
    (id: string) => {
      onChangeOption(id as CreateGameOption)
    },
    [onChangeOption],
  )

  return (
    <div className={styles.container}>
      <h1>{t('game_editor.wizard.new_game_step.title', "Let's start a new game")}</h1>
      <h4>{t('game_editor.wizard.new_game_step.subtitle', 'How would you like to begin?')}</h4>
      <div className={styles.buttonsWrapper}>
        <SelectableIcon
          id={CreateGameOption.QUICK_START}
          size={'Large'}
          checked={createGameOption === CreateGameOption.QUICK_START}
          text={t('game_editor.wizard.new_game_step.quick_start', 'Quick start')}
          onChange={onChangeOptionInternal}
          iconName={'play'}
        />
        {/* TODO: outscoped - needs to be worked on later */}
        {/* <SelectableIcon
          id={SelectOptions.SEARCH_COMMUNITY}
          size={'Large'}
          checked={selectedOption === SelectOptions.SEARCH_COMMUNITY}
          text={'Search community'}
          onChange={onChangeOptionInternal}
          iconName={'search'}
        /> */}
        <SelectableIcon
          id={CreateGameOption.GAME_SETTINGS}
          size={'Large'}
          checked={createGameOption === CreateGameOption.GAME_SETTINGS}
          text={t('game_editor.wizard.new_game_step.game_settings', 'Open to game settings')}
          onChange={onChangeOptionInternal}
          iconName={'settings'}
        />
      </div>
    </div>
  )
}
