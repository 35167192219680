// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectableIconFormField_inputsWrapper__fFRQD {\n  display: flex;\n  gap: 0.5rem;\n  border-radius: 0.5rem;\n}\n\n.SelectableIconFormField_withError__Ezuj\\+ {\n  outline: 0.125rem solid var(--validation-error);\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/Form/CheckboxGroupField/SelectableIconFormField.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".inputsWrapper {\n  display: flex;\n  gap: 0.5rem;\n  border-radius: 0.5rem;\n}\n\n.withError {\n  outline: 0.125rem solid var(--validation-error);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsWrapper": "SelectableIconFormField_inputsWrapper__fFRQD",
	"withError": "SelectableIconFormField_withError__Ezuj+"
};
export default ___CSS_LOADER_EXPORT___;
