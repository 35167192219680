import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCommunityGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 26 26' {...props}>
    <linearGradient id='community_gradient_icon_svg__a' x1={13} x2={13} y1={0} y2={26} gradientUnits='userSpaceOnUse'>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g fillRule='evenodd' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M3.808 3.808a13 13 0 0 1 18.928.578c.263.083.487.26.636.494a14.07 14.07 0 0 1 2.399 5.689c.151.795.229 1.61.229 2.431v.06a14.247 14.247 0 0 1-1.537 6.513 1.19 1.19 0 0 1-.594.56 13.002 13.002 0 0 1-21.22.732 12.039 12.039 0 0 1-1.899-3.33 11.837 11.837 0 0 1-.748-4.336 13 13 0 0 1 3.806-9.392zM23.473 15.2c-.188.895-.49 1.762-.897 2.578H21.36a.429.429 0 0 1-.284-.108.465.465 0 0 1-.15-.274l-.848-5.32c-.095-.59-.583-1.022-1.156-1.022h-1.696a.424.424 0 0 1-.346-.176.468.468 0 0 1-.08-.393l.806-3.36a.46.46 0 0 1 .156-.25.428.428 0 0 1 .27-.098h3.675c.396.555.737 1.144 1.02 1.76a11.801 11.801 0 0 1 .747 6.663zM19.278 4.332h-1.246c-.62 0-1.223.217-1.712.615s-.836.956-.987 1.583l-.805 3.36c-.103.429-.11.875-.023 1.307.087.431.268.836.528 1.184.26.347.593.628.973.822.38.193.798.294 1.22.294h.703l.687 4.3c.108.678.444 1.293.946 1.737.392.346.865.57 1.367.652A10.701 10.701 0 0 1 6.604 21.58l.704-3.426h.186c.398 0 .79-.088 1.148-.258.357-.17.67-.416.915-.721.245-.306.415-.66.497-1.04a2.48 2.48 0 0 0-.021-1.146l-.758-2.949c-.141-.55-.468-1.04-.928-1.39s-1.027-.539-1.61-.539H2.696a10.701 10.701 0 0 1 16.582-5.778zM2.298 13c0 2.485.865 4.881 2.427 6.787l.6-2.915a1.095 1.095 0 0 1 1.08-.862h1.09a.424.424 0 0 0 .325-.155.397.397 0 0 0 .075-.345l-.757-2.95a.401.401 0 0 0-.147-.219.42.42 0 0 0-.254-.085H2.324c-.017.247-.026.495-.026.744z'
      />
      <path
        fill='url(#community_gradient_icon_svg__a)'
        fillOpacity={0.25}
        d='M3.808 3.808a13 13 0 0 1 18.928.578c.263.083.487.26.636.494a14.07 14.07 0 0 1 2.399 5.689c.151.795.229 1.61.229 2.431v.06a14.247 14.247 0 0 1-1.537 6.513 1.19 1.19 0 0 1-.594.56 13.002 13.002 0 0 1-21.22.732 12.039 12.039 0 0 1-1.899-3.33 11.837 11.837 0 0 1-.748-4.336 13 13 0 0 1 3.806-9.392zM23.473 15.2c-.188.895-.49 1.762-.897 2.578H21.36a.429.429 0 0 1-.284-.108.465.465 0 0 1-.15-.274l-.848-5.32c-.095-.59-.583-1.022-1.156-1.022h-1.696a.424.424 0 0 1-.346-.176.468.468 0 0 1-.08-.393l.806-3.36a.46.46 0 0 1 .156-.25.428.428 0 0 1 .27-.098h3.675c.396.555.737 1.144 1.02 1.76a11.801 11.801 0 0 1 .747 6.663zM19.278 4.332h-1.246c-.62 0-1.223.217-1.712.615s-.836.956-.987 1.583l-.805 3.36c-.103.429-.11.875-.023 1.307.087.431.268.836.528 1.184.26.347.593.628.973.822.38.193.798.294 1.22.294h.703l.687 4.3c.108.678.444 1.293.946 1.737.392.346.865.57 1.367.652A10.701 10.701 0 0 1 6.604 21.58l.704-3.426h.186c.398 0 .79-.088 1.148-.258.357-.17.67-.416.915-.721.245-.306.415-.66.497-1.04a2.48 2.48 0 0 0-.021-1.146l-.758-2.949c-.141-.55-.468-1.04-.928-1.39s-1.027-.539-1.61-.539H2.696a10.701 10.701 0 0 1 16.582-5.778zM2.298 13c0 2.485.865 4.881 2.427 6.787l.6-2.915a1.095 1.095 0 0 1 1.08-.862h1.09a.424.424 0 0 0 .325-.155.397.397 0 0 0 .075-.345l-.757-2.95a.401.401 0 0 0-.147-.219.42.42 0 0 0-.254-.085H2.324c-.017.247-.026.495-.026.744z'
      />
    </g>
  </svg>
)
export default SvgCommunityGradientIcon
