import React from 'react'
import { ThreeDots } from '../../../common/components/loaders/ThreeDots/ThreeDots'
import { useGetGameMapsAndExercises } from '../../../hooks/useGetGameMapsAndExercises'
import { ExercisesPreview } from './ExercisesPreview'
import styles from './GamePreview.module.css'
import { MapsPreview } from './MapsPreview'

type GamePreviewProps = {
  gameId: number
  isLibrary: boolean
}

export const GamePreview: React.FC<GamePreviewProps> = ({ gameId, isLibrary }) => {
  const { data, loading } = useGetGameMapsAndExercises(gameId, isLibrary)

  if (loading) {
    return <ThreeDots variant={isLibrary ? 'secondary' : 'primary'} />
  }

  if (data == null) {
    return null
  }

  return (
    <div className={styles.gamePreviewContainer}>
      <MapsPreview maps={data?.maps ?? []} isLibrary={isLibrary} />
      <ExercisesPreview exercises={data?.exercises ?? []} />
    </div>
  )
}
