import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { NotificationItemProps, NotificationType } from '../composites/notificationDisplay/NotificationItem'
import { NotificationsWrapper } from '../composites/notificationDisplay/NotificationsWrapper'
import { noop } from '../util/functional'
import { useLocation } from 'react-router-dom'

type NotifyProps = Omit<NotificationItemProps, 'id' | 'onClose'> & {
  timeout: number | false
  allowClose?: boolean
}

type SpecificNotifyProps = Omit<NotifyProps, 'type' | 'timeout'> & {
  timeout?: number | false
}

type NotifyProgressProps = Pick<NotifyProps, 'title' | 'content' | 'customButton'>

type NotificationContextType = {
  notifySuccess: (props: SpecificNotifyProps) => string
  notifyInfo: (props: SpecificNotifyProps) => string
  notifyWarning: (props: SpecificNotifyProps) => string
  notifyError: (props: SpecificNotifyProps) => string
  notifyProgress: (props: NotifyProgressProps) => string
  finishProgress: (id: string, success: boolean, props?: Partial<SpecificNotifyProps>) => void
  closeNotification: (id: string) => void
}

const INITIAL_FN = () => 'dummy-id'

const NotificationContext = createContext<NotificationContextType>({
  notifySuccess: INITIAL_FN,
  notifyInfo: INITIAL_FN,
  notifyWarning: INITIAL_FN,
  notifyError: INITIAL_FN,
  notifyProgress: INITIAL_FN,
  finishProgress: noop,
  closeNotification: noop,
})

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationItemProps[]>([])
  const location = useLocation()

  useEffect(() => {
    setNotifications((prev) => prev.filter((notification) => !notification.closeOnRouteChange))
  }, [location])

  const notify = useCallback((props: NotifyProps) => {
    const { title, content, type, timeout, customButton, allowClose = true, closeOnRouteChange } = props
    const id = uuid()
    const closeById = () => setNotifications((prev) => prev.filter((notification) => notification.id !== id))
    const onClose = allowClose ? closeById : undefined
    setNotifications((prev) => [...prev, { id, title, content, customButton, type, onClose, closeOnRouteChange }])
    if (timeout) {
      setTimeout(closeById, timeout)
    }
    return id
  }, [])

  const notifySuccess = useCallback(
    (props: SpecificNotifyProps) => {
      return notify({ timeout: 12_000, type: NotificationType.Success, ...props })
    },
    [notify],
  )

  const notifyInfo = useCallback(
    (props: SpecificNotifyProps) => {
      return notify({ timeout: 12_000, type: NotificationType.Info, ...props })
    },
    [notify],
  )

  const notifyWarning = useCallback(
    (props: SpecificNotifyProps) => {
      return notify({ timeout: 15_000, type: NotificationType.Warning, ...props })
    },
    [notify],
  )

  const notifyError = useCallback(
    (props: SpecificNotifyProps) => {
      return notify({ timeout: 20_000, type: NotificationType.Error, ...props })
    },
    [notify],
  )

  const notifyProgress = useCallback((props: NotifyProgressProps) => {
    const { title, content } = props
    const id = uuid()
    setNotifications((prev) => [...prev, { id, title, content, timeout: false, type: NotificationType.Loading }])
    return id
  }, [])

  const finishProgress = useCallback(
    (notificationId: string, success: boolean, props?: Partial<SpecificNotifyProps>) => {
      setNotifications((prev) =>
        prev.map((notification) => {
          if (notification.id !== notificationId) {
            return notification
          }
          return {
            id: notificationId,
            title: props?.title ?? notification.title,
            content: props?.content ?? notification.content,
            onClose: () =>
              setNotifications((prev) => prev.filter((notification) => notification.id !== notificationId)),
            type: success ? NotificationType.Success : NotificationType.Error,
            timeout: props?.timeout ?? success ? 4_000 : 7_000,
            customButton: props?.customButton,
          }
        }),
      )
    },
    [],
  )

  const closeNotification = useCallback((id: string) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id))
  }, [])

  return (
    <NotificationContext.Provider
      value={{
        notifySuccess,
        notifyInfo,
        notifyWarning,
        notifyError,
        notifyProgress,
        finishProgress,
        closeNotification,
      }}
    >
      <NotificationsWrapper notifications={notifications} />
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) throw new Error('Expected to be wrapped in a NotificationContextProvider!')
  return context
}
