import { CSSProperties } from 'react'
import { IconName, getIcon } from '../icons/utils'

import styles from './LinkButton.module.css'

type LinkButtonProps = React.HTMLProps<HTMLAnchorElement> & {
  label: string
  iconName?: IconName
  anchorStyle?: CSSProperties
}

export const LinkButton: React.FC<LinkButtonProps> = ({ iconName, label, anchorStyle, ...restProps }) => {
  return (
    <div className={styles.linkButtonWrapper}>
      {iconName != null && <span className={styles.iconWrapper}>{getIcon(iconName)}</span>}
      <a className={styles.buttonLink} {...restProps} style={anchorStyle}>
        {label}
      </a>
    </div>
  )
}
