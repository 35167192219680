import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLevelGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <linearGradient id='level_gradient_icon_svg__a' x1={16} x2={16} y1={1} y2={31} gradientUnits='userSpaceOnUse'>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g fillRule='evenodd' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M16 1C9.663 1 4.525 6.1 4.525 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.475 6.1 22.337 1 16 1zm-.644 8.457-4.125 4.095a.927.927 0 0 0 0 1.312c.363.359.96.359 1.322 0l3.464-3.428 3.464 3.438a.937.937 0 0 0 1.599-.656.916.916 0 0 0-.277-.655l-4.125-4.106a.944.944 0 0 0-1.322 0zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
      />
      <path
        fill='url(#level_gradient_icon_svg__a)'
        fillOpacity={0.25}
        d='M16 1C9.663 1 4.525 6.1 4.525 12.39c0 6.29 5.138 11.39 11.475 11.39s11.475-5.1 11.475-11.39C27.475 6.1 22.337 1 16 1zm-.644 8.457-4.125 4.095a.927.927 0 0 0 0 1.312c.363.359.96.359 1.322 0l3.464-3.428 3.464 3.438a.937.937 0 0 0 1.599-.656.916.916 0 0 0-.277-.655l-4.125-4.106a.944.944 0 0 0-1.322 0zM4.342 19.15l-3.13 3.803a.928.928 0 0 0-.157.904c.11.308.376.536.699.6l4.154.826 1.703 5.08a.94.94 0 0 0 1.683.202l3.3-5.206a13.517 13.517 0 0 1-8.252-6.21zm15.065 6.209 3.3 5.206a.94.94 0 0 0 1.682-.202l1.703-5.08 4.154-.826c.323-.064.588-.292.7-.6s.05-.65-.158-.904l-3.13-3.803a13.517 13.517 0 0 1-8.251 6.209z'
      />
    </g>
  </svg>
)
export default SvgLevelGradientIcon
