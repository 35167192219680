import * as React from 'react'
import type { SVGProps } from 'react'
const SvgReceiptGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <linearGradient id='receipt_gradient_icon_svg__a' x1={16} x2={16} y1={2} y2={30} gradientUnits='userSpaceOnUse'>
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g fillRule='evenodd' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M11.625 2A4.375 4.375 0 0 0 7.25 6.375V21.25h-.875A4.375 4.375 0 0 0 2 25.625v3.5c0 .483.392.875.875.875h12.25a.875.875 0 0 0 .875-.875v-.875h6.125a2.625 2.625 0 0 0 2.625-2.625V10.167h4.375A.875.875 0 0 0 30 9.292V6.375A4.375 4.375 0 0 0 25.625 2h-1.75zM24.75 3.75v4.667h3.5V6.375a2.625 2.625 0 0 0-2.625-2.625zm-1.75 0H11.625A2.625 2.625 0 0 0 9 6.375V21.25h8.75a3.5 3.5 0 0 1 3.5 3.5v.875a.875.875 0 1 0 1.75 0V9.292zM19.65 26.5a2.625 2.625 0 0 1-.15-.875v-.875a1.75 1.75 0 0 0-3.5 0v1.75zm-5.4.875V24.75a3.5 3.5 0 0 1 .469-1.75H6.375a2.625 2.625 0 0 0-2.625 2.625v2.625h10.5zM12.208 8.417a.875.875 0 0 0 0 1.75h7.584a.875.875 0 0 0 0-1.75zm-.875 4.958c0-.483.392-.875.875-.875h7.584a.875.875 0 0 1 0 1.75h-7.584a.875.875 0 0 1-.875-.875zm.875 3.208a.875.875 0 0 0 0 1.75h7.584a.875.875 0 1 0 0-1.75z'
      />
      <path
        fill='url(#receipt_gradient_icon_svg__a)'
        fillOpacity={0.25}
        d='M11.625 2A4.375 4.375 0 0 0 7.25 6.375V21.25h-.875A4.375 4.375 0 0 0 2 25.625v3.5c0 .483.392.875.875.875h12.25a.875.875 0 0 0 .875-.875v-.875h6.125a2.625 2.625 0 0 0 2.625-2.625V10.167h4.375A.875.875 0 0 0 30 9.292V6.375A4.375 4.375 0 0 0 25.625 2h-1.75zM24.75 3.75v4.667h3.5V6.375a2.625 2.625 0 0 0-2.625-2.625zm-1.75 0H11.625A2.625 2.625 0 0 0 9 6.375V21.25h8.75a3.5 3.5 0 0 1 3.5 3.5v.875a.875.875 0 1 0 1.75 0V9.292zM19.65 26.5a2.625 2.625 0 0 1-.15-.875v-.875a1.75 1.75 0 0 0-3.5 0v1.75zm-5.4.875V24.75a3.5 3.5 0 0 1 .469-1.75H6.375a2.625 2.625 0 0 0-2.625 2.625v2.625h10.5zM12.208 8.417a.875.875 0 0 0 0 1.75h7.584a.875.875 0 0 0 0-1.75zm-.875 4.958c0-.483.392-.875.875-.875h7.584a.875.875 0 0 1 0 1.75h-7.584a.875.875 0 0 1-.875-.875zm.875 3.208a.875.875 0 0 0 0 1.75h7.584a.875.875 0 1 0 0-1.75z'
      />
    </g>
  </svg>
)
export default SvgReceiptGradientIcon
