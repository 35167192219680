import React from 'react'
import { BackMenuItem } from '../../../../common/components/MenuItem/components/BackMenuItem'

import { useTranslation } from 'react-i18next'
import { MenuItem } from '../../../../common/components/MenuItem/MenuItem'
import { USER_MENU_INITIAL } from '../../../../common/constants'
import { useUser } from '../../../../contexts/userContext'
import { getActiveBusinessId } from '../../helpers'

type AccountMenuProps = {
  isActiveUser?: boolean
  onMenuItemClick: (menuItemName: string, value: string) => void
  onMenuBackClick: (menuType: string) => void
}

export const AccountMenu: React.FC<AccountMenuProps> = ({ onMenuItemClick, onMenuBackClick }) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const renderAccountMenuItems = React.useMemo(() => {
    if (!user) {
      return null
    }
    return user?.businesses.map((business) => {
      return (
        <MenuItem
          fontSize='normal'
          text={business.name}
          itemType='business'
          menuItemName={business.name}
          value={business.id}
          isUserMenu
          isActiveUser={business.id === getActiveBusinessId(user)}
          onMenuItemClick={onMenuItemClick}
        />
      )
    })
  }, [onMenuItemClick, user])

  return (
    <>
      <BackMenuItem
        fontSize='normal'
        icon='backIcon'
        text={t('user_menu.back', 'Back')}
        itemType={USER_MENU_INITIAL}
        onMenuItemClick={onMenuBackClick}
      />
      {renderAccountMenuItems}
    </>
  )
}
