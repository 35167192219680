import { ExerciseType } from '../../../../../api/gameTypes'
import { EditorFormField } from '../../../../../common/components/Form/EditorFormField/EditorFormField'
import { MissingWordEditorFormField } from '../../../../../common/components/Form/EditorFormField/MissingWordEditorFormField'
import { getIcon } from '../../../../../common/components/icons/utils'
import { CreativeSubtask, Subtask } from '../../../../../types/commonTypes'
import { getDescriptionForTaskType, getIconNameForTaskType, getTitleForTaskType } from './helpers'
import { CreativeSubtaskFields } from './SubtaskFields/CreativeSubtask/CreativeSubtaskFields'
import styles from './TaskModal.module.css'

type SubtaskFieldProps = {
  value: Subtask
  name: string
  onDelete: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
}

export const SubtaskField: React.FC<SubtaskFieldProps> = ({
  value,
  name,
  onDelete,
  onMoveUp,
  onMoveDown,
}) => {
  return (
    <div className={styles.subtaskContainer}>
      <div className={styles.subtaskHeader}>
        <div className={styles.subtaskHeaderTitle}>
          <span>
            {getIcon(getIconNameForTaskType(value.type))}
          </span>
          {getTitleForTaskType(value.type)} task
        </div>
        <div className={styles.subtaskHeaderActions}>
          { onMoveDown != null && (
            <button
              className={styles.smallActionButton}
              onClick={onMoveDown}
              type='button'
            >
              {getIcon('longerArrowDown')}
            </button>
          ) }
          { onMoveUp != null && (
            <button
              className={styles.smallActionButton}
              onClick={onMoveUp}
              type='button'
            >
              {getIcon('longerArrowUp')}
            </button>
          ) }
          <button
            className={styles.smallActionButton}
            onClick={onDelete}
            type='button'
          >
            {getIcon('trash')}
          </button>
        </div>
      </div>
      <div className={styles.subtaskFieldsContainer}>
        <h5 className={styles.subtaskTitle}>Task description</h5>
        <p className={styles.subtaskDescription}>
          {getDescriptionForTaskType(value.type)}
        </p>
        { value.type === ExerciseType.MissingWordExercise ? (
          <MissingWordEditorFormField
            name={`${name}.description`}
            label='Describe the task'
          />
        ) : (
          <EditorFormField
            name={`${name}.description`}
            label='Describe the task'
            srOnlyLabel
          />
        )}
        <div className={styles.subtaskFieldsContainer}>
          { value.type === ExerciseType.CreativeExercise && (
            <CreativeSubtaskFields
              name={name}
              value={value as CreativeSubtask}
            />
          )}
        </div>
      </div>
    </div>
  )
}
