import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotification } from '../contexts/NotificationContext'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'

export const useGameNotifications = () => {
  const { notifyProgress, finishProgress, notifyError } = useNotification()
  const { t } = useTranslation('translation', { keyPrefix: 'game_action_notifications' })
  const navigate = useNavigate()

  const notifyGameImportStart = useCallback(
    (gameName: string) => {
      return notifyProgress({
        title: t('import.progress.title', 'Importing game'),
        content: t('import.progress.content', { gameName, defaultValue: 'Importing %{gameName}...' }),
      })
    },
    [notifyProgress, t],
  )

  const notifyGameImportFinish = useCallback(
    (notifyId: string, gameName: string, success: boolean) => {
      const title = success
        ? t('import.finished.success.title', 'Game imported')
        : t('import.finished.failure.title', 'Game import failed')

      const content = success
        ? t('import.finished.success.content', { gameName, defaultValue: 'Go to dashboard to see %{gameName}' })
        : t('import.finished.failure.content', {
            gameName,
            defaultValue: 'Failed to import %{gameName}, please try again or contact us for support',
          })

      const customButton = success
        ? {
            text: t('import.finished.success.go_to_dashboard_button_text', 'Go'),
            onClick: () => navigate(routes.homepage),
            closeOnClick: true,
          }
        : undefined

      finishProgress(notifyId, success, { title, content, customButton })
    },
    [finishProgress, t, navigate],
  )

  const notifyGameDuplicateStart = useCallback(
    (gameName: string) => {
      return notifyProgress({
        title: t('duplicate.progress.title', 'Duplicating game'),
        content: t('duplicate.progress.content', { gameName, defaultValue: 'Duplicating %{gameName}...' }),
      })
    },
    [notifyProgress, t],
  )

  const notifyGameDuplicateFinish = useCallback(
    (notifyId: string, gameName: string, success: boolean) => {
      const title = success
        ? t('duplicate.finished.success.title', 'Game duplicated')
        : t('duplicate.finished.failure.title', 'Game duplication failed')

      const content = success
        ? t('duplicate.finished.success.content', { gameName, defaultValue: 'Duplicated game %{gameName}' })
        : t('duplicate.finished.failure.content', {
            gameName,
            defaultValue: 'Failed to duplicate %{gameName}, please try again or contact us for support',
          })

      finishProgress(notifyId, success, { title, content })
    },
    [finishProgress, t],
  )

  const notifyGameDeleteStart = useCallback(
    (gameName: string) => {
      return notifyProgress({
        title: t('delete.progress.title', 'Deleting game'),
        content: t('delete.progress.content', { gameName, defaultValue: 'Deleting %{gameName}...' }),
      })
    },
    [notifyProgress, t],
  )

  const notifyGameDeleteFinish = useCallback(
    (notifyId: string, gameName: string, success: boolean) => {
      const title = success
        ? t('delete.finished.success.title', 'Game deleted')
        : t('delete.finished.failure.title', 'Game deletion failed')

      const content = success
        ? t('delete.finished.success.content', { gameName, defaultValue: 'Deleted game %{gameName}' })
        : t('delete.finished.failure.content', {
            gameName,
            defaultValue: 'Failed to delete %{gameName}, please try again or contact us for support',
          })

      finishProgress(notifyId, success, { title, content })
    },
    [finishProgress, t],
  )

  const notifyFetchingGamesFailed = useCallback(() => {
    notifyError({
      title: t('loading_games_error.title', 'Failed to load games'),
      content: t(
        'loading_games_error.content',
        'An error occurred while loading games. Please try again or contact us for support',
      ),
    })
  }, [notifyError, t])

  return {
    notifyGameImportStart,
    notifyGameImportFinish,
    notifyGameDuplicateStart,
    notifyGameDuplicateFinish,
    notifyGameDeleteStart,
    notifyGameDeleteFinish,
    notifyFetchingGamesFailed,
  }
}
