import classNames from 'classnames'
import mapboxgl from 'mapbox-gl'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapType } from '../../../../api/gameTypes'
import { SelectableIcon } from '../../../../common/components/SelectableIcon/SelectableIcon'
import { RoundButton } from '../../../../common/components/button/RoundButton'
import { TextIcon } from '../../../../common/components/icons'
import { DEFAULT_MAP_OPTIONS, READY_360_GAME_BOARDS, READY_STATIC_GAME_BOARDS } from '../../../../common/constants'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { MapInstanceID, useMapApi } from '../../../../util/map'
import { isNullOrZero } from '../../../../util/number'
import { GameBoard, GameBoardSettings } from '../../types'
import { MapboxContainer } from '../MapboxContainer/MapboxContainer'
import { AddGameBoardsModal } from './AddGameBoardsModal'
import styles from './GameBoardsPicker.module.css'

type GameBoardPickerProps = {
  initialValues: GameBoardSettings
  onChange: (settings: GameBoardSettings) => void
}

const hasMapDataForType = (currentState: GameBoardSettings, map: mapboxgl.Map): boolean => {
  switch (currentState.gameBoardType) {
    case MapType.LIVE:
      return (
        map.getZoom() !== DEFAULT_MAP_OPTIONS.zoom ||
        map.getCenter().lng !== DEFAULT_MAP_OPTIONS.center[0] ||
        map.getCenter().lat !== DEFAULT_MAP_OPTIONS.center[1]
      )
    default:
      return !isNullOrZero(currentState.gameBoards?.length)
  }
}

const shouldRequestConfirmation = (
  newBoardType: string,
  currentState: GameBoardSettings,
  map: mapboxgl.Map,
): boolean => {
  return currentState.gameBoardType !== newBoardType && hasMapDataForType(currentState, map)
}

export const GameBoardPicker: React.FC<GameBoardPickerProps> = ({ initialValues, onChange }) => {
  const { t } = useTranslation()
  const [gameBoardsState, setGameBoardsState] = useState<GameBoardSettings>(initialValues)
  const [uploadedGameBoards, setUploadedGameBoards] = useState<GameBoard[]>([])
  const [showAddGameBoards, setShowAddGameBoards] = useState(false)
  const { requestConfirmation } = useConfirmation()
  const { map } = useMapApi(MapInstanceID.MODAL)

  const toggleAddGameBoardsModalVisibility = useCallback(() => {
    setShowAddGameBoards((prev) => !prev)
  }, [])

  const handleGameBoardSelectionChange = useCallback((boards: GameBoard[]) => {
    setGameBoardsState((prev) => {
      return {
        ...prev,
        gameBoards: boards,
      }
    })
    setShowAddGameBoards(false)
  }, [])

  const onChangeBoardTypeInternal = useCallback(
    (id: string) => {
      if (!shouldRequestConfirmation(id, gameBoardsState, map)) {
        setGameBoardsState({
          gameBoardType: id as MapType,
          liveMap: DEFAULT_MAP_OPTIONS,
          gameBoards: [],
        })
      } else {
        requestConfirmation({
          title: t('game_editor.game_boards.change_board_type_confirmation.title', 'Changing the board type'),
          text:
            gameBoardsState.gameBoardType === MapType.LIVE
              ? t(
                  'game_editor.game_boards.change_board_type_confirmation.text.live_map',
                  'You already set a position for live map. Do you want to reset it and choose different game boards instead?',
                )
              : t(
                  'game_editor.game_boards.change_board_type_confirmation.text.static_or_360',
                  'You already have boards of one type selected. Do you want to remove them and select another type?',
                ),
        }).then((response) => {
          if (response) {
            setGameBoardsState({
              gameBoardType: id as MapType,
              liveMap: DEFAULT_MAP_OPTIONS,
              gameBoards: [],
            })
          }
        })
      }
    },
    [gameBoardsState, requestConfirmation, map, t],
  )

  useEffect(() => {
    onChange(gameBoardsState)
  }, [onChange, gameBoardsState])

  const handleDeleteGameBoard = useCallback(
    (url: string) => () => {
      setGameBoardsState((prev) => {
        return {
          ...prev,
          gameBoards: prev.gameBoards.filter((map) => map.url !== url),
        }
      })
    },
    [],
  )

  const handleUploadGameBoard = useCallback((newBoard: GameBoard) => {
    setUploadedGameBoards((prev) => [...prev, newBoard])
    setGameBoardsState((prev) => {
      return {
        ...prev,
        gameBoards: [...(prev.gameBoards || []), newBoard],
      }
    })
    setShowAddGameBoards(false)
  }, [])

  const allGameBoards = useMemo(() => {
    return [
      ...uploadedGameBoards,
      ...(gameBoardsState.gameBoardType === MapType.PANORAMA ? READY_360_GAME_BOARDS : READY_STATIC_GAME_BOARDS),
    ]
  }, [gameBoardsState.gameBoardType, uploadedGameBoards])

  return (
    <>
      {showAddGameBoards && (
        <AddGameBoardsModal
          allGameBoards={allGameBoards}
          previouslySelectedBoards={gameBoardsState.gameBoards}
          onClickBack={toggleAddGameBoardsModalVisibility}
          onClickAddSelection={handleGameBoardSelectionChange}
          onUploadGameBoard={handleUploadGameBoard}
        />
      )}
      <div className={classNames(styles.container)}>
        <div className={styles.row}>
          <label className='normal'>{t('game_editor.game_boards.board_type_label', 'Game board type')}</label>
          <div className={styles.buttonsWrapper}>
            <SelectableIcon
              id={MapType.LIVE}
              checked={gameBoardsState.gameBoardType === MapType.LIVE}
              text={t('game_editor.game_boards.live_map.text', 'Live map')}
              iconName='selectMap'
              size='Medium'
              tooltipText={t(
                'game_editor.game_boards.live_map.tooltip',
                'Real world map relative to a geo-location.',
              )}
              onChange={onChangeBoardTypeInternal}
            />
            <SelectableIcon
              id={MapType.STATIC}
              checked={gameBoardsState.gameBoardType === MapType.STATIC}
              text={t('game_editor.game_boards.image.text', 'Image')}
              iconName='photo'
              size='Medium'
              tooltipText={t(
                'game_editor.game_boards.image.tooltip',
                'An image from our default selection, or an uploaded image from your computer.',
              )}
              onChange={onChangeBoardTypeInternal}
            />
            <SelectableIcon
              id={MapType.PANORAMA}
              checked={gameBoardsState.gameBoardType === MapType.PANORAMA}
              text={t('game_editor.game_boards.360.text', '360° image')}
              iconName='radar'
              size='Medium'
              tooltipText={t('game_editor.game_boards.360.tooltip', 'A panorama type image.')}
              onChange={onChangeBoardTypeInternal}
            />
          </div>
        </div>
        <div className={styles.row}>
          {gameBoardsState.gameBoardType !== MapType.LIVE && (
            <div className={classNames(styles.boardsWrapper)}>
              {gameBoardsState.gameBoards.map((board, i) => {
                return (
                  // TODO: needs to be discussed at this point - add ability to remove a board by clicking on it
                  // so that the user doesn't need to go to "ADD GAME BOARDS" to "REMOVE" a board, otherwise refactor
                  // this button to a div
                  <button
                    key={`${board.url}:${i}`}
                    className={styles.selectedBoardThumbnail}
                    onClick={handleDeleteGameBoard(board.url)}
                  >
                    <div>
                      <img className={styles.image} alt={board.url} src={board.url} />
                    </div>
                    <span>
                      <TextIcon color='var(--primary-normal)' />
                      <label>
                        {t('game_editor.game_boards.board', 'Board')} {i + 1}
                      </label>
                    </span>
                  </button>
                )
              })}
              <div
                className={classNames(
                  styles.addButton,
                  gameBoardsState.gameBoards.length ? styles.withoutLabel : styles.withLabel,
                )}
              >
                <RoundButton
                  buttonStyle={{
                    backgroundColor: 'white',
                    color: 'var(--primary-normal)',
                    fontSize: '1.375rem',
                  }}
                  icon='plus'
                  onClick={toggleAddGameBoardsModalVisibility}
                />
                {!gameBoardsState.gameBoards.length && (
                  <label className='medium' style={{ color: 'var(--primary-normal)' }}>
                    {t('game_editor.game_boards.add_board_label', 'Add game board')}
                  </label>
                )}
              </div>
            </div>
          )}
          {gameBoardsState.gameBoardType === MapType.LIVE && (
            <div className={styles.mapboxContainer}>
              <MapboxContainer
                mapInstanceId={MapInstanceID.MODAL}
                mapOptions={gameBoardsState.liveMap}
                boardType={gameBoardsState.gameBoardType}
                showMapbox={gameBoardsState.gameBoardType === MapType.LIVE}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
