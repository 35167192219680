import classNames from 'classnames'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IconName, getIcon } from '../../common/components/icons/utils'
import { ThreeDots } from '../../common/components/loaders/ThreeDots/ThreeDots'
import { useFadeAnimation } from '../../hooks/FadeAnimation/useFadeAnimation'
import styles from './Notification.module.css'

export enum NotificationType {
  Success = 'Success',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
  Loading = 'Loading',
}

type NotifyCustomButtonProps = {
  text: string
  onClick: () => void
  closeOnClick?: boolean
}

export type NotificationItemProps = {
  title: string | JSX.Element
  content: string | JSX.Element
  type: NotificationType
  id: string
  customButton?: NotifyCustomButtonProps
  onClose?: () => void
  closeOnRouteChange?: boolean
}

const getIconNameForType = (type: NotificationType): IconName => {
  switch (type) {
    case NotificationType.Success:
      return 'circleCheckmark'
    case NotificationType.Warning:
    case NotificationType.Error:
      return 'alert'
    default:
      if (type !== NotificationType.Info) {
        console.warn(`Unknown notification type to icon mapping. Type is: ${type}. Returning icon for type info.`)
      }
      return 'info'
  }
}

export const NotificationItem: React.FC<NotificationItemProps> = ({ title, content, type, customButton, onClose }) => {
  const { t } = useTranslation()
  const { fadeTransition, closeWithDelay } = useFadeAnimation()

  const handleCustomButtonClick = useCallback(() => {
    customButton?.onClick()
    if (customButton?.closeOnClick && onClose != null) {
      closeWithDelay(350, onClose)
    }
  }, [closeWithDelay, customButton, onClose])

  const handleClickCloseWithDelay = () => {
    if (onClose != null) {
      closeWithDelay(350, onClose)
    }
  }

  return (
    <div className={classNames(styles.notificationItem, fadeTransition, styles.slideIn)}>
      <div className={styles.iconAndTextContainer}>
        <div className={styles.iconContainer}>
          {type === NotificationType.Loading ? (
            <ThreeDots remSize={0.625} />
          ) : (
            <span className={classNames(styles.notificationItemIcon, styles[`notificationItemIcon_${type}`])}>
              {getIcon(getIconNameForType(type))}
            </span>
          )}
        </div>
        <div className={styles.textContainer}>
          <div>{title}</div>
          <div>{content}</div>
        </div>
      </div>
      {customButton != null && (
        <div className={styles.actionsContainer}>
          <button className={styles.actionButton} onClick={handleCustomButtonClick}>
            {customButton.text}
          </button>
        </div>
      )}
      {onClose != null && (
        <div className={styles.actionsContainer}>
          <button className={styles.actionButton} onClick={handleClickCloseWithDelay}>
            {t('notification_toast.dismiss_button_text', 'Dismiss')}
          </button>
        </div>
      )}
    </div>
  )
}
