// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrgLibrary_container__XAJoI {\n  position: relative;\n  height: 100%;\n}\n.OrgLibrary_EmptyContent__7Ot\\+F {\n  padding-top: 3rem;\n  display: flex;\n  flex-direction: column;\n  color: var(--grey-800);\n  text-align: center;\n  line-height: 5rem;\n}\n\n.OrgLibrary_noContentButtons__UjwAU {\n  display: flex;\n  justify-content: center;\n  gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OrgLibrary/OrgLibrary.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX","sourcesContent":[".container {\n  position: relative;\n  height: 100%;\n}\n.EmptyContent {\n  padding-top: 3rem;\n  display: flex;\n  flex-direction: column;\n  color: var(--grey-800);\n  text-align: center;\n  line-height: 5rem;\n}\n\n.noContentButtons {\n  display: flex;\n  justify-content: center;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OrgLibrary_container__XAJoI",
	"EmptyContent": "OrgLibrary_EmptyContent__7Ot+F",
	"noContentButtons": "OrgLibrary_noContentButtons__UjwAU"
};
export default ___CSS_LOADER_EXPORT___;
