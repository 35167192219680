import React from 'react'

import { RoundButton, RoundButtonProps } from './RoundButton'

export const CloseButton = React.forwardRef<HTMLButtonElement, RoundButtonProps>(
  ({ buttonStyle, onClick, accessibilityLabel }, ref) => {
    return (
      <RoundButton
        buttonStyle={{
          fontSize: '1rem',
          background: 'transparent',
          boxShadow: 'none',
          padding: '0.5rem',
          top: '-5px',
          position: 'relative',
          alignItems: 'baseline',
          ...buttonStyle,
        }}
        icon={'close'}
        onClick={onClick}
        accessibilityLabel={accessibilityLabel}
      />
    )
  },
)
