// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormError_fieldErrorContainer__fyZcQ {\n  transform: translateY(-0.25rem);\n  font-size: 0.75rem;\n  padding: 0.25rem 0.5rem;\n  border-radius: 0.25rem;\n  margin: 0 0.5rem;\n  display: inline-block;\n  min-height: 1.0625rem;\n}\n\n.FormError_withError__rOEO8 {\n  color: #fff;\n  background-color: var(--validation-error);\n}\n\n.FormError_submitErrorContainer__hSvV0 {\n  padding: 0.5rem;\n  border-radius: 0.5rem;\n  color: var(--validation-error);\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/Form/FormFieldError/FormError.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":[".fieldErrorContainer {\n  transform: translateY(-0.25rem);\n  font-size: 0.75rem;\n  padding: 0.25rem 0.5rem;\n  border-radius: 0.25rem;\n  margin: 0 0.5rem;\n  display: inline-block;\n  min-height: 1.0625rem;\n}\n\n.withError {\n  color: #fff;\n  background-color: var(--validation-error);\n}\n\n.submitErrorContainer {\n  padding: 0.5rem;\n  border-radius: 0.5rem;\n  color: var(--validation-error);\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldErrorContainer": "FormError_fieldErrorContainer__fyZcQ",
	"withError": "FormError_withError__rOEO8",
	"submitErrorContainer": "FormError_submitErrorContainer__hSvV0"
};
export default ___CSS_LOADER_EXPORT___;
