import classNames from 'classnames'
import React from 'react'
import { GameBoardSettings } from '../../../types'
import { GameBoardPicker } from '../../GameBoardsPicker/GameBoardsPicker'
import styles from './Steps.module.css'

type GameBoardStepProps = {
  initialSettings: GameBoardSettings
  onChange: (settings: GameBoardSettings) => void
}

export const GameBoardStep: React.FC<GameBoardStepProps> = ({ initialSettings, onChange }) => {
  return (
    <div className={classNames(styles.container)}>
      <GameBoardPicker initialValues={initialSettings} onChange={onChange} />
    </div>
  )
}
