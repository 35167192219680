import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../common/components/button/Button'
import { Tooltip } from '../../../../../common/components/tooltip/Tooltip'
import { TUser } from '../../../../../types/commonTypes'
import sharedStyles from '../../../Settings.module.css'
import { EditablePreviewItem } from './EditablePreviewItem'
import styles from './Profile.module.css'

// NOTE: name attribute is displayed as email, chatName as in-game-alias, contactName as name
export type UserProfile = Pick<TUser, 'contactName' | 'chatName' | 'name'>

type ProfileSectionProps = {
  userProfile: UserProfile
  onUpdateField: (key: keyof Omit<UserProfile, 'name'>, value: string, label: string) => Promise<boolean>
  onResetPasswordClick: () => void
  onLogOutClick: () => void
  isResetPasswordLoading: boolean
}

export const ProfileSection: React.FC<ProfileSectionProps> = ({
  userProfile,
  onUpdateField,
  onResetPasswordClick,
  onLogOutClick,
  isResetPasswordLoading,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings_general.profile' })

  return (
    <div className={sharedStyles.section}>
      <h1 className={sharedStyles.sectionTitle}>{t('title', 'Profile Settings')}</h1>
      <div className={classNames(sharedStyles.sectionCardContainer, styles.profileContainer)}>
        <EditablePreviewItem
          label={t('labels.name', 'Name')}
          initialValue={userProfile.contactName}
          onUpdate={(value: string) => onUpdateField('contactName', value, t('labels.name', 'Name'))}
        />
        <div className={sharedStyles.divider} />
        <EditablePreviewItem
          label={t('labels.game_alias', 'In-game alias')}
          initialValue={userProfile.chatName}
          onUpdate={(value: string) => onUpdateField('chatName', value, t('labels.game_alias', 'In-game alias'))}
        />
        <div className={sharedStyles.divider} />
        <div className={styles.rowContainer}>
          <label className={styles.firstColumn}>{t('labels.email', 'Email')}</label>
          <span className={classNames(styles.secondColumn, styles.textContent)}>{userProfile.name}</span>
          <Tooltip
            text={t('change_email_tooltip', 'To change your email, contact support.')}
            iconClassName={styles.emailTooltipIcon}
          />
        </div>
        <div className={sharedStyles.divider} />
        <div className={styles.rowContainer}>
          <label className={styles.firstColumn}>{t('labels.password', 'Password')}</label>
          <span className={classNames(styles.secondColumn, styles.textContent)}>••••••••</span>
          <Button
            type='button'
            styleVariant='tertiary'
            sizeVariant='tiny'
            label={t('buttons_text.reset_password', 'Reset')}
            onClick={onResetPasswordClick}
            disabled={isResetPasswordLoading}
          />
        </div>
        <div className={styles.logOutButtonContainer}>
          <Button
            type='button'
            styleVariant='tertiary'
            sizeVariant='tiny'
            label={t('buttons_text.log_out', 'Log out of this account')}
            onClick={onLogOutClick}
            iconLeft='logout'
            iconLeftContainerStyle={{ fontSize: '1rem' }}
          />
        </div>
      </div>
    </div>
  )
}
