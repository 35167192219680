import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { copyOwnGame, deleteOwnGame, getOwnGames, PAGE_SIZE } from '../api/gameApiService'
import { LibrarySource } from '../api/gameTypes'
import { parseGamesResponseToGameCards } from '../api/typeConverters'
import { PaginationSelectOption } from '../composites/GamesOverview/components/PaginationControls'
import { useGameNotifications } from '../hooks/useGameNotifications'
import { TGameCard } from '../types/commonTypes'
import { noop } from '../util/functional'
import { useUser } from './userContext'

export const PAGE_SIZE_OPTIONS = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
]

export type GameFilters = {
  search: string
  language?: string
  subject?: string
  age?: string
  updatedAt?: string
  approvedBySeppo?: string
  source?: LibrarySource
}

export interface GamesSourceCount {
  total: number
  own: number
  community: number
  orgLibrary: number
  template: number
}

export type TGameAction = (gameId: number, gameName: string) => void

export interface GameDataProviderInterface {
  currentPage: number
  pageSize: number
  pageData: TGameCard[] | undefined
  loading: boolean
  lastPage: number
  allPageOptions: PaginationSelectOption[]
  selectPageSize: (newPageSize: number) => void
  prev: () => void
  next: () => void
  setPage: (newPage: number) => void
  copyGame: TGameAction
  deleteGame: TGameAction
  importGame: TGameAction
  refreshGames: () => void
  orderBy?: string
  orderDir?: string
  setOrderBy?: (orderAttribute: string) => void
  setOrderDir?: (orderDir: string) => void
  filters?: GameFilters
  setFilters?: (filters: GameFilters | Partial<GameFilters>, isPartial?: boolean) => void
  gamesSourceCount?: GamesSourceCount
}

// TODO: Add last
const OwnGamesDataContext = createContext<GameDataProviderInterface>({
  currentPage: 1,
  pageSize: PAGE_SIZE,
  pageData: undefined,
  loading: true,
  // TODO: we need a total number of items to determine the last page so we can disable paginating after that point
  lastPage: 1,
  allPageOptions: [] as PaginationSelectOption[],
  selectPageSize: noop,
  prev: noop,
  next: noop,
  setPage: noop,
  copyGame: noop,
  deleteGame: noop,
  importGame: noop,
  refreshGames: noop,
  orderBy: 'updated_at',
  orderDir: 'desc',
  setOrderBy: noop,
  setOrderDir: noop,
})

export const OwnGamesDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageData, setPageData] = useState<TGameCard[]>()
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)
  const [loading, setLoading] = useState(true)
  const [lastPage, setLastPage] = useState<number>(1)
  const [allPageOptions, setAllPageOptions] = useState<PaginationSelectOption[]>([{ value: 1, label: '1' }])
  const [orderBy, setOrderBy] = useState('updated_at')
  const [orderDir, setOrderDir] = useState('desc')
  const { user } = useUser()

  const {
    notifyGameDeleteStart,
    notifyGameDeleteFinish,
    notifyGameDuplicateStart,
    notifyGameDuplicateFinish,
    notifyFetchingGamesFailed,
  } = useGameNotifications()

  const next = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState + 1)
  }, [loading])

  const prev = useCallback(() => {
    if (loading) {
      return
    }

    setCurrentPage((currentState) => currentState - 1)
  }, [loading])

  const selectPageSize = useCallback(
    (newPageSize: number) => {
      if (loading) {
        return
      }

      setCurrentPage(1)
      setPageSize(newPageSize)
    },
    [loading],
  )

  const setPage = useCallback(
    (newPage: number) => {
      if (loading) {
        return
      }

      setCurrentPage(newPage)
    },
    [loading],
  )

  useEffect(() => {
    if (user?.id == null) {
      return
    }
    const abortController = new AbortController()
    setLoading(true)
    getOwnGames({ perPage: pageSize, page: currentPage, userId: user?.id, orderBy: `${orderBy} ${orderDir}` })
      .then((ownGamesResponse) => {
        if (ownGamesResponse.success) {
          setLastPage(Math.ceil(ownGamesResponse.value.count / pageSize))
          setPageData(parseGamesResponseToGameCards(ownGamesResponse.value, LibrarySource.OWN))
        } else {
          notifyFetchingGamesFailed()
          console.error(ownGamesResponse.error)
        }
      })
      .finally(() => setLoading(false))
    return () => abortController.abort()
  }, [currentPage, orderBy, orderDir, pageSize, user?.id, notifyFetchingGamesFailed])

  useEffect(() => {
    const calculateAllPageOptions = Array.from({ length: lastPage }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }))
    setAllPageOptions(calculateAllPageOptions)
  }, [lastPage])

  const refreshGames = useCallback(() => {
    if (user?.id == null) {
      return
    }
    getOwnGames({ userId: user?.id, orderBy: `${orderBy} ${orderDir}` }).then((ownGamesResponse) => {
      if (ownGamesResponse.success) {
        setPageData(parseGamesResponseToGameCards(ownGamesResponse.value, LibrarySource.OWN))
        setLastPage(Math.ceil(ownGamesResponse.value.count / pageSize))
      } else {
        console.error(ownGamesResponse.error)
      }
    })
  }, [orderBy, orderDir, pageSize, user?.id])

  const copyGame = useCallback(
    async (gameId: number, gameName: string) => {
      const notifyId = notifyGameDuplicateStart(gameName)
      const copyResponse = await copyOwnGame({ gameId })
      if (copyResponse.success && pageData) {
        const combinedArray = parseGamesResponseToGameCards(copyResponse.value, LibrarySource.OWN).concat(pageData)
        setPageData(combinedArray)
      } else if (!copyResponse.success) {
        console.error(copyResponse.error)
      }
      notifyGameDuplicateFinish(notifyId, gameName, copyResponse.success)
    },
    [pageData, notifyGameDuplicateFinish, notifyGameDuplicateStart],
  )

  const deleteGame = useCallback(
    async (gameId: number, gameName: string, force = false) => {
      const notifyId = notifyGameDeleteStart(gameName)
      const deleteResponse = await deleteOwnGame({ gameId, force })
      if (deleteResponse.success && pageData) {
        const newArray = pageData.filter((game) => parseInt(game.id) !== gameId)
        setPageData(newArray)
      } else if (!deleteResponse.success) {
        console.error(deleteResponse.error)
      }
      notifyGameDeleteFinish(notifyId, gameName, deleteResponse.success)
    },
    [pageData, notifyGameDeleteStart, notifyGameDeleteFinish],
  )

  return (
    <OwnGamesDataContext.Provider
      value={{
        currentPage,
        pageSize,
        pageData,
        lastPage,
        allPageOptions,
        loading,
        setPage,
        selectPageSize,
        next,
        prev,
        copyGame,
        deleteGame,
        importGame: noop,
        refreshGames,
        orderBy,
        orderDir,
        setOrderBy,
        setOrderDir,
      }}
    >
      {children}
    </OwnGamesDataContext.Provider>
  )
}

export const useGameData = () => {
  const context = useContext(OwnGamesDataContext)

  return context
}
