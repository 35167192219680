import classNames from 'classnames'
import styles from './ExerciseAccordionItem.module.css'
import { StarIcon } from '../icons'

type TypeAndPointsProps = {
  title: string
  points: number
  className?: string
}

export const TypeAndPoints: React.FC<TypeAndPointsProps> = ({ title, points, className }) => {
  return (
    <div className={classNames(styles.secondRow, className)}>
      <div className={styles.tag}>
        <p>{title}</p>
      </div>
      <div className={styles.pointsWrapper}>
        <span className={styles.starIcon}>
          <StarIcon fontSize={10} />
        </span>
        <p>{points}</p>
      </div>
    </div>
  )
}
