import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useManageGame } from '../../hooks/useManageGame'
import { routes } from '../../routes'
import { safeParseInt } from '../../util/number'
import BoardSection from './components/BoardSection/BoardSection'
import { GameSettingsModal } from './components/GameSettings/GameSettingsModal'
import { Header } from './components/Header/Header'
import { QuickStartWizard } from './components/QuickStartWizard/QuickStartWizard'
import { TaskSidebar } from './components/Tasks/TaskSidebar/TaskSidebar'
import styles from './GameEditor.module.css'
import { GameForm, TabType } from './types'

export const GameEditor: React.FC<{}> = () => {
  const { gameId: gameIdParam } = useParams()
  const gameId = useMemo<number | null>(() => {
    return safeParseInt(gameIdParam)
  }, [gameIdParam])

  const navigate = useNavigate()

  const { gameData, createGame, updateGame, loadingGetGame, loadingCreateGame, loadingUpdateGame } =
    useManageGame(gameId)

  useEffect(() => {
    if (gameData?.gameId != null && gameIdParam == null) {
      navigate(routes.createGameEditorWithId(gameData.gameId.toString()), {
        replace: true,
      })
    }
  }, [gameData?.gameId, gameIdParam, navigate])

  const [isQuickStartWizardOpen, setIsQuickStartWizardOpen] = useState(gameId == null)
  const [isGameSettingsModalOpen, setIsGameSettingsModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(TabType.BUILD)

  const handleClickTab = useCallback((id: string) => {
    setActiveTab(id as TabType)
  }, [])

  const handleClickGameSettings = () => {
    setIsGameSettingsModalOpen(true)
  }

  const handleClickCloseGameSettings = () => {
    setIsGameSettingsModalOpen(false)
  }

  const handleCloseQuickStartWizard = () => {
    setIsQuickStartWizardOpen(false)
  }

  const handleOpenGameSettingsFromWizard = () => {
    setIsQuickStartWizardOpen(false)
    setIsGameSettingsModalOpen(true)
  }

  const switchSettingsToQuickStartWizard = () => {
    setIsGameSettingsModalOpen(false)
    setIsQuickStartWizardOpen(true)
  }

  const handleSubmitQuickStartWizard = (data: GameForm) => {
    createGame(data).then((success) => {
      setIsQuickStartWizardOpen(!success)
    })
  }

  const handleSubmitGameSettings = (data: GameForm) => {
    if (gameData == null) {
      createGame(data).then((success) => setIsGameSettingsModalOpen(!success))
    } else {
      updateGame(data).then((success) => setIsGameSettingsModalOpen(!success))
    }
  }

  return (
    <div className={styles.container}>
      {isQuickStartWizardOpen && (
        <QuickStartWizard
          loading={loadingCreateGame}
          onClose={handleCloseQuickStartWizard}
          onOpenGameSettings={handleOpenGameSettingsFromWizard}
          onSubmit={handleSubmitQuickStartWizard}
        />
      )}
      <Header
        gameName={gameData?.name}
        activeTab={activeTab}
        onClickTab={handleClickTab}
        onClickGameSettings={handleClickGameSettings}
      />
      <TaskSidebar game={gameData} />
      <BoardSection gameData={gameData} loading={loadingGetGame} />
      {isGameSettingsModalOpen && (
        <GameSettingsModal
          loading={loadingCreateGame || loadingUpdateGame}
          gameData={gameData}
          onClose={handleClickCloseGameSettings}
          onSubmit={handleSubmitGameSettings}
          onSwitchToQuickStartWizard={switchSettingsToQuickStartWizard}
        />
      )}
    </div>
  )
}
