import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoundButton } from '../../common/components/button/RoundButton'
import { USER_MENU_INITIAL } from '../../common/constants'
import shared from '../../common/styles/shared.module.css'
import { useUser } from '../../contexts/userContext'
import { routes } from '../../routes'
import { Language } from '../../types/commonTypes'
import { getEnumKeyByValue } from '../../util/functional'
import { capitalizeFirstLetter } from '../../util/string'
import styles from './UserMenu.module.css'
import { MenuSelector } from './components/MenuSelector/MenuSelector'
import { getActiveBusiness, ifUserHasMultipleBusinesses } from './helpers'

type UserMenuProps = {
  containerStyle?: React.CSSProperties
  isUserMenuOpen: boolean
  onMenuClose: () => void
  onMenuItemClick?: () => void
}

export const UserMenuContainer = forwardRef<HTMLDivElement, UserMenuProps>(
  ({ onMenuClose, containerStyle, isUserMenuOpen }, ref) => {
    const navigate = useNavigate()
    const { user, doLogout, updateUser } = useUser()

    const onContainerClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation()
    }

    const [menuType, setMenuType] = React.useState(USER_MENU_INITIAL)

    React.useEffect(() => {
      return () => {
        setMenuType(USER_MENU_INITIAL)
      }
    }, [isUserMenuOpen])

    const handleBackItemClick = (menuType: string) => {
      setMenuType(menuType)
    }

    const onCloseButtonClick = React.useCallback(
      (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onMenuClose()
        setMenuType(USER_MENU_INITIAL)
      },
      [onMenuClose],
    )

    const handleInitialMenuItemClick = (menuItem: string) => {
      if (menuItem === 'logout') {
        doLogout()
      } else if (menuItem === 'generalSettings') {
        navigate(routes.generalSettings)
        onMenuClose()
      } else {
        setMenuType(menuItem)
      }
    }

    const handleLanguageMenuItemClick = (name: string, value: string) => {
      updateUser({ key: 'language', value })
      setMenuType(USER_MENU_INITIAL)
    }

    const handleAccountMenuItemClick = (name: string, value: string) => {
      updateUser({ key: 'primaryBusinessId', value }, false, true)
      setMenuType(USER_MENU_INITIAL)
    }

    if (isUserMenuOpen == null || isUserMenuOpen === false) {
      return null
    }

    return (
      <div
        ref={ref}
        onClick={onContainerClick}
        className={classNames(styles.container, shared.smallShadow, shared.col)}
        style={containerStyle}
      >
        <div className={classNames(styles.header)}>
          <p className={classNames(styles.profileInitials)}>{user?.userInitials}</p>

          <span className={classNames(styles.profileName)}>{user?.name}</span>

          <RoundButton
            buttonStyle={{
              fontSize: '1rem',
              background: 'transparent',
              boxShadow: 'none',
              padding: '0.5rem',
            }}
            icon={'close'}
            onClick={onCloseButtonClick}
          />
        </div>
        <div className={styles.rowSeparator} />
        {user && (
          <MenuSelector
            menuType={menuType}
            activeLanguage={capitalizeFirstLetter(getEnumKeyByValue(user?.language ?? Language.ENGLISH, Language))}
            activeBusiness={getActiveBusiness(user)}
            userBelongsToSeveralBusinesses={ifUserHasMultipleBusinesses(user?.businesses)}
            onInitialMenuItemClick={handleInitialMenuItemClick}
            onMenuBackClick={handleBackItemClick}
            onLanguageMenuItemClick={handleLanguageMenuItemClick}
            onAccountMenuItemClick={handleAccountMenuItemClick}
          />
        )}
      </div>
    )
  },
)
