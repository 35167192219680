import classNames from 'classnames'
import React from 'react'

import { getIcon, IconName } from '../icons/utils'
import styles from './Button.module.css'

export type RoundButtonProps = React.HTMLProps<HTMLButtonElement> & {
  /**
   * Button icon selector
   */
  icon?: IconName
  /**
   * Button text
   */
  text?: string
  /**
   * Customise button through styles
   */
  buttonStyle?: React.CSSProperties
  /**
   * Button without background
   */
  inverted?: boolean
  /**
   * Direction the icon is facing, default is to the right
   */
  iconDirection?: 'right' | 'left' | 'up' | 'down'
  /**
   * Disable hover shadow effect
   */
  noHoverShadow?: boolean

  accessibilityLabel?: string
}

export const RoundButton = React.forwardRef<HTMLButtonElement, RoundButtonProps>(
  (
    {
      disabled = false,
      icon,
      text,
      buttonStyle,
      iconDirection = 'right',
      noHoverShadow = false,
      type = 'button',
      className,
      accessibilityLabel,
      ...props
    },
    ref,
  ) => {
    // TODO: this exact function is used on couple of places in the code so it should be moved to some Helper class
    const textOrIconSelector = React.useMemo(() => {
      if (icon != null) {
        return getIcon(icon)
      }
      if (text != null) {
        return text
      }
      return null
    }, [icon, text])

    return (
      <button
        type={type as 'button' | 'reset' | 'submit' | undefined}
        ref={ref}
        disabled={disabled}
        className={classNames(
          styles.button,
          styles.button_round,
          disabled && styles.button_round_disabled,
          noHoverShadow && styles.noHoverShadow,
          className,
        )}
        style={buttonStyle}
        {...props}
        aria-label={accessibilityLabel}
      >
        <span className={classNames(iconDirection !== 'right' && styles[`rotate_${iconDirection}`])}>
          {textOrIconSelector}
        </span>
      </button>
    )
  },
)
