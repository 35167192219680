// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Templates_container__eI8V1 {\n    position: relative;\n    height: 100%;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/pages/templates/Templates.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;EACd","sourcesContent":[".container {\n    position: relative;\n    height: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Templates_container__eI8V1"
};
export default ___CSS_LOADER_EXPORT___;
