import classNames from 'classnames'
import React from 'react'

import { getIcon, IconName } from '../icons/utils'
import styles from './Radio.module.css'
import { isFirefox } from '../../../util/browserSpecifics'

type RadioItemProps = {
  name: string
  id: string
  label: string
  iconName: IconName
  isChecked?: string
  onSelect?: (e: React.SyntheticEvent<HTMLInputElement>) => void
}

export const RadioItem: React.FC<RadioItemProps> = ({ name, id, label, iconName, isChecked, onSelect }) => {
  return (
    <label className={classNames(styles.radioItem, styles.wrapper, isChecked === id && styles.wrapperShadow)}>
      <input type='radio' name={name} id={id} onChange={onSelect} checked={isChecked === id} />
      {isFirefox() && <div className={styles.firefoxOutlineWorkaround} />}
      <span className={styles.labelText}>{label}</span>
      <span className={styles.iconContainer}>{getIcon(iconName)}</span>
    </label>
  )
}
