import React, { useMemo } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { MapType } from '../../../../api/gameTypes'
import { RoundButton } from '../../../../common/components/button/RoundButton'
import { Game } from '../../../../types/commonTypes'
import { MapInstanceID } from '../../../../util/map'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { MapboxContainer } from '../MapboxContainer/MapboxContainer'
import styles from './BoardSection.module.css'
import { ThreeDots } from '../../../../common/components/loaders/ThreeDots/ThreeDots'

export type BoardSectionProps = {
  gameData: Game | undefined
  loading: boolean
}

const MIN_SCALE = 0.2

const fabButtonStyle = {
  fontSize: '1.7rem',
  width: '4.25rem',
  height: '4.25rem',
  background: 'var(--white-gradient)',
  color: 'var(--primary-normal)',
  marginTop: '1rem',
}

const MISSING_MAP_URL = 'https://smartfeet-assets-staging.s3.amazonaws.com/assets/default_map_en.jpeg'

export const BoardSection: React.FC<BoardSectionProps> = ({ gameData, loading }) => {
  const currentMapUrl = useMemo<string>(() => {
    return gameData == null || safeIsNullOrEmpty(gameData?.gameBoardSettings?.gameBoards?.[0]?.url)
      ? MISSING_MAP_URL
      : gameData.gameBoardSettings.gameBoards[0].url
  }, [gameData])

  if (loading) {
    return (
      <div>
        <ThreeDots />
      </div>
    )
  }

  return gameData?.gameBoardSettings?.gameBoardType === MapType.LIVE ? (
    <div className={styles.mapboxContainer}>
      <MapboxContainer
        mapInstanceId={MapInstanceID.EDITOR}
        mapOptions={gameData.gameBoardSettings.liveMap}
        boardType={gameData.gameBoardSettings.gameBoardType}
        showMapbox={gameData.gameBoardSettings.gameBoardType === MapType.LIVE}
      />
    </div>
  ) : (
    <div className={styles.container}>
      <TransformWrapper limitToBounds={false} minScale={MIN_SCALE}>
        {({ zoomIn, zoomOut, ...rest }) => (
          <React.Fragment>
            <div className={styles.zoomButtons}>
              <RoundButton icon='close' buttonStyle={{ ...fabButtonStyle, rotate: '45deg' }} onClick={() => zoomIn()} />
              <RoundButton icon='minus' buttonStyle={fabButtonStyle} onClick={() => zoomOut()} />
            </div>
            <TransformComponent wrapperStyle={{ width: '100%' }}>
              <img src={currentMapUrl} alt='test' />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  )
}

export default BoardSection
