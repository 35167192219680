import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'

import { Button } from '../../common/components/button/Button'
import { RoundButton } from '../../common/components/button/RoundButton'
import { InputField } from '../../common/components/input/InputField'
import { useUser } from '../../contexts/userContext'
import { routes } from '../../routes'
import { LicenseModal } from '../LicenseModal/LicenseModal'
import { UserMenuContainer } from '../UserMenu/UserMenu'
import styles from './Topbar.module.css'
import { getUserActiveDays } from '../WelcomeBlock/helpers'
import { TRIAL_PERIOD_LENGTH } from '../WelcomeBlock/constants'
import { BILLING_DEFAULT_PRICE } from '../../common/constants'
import { Currencies } from '../../types/commonTypes'

export const Topbar: React.FC = () => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = React.useState('')
  const [isUserMenuVisible, setIsUserMenuVisible] = React.useState(false)
  const [showLicenseModal, setShowLicenseModal] = React.useState(false)
  const [trialDaysLeft, setTrialDaysLeft] = useState(TRIAL_PERIOD_LENGTH)
  const [showErrorMessage, setShowErrorMessage] = React.useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { user } = useUser()
  const location = useLocation()
  const [_, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (user && user.createdAt) {
      const activeDays = Math.floor(getUserActiveDays(user?.createdAt))
      setTrialDaysLeft(Math.max(TRIAL_PERIOD_LENGTH - activeDays, 0))
    }
  }, [user])

  const onChangeSearchValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
    if (e.currentTarget.value.length === 0) {
      setShowErrorMessage(false)
    }
    if (showErrorMessage && e.currentTarget.value.length > 2) {
      setShowErrorMessage(false)
    }
  }

  const applySearch = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (searchValue.trim().length > 2) {
      if (location.pathname === routes.search) {
        setSearchParams((prev) => {
          prev.set('search', searchValue.trim())
          return prev
        })
      } else {
        navigate({
          pathname: routes.search,
          search: `?${createSearchParams({ search: searchValue.trim() })}`,
        })
      }
      setSearchValue('')
    } else if (searchValue.length > 0 && searchValue.length < 3) {
      setShowErrorMessage(true)
    }
  }

  useOnClickOutside(menuRef, () => {
    setIsUserMenuVisible(false)
  })

  const handleClosingMenu = () => {
    setIsUserMenuVisible(false)
  }

  const handleClickTrialButton = () => setShowLicenseModal(true)

  const handleClickClose = () => setShowLicenseModal(false)

  return (
    <div className={styles.searchbarContainer}>
      {showLicenseModal && (
        <LicenseModal
          trialDaysLeft={trialDaysLeft}
          startingPricePerMonth={BILLING_DEFAULT_PRICE}
          currency={Currencies.Euro}
          onClickClose={handleClickClose}
        />
      )}
      <>
        <form className={styles.relativeParent} onSubmit={applySearch} onBlur={() => setShowErrorMessage(false)}>
          <InputField
            placeholder={t('top_bar.search', 'Search')}
            onChange={onChangeSearchValue}
            value={searchValue}
            leftElement={{
              iconName: 'search',
              variant: 'icon',
            }}
            rightElement={
              searchValue.length === 0
                ? undefined
                : {
                    iconName: 'arrowRight',
                    variant: 'button',
                    type: 'submit',
                  }
            }
            inputContainerClassName={styles.searchInput}
            className={showErrorMessage ? styles.overrideDefaultOutline : undefined}
          />
          {showErrorMessage && (
            <p className={styles.warningBadge}>
              {t('top_bar.search_input.error_message', 'Please write at least 3 characters')}
            </p>
          )}
        </form>
        {user?.isTrial && (
          <span className={styles.trialButton}>
            <Button
              styleVariant='primary'
              sizeVariant='medium'
              style={{ whiteSpace: 'nowrap' }}
              label={
                user == null
                  ? t('loading', 'Loading...')
                  : t('top_bar.license_modal.trial_button.trial_days', {
                      count: trialDaysLeft,
                      defaultValue: '%{count} days left in trial',
                    })
              }
              onClick={handleClickTrialButton}
            />
          </span>
        )}
        <div
          onClick={() => {
            setIsUserMenuVisible(true)
          }}
          className={styles.userMenuDropdown}
        >
          <>
            <RoundButton
              buttonStyle={{
                width: 40,
                height: 40,
                fontSize: 18,
                color: 'white',
                background: 'var(--gradient-primary-normal)',
              }}
              text={user?.userInitials ?? ''}
            />
            <UserMenuContainer ref={menuRef} isUserMenuOpen={isUserMenuVisible} onMenuClose={handleClosingMenu} />
            <RoundButton
              buttonStyle={{
                fontSize: 18,
                background: 'transparent',
                boxShadow: 'none',
              }}
              icon={'arrowDown'}
              accessibilityLabel={
                isUserMenuVisible
                  ? t('accessibility.user_menu_button.close', 'Close user menu')
                  : t('accessibility.user_menu_button.open', 'Open user menu')
              }
            />
          </>
        </div>
      </>
    </div>
  )
}
