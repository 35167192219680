// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskSidebar_sidebarContainer__gFA8w {\n  --task-sidebar-width: 22.5rem;\n  --task-sidebar-padding: 2rem;\n\n  box-sizing: border-box;\n  width: 25%;\n  min-width: var(--task-sidebar-width);\n  max-width: var(--task-sidebar-width);\n  height: 80%;\n  padding: var(--task-sidebar-padding);\n  margin: var(--task-sidebar-padding);\n  position: fixed;\n  background-color: white;\n  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);\n\n  overflow-y: auto;\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  z-index: 1;\n  border-radius: 8px;\n}\n\n.TaskSidebar_gameEditorBtnWrapper__gOwhp {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GameEditor/components/Tasks/TaskSidebar/TaskSidebar.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4BAA4B;;EAE5B,sBAAsB;EACtB,UAAU;EACV,oCAAoC;EACpC,oCAAoC;EACpC,WAAW;EACX,oCAAoC;EACpC,mCAAmC;EACnC,eAAe;EACf,uBAAuB;EACvB,2CAA2C;;EAE3C,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".sidebarContainer {\n  --task-sidebar-width: 22.5rem;\n  --task-sidebar-padding: 2rem;\n\n  box-sizing: border-box;\n  width: 25%;\n  min-width: var(--task-sidebar-width);\n  max-width: var(--task-sidebar-width);\n  height: 80%;\n  padding: var(--task-sidebar-padding);\n  margin: var(--task-sidebar-padding);\n  position: fixed;\n  background-color: white;\n  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);\n\n  overflow-y: auto;\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  z-index: 1;\n  border-radius: 8px;\n}\n\n.gameEditorBtnWrapper {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarContainer": "TaskSidebar_sidebarContainer__gFA8w",
	"gameEditorBtnWrapper": "TaskSidebar_gameEditorBtnWrapper__gOwhp"
};
export default ___CSS_LOADER_EXPORT___;
