import React, { useCallback, useState } from 'react'

import { Form } from 'react-final-form'
import { Button } from '../../../../common/components/button/Button'
import { RoundButton } from '../../../../common/components/button/RoundButton'
import { SettingsIcon } from '../../../../common/components/icons'
import { useConfirmation } from '../../../../contexts/ConfirmationContext'
import { useOnEscPress } from '../../../../hooks/useOnEscPress'
import { useTrapFocus } from '../../../../hooks/useTrapFocus'
import { Game } from '../../../../types/commonTypes'
import { MapInstanceID, useMapApi } from '../../../../util/map'
import { extendGameDataWithLiveMap } from '../../helpers'
import { DEFAULT_GAME_BOARD_SETTINGS, GameBoardSettings, GameForm } from '../../types'
import styles from './GameSettingsModal.module.css'
import { Accordion } from './components/Accordion'
import { General } from './components/General'
import { Notifications } from './components/Notifications'
import { Advanced } from './components/Advanced'

const FORM_ID = 'game-settings-modal-form'

type GameSettingsProps = {
  loading: boolean
  gameData?: Game
  onClose: () => void
  onSubmit: (data: GameForm) => void
  onSwitchToQuickStartWizard: () => void
}

export const GameSettingsModal: React.FC<GameSettingsProps> = ({
  loading,
  gameData,
  onClose,
  onSubmit,
  onSwitchToQuickStartWizard,
}) => {
  const { requestConfirmation } = useConfirmation()
  const { map } = useMapApi(MapInstanceID.MODAL)
  const focusTrapRef = useTrapFocus<HTMLDivElement>()

  const [initialValues] = useState<Partial<GameForm>>(() => {
    return {
      name: gameData?.name,
      description: gameData?.description,
      gameBoardSettings: gameData?.gameBoardSettings,
      ages: gameData?.ages ?? [],
      keywords: gameData?.keywords ?? [],
      language: gameData?.language,
      topics: gameData?.topics ?? [],
      answers: gameData?.notificationSettings?.answers,
      messages: gameData?.notificationSettings?.messages,
      instantEnabled: gameData?.notificationSettings?.instantEnabled,
      summaryEnabled: gameData?.notificationSettings?.summaryEnabled,
      emails: gameData?.notificationSettings?.emails,
      allowBranching: gameData?.allowBranching,
      chatEnabled: gameData?.chatEnabled,
      gpsEnabled: gameData?.gpsEnabled,
      happyOrNot: gameData?.happyOrNot,
      hideScoreboard: gameData?.hideScoreboard,
      levelsEnabled: gameData?.levelsEnabled,
      noPointsGame: gameData?.noPointsGame,
      playerContinuesSentAnsEnabled: gameData?.playerContinuesSentAnsEnabled,
      explorationMode: gameData?.explorationMode,
    }
  })

  const [internalGameBoardSettings, setInternalGameBoardSettings] = useState<GameBoardSettings>(
    initialValues.gameBoardSettings || DEFAULT_GAME_BOARD_SETTINGS,
  )

  const updateGameBoardSettings = useCallback((settings: GameBoardSettings) => {
    setInternalGameBoardSettings(settings)
  }, [])

  const onCloseInternal = useCallback(() => {
    requestConfirmation({
      title: 'Unsaved changes',
      text: 'Are you sure you want to exit? Any unsaved changes will be lost.',
    }).then((response) => {
      if (response) {
        onClose()
      }
    })
  }, [requestConfirmation, onClose])

  useOnEscPress(() => {
    onCloseInternal()
  })

  const handleSubmit = useCallback(
    (values: GameForm) => {
      const submitValues = extendGameDataWithLiveMap({ ...values, gameBoardSettings: internalGameBoardSettings }, map)
      onSubmit(submitValues)
    },
    [onSubmit, map, internalGameBoardSettings],
  )

  return (
    <>
      <div className={styles.backdrop} />
      <div ref={focusTrapRef} className={styles.container}>
        <div className={styles.header}>
          <span>
            <SettingsIcon color={'var(--primary-normal)'} />
            <h4>Game settings</h4>
          </span>
          <RoundButton
            buttonStyle={{ background: 'transparent', boxShadow: 'unset', fontSize: '1rem' }}
            icon={'close'}
            onClick={onCloseInternal}
          />
        </div>
        <div className={styles.content}>
          <Form<GameForm> onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} id={FORM_ID}>
                <div className={styles.descriptionContainer}>
                  <h4>Define your game information</h4>
                  <p>
                    In this screen you can define your game properties - make sure to give your game a name and a game
                    board.
                    {gameData == null && (
                      <>
                        If you have issues try our{' '}
                        <button type='button' className={styles.linkButton} onClick={onSwitchToQuickStartWizard}>
                          Quick Setup Wizard
                        </button>
                      </>
                    )}
                    .
                  </p>
                </div>
                <Accordion title={'General'} isOpen>
                  <General
                    initialGameBoardSettings={initialValues.gameBoardSettings || DEFAULT_GAME_BOARD_SETTINGS}
                    onChangeGameBoardSettings={updateGameBoardSettings}
                  />
                </Accordion>
                <Accordion title={'Notifications'}>
                  <Notifications />
                </Accordion>
                <Accordion title={'Advanced'}>
                  <Advanced mapType={gameData?.gameBoardSettings.gameBoardType} />
                </Accordion>
              </form>
            )}
          </Form>
        </div>
        <div className={styles.footer}>
          <Button
            styleVariant='tertiary'
            sizeVariant='small'
            label='Cancel'
            buttonStyles={{ minWidth: 'unset' }}
            onClick={onCloseInternal}
          />
          <Button
            form={FORM_ID}
            styleVariant='secondary'
            sizeVariant='small'
            label={loading ? 'loading...' : 'Save'}
            disabled={loading}
            buttonStyles={{ minWidth: 'unset' }}
            type='submit'
          />
        </div>
      </div>
    </>
  )
}
