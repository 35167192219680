import { Maybe, DateTime } from '../types/commonTypes'

export interface GetGameParams
  extends Readonly<{
    gameId: number
    authorization?: string | null
    isLibrary?: boolean
  }> {}

export interface GetOwnGamesParams
  extends Readonly<{
    authorization?: string | null
    userId?: number
    perPage?: number
    page?: number
    orderBy?: string //Should be like: updated_at+asc or name+desc etc
  }> {}

export interface GetLibraryGamesParams
  extends Readonly<{
    authorization?: string | null
    language: string
    perPage?: number
    page?: number
    corporate?: boolean
  }> {}

export interface GetCombinedSearchParams
  extends Readonly<{
    authorization?: string | null
    search: string //targets game's name | creator name | keywords. Is required and should 3 chars or more to perform search
    language?: string
    subject?: string
    age?: string
    updatedAt?: string //01-31-2023 i.e. mm-dd-yyyy
    approvedBySeppo?: boolean
    source?: LibrarySource
    perPage?: number
    page?: number
    orderBy?: string //Should be like: updated_at+asc or name+desc etc
  }> {}

export interface CopyOwnGameParams
  extends Readonly<{
    authorization?: string | null
    gameId: number
  }> {}

export interface DeleteOwnGameParams
  extends Readonly<{
    authorization?: string | null
    gameId: number
    force?: boolean
  }> {}

export interface CloneLibraryGameParams
  extends Readonly<{
    authorization?: string | null
    gameId: number
  }> {}

export type GetGameResponse = StaticGame | LiveGame | PanoramaGame

export interface GetGamesResponse {
  active_acount: number
  count: number
  my_games: GetGameResponse[]
}

export interface GetLibraryGamesResponse {
  count: number
  games: GetGameResponse[]
}

export interface GetCombinedSearchResponse {
  own_count: number
  community_count: number
  org_count: number
  all_games: GetGameResponse[]
  template_count: number
}

export interface BaseGameSubset {
  name: string
  description: string
  rules?: string
  language?: string
  ages?: string[]
  subjects?: string[]
  keywords?: string // comma separator
  map_type: string
  longitude?: number
  latitude?: number
  zoom?: number
  maps?: AdditionalMap[]
  notification_settings?: NotificationSettings
  no_chat?: boolean
  no_gps?: boolean
  exploration_mode?: boolean
  levels_enabled?: boolean
  branch_type?: 'TREE' | null
  player_continues_sent_ans_enabled?: boolean
  no_points_game?: boolean
  hide_scoreboard?: boolean
  enable_badges?: boolean
  happy_or_not?: boolean
}

export interface CreateGameParams {
  authorization?: string | null
  game: BaseGameSubset
}

export enum LibraryLanguage {
  'fi' = 'Suomi',
  'en' = 'English',
  'sv' = 'Svenska',

  'nl' = 'Nederlands',
  'pt' = 'Portuguese',
  'sp' = 'Spanish',
  'de' = 'Deutsch',
  'nb' = 'Norsk',
}

export enum LibraryCountry {
  'FI' = 'Finland',
  'SE' = 'Sweden',
  'NL' = 'Netherlands',
  'US' = 'USA',
  'CH' = 'Switzerland',
  'AE' = 'UAE',
  'QA' = 'Qatar',
  'KW' = 'Kuwait',
  'HK' = 'Hong Kong',
  'BR' = 'Brazil',
  'GB' = 'UK',
  'NO' = 'Norway',
  'DK' = 'Denmark',
  'DE' = 'Germany',
  'PL' = 'Poland',
  'LT' = 'Lithuania',
  'LV' = 'Latvia',
  'EE' = 'Estonia',
  'SA' = 'Saudi-Arabia',
  'IN' = 'India',
  'HKE' = 'Hong Kong Edcity',
  'HU' = 'Hungary',
  'CO' = 'Colombia',
  'MX' = 'Mexico',
  'PE' = 'Peru',
  'CL' = 'Chile',
  'EC' = 'Ecuador',
  'PA' = 'Panama',
  'AR' = 'Argentina',
}

export enum LibrarySource {
  OWN = 'OWN',
  ORG = 'ORG',
  COMMUNITY = 'COMMUNITY',
  TEMPLATE = 'TEMPLATE',
}

interface BaseGame
  extends Readonly<{
    additional_maps: AdditionalMap[]
    advanced_mode: boolean
    age: string[]
    badges: Badge[]
    custom_chat: boolean
    description: string
    description_html: string
    downloaded: Maybe<number>
    exercise_count: number
    exercises: Exercise[]
    first_time: boolean
    good_trough: Maybe<DateTime>
    hide_scoreboard: boolean
    id: number
    keywords: string
    language: string
    lat: number
    levels_criteria: Maybe<string> // Needs to be JSON.parse()'d, @see LevelCriteria
    levels_enabled: boolean
    lng: number
    map_structure: Maybe<string>
    map_style: string
    map_url: string
    map: string
    max_points: number
    name: string
    no_chat: boolean
    no_gps: boolean
    no_points_game: boolean
    offline_enabled: boolean
    open: boolean
    ordering_enabled: boolean
    player_continues_sent_ans_enabled: boolean
    player_email_required: Maybe<boolean>
    player_info: Maybe<number>
    rating_details: Maybe<GameRating[]>
    realtime_host: string
    rules: string
    rules_html: string
    short_description: Maybe<string>
    shoutbox_open: Maybe<boolean>
    show_story_rules: Maybe<boolean>
    source: Maybe<LibrarySource>
    subject: string[]
    use_avatar: boolean
    user_name: string
    uses_indoor_pos: Maybe<boolean>
    zoom: number
    logo_image_url: Maybe<string>
    primary_color: Maybe<string>
    secondary_color: Maybe<string>
    hide_app_animations: Maybe<boolean>
    hide_exercise_animations: Maybe<boolean>
    app_picture: Maybe<string>

    // Undocumented
    allow_poll: boolean
    allow_re_use: Maybe<boolean>
    answers_count: number
    approved_by_seppo: Maybe<boolean>
    bearing: number
    branch_type: 'TREE' | null
    business_id: Maybe<string>
    conlib_game_already_rated: Maybe<boolean>
    conlib_game_id: Maybe<number>
    corporate: Maybe<boolean>
    cover_picture_content_type: Maybe<string>
    cover_picture_file_name: Maybe<string>
    cover_picture_file_size: Maybe<number>
    cover_picture_updated_at: Maybe<string>
    cover_picture_url: string
    created_at: DateTime
    creator: string
    dikaios_id: Maybe<number>
    dikaios_user: boolean
    edit_restricted: Maybe<boolean>
    enforce_game_licence: Maybe<boolean>
    exported: Maybe<boolean>
    game_owners_count: Maybe<number>
    game_recipe_count: number
    happy_or_not: boolean
    imported: Maybe<boolean>
    instructions_for_teacher: string
    is_archived: boolean
    is_freemium: boolean
    is_recipe_sample: Maybe<boolean>
    is_sample: Maybe<boolean>
    is_trial: boolean
    isbn: string
    late_name_prompt: Maybe<boolean>
    licence_count: Maybe<number>
    map_content_type: string
    map_file_name: string
    map_file_size: number
    map_processing: unknown
    map_updated_at: Maybe<DateTime>
    master_owner_id: number
    messages: string
    notification_settings: NotificationSettings
    number_of_times_game_opened: number
    overlay_data: unknown
    owners_list: number[]
    owner_names: string[]
    p2p_chat: boolean
    passcode: string
    pin_code_player: string
    pin_code_player_enabled: boolean
    realtime_conf: number
    recipe: unknown
    root_id: Maybe<number>
    share_secret: unknown
    sub_game_names: string
    sponsored: Maybe<boolean>
    team_member_names_required: Maybe<boolean>
    theme_id: Maybe<number>
    thumbnail: Maybe<string>
    thumbnail_url: Maybe<string>
    updated_at: Maybe<DateTime>
    user_id: number
    user_is_anonymous: boolean
    uses_new_map_api: Maybe<boolean>
    video_id: unknown
    user_start_task: number
  }> {}

interface NotificationSettings
  extends Readonly<{
    messages: number
    answers: number
    instant_enabled: boolean
    summary_enabled: boolean
    emails: string
  }> {}

interface StaticGame
  extends BaseGame,
    Readonly<{
      map_type: MapType.STATIC
      map_dimensions: MapDimensions
    }> {}

interface LiveGame
  extends BaseGame,
    Readonly<{
      map_type: MapType.LIVE
      no_gps: boolean
    }> {}

interface PanoramaGame
  extends BaseGame,
    Readonly<{
      map_type: MapType.PANORAMA
    }> {}

interface AdditionalMap
  extends Readonly<{
    map_id: number
    map_url: string
    name?: string
    map_dimensions: MapDimensions
    location: string
  }> {}

interface GameRatingDetail
  extends Readonly<{
    comment: string
    time: string
    user: string
    user_id: number
    value: number
  }> {}

interface GameRating
  extends Readonly<{
    av_rating: number
    details: GameRatingDetail[]
  }> {}

interface Badge
  extends Readonly<{
    id: number
    image_url: string
    name: string
    url: Maybe<string>
  }> {}

export interface LevelCriteria
  extends Readonly<{
    points: number | string
    completed_exercises?: number
    name: string
    defaultMap: Maybe<string>
  }> {}

interface MapDimensions
  extends Readonly<{
    x: number
    y: number
  }> {}

export interface MapStructure
  extends Readonly<{
    /* eslint-disable camelcase */
    [key: string]: {
      doors: Array<{
        x: number
        y: number
        id: number
        leadsToMapId: number | string
      }>
      world_pos: {
        x: string
        y: string
      }
    }
    /* eslint-enable camelcase */
  }> {}

export enum MapType {
  STATIC = 'STATIC',
  LIVE = 'LIVE',
  PANORAMA = 'PANORAMA',
}

export type Exercise =
  | CreativeExercise
  | MultichoiceExercise
  | PollExercise
  | MissingWordExercise
  | CombineExercise
  | CollaborationExercise

interface BaseExercise
  extends Readonly<{
    /* eslint-disable camelcase */
    allows_audio: Maybe<boolean>
    allows_image: Maybe<boolean>
    allows_text: Maybe<boolean>
    allows_video: Maybe<boolean>
    answers: Answer[]
    badge_auto_enabled: boolean
    badge_id: Maybe<number>
    badge_points_req: Maybe<number>
    children: Exercise[]
    code_hint: string
    created_at: DateTime
    has_default_feedback: Maybe<boolean>
    default_feedback: Maybe<string>
    auto_score_percentage: Maybe<number>
    description: string
    description_html: string
    edit_locked: Maybe<boolean>
    game_id: number
    has_lock_code: boolean
    has_proximity: boolean
    icon_type: IconType
    id: number
    is_being_answered: boolean
    is_branch_end: boolean
    is_flash: boolean
    is_hidden: Maybe<boolean>
    level: Maybe<number>
    lock_code: string
    map_id: Maybe<number>
    mascot_enabled: boolean
    mascot_entry_message: string
    mascot_exit_message: string
    name: string
    open: boolean
    order_number: Maybe<number>
    parent_id: Maybe<number>
    pathname: string
    points: number
    positionchoice: string
    has_reference_answer: Maybe<boolean>
    reference_answer: Maybe<string>
    required_proximity: number
    requires_postcard: boolean
    responses_left: number
    s3_upload: Maybe<S3Upload>
    show_some_buttons: boolean
    stand_alone: boolean
    state: ExerciseState
    theme: string
    time_pressure: string
    updated_at: Maybe<DateTime>
    x: number
    y: number
    show_player_names: boolean
    require_answer_before_comments: boolean
    allow_comments: boolean
    grading_mode: string

    // Undocumented
    allows_avatar: Maybe<boolean>
    child_order: number
    flash_sort_order: Maybe<number>
    is_individual: boolean
    is_indoor: boolean
    is_outdoor: boolean
    is_team: boolean
    next_ex: Maybe<number>[]
    prev_ex: Maybe<number>[]
    x2: Maybe<number>
    y2: Maybe<number>
    z: Maybe<number>
    z2: Maybe<number>
    /* eslint-enable camelcase */
  }> {}

export interface CreativeExercise
  extends BaseExercise,
    Readonly<{
      type: ExerciseType.CreativeExercise
      data: null
    }> {}

export interface MultichoiceExercise
  extends BaseExercise,
    Readonly<{
      type: ExerciseType.MultichoiceExercise
      data: MultichoiceExerciseData
    }> {}

export interface PollExercise
  extends BaseExercise,
    Readonly<{
      type: ExerciseType.PollExercise
      data: PollExerciseData
    }> {}

export interface MissingWordExercise
  extends BaseExercise,
    Readonly<{
      type: ExerciseType.MissingWordExercise
      data: MissingWordExerciseData
    }> {}

export interface CombineExercise
  extends BaseExercise,
    Readonly<{
      type: ExerciseType.CombineExercise
      data: CombineExerciseData
    }> {}

export interface CollaborationExercise
  extends BaseExercise,
    Readonly<{
      type: ExerciseType.CollaborationExercise
      data: null
    }> {}

interface MultichoiceExerciseData
  extends Readonly<{
    /* eslint-disable camelcase */
    choices: string[]
    correct: Maybe<string>
    correct_index: Maybe<number>
    feedbacks: Array<Maybe<string>>
    next_ex_ids: Array<Maybe<string>>
    picture_urls: string[]
    points: number[]
    /* eslint-enable camelcase */
  }> {}

interface PollExerciseData
  extends Readonly<{
    /* eslint-disable camelcase */
    choices: string[]
    correct: Maybe<string>
    correct_index: Maybe<number>
    feedbacks: Array<Maybe<string>>
    next_ex_ids: Array<Maybe<number>>
    picture_urls: string[]
    points: number[]
    /* eslint-enable camelcase */
  }> {}

interface MissingWordExerciseData
  extends Readonly<{
    /* eslint-disable camelcase */
    choices: string[]
    correct: Maybe<string>
    correct_index: null
    feedbacks: null[]
    next_ex_ids: null[]
    points: number[]
    /* eslint-enable camelcase */
  }> {}

interface CombineExerciseData
  extends Readonly<{
    /* eslint-disable camelcase */
    choices: Array<{
      leftImage: string // Can be an empty string
      leftText: string // Can be an empty string
      rightImage: string // Can be an empty string
      rightText: string // Can be an empty string
    }>
    correct: Maybe<string>
    correct_index: Maybe<number>
    feedbacks: Array<Maybe<string>>
    next_ex_ids: Array<Maybe<number>>
    picture_urls: string[]
    points: number[]
    /* eslint-enable camelcase */
  }> {}

interface Postcard
  extends Readonly<{
    /* eslint-disable camelcase */
    user_name: string
    greetings: string
    image_url: string
    id: number
    /* eslint-enable camelcase */
  }> {}

export interface Answer
  extends Readonly<{
    /* eslint-disable camelcase */
    answer: string
    answer_index: Maybe<number>
    answer_indexes: string
    badge: unknown
    children: Answer[]
    comment: Maybe<string>
    completed_at: Maybe<DateTime>
    exercise_id: number
    extra_time_pressure: Maybe<string>
    feedback: string
    final_answer: unknown
    game_id: number
    hint_listened: unknown
    id: number
    opened_timestamp: Maybe<string>
    pathname: string
    points: number
    postcard: Maybe<Postcard>
    previous_answers: Answer[]
    requires_postcard: boolean
    skip_in_ui: boolean
    state: string
    state_label: string
    updated_at: DateTime
    teacher_graded: Maybe<boolean>
    answer_type: Maybe<string>
    comments: Maybe<Comment[]>
    likes_count: number
    likers: string
    is_hidden: Maybe<boolean>
    user: {
      allowed_isbns: Maybe<boolean>
      avatar_layers: unknown
      avatar_url: Maybe<string>
      business_group_id: Maybe<number>
      contact_name: Maybe<string>
      created_at: DateTime
      created_games_count: number
      dashboard_message: Maybe<string>
      dikaios_role: unknown
      email: Maybe<string>
      extra_info: Maybe<string>
      force_old_dashboard: Maybe<boolean>
      game_packages: unknown
      has_used_freemium_trial: Maybe<boolean>
      id: number
      is_business_group_master: boolean
      language: string
      lms_user_id: Maybe<number>
      manages_countries: unknown
      manages_themes: unknown
      name: string
      primary_business_id: unknown
      real_name: string
      results_url: Maybe<string>
      seen_messages: unknown
      session_id: string
      show_team_limit_changed_message: Maybe<boolean>
      start_task: unknown
      super_user: unknown
      team_member_names: unknown
      terms_accepted: Maybe<boolean>
      unarchive_rights: Maybe<boolean>
    }
    /* eslint-enable camelcase */
  }> {}

export enum IconType {
  seppo = 'seppo',
  crown = 'crown',
  star = 'star',
  diamond = 'diamond',
  circle = 'circle',
  question = 'question',
  flag = 'flag',
  skull = 'skull',
  'two_stars' = 'two_stars',
  'three_stars' = 'three_stars',
}

interface S3Upload
  extends Readonly<{
    data: S3UploadData
    url: string
  }> {}

interface S3UploadData
  extends Readonly<{
    /* eslint-disable camelcase */
    acl: string
    key: string
    policy: string
    success_action_status: string
    'x-amz-algorithm': string
    'x-amz-credential': string
    'x-amz-date': string
    'x-amz-signature': string
    /* eslint-enable camelcase */
  }> {}

export enum ExerciseState {
  'open' = 'open',
  'in-progress' = 'in-progress',
  'grade' = 'grade',
  'graded' = 'graded',
  'revision' = 'revision',
  'grade-closed' = 'grade-closed',
  'time-finished' = 'time-finished',
  'closed' = 'closed',
}

export enum ExerciseType {
  CreativeExercise = 'CreativeExercise',
  MultichoiceExercise = 'MultichoiceExercise',
  PollExercise = 'PollExercise',
  MissingWordExercise = 'MissingWordExercise',
  CombineExercise = 'CombineExercise',
  CollaborationExercise = 'CollaborationExercise',
}

export interface GetTaskParams
  extends Readonly<{
    authorization?: string | null
    gameId: number
    taskId: number
  }> {}

export interface GetTaskResponse
  extends Readonly<{
    data: Exercise
  }> {}
