import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChatUndreadGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <path fill='currentColor' d='M22 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z' />
    <path fill='url(#chat_undread_gradient_icon_svg__a)' fillOpacity={0.25} d='M22 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z' />
    <path
      fill='currentColor'
      d='M22 12c.828 0 1.616-.168 2.333-.47H27c1.473 0 2.667 1.164 2.667 2.637v6.993A2.667 2.667 0 0 1 27 23.827h-1.333v2.837a.667.667 0 0 1-1.055.543l-4.732-3.38h-3.547a2.667 2.667 0 0 1-2.666-2.667V20h1.6v.893c0 .737.597 1.334 1.333 1.334h3.808l3.659 2.613v-2.613h2.666c.737 0 1.334-.597 1.334-1.334v-6.46c0-.736-.597-1.333-1.334-1.333h-2.4V16a2.667 2.667 0 0 1-2.666 2.667H13.72l-5.666 4.047A.667.667 0 0 1 7 22.17v-3.504H5.667A2.667 2.667 0 0 1 3 16V6.667A2.667 2.667 0 0 1 5.667 4H16.34A6 6 0 0 0 22 12Z'
    />
    <path
      fill='url(#chat_undread_gradient_icon_svg__b)'
      fillOpacity={0.25}
      d='M22 12c.828 0 1.616-.168 2.333-.47H27c1.473 0 2.667 1.164 2.667 2.637v6.993A2.667 2.667 0 0 1 27 23.827h-1.333v2.837a.667.667 0 0 1-1.055.543l-4.732-3.38h-3.547a2.667 2.667 0 0 1-2.666-2.667V20h1.6v.893c0 .737.597 1.334 1.333 1.334h3.808l3.659 2.613v-2.613h2.666c.737 0 1.334-.597 1.334-1.334v-6.46c0-.736-.597-1.333-1.334-1.333h-2.4V16a2.667 2.667 0 0 1-2.666 2.667H13.72l-5.666 4.047A.667.667 0 0 1 7 22.17v-3.504H5.667A2.667 2.667 0 0 1 3 16V6.667A2.667 2.667 0 0 1 5.667 4H16.34A6 6 0 0 0 22 12Z'
    />
    <defs>
      <linearGradient
        id='chat_undread_gradient_icon_svg__a'
        x1={16.333}
        x2={16.333}
        y1={2}
        y2={27.332}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='chat_undread_gradient_icon_svg__b'
        x1={16.333}
        x2={16.333}
        y1={2}
        y2={27.332}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgChatUndreadGradientIcon
