import { PropsWithChildren } from 'react'
import { useField } from 'react-final-form'
import { FormFieldError } from '../../../../../../../common/components/Form/FormFieldError/FormFieldError'
import { ToggleSwitch } from '../../../../../../../common/components/ToggleSwitch/ToggleSwitch'
import styles from './ExpandableToggle.module.css'

type ExpandableToggleProps = {
  title: string
  description?: string | JSX.Element
  id: string
  name: string
  reserveErrorSpace?: boolean
}

export const ExpandableToggle: React.FC<PropsWithChildren<ExpandableToggleProps>> = ({
  title,
  description,
  children,
  id,
  name,
  reserveErrorSpace,
}) => {
  const { input, meta } = useField(name, { type: 'checkbox' })

  return (
    <>
      <div className={styles.container}>
        <ToggleSwitch
          {...input}
          id={id ?? name}
        />
        <label htmlFor={id} className={styles.titleAndDescription}>
          <span className={input.checked ? styles.checkedTitle : styles.uncheckedTitle}>{title}</span>
          <span className={styles.description}>{description}</span>
        </label>
      </div>
      { input.checked && children }
      <FormFieldError meta={meta} reserveErrorSpace={reserveErrorSpace} />
    </>
  )
}
