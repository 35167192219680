import classNames from 'classnames'
import React from 'react'

import shared from '../../styles/shared.module.css'
import styles from './Card.module.css'

import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside'
import { LibrarySource } from '../../../api/gameTypes'
import { ContextMenu } from '../../../composites/ContextMenu/ContextMenu'
import { getFocusableGameItemId } from '../../../composites/GamesOverview/helpers'
import { usePositionedMenu } from '../../../hooks/usePositionedMenu'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import { StatusPill } from '../StatusPill/StatusPill'
import { RoundButton } from '../button/RoundButton'
import { GameInfo } from './components/GameInfo'

type CardProps = {
  game: TGameCard
  maxWidth?: string
  minWidth?: string
  archivedAt?: string
  onGameAction: (action: GameAction, game: TGameCard) => void
  libraryMode?: boolean
  disabled?: boolean
}

export const Card: React.FC<CardProps> = ({
  game,
  maxWidth,
  minWidth,
  archivedAt,
  onGameAction,
  libraryMode,
  disabled,
}) => {
  const { t } = useTranslation()

  const { id, modifiedAt, gameName, thumbnail, status, secondaryStatus, exercisesCount, mapsCount } = game

  const [isContextMenuOpen, setIsContextMenuOpen] = React.useState(false)
  const { anchorElementRef, isMenuPositioned, menuCoordinates, menuElementRef } =
    usePositionedMenu<HTMLButtonElement>(isContextMenuOpen)

  const cardId = getFocusableGameItemId(id)

  const internalOnContextMenuButtonClick = React.useCallback((e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setIsContextMenuOpen((prevState) => !prevState)
  }, [])

  const internalOnAddClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onGameAction(GameAction.IMPORT, game)
  }

  const internalOnCardClick = () => {
    onGameAction(GameAction.VIEW, game)
  }

  const onCardKeyDownInternal = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      e.key !== 'Enter' ||
      (e.target as EventTarget & { getAttribute: (attr: string) => string })?.getAttribute?.('id') !== cardId
    ) {
      return
    }
    e.preventDefault()
    onGameAction(GameAction.VIEW, game)
  }

  useOnClickOutside(menuElementRef, () => {
    // NOTE: small timeout to prevent events on underlying card elements while closing the menu
    setTimeout(() => setIsContextMenuOpen(false), 100)
  })

  const handleMenuItemClick = (action: GameAction) => {
    setIsContextMenuOpen(false)
    onGameAction(action, game)
  }

  return (
    <div style={{ position: 'relative' }}>
      {disabled && <div className={styles.cardDisabledOverlay} />}
      <div
        className={classNames(
          styles.container,
          isContextMenuOpen && shared.largeShadow,
          status === 'IN_PROGRESS' && styles.inProgressBorder,
        )}
        style={{
          maxWidth,
          minWidth,
          zIndex: isContextMenuOpen ? 100 : undefined,
          pointerEvents: isContextMenuOpen ? 'none' : 'auto',
          touchAction: isContextMenuOpen ? 'none' : 'auto',
        }}
        onClick={internalOnCardClick}
        role='button'
        tabIndex={0}
        onKeyDown={onCardKeyDownInternal}
        id={cardId}
      >
        {status === 'ARCHIVED' ? <div className={styles.cardArchivedOverlay} /> : null}
        <div className={styles.top} style={{ backgroundImage: `url(${thumbnail})` }}>
          <StatusPill status={status} variant={'card'} secondaryStatus={secondaryStatus} />
          <div>
            {status !== 'ARCHIVED' && game.librarySource === LibrarySource.OWN && (
              <RoundButton icon={'text'} onClick={() => onGameAction(GameAction.EDIT, game)} />
            )}
            {game.librarySource !== LibrarySource.OWN && <RoundButton icon='plus' onClick={internalOnAddClick} />}
            <RoundButton
              disabled={isContextMenuOpen}
              ref={anchorElementRef}
              icon='more'
              onClick={internalOnContextMenuButtonClick}
            />
            <ContextMenu
              ref={menuElementRef}
              anchorX={menuCoordinates?.x}
              anchorY={menuCoordinates?.y}
              isOpen={isContextMenuOpen}
              isPositioned={isMenuPositioned}
              libraryMode={libraryMode || game.librarySource !== LibrarySource.OWN}
              onMenuItemClick={handleMenuItemClick}
            />
          </div>
        </div>
        <div className={styles.bot}>
          <h5 className={styles.gameTitle}>
            {gameName || t('game_info.missing_title_placeholder', 'Game title missing')}
          </h5>
          <GameInfo
            status={status}
            archivedAt={archivedAt}
            modifiedAt={modifiedAt}
            exercisesNumber={exercisesCount}
            mapsNumber={mapsCount}
            playersCount={game.playersCount}
            showModifiedAt={true}
          />
        </div>
      </div>
    </div>
  )
}
