import React from 'react'

import styles from '../ContextMenu.module.css'
import shared from '../../../common/styles/shared.module.css'
import classNames from 'classnames'
import { getIcon, IconName } from '../../../common/components/icons/utils'

type MenuItemProps = {
  icon: IconName
  text: string
  onMenuItemClick: () => void
}

export const MenuItem: React.FC<MenuItemProps> = ({ onMenuItemClick, icon, text }) => {
  const handleButtonClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onMenuItemClick()
  }

  const iconSelector = React.useMemo(() => {
    if (icon == null) {
      return null
    } else {
      return getIcon(icon)
    }
  }, [icon])

  return (
    <div className={classNames(shared.row, styles.menuItem)}>
      <span className={styles.iconContainer}>{iconSelector}</span>
      <button className={classNames(styles.menuButton, 'tiny')} onClick={handleButtonClick}>
        {text}
      </button>
    </div>
  )
}
