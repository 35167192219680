import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getIcon } from '../../../common/components/icons/utils'
import { HelpMenu } from '../../../composites/HelpMenu/HelpMenu'
import { GlobalNotification } from '../../../composites/layout/GlobalNotification'
import { useGameData } from '../../../contexts/OwnGamesContextProvider'
import { useTheme } from '../../../contexts/ThemeContext'
import { getOldUIRoutes, routes } from '../../../routes'
import { UserMenu } from '../components/Header/UserMenu'
import styles from './LegacyEditor.module.css'

export const LegacyGameEditor: React.FC = () => {
  const navigate = useNavigate()
  const { gameId } = useParams()
  const [searchParams] = useSearchParams()
  const { logoUrl } = useTheme()
  const { refreshGames } = useGameData()

  const handleNavigateBack = () => navigate(-1)
  const handleGoToDashboard = () => navigate(routes.homepage)

  useEffect(() => {
    return () => {
      refreshGames()
    }
  }, [refreshGames])

  if (gameId == null) {
    return null
  }

  const url = getOldUIRoutes.gameEditor(gameId, searchParams.get('is_new') === 'true')

  return (
    <div className={styles.mainContainer}>
      <GlobalNotification/>
      <div className={styles.topbar}>
        <div className={styles.topbarSection}>
          <button onClick={handleNavigateBack} className={styles.backButton}>
            {getIcon('backIcon')}
          </button>
          <img onClick={handleGoToDashboard} className={styles.logo} width={90} height={26} src={logoUrl} alt='logo' />
        </div>
        <div className={styles.topbarSection}>
          <UserMenu />
          <HelpMenu isLegacyEditor />
        </div>
      </div>
      <div className={styles.editorContainer}>
        <iframe title='Game editor' src={url} className={styles.editorIframe} />
      </div>
    </div>
  )
}
