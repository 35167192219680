import mapboxgl from 'mapbox-gl'
import { MapType } from '../../api/gameTypes'
import { GameForm } from './types'

export const extendGameDataWithLiveMap = (data: GameForm, map: mapboxgl.Map): GameForm => {
  if (data.gameBoardSettings.gameBoardType === MapType.LIVE) {
    const mapCenter = map.getCenter()
    const zoom = map.getZoom()
    return {
      ...data,
      gameBoardSettings: {
        ...data.gameBoardSettings,
        liveMap: {
          center: [mapCenter.lng, mapCenter.lat],
          zoom,
        },
        gameBoards: [{
          url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${mapCenter.lng},${mapCenter.lat},${zoom},0/600x400?logo=false&access_token=${process.env.REACT_APP_MAPBOX_DEV_ACCESS_TOKEN}`,
          fileName: '',
          name: '',
        }],
      },
    }
  }
  return data
}
