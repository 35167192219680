import React from 'react'

type FocusableElement = HTMLLinkElement | HTMLButtonElement | HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement

const FOCUSABLE_SELECTORS = [
  'a[href]',
  'button:not([disabled])',
  'textarea:not([disabled])',
  'input:not([disabled])',
  'select:not([disabled])',
]

export const useTrapFocus = <T extends HTMLElement>(isActive: boolean = true): React.RefObject<T> => {
  const focusContainerRef = React.useRef<T>(null)

  React.useEffect(() => {
    if (focusContainerRef.current == null) {
      return
    }

    const keyDownHandler = (e: KeyboardEvent) => {
      if (focusContainerRef.current == null || e.key !== 'Tab') {
        return
      }

      const focusableElements = focusContainerRef.current.querySelectorAll(FOCUSABLE_SELECTORS.join(', '))
      const firstElement = focusableElements[0] as FocusableElement
      const lastElement = focusableElements[focusableElements.length - 1] as FocusableElement

      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus()
        e.preventDefault()
      } else if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus()
        e.preventDefault()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [isActive])

  return focusContainerRef
}
