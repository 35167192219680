import React from 'react'
import styles from './Tab.module.css'

type TabProps = {
  title: string
  label: string
  isActive: boolean
  onClickTab: (id: string) => void
}

export const Tab: React.FC<TabProps> = ({ title, label, isActive, onClickTab }) => {
  const onClickInternal = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget
    onClickTab(id)
  }

  return (
    <div className={styles.tab}>
      <label className={styles.label}>
        <input
          name='headerTabs'
          id={title}
          className={styles.originalRadio}
          type='radio'
          checked={isActive}
          onChange={onClickInternal}
        />
        <div className={styles.tabContent}>
          <p className={styles.title}>{title}</p>
          <p className={styles.label}>{label}</p>
        </div>
      </label>
    </div>
  )
}
