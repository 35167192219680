import React from 'react'
import { LibrarySource } from '../../../api/gameTypes'
import { SimpleGameCard } from '../../../common/components/card/SimpleGameCard'
import { GameAction, TGameCard } from '../../../types/commonTypes'
import styles from './GamePreviewModalSidebar.module.css'
import { SidebarActions } from './SidebarActions'
import { SidebarInfoList } from './SidebarInfoList'
import { SidebarInfoTabs } from './SidebarInfoTabs'
import { SidebarShowMore } from './SidebarShowMore'
import { getInfoListForGame, getInfoTabsForGame } from './helpers'
import { useTranslation } from 'react-i18next'

type GamePreviewModalSidebarProps = {
  game: TGameCard
  onGameAction: (action: GameAction) => void
}

export const GamePreviewModalSidebar: React.FC<GamePreviewModalSidebarProps> = ({ game, onGameAction }) => {
  const { t } = useTranslation()
  const infoList = getInfoListForGame(game, t)
  const infoTabs = getInfoTabsForGame(game, t)
  const isLibrary = game.librarySource !== LibrarySource.OWN

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarCardContainer}>
        <SimpleGameCard game={game} />
      </div>
      <SidebarActions isLibrary={isLibrary} onGameAction={onGameAction} />
      <div>
        <SidebarInfoList infoList={infoList} isLibrary={isLibrary} />
        <SidebarShowMore
          subjects={game.subjects}
          description={game.description}
          ages={game.ages}
          language={game.language}
          tags={game.tags}
          isLibrary={isLibrary}
        />
        <div className={styles.sidebarDivider} />
        <SidebarInfoTabs tabs={infoTabs} isLibrary={isLibrary} />
      </div>
    </div>
  )
}
