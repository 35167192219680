import classNames from 'classnames'
import React from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { SelectFormField } from '../../../common/components/Form/SelectFormField/SelectFormField'
import {
  COUNTRY_SELECT_DEFAULT_OPTION,
  COUNTRY_SELECT_OPTIONS,
  LANGUAGE_SELECT_DEFAULT_OPTION,
  LANGUAGE_SELECT_OPTIONS,
} from '../../../common/constants'
import shared from '../../../common/styles/shared.module.css'
import styles from '../OnboardingWizard.module.css'

export const CountryAndLanguageStep: React.FC = () => {
  const { t } = useTranslation()
  const { input } = useField('firstName')

  return (
    <>
      <div className={classNames(shared.flex, shared.col, shared.sAxisC)}>
        <h2>
          {t('onboarding_wizard.language_step.title', {
            firstName: input.value,
            defaultValue: 'Nice to meet you %{firstName}!',
          })}
        </h2>
        <div style={{ textAlign: 'center', padding: '1rem', width: '60%' }}>
          <h5 style={{ color: 'grey' }}>
            {t('onboarding_wizard.language_step.subtitle', 'Help us customise Seppo to suit your preferences')}
          </h5>
        </div>
      </div>
      <div className={classNames(shared.flex, shared.mAxisC, shared.gap_075, styles.step3Options)}>
        <div className={styles.countryInput}>
          <h4 style={{ whiteSpace: 'nowrap' }}>
            {t('onboarding_wizard.language_step.location', 'Where do you live?')}
          </h4>
          <SelectFormField
            autoFocus
            name='country'
            options={COUNTRY_SELECT_OPTIONS}
            defaultValue={COUNTRY_SELECT_DEFAULT_OPTION}
            label={t('onboarding_wizard.language_step.location', 'Where do you live?')}
            srOnlyLabel
          />
        </div>
        <div className={styles.languageInput}>
          <h4 style={{ whiteSpace: 'nowrap' }}>
            {t('onboarding_wizard.language_step.language', 'Which language do you prefer?')}
          </h4>
          <SelectFormField
            name='language'
            options={LANGUAGE_SELECT_OPTIONS}
            defaultValue={LANGUAGE_SELECT_DEFAULT_OPTION}
            label={t('onboarding_wizard.language_step.language', 'Which language do you prefer?')}
            srOnlyLabel
          />
        </div>
      </div>
    </>
  )
}
