// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormFields_inlineField__WQkMF {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.FormFields_multilineInlineField__zCfqC {\n  display: flex;\n  align-items: flex-start;\n  gap: 1rem;\n}\n\n.FormFields_label__L21gS {\n  color: var(--grey-700);\n  display: inline-block;\n  margin-bottom: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/Form/FormFields.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,qBAAqB;AACvB","sourcesContent":[".inlineField {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.multilineInlineField {\n  display: flex;\n  align-items: flex-start;\n  gap: 1rem;\n}\n\n.label {\n  color: var(--grey-700);\n  display: inline-block;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineField": "FormFields_inlineField__WQkMF",
	"multilineInlineField": "FormFields_multilineInlineField__zCfqC",
	"label": "FormFields_label__L21gS"
};
export default ___CSS_LOADER_EXPORT___;
