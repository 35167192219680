import { forwardRef, useCallback, useRef } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { RoundButton } from '../../common/components/button/RoundButton'
import { HELP_MENU_CONTROL_ID, useHelpMenu } from '../../contexts/HelpMenuContextProvider'
import { useUser } from '../../contexts/userContext'
import styles from './HelpMenu.module.css'
import { HelpMenuItem } from './components/HelpMenuItem/HelpMenuItem'
import useOnClickOutside from 'use-onclickoutside'
import { openNinchat } from '../../util/ninchat'

type HelpMenuProps = {
  isLegacyEditor?: boolean
  buttonClassName?: string
}

export const HelpMenu = forwardRef<HTMLDivElement, HelpMenuProps>(({ buttonClassName, isLegacyEditor }, ref) => {
  const { showHelpMenu, toggleDisplayMenu } = useHelpMenu()
  const { user } = useUser()
  const { t } = useTranslation()
  window.current_user = { language: user?.language } //Needed for ninchat to be in window as global

  const helpMenuRef = useRef<HTMLDivElement>(null)
  const helpButtonRef = useRef<HTMLButtonElement>(null)

  useOnClickOutside(helpMenuRef, (e) => {
    if (
      (e.target as EventTarget & { getAttribute: (attr: string) => string })
        ?.getAttribute?.('id')
        ?.includes(HELP_MENU_CONTROL_ID) ||
      helpButtonRef.current?.contains(e.target as any)
    ) {
      return
    } else {
      toggleDisplayMenu()
    }
  })

  // TODO: replace with non-hardcoded value. Read from config/env file?
  const getFeedbackFormUrl = useCallback((): string => {
    switch (user?.language) {
      case 'fi':
        return `${process.env.REACT_APP_FEEDBACK_FINNISH_LANGUAGE}`
      default:
        return `${process.env.REACT_APP_FEEDBACK_DEFAULT_LANGUAGE}`
    }
  }, [user?.language])

  const handleContactSupport = useCallback(
    (retries = 3) =>
      () => {
        console.log('--retries ' + retries)
        openNinchat(toggleDisplayMenu)
      },
    [toggleDisplayMenu],
  )

  const handleClickSeppoGuide = useCallback(() => {
    window.open('https://seppo.io/manual/', '_blank')
    toggleDisplayMenu()
  }, [toggleDisplayMenu])

  const handleClickSubmitFeedback = useCallback(() => {
    window.open(getFeedbackFormUrl(), '_blank')
    toggleDisplayMenu()
  }, [getFeedbackFormUrl, toggleDisplayMenu])

  return (
    <>
      <div
        ref={ref}
        className={classNames(isLegacyEditor ? styles.helpMenuContainerTempLegacyEditor : styles.helpMenuContainer)}
      >
        {showHelpMenu && (
          <div ref={helpMenuRef} className={styles.helpMenu}>
            <div>
              <HelpMenuItem
                icon={'receipt'}
                label={t('help_menu.seppo_guide', 'Seppo Guide')}
                onClickAction={handleClickSeppoGuide}
              />
              <HelpMenuItem
                icon={'multipleUsers'}
                label={t('help_menu.contact_support', 'Contact support')}
                onClickAction={handleContactSupport()}
              />
              <HelpMenuItem
                icon={'messageFail'}
                label={t('help_menu.submit_feedback', 'Submit feedback')}
                onClickAction={handleClickSubmitFeedback}
              />
            </div>
          </div>
        )}
      </div>
      <span className={classNames(isLegacyEditor ? styles.fabContainerTempLegacyEditor : styles.fabContainer)}>
        <RoundButton
          icon='help'
          className={classNames(isLegacyEditor ? styles.fabButtonTempLegacyEditor : styles.fabButton, buttonClassName)}
          onClick={toggleDisplayMenu}
          id={`${HELP_MENU_CONTROL_ID}_fabButton`}
          ref={helpButtonRef}
          accessibilityLabel={
            showHelpMenu
              ? t('accessibility.help_menu_button.close', 'Close help menu')
              : t('accessibility.help_menu_button.open', 'Open help menu')
          }
        />
      </span>
    </>
  )
})
