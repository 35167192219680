import { FORM_ERROR } from 'final-form'
import React from 'react'
import { ExerciseType, LibrarySource } from '../api/gameTypes'
import { GameBoardSettings, NotificationSettings } from '../pages/GameEditor/types'

export enum GameStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT', // not open, no answers
  IN_PROGRESS = 'IN_PROGRESS', // open
  PAUSED = 'PAUSED', // not open, has answers
  COMMUNITY = 'COMMUNITY',
  SPONSORED = 'SPONSORED',
  LIBRARY = 'LIBRARY',
  TEMPLATE = 'TEMPLATE',
}

export enum SecondaryGameStatus {
  DOWNLOADED = 'DOWNLOADED', // own game imported from community or library
  PUBLISHED = 'PUBLISHED', // own game that was shared
}

export type SubtaskPreview = {
  descriptionHtml?: string
  type?: ExerciseType
  points?: number
}

export type TaskPreview = {
  id: string
  name: string
  subtasks: SubtaskPreview[]
  pointsSum: number
}

export type TGameRating = {
  average: number
  count: number
}

export type TGameCard = {
  id: string
  exercisesCount?: number
  gameName: string
  gameOwners: string[]
  modifiedAt: string
  status: GameStatus
  secondaryStatus?: SecondaryGameStatus
  librarySource: LibrarySource
  thumbnail?: string
  archivedAt?: string
  mapsCount?: number
  storyHtml?: string
  rulesHtml?: string
  language?: string
  ages?: string[]
  subjects?: string[]
  instructionsForTeacherHtml?: string
  description?: string
  rating?: TGameRating
  downloaded?: number
  tags?: string[]
  playersCount?: number
}

export enum GameAction {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  COPY = 'COPY',
  IMPORT = 'IMPORT',
  DELETE = 'DELETE',
}

export type TGameItem = TGameCard & {
  onContextMenuClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  onGameEditorClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  onAddClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void
}

export type GameMap = {
  url: string
  dimensions?: {
    x: number
    y: number
  }
}

export type GameMapsAndExercises = {
  gameId: number
  exercises: TaskPreview[]
  maps: GameMap[]
}

export type Game = {
  gameId: number
  name: string
  description?: string
  language?: string
  ages?: string[]
  keywords?: string[]
  topics?: string[]
  tasks: TaskPreview[]
  storyHtml?: string
  rulesHtml?: string
  instructionsForTeacherHtml?: string
  gameBoardSettings: GameBoardSettings
  notificationSettings?: NotificationSettings
  chatEnabled?: boolean
  gpsEnabled?: boolean
  explorationMode?: boolean
  levelsEnabled?: boolean
  allowBranching?: boolean
  playerContinuesSentAnsEnabled?: boolean
  noPointsGame?: boolean
  hideScoreboard?: boolean
  enableBadges?: boolean
  happyOrNot?: boolean
}

export type Instructor = {
  name: string
  id: string
}

export enum AllowedOption {
  COLLABORATION = 'COLLABORATION',
  ADVANCED_MODE = 'ADVANCED_MODE',
  TREE_MODEL = 'TREE_MODEL',
  PLAYER_FEEDBACK = 'PLAYER_FEEDBACK',
  GAME_PACKAGES = 'GAME_PACKAGES',
  SPONSORED_GAMES_REPORTING = 'SPONSORED_GAMES_REPORTING',
  ALLOW_NEW_PLAYER_UI = 'ALLOW_NEW_PLAYER_UI',
  ADD_PLAYERS_BY_EMAIL = 'ADD_PLAYERS_BY_EMAIL',
  PLAYER_REPORTING = 'PLAYER_REPORTING',
  LEVELS = 'LEVELS',
  MULTIMAPS = 'MULTIMAPS',
  NO_POINTS_GAME = 'NO_POINTS_GAME',
  PLAYERCONTINUESENTANS = 'PLAYERCONTINUESENTANS',
  '360_MAPS' = '360_MAPS',
  TEACHER_REPORTING = 'TEACHER_REPORTING',
  EXPORT_RESULTS = 'EXPORT_RESULTS',
  ORDERED_EXERCISES = 'ORDERED_EXERCISES',
  CUSTOM_CHAT = 'CUSTOM_CHAT',
  GRADINGLICENCE = 'GRADINGLICENCE',
  PUBLICSUMMARY = 'PUBLICSUMMARY',
  STUDENTLICENCE = 'STUDENTLICENCE',
  SUMMARY_DOWNLOAD = 'SUMMARY_DOWNLOAD',
  P2P_MESSAGING = 'P2P_MESSAGING',
  EXERCISE_CODE = 'EXERCISE_CODE',
  PRIVATE_LIBRARY = 'PRIVATE_LIBRARY',
  MISSINGWORDEXERCISE = 'MISSINGWORDEXERCISE',
  MATCHPAIRS = 'MATCHPAIRS',
  CORPORATE_LIBRARY = 'CORPORATE_LIBRARY',
}

export enum Language {
  // TODO: Exported from Smartfeets. Should the list be replaced with new one?
  SUOMI = 'fi',
  ENGLISH = 'en',
  SVENSKA = 'sv',
  ARABIC = 'ar',
  NEDERLANDS = 'nl',
  PORTUGUESE = 'pt',
  SPANISH = 'es',
  HUNGARIAN = 'hu',
  DEUTSCH = 'de',
  NORSK = 'nb',
}

export enum Report {
  BUSINESS = 'BUSINESS',
  GROUP = 'GROUP',
  TEACHER = 'TEACHER',
  SPONSORED_GAMES = 'SPONSORED_GAMES',
  PLAYER = 'PLAYER',
}

export type Business = {
  id: number
  name: string
  validUntil: DateTime
  country: string
  industry: string
  loginCount: number //For some businesses it is limited how many players they can have and the current count is shown in old dashboard
}

export type Tag = {
  id: number
  label: string
  businessId: number
}

export type Theme = {
  themeLogoUrl: string
  themeAllowedLanguages: string //List of lang codes
  themeMainColor: string //#aabbcc or rgb(1,2,3)
  themeSecondaryColor: string //#aabbcc or rgb(1,2,3)
  themeFaviconUrl: string
}

export type TUser = {
  id: number
  name: string //This user's unique login/username, which is often the same as email
  userInitials: string
  email: string | null
  language: string //en | fi | sv ...
  chatName: string
  contactName: string
  hasPlayerAccounts: boolean
  isBusinessAdmin: boolean
  isBusinessGroupMaster: boolean
  isBusinessGroupTechMaster: boolean
  isBusinessSuperUser: boolean
  isStudentTeacher: boolean
  isGradingInstructor: boolean
  isSponsoredUser: boolean
  isTrial: boolean
  isCorporate: boolean
  isEducation: boolean
  hasOrgLibrary: boolean
  hasCommunity: boolean
  hasTemplates: boolean
  passwordChanged: boolean
  termsAccepted: boolean
  hasTeacherReporting: boolean
  allowedOptions: AllowedOption[] //List of additional allowed options, we could put them also as separate fields
  activeBusiness: Business
  primaryBusinessId: number
  businesses: Business[]
  tags: Tag[] //List of tags, related to player accounts feature
  createGame: Boolean
  createdAt: string
  theme: Theme
  reports: Report[] // list of reports visible to user
  businessReportUrl: string
  useOldDashboard: boolean
}

export type UserDataField = keyof TUser

//Below is copied from native app util/types.ts
export type Maybe<T> = T | null

export type Try<T, E extends Error = Error> = Success<T> | Failure<E>

export interface DateTime extends String {
  // '2019-10-17T12:43:45.044Z'
  _dateTime: never
}

export interface Success<T>
  extends Readonly<{
    value: T
    success: true
  }> {}

export interface Failure<E>
  extends Readonly<{
    error: E
    success: false
  }> {}

export type Either<L, R> = Left<L> | Right<R>

export interface Left<T>
  extends Readonly<{
    tag: 'left'
    value: T
  }> {}

export interface Right<T>
  extends Readonly<{
    tag: 'right'
    value: T
  }> {}

export function toSuccess<T>(value: T): Success<T> {
  return {
    success: true,
    value,
  }
}

export function toFailure<E>(error: E): Failure<E> {
  return {
    error,
    success: false,
  }
}

export type DeepMappedType<T, K> = {
  [Property in keyof T]: T[Property] extends object ? DeepMappedType<T[Property], K> : K
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type ObjectAny = {
  [key: string]: any
}

export type ObjectGeneric<T> = {
  [key: string]: T
}

export type FormErrorType<T> = DeepPartial<DeepMappedType<T, string> & { [FORM_ERROR]: string }>

export type FormFieldProps<T extends ObjectAny> = Omit<T, 'name' | 'label'> & {
  name: string
  label: string | JSX.Element
  labelSubtext?: string | JSX.Element
  srOnlyLabel?: boolean
  id?: string
  fieldContainerClassName?: string
  inline?: boolean
  placeholder?: string
  reserveErrorSpace?: boolean
  validateOnlyIfDirty?: boolean
  errorClassName?: string
  controlWithLabelClassName?: string
}

export type Subtask = {
  type: ExerciseType
  description: string
}

export type Task = {
  name: string
  // TODO: fix typing when we define all task types (any was added for testing creative task validations)
  subtasks: Array<Subtask & any>
  advanced: ObjectGeneric<boolean>
}

export enum CreativeAnswerOption {
  Text = 'Text',
  Images = 'Images',
  Video = 'Video',
  Audio = 'Audio',
}

export enum PointsType {
  Evaluate = 'Evaluate',
  Automatic = 'Automatic',
}

export enum Currencies {
  Euro = '€',
}

export type CreativeSubtask = Subtask & {
  creative: {
    answerOptions: CreativeAnswerOption[]
    points: number
    autoScorePercentage?: number
    hasEvaluationGuideline: boolean
    evaluationGuideline?: string
    hasAutomatedFeedback: boolean
    automatedFeedback?: string
  }
}

// TODO: finalize business info types once we decide what to show
export type NewBusinessInfo = {
  paymentType: string
}

export type BusinessInfo = {
  validUntil: string
  totalPlayersCount: number
  newBusinessInfo?: NewBusinessInfo
}

export type TBusinessSettings = {
  businessInfo: BusinessInfo
  members: TMember[]
}

export type TMember = {
  id: number
  name: string
  email: string
  role: MemberRole
  lastActive?: string
}

export type TAddMember = Omit<TMember, 'id'>

export enum MemberRole {
  ADMIN = 'ADMIN',
  INSTRUCTOR = 'INSTRUCTOR',
  // roles below can be assigned only by Seppo admin
  SUPERUSER = 'SUPERUSER',
  SUPERUSER_ADMIN = 'SUPERUSER_ADMIN',
}
