// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Profile_profileContainer__Fv30l {\n  display: flex;\n  flex-direction: column;\n}\n\n.Profile_rowContainer__Yt0h3 {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  justify-content: space-between;\n  min-height: 3rem;\n}\n\n.Profile_firstColumn__iOsNZ {\n  min-width: min(25%, 11rem);\n  font-weight: 700;\n}\n\n.Profile_secondColumn__YmrR- {\n  flex: 1 1;\n}\n\n.Profile_inputField__vcv3O {\n  min-width: 20rem;\n}\n\n.Profile_logOutButtonContainer__vHH9y {\n  margin-top: 2.5rem;\n}\n\n.Profile_textContent__Vlw0X {\n  font-size: 1.125rem;\n  line-height: 1.5rem;\n}\n\n.Profile_emailTooltipIcon__qJ2kg {\n  font-size: 1.5rem;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/settings/general/components/profile/Profile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".profileContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.rowContainer {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  justify-content: space-between;\n  min-height: 3rem;\n}\n\n.firstColumn {\n  min-width: min(25%, 11rem);\n  font-weight: 700;\n}\n\n.secondColumn {\n  flex: 1;\n}\n\n.inputField {\n  min-width: 20rem;\n}\n\n.logOutButtonContainer {\n  margin-top: 2.5rem;\n}\n\n.textContent {\n  font-size: 1.125rem;\n  line-height: 1.5rem;\n}\n\n.emailTooltipIcon {\n  font-size: 1.5rem;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileContainer": "Profile_profileContainer__Fv30l",
	"rowContainer": "Profile_rowContainer__Yt0h3",
	"firstColumn": "Profile_firstColumn__iOsNZ",
	"secondColumn": "Profile_secondColumn__YmrR-",
	"inputField": "Profile_inputField__vcv3O",
	"logOutButtonContainer": "Profile_logOutButtonContainer__vHH9y",
	"textContent": "Profile_textContent__Vlw0X",
	"emailTooltipIcon": "Profile_emailTooltipIcon__qJ2kg"
};
export default ___CSS_LOADER_EXPORT___;
