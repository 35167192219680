// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageWithTopbar_pageContainer__RZ8GK {\n  width: 100%;\n  min-height: calc(100vh - var(--global-notification-height));\n  padding: var(--page-with-topbar-padding);\n  display: flex;\n  flex-direction: column;\n  max-width: calc(100vw - var(--nav-sidebar-width));\n  overflow-x: auto;\n}\n\n.PageWithTopbar_mainContainer__KDk4J {\n  flex: 1 1;\n  display: flex;\n  flex-direction: column;\n  padding-top: 64px;\n}\n", "",{"version":3,"sources":["webpack://./src/composites/layout/PageWithTopbar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2DAA2D;EAC3D,wCAAwC;EACxC,aAAa;EACb,sBAAsB;EACtB,iDAAiD;EACjD,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".pageContainer {\n  width: 100%;\n  min-height: calc(100vh - var(--global-notification-height));\n  padding: var(--page-with-topbar-padding);\n  display: flex;\n  flex-direction: column;\n  max-width: calc(100vw - var(--nav-sidebar-width));\n  overflow-x: auto;\n}\n\n.mainContainer {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  padding-top: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "PageWithTopbar_pageContainer__RZ8GK",
	"mainContainer": "PageWithTopbar_mainContainer__KDk4J"
};
export default ___CSS_LOADER_EXPORT___;
