import classNames from 'classnames'
import { FORM_ERROR } from 'final-form'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import WomanWithGlasses from '../../../assets/images/skirt_1.png'
import { Button } from '../../../common/components/button/Button'
import { useTheme } from '../../../contexts/ThemeContext'
import { UserContext, UserContextType } from '../../../contexts/userContext'
import { useFadeAnimation } from '../../../hooks/FadeAnimation/useFadeAnimation'
import { routes } from '../../../routes'
import { FORM_LOGIN_STEPS, FORM_TRIAL_STEPS } from '../helpers'
import styles from './LoginMethod.module.css'

export type LoginFormValues = {
  email: string
  password: string
}

type LoginFormProps = {
  onClickBack: (shouldAutofocusEnterCode?: boolean) => void
}

export const LoginForm: React.FC<LoginFormProps> = ({ onClickBack }) => {
  const { t } = useTranslation()
  const { logoUrl } = useTheme()
  const { user, doLogin } = useContext(UserContext) as UserContextType
  const { fadeTransition } = useFadeAnimation()
  const navigate = useNavigate()
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [isTrial, setIsTrial] = useState(false)
  const [isGuestInstructor, setIsGuestInstructor] = useState(false)

  useEffect(() => {
    if (!user) {
      console.log('NO USER, REDIRECT TO LOGIN', user)
      navigate(routes.login)
    } else {
      if (user.termsAccepted) {
        navigate(routes.homepage)
      } else {
        navigate(routes.onboardingWizard)
      }
    }
  }, [navigate, user])

  const next = () => setStepIndex(1)

  const prev = () => {
    if (isGuestInstructor) {
      setIsGuestInstructor(false)
    }
    setStepIndex(0)
  }

  const onSubmit = useCallback(
    async (values: LoginFormValues) => {
      if (stepIndex === 1) {
        const result = await doLogin(values.email, values.password)
        if (!result) {
          return {
            [FORM_ERROR]: t(
              'login.login_failed_error_message',
              'Failed to log in. Please check that you are entering correct values and try again or contact us for support',
            ),
          }
        }
      } else {
        if (isTrial) {
          console.log('checkpoint 1')
          const result = await doLogin(values.email, 'NEWTRIAL')
          if (!result) {
            return {
              [FORM_ERROR]: t(
                'login.login_failed_error_message',
                'Failed to log in. Please check that you are entering correct values and try again or contact us for support',
              ),
            }
          }
        } else {
          next()
        }
      }
    },
    [doLogin, isTrial, stepIndex, t],
  )

  const validate = useCallback(
    (values: Partial<LoginFormValues>) => {
      return FORM_LOGIN_STEPS?.[stepIndex]?.validateCreator(t)(values) ?? {}
    },
    [stepIndex, t],
  )

  const onClickBackInternal = useCallback(
    (shouldAutofocusEnterCode?: boolean) => () => {
      if (shouldAutofocusEnterCode != null) {
        onClickBack(true)
      } else {
        onClickBack()
      }
    },
    [onClickBack],
  )

  const goToGuestInstructor = () => {
    setIsGuestInstructor(true)
    next()
  }

  // NOTE: Don't delete, revive in the future when it's green lit
  // const onClickSwitchFormSteps = () => {
  //   // switch between trial account creation and normal login
  //   setIsTrial((prev) => !prev)
  //   setStepIndex(0)
  // }

  return (
    <div className={classNames(styles.container, fadeTransition)}>
      <div className={styles.leftSideContainer}>
        <div className={styles.firstRow}>
          <Button
            styleVariant='borderless'
            sizeVariant='tiny'
            label={t('login.instructor.left.back_button', 'Back')}
            onClick={onClickBackInternal()}
            buttonStyles={{
              color: 'white',
              fontSize: '1.125rem',
              minWidth: 'unset',
              marginLeft: '-1.25rem',
            }}
            iconLeft={'arrowRight'}
            iconLeftContainerStyle={{
              fontSize: '0.375rem',
              rotate: '180deg',
              marginRight: '0.25rem',
            }}
          />
        </div>
        {isTrial ? (
          <div className={styles.imageWrapper}>
            <img src={WomanWithGlasses} alt='Woman with glasses in a long skirt' />
          </div>
        ) : (
          <div className={styles.secondRow}>
            <h1>{t('login.instructor.left.title', 'Join an existing game')}</h1>
            <p>
              {t(
                'login.instructor.left.body',
                'Return to the previous page to enter a game code if you want to join a game.',
              )}
            </p>
            <Button
              label={t('login.instructor.left.enter_code_button', 'Enter game code').toUpperCase()}
              styleVariant='tertiary'
              sizeVariant='tiny'
              onClick={onClickBackInternal(true)}
              buttonStyles={{
                color: 'var(--primary-normal)',
                maxWidth: 'fit-content',
                padding: '0.5rem 1.25rem',
                lineHeight: '1rem',
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.rightSideContainer}>
        <div className={styles.logoContainer}>
          <img src={logoUrl} alt='Seppo' height={26} className={styles.seppoLogo} />
        </div>
        {stepIndex === 1 && (
          <div>
            <Button
              styleVariant='borderless'
              sizeVariant='tiny'
              label={t('login.instructor.right.normal.second_step.back_button', 'Back')}
              onClick={prev}
              buttonStyles={{
                color: 'black',
                fontSize: '1.125rem',
                minWidth: 'unset',
                marginLeft: '-20px',
                marginBottom: '24px',
              }}
              iconLeft={'arrowRight'}
              iconLeftContainerStyle={{
                fontSize: '1.25rem',
                rotate: '180deg',
                marginRight: '20px',
                color: 'var(--primary-normal)',
              }}
            />
          </div>
        )}
        <Form<LoginFormValues> onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, submitting, submitError, modifiedSinceLastSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              {(isTrial ? FORM_TRIAL_STEPS : FORM_LOGIN_STEPS)[stepIndex].component(isGuestInstructor)}
              <div className={styles.formLabelAndButtonContainer}>
                {/*stepIndex === 1 && (
                  <p className={styles.lostPasswordInstructions}>
                    {t(
                      'login.instructor.right.normal.second_step.lost_password_instructions',
                      "If you've forgotten your password, log in using the e-mail code or link and define a new password in your Profile settings.",
                    )}
                  </p>
                    )*/}
                <Button
                  styleVariant='secondary'
                  sizeVariant='large'
                  wide
                  label={
                    submitting
                      ? t('loading', 'Loading...')
                      : stepIndex === 0
                      ? t('login.instructor.right.normal.first_step.continue_button', 'Continue with email')
                      : t('login.instructor.right.normal.second_step.continue_button', 'Continue')
                  }
                  type='submit'
                  disabled={submitting}
                  buttonStyles={{ borderRadius: '8px', fontSize: '1.125rem', fontWeight: 'bold' }}
                />
              </div>
              {submitError && !modifiedSinceLastSubmit && <div className={styles.submitError}>{submitError}</div>}
            </form>
          )}
        </Form>
        <div className={styles.loginOrTrialContainer}>
          {!isGuestInstructor && stepIndex === 0 && (
            <Button
              styleVariant='tertiary'
              sizeVariant='tiny'
              label={t('login.instructor.right.guest_instructor.buttonn', 'GUEST INSTRUCTOR LOGIN')}
              onClick={goToGuestInstructor}
            />
          )}
          {/* NOTE: temporarily hidden */}
          {/* <p className={styles.loginOrTrialFont}>
            {isTrial
              ? t('login.login_trial_switch.already_user_question', 'Already a user?')
              : t('login.login_trial_switch.create_trial_question', 'Want to create a trial account?')}
          </p>
          <button
            className={classNames(styles.linkLikeButton, styles.loginOrTrialFont)}
            onClick={onClickSwitchFormSteps}
          >
            {isTrial
              ? t('login.login_trial_switch.log_in_link', 'Login here')
              : t('login.login_trial_switch.go_to_trial_link', 'Click here')}
          </button> */}
        </div>
      </div>
    </div>
  )
}

// NOTE: here for translation generation, remove once trial link is revived
// t('login.login_trial_switch.already_user_question', 'Already a user?')
// t('login.login_trial_switch.create_trial_question', 'Want to create a trial account?')}
// t('login.login_trial_switch.log_in_link', 'Login here')
// t('login.login_trial_switch.go_to_trial_link', 'Click here')}
