import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { GameAction } from '../../../types/commonTypes'
import { MenuItem } from '../../ContextMenu/components/MenuItem'
import styles from './GamePreviewModalSidebar.module.css'

type SidebarMoreMenuProps = {
  onMenuItemClick: (action: GameAction) => void
}

export const SidebarMoreMenu = forwardRef<HTMLDivElement, SidebarMoreMenuProps>(({ onMenuItemClick }, ref) => {
  const onContainerClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }
  const { t } = useTranslation()

  return (
    <div className={styles.moreMenuContainer} ref={ref} onClick={onContainerClick}>
      <MenuItem
        icon='duplicate'
        text={t('context_menu.duplicate', 'Duplicate game')}
        onMenuItemClick={() => onMenuItemClick(GameAction.COPY)}
      />
      <MenuItem
        icon='trash'
        text={t('context_menu.delete_game', 'Delete game')}
        onMenuItemClick={() => onMenuItemClick(GameAction.DELETE)}
      />
    </div>
  )
})
