import { useTranslation } from 'react-i18next'
import { useNotification } from '../../../contexts/NotificationContext'
import { useUser } from '../../../contexts/userContext'
import styles from '../Settings.module.css'
import { GeneralSection } from './components/general/GeneralSection'
import { ProfileSection, UserProfile } from './components/profile/ProfileSection'
import { useState } from 'react'

export const GeneralSettings: React.FC = () => {
  const { user, updateUser, doLogout, requestPasswordReset } = useUser()
  const { notifySuccess, notifyError } = useNotification()
  const { t } = useTranslation()
  const [isResetPasswordLoading, setIsResetPasswordLoading] = useState<boolean>(false)

  if (user == null) {
    return null
  }

  const handleLanguageChange = (language?: string) => {
    if (language != null) {
      updateUser({ key: 'language', value: language })
    }
  }

  const handleProfileFieldUpdate = async (key: keyof Omit<UserProfile, 'email'>, value: string, label: string) => {
    const result = await updateUser({ key, value }, true)
    if (!result) {
      notifyError({
        title: t('settings_general.profile.update_failed_notification.title', {
          fieldName: label,
          defaultValue: 'Failed to update %{fieldName}',
        }),
        content: t(
          'settings_general.profile.update_failed_notification.content',
          'Please try again or contact us for support',
        ),
      })
    }
    return result
  }

  const handleResetPasswordClick = async () => {
    setIsResetPasswordLoading(true)
    const result = await requestPasswordReset()
    if (result) {
      notifySuccess({
        title: t(
          'settings_general.profile.reset_password_notification.success.title',
          'Password reset link sent to your email',
        ),
        content: t(
          'settings_general.profile.reset_password_notification.success.content',
          'A password reset link was sent to your email address.',
        ),
      })
    } else {
      notifyError({
        title: t('settings_general.profile.reset_password_notification.failure.title', 'Password reset failed'),
        content: t(
          'settings_general.profile.reset_password_notification.failure.content',
          'An error ocurred while requesting a password reset. Please try again or contact us for support.',
        ),
      })
    }
    setIsResetPasswordLoading(false)
  }

  const handleLogOutClick = () => {
    doLogout()
  }

  const userProfile: UserProfile = {
    contactName: user.contactName,
    chatName: user.chatName,
    name: user.name,
  }

  return (
    <div className={styles.sectionsContainer}>
      <GeneralSection userLanguage={user.language} onLanguageChange={handleLanguageChange} />
      <ProfileSection
        userProfile={userProfile}
        onUpdateField={handleProfileFieldUpdate}
        onResetPasswordClick={handleResetPasswordClick}
        onLogOutClick={handleLogOutClick}
        isResetPasswordLoading={isResetPasswordLoading}
      />
    </div>
  )
}
