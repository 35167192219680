import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { MODALS_ROOT_ID } from '../../constants'

export const ModalWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const containerElement = document.querySelector(`#${MODALS_ROOT_ID}`)
  return containerElement != null
    ? createPortal(children, containerElement)
    : null
}
