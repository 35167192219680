import { useCallback, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { uploadGameBoard } from '../../../../api/fileUploadApiService'
import { Button } from '../../../../common/components/button/Button'
import { GameBoard } from '../../types'
import { useTranslation } from 'react-i18next'

type UploadGameBoardProps = {
  disabled?: boolean
  onUploadSuccessful: (board: GameBoard) => void
}

const MAX_FILE_SIZE = 2048 //2MB

export const UploadGameBoard: React.FC<UploadGameBoardProps> = ({ disabled, onUploadSuccessful }) => {
  const { t } = useTranslation()
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleUploadBoardGame = useCallback(() => {
    if (hiddenFileInput.current?.files?.[0] == null) {
      return
    }
    const inputFile = hiddenFileInput.current.files[0]
    const fileSizeKiloBytes = inputFile.size / 1024
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      alert('File size needs to be under 2MB')
      return
    }
    setLoading(true)
    const fileNameWithoutExt = inputFile.name.substring(0, inputFile.name.lastIndexOf('.')) || inputFile.name
    const uniqueFileName = `${uuid()}-${inputFile.name}`
    uploadGameBoard({ fileName: uniqueFileName, inputFile })
      .then((result) => {
        if (result.success) {
          onUploadSuccessful({ name: fileNameWithoutExt, fileName: uniqueFileName, url: result.value.url })
        } else {
          console.error(result.error)
          alert('Failed to upload game board. Please try again or contact us for support.')
        }
      })
      .catch((e) => {
        console.error(e)
        alert('Failed to upload game board. Please try again or contact us for support.')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [onUploadSuccessful])

  const handleAddImageClick = useCallback(() => {
    hiddenFileInput.current?.click()
  }, [])

  return (
    <>
      <Button
        styleVariant='secondary'
        sizeVariant='small'
        label={t('game_editor.game_boards.upload_own_game_button', 'Upload your own file')}
        disabled={disabled || loading}
        onClick={handleAddImageClick}
      />
      <input
        type='file'
        onChange={handleUploadBoardGame}
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        accept='image/*'
      />
    </>
  )
}
