// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StepProgress_progressContainer__0GgiG {\n  background-color: transparent;\n  border-radius: 0.5rem;\n  width: 100%;\n}\n\n.StepProgress_progress__5dZJb {\n  background-color: var(--primary-normal);\n  height: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/composites/wizard/components/StepProgress.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,uCAAuC;EACvC,cAAc;AAChB","sourcesContent":[".progressContainer {\n  background-color: transparent;\n  border-radius: 0.5rem;\n  width: 100%;\n}\n\n.progress {\n  background-color: var(--primary-normal);\n  height: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressContainer": "StepProgress_progressContainer__0GgiG",
	"progress": "StepProgress_progress__5dZJb"
};
export default ___CSS_LOADER_EXPORT___;
