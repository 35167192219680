import { TFunction } from 'i18next'
import { FormErrorType } from '../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../util/string'
import { LoginFormValues } from './components/LoginForm'
import { LoginMethod } from './components/LoginMethod'
import { PasswordOrCode } from './components/PasswordOrCode'
import { Trial } from './components/Trial'

type FormStep = {
  component: (isGuestInstructor: boolean) => JSX.Element
  validateCreator: (t: TFunction) => (values: Partial<LoginFormValues>) => FormErrorType<LoginFormValues>
}

export const emailStepValidate = (t: TFunction) => (values: Partial<LoginFormValues>) => {
  const errors = {} as FormErrorType<LoginFormValues>
  if (safeIsNullOrEmpty(values.email)) {
    errors.email = t('validation_errors.required', 'Required')
  }
  return errors
}

export const passwordStepValidate = (t: TFunction) => (values: Partial<LoginFormValues>) => {
  const errors = {} as FormErrorType<LoginFormValues>
  if (safeIsNullOrEmpty(values.password)) {
    errors.password = t('validation_errors.required', 'Required')
  }
  return errors
}

export const FORM_LOGIN_STEPS: FormStep[] = [
  {
    component: () => <LoginMethod />,
    validateCreator: (t: TFunction) => emailStepValidate(t),
  },
  {
    component: (isGuestInstructor: boolean) => <PasswordOrCode isGuestInstructor={isGuestInstructor} />,
    validateCreator: (t: TFunction) => passwordStepValidate(t),
  },
]

export const FORM_TRIAL_STEPS: FormStep[] = [
  {
    component: () => <Trial />,
    validateCreator: (t: TFunction) => emailStepValidate(t),
  },
]
