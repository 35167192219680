import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCircleCheckmarkGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <linearGradient
      id='circle_checkmark_gradient_icon_svg__a'
      x1={16}
      x2={16}
      y1={1.997}
      y2={30}
      gradientUnits='userSpaceOnUse'
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <g fillRule='evenodd' clipRule='evenodd'>
      <path
        fill='currentColor'
        d='M3.999 15.999C3.999 9.37 9.372 3.997 16 3.997c6.628 0 12.001 5.374 12.001 12.002C28.001 22.627 22.628 28 16 28 9.372 28 3.999 22.627 3.999 15.999zM16 1.997C8.268 1.997 1.999 8.267 1.999 16 1.999 23.73 8.268 30 16 30c7.732 0 14.001-6.269 14.001-14.001 0-7.733-6.269-14.002-14.001-14.002zm7.719 8.646a1 1 0 1 0-1.57-1.24L13.087 20.87l-.012.015a.213.213 0 0 1-.346-.01l-.013-.02-2.832-4.018A1 1 0 1 0 8.25 17.99L11.074 22a2.213 2.213 0 0 0 3.061.596l.005-.003a2.24 2.24 0 0 0 .52-.488z'
      />
      <path
        fill='url(#circle_checkmark_gradient_icon_svg__a)'
        fillOpacity={0.25}
        d='M3.999 15.999C3.999 9.37 9.372 3.997 16 3.997c6.628 0 12.001 5.374 12.001 12.002C28.001 22.627 22.628 28 16 28 9.372 28 3.999 22.627 3.999 15.999zM16 1.997C8.268 1.997 1.999 8.267 1.999 16 1.999 23.73 8.268 30 16 30c7.732 0 14.001-6.269 14.001-14.001 0-7.733-6.269-14.002-14.001-14.002zm7.719 8.646a1 1 0 1 0-1.57-1.24L13.087 20.87l-.012.015a.213.213 0 0 1-.346-.01l-.013-.02-2.832-4.018A1 1 0 1 0 8.25 17.99L11.074 22a2.213 2.213 0 0 0 3.061.596l.005-.003a2.24 2.24 0 0 0 .52-.488z'
      />
    </g>
  </svg>
)
export default SvgCircleCheckmarkGradientIcon
