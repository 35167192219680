import { Try, toFailure, toSuccess } from '../types/commonTypes'
import { createAxios } from './axios'
import {
  CloneLibraryGameParams,
  CopyOwnGameParams,
  CreateGameParams,
  DeleteOwnGameParams,
  Exercise,
  GetCombinedSearchParams,
  GetCombinedSearchResponse,
  GetGameParams,
  GetGameResponse,
  GetGamesResponse,
  GetLibraryGamesParams,
  GetLibraryGamesResponse,
  GetOwnGamesParams,
  GetTaskParams,
  GetTaskResponse,
  LibraryLanguage,
} from './gameTypes'
import { TEST_USER_AUTHORIZATION } from './userApiService'

export const PAGE_SIZE = 10
export async function getGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  isLibrary,
}: GetGameParams): Promise<Try<GetGameResponse>> {
  try {
    const url = isLibrary ? `/admin/games/conlib_game.json?conlib_game_id=${gameId}` : `/games/${gameId}`
    const response = await createAxios(authorization).get<GetGameResponse>(url, {
      params: {
        include_exercises: true,
        no_hidden_answers: true,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getCombinedSearch({
  authorization = TEST_USER_AUTHORIZATION,
  search,
  subject,
  language,
  age,
  updatedAt,
  approvedBySeppo,
  source,
  perPage = 10,
  page = 1,
  orderBy = 'updated_at desc',
}: GetCombinedSearchParams): Promise<Try<GetCombinedSearchResponse>> {
  try {
    const response = await createAxios(authorization).get<GetCombinedSearchResponse>(
      '/admin/games/combined_search.json',
      {
        params: {
          search: search,
          order_by: orderBy,
          per_page: perPage,
          page: page,
          exclude_sponsored: true, 
          not_in_package: true,
          ...(subject && { subject }),
          ...(language && { language }),
          ...(age && { age }),
          ...(updatedAt && { updated_at: updatedAt }),
          ...(approvedBySeppo && { approved_by_seppo: true }),
          ...(source && { source }),
        },
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getOwnGames({
  authorization = TEST_USER_AUTHORIZATION,
  userId,
  perPage = 10,
  page = 1,
  orderBy = 'updated_at desc',
}: GetOwnGamesParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetGamesResponse>(`/users/${userId}`, {
      params: {
        order_by: orderBy,
        per_page: perPage,
        page: page,
        ux3: true,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getPrivateLibraryGames({
  authorization = TEST_USER_AUTHORIZATION,
  perPage = 10,
  page = 1,
}: GetOwnGamesParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetLibraryGamesResponse>('/admin/games/library_data.json', {
      params: {
        business_id: 'PRIVATE_MODE',
        per_page: perPage,
        page: page,
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getCommunityPopularGames({
  authorization = TEST_USER_AUTHORIZATION,
  language = 'en',
  page = 1,
}: GetLibraryGamesParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetLibraryGamesResponse>(
      '/admin/games/library_popular_games.json',
      {
        params: {
          per_page: 10,
          page: page,
          language: getLanguage((language as keyof typeof LibraryLanguage) ?? 'en'),
          only_freemium_enabled: false,
        },
      },
    )

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function getLibraryGames({
  authorization = TEST_USER_AUTHORIZATION,
  language,
  perPage = 10,
  page = 1,
  corporate = false,
}: GetLibraryGamesParams): Promise<Try<GetLibraryGamesResponse>> {
  try {
    const response = await createAxios(authorization).get<GetLibraryGamesResponse>('/admin/games/library_data.json', {
      params: {
        per_page: perPage,
        page: page,
        //TODO: We ignore the language for now, but will probably bring it back later
        //language: language ? getLanguage(language as keyof typeof LibraryLanguage) : null,
        exclude_sponsored: true, //This will excelude sponsored game from reply - First release does not support importing them
        not_in_package: true, //This will excelude games belonging to a game package from reply
        ...(corporate && { corporate: true }),
      },
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function copyOwnGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: CopyOwnGameParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).post<GetGameResponse>(`/admin/games/${gameId}/copy.json`, {
      return_game: true,
    })

    const { data } = response

    return toSuccess({ active_acount: 0, count: 0, my_games: [data] })
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function deleteOwnGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  force = false,
}: DeleteOwnGameParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).delete<GetGameResponse>(`/admin/games/${gameId}.json`, {
      params: {
        return_game: true,
        force: force,
      },
    })

    const { data } = response

    return toSuccess({ active_acount: 0, count: 0, my_games: [data] })
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function cloneLibraryGame({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
}: CloneLibraryGameParams): Promise<Try<GetGamesResponse>> {
  try {
    const response = await createAxios(authorization).post<GetGameResponse>('/admin/games/clone_import.json', {
      game_id: gameId,
    })

    const { data } = response

    return toSuccess({ active_acount: 0, count: 0, my_games: [data] })
  } catch (e: any) {
    return toFailure(e)
  }
}

export const getTask = async ({
  authorization = TEST_USER_AUTHORIZATION,
  gameId,
  taskId,
}: GetTaskParams): Promise<Try<GetTaskResponse>> => {
  try {
    const { data } = await createAxios(authorization).get<Exercise>(
      `/games/${gameId}/exercises/${taskId}.json?raw=true`,
    )
    return toSuccess({ data })
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function createNewGame({
  authorization = TEST_USER_AUTHORIZATION,
  game,
}: CreateGameParams): Promise<Try<GetGameResponse>> {
  try {
    const response = await createAxios(authorization).post<GetGameResponse>('/admin/games/new.json', {
      ux3: true, //Statically true to inform backend that request comes from UX3
      ...game,
    })

    const { data } = response

    return toSuccess(data)
  } catch (e: any) {
    return toFailure(e)
  }
}

export async function createNewEmptyGame(authorization = TEST_USER_AUTHORIZATION): Promise<Try<number>> {
  try {
    const response = await createAxios(authorization).get<GetGameResponse>('/admin/games/new.json')

    const { data } = response

    return toSuccess(data.id)
  } catch (e: any) {
    return toFailure(e)
  }
}

const getLanguage = (languageKey: keyof typeof LibraryLanguage): string => {
  return LibraryLanguage[languageKey]
}
