import React from 'react'
import { useTranslation } from 'react-i18next'

import { GamesOverview } from '../../composites/GamesOverview/GamesOverview'
import { useGameData } from '../../contexts/OrgLibraryGamesContextProvider'
import { isNullOrZero } from '../../util/number'
import styles from './OrgLibrary.module.css'
import { LoaderBlock } from '../../common/components/loaders/LoaderBlock/LoaderBlock'
import { useUser } from '../../contexts/userContext'
import { getEmptyContentBrowseButton } from '../../util/game'

export const OrgLibrary: React.FC<{}> = () => {
  const gameData = useGameData()
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <div className={styles.container}>
      {isNullOrZero(gameData.pageData?.length) && gameData.loading ? (
        <LoaderBlock variant='secondary' />
      ) : (
        <GamesOverview
          title={t('org_library.title', 'Organization library')}
          libraryMode
          gameData={gameData}
          emptyContent={{
            title: t('org_library.empty.title', 'There are no games in your org library yet'),
            subtitle: t(
              'org_library.empty.content',
              'Add games as templates to your organisation library from within the game editor',
            ),
            browseButton: getEmptyContentBrowseButton(user, t),
          }}
        />
      )}
    </div>
  )
}
