import React from 'react'

import { InputFormField } from '../../../../../common/components/Form/InputFormField/InputFormField'
import { CreatableMultiSelectFormField, MultiSelectFormField, SelectFormField } from '../../../../../common/components/Form/SelectFormField/SelectFormField'
import { getAgeOptions, getLanguageOptions, getSubjectOptions } from '../../../../search/components/constants'
import { GameBoardSettings } from '../../../types'
import { GameBoardPicker } from '../../GameBoardsPicker/GameBoardsPicker'
import styles from '../GameSettingsModal.module.css'
import { EditorFormField } from '../../../../../common/components/Form/EditorFormField/EditorFormField'
import { useTranslation } from 'react-i18next'

type GeneralProps = {
  initialGameBoardSettings: GameBoardSettings
  onChangeGameBoardSettings: (settings: GameBoardSettings) => void
}

export const General: React.FC<GeneralProps> = ({
  initialGameBoardSettings,
  onChangeGameBoardSettings,
}) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <InputFormField
        name='name'
        label='Game name'
      />
      <EditorFormField
        name='description'
        label='Description'
        placeholder='Write a general description of the game'
        onlyBasicOptions
        initProps={{ height: 175 }}
      />
      <div className={styles.dropdownsContainer}>
        <MultiSelectFormField
          name='topics'
          label='Topics'
          options={getSubjectOptions(t)}
          isSearchable
        />
        <SelectFormField
          name='language'
          label='Language'
          placeholder='Select language'
          options={getLanguageOptions(t)}
        />
      </div>
      <div className={styles.dropdownsContainer}>
        <CreatableMultiSelectFormField
          label='Keywords'
          name='keywords'
        />
        <MultiSelectFormField
          name='ages'
          label='Ages'
          options={getAgeOptions(t)}
        />
      </div>
      <div className={styles.separator} />
      <GameBoardPicker
        initialValues={initialGameBoardSettings}
        onChange={onChangeGameBoardSettings}
      />
    </div>
  )
}
