import { useEffect, useState } from 'react'
import { getTask } from '../api/gameApiService'
import { parseTaskResponseToTaskVm } from '../api/typeConverters'
import { Task } from '../types/commonTypes'

export const useGetTask = (gameId: number | null, taskId: number | null) => {
  const [data, setData] = useState<Task>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  useEffect(() => {
    if (gameId != null && taskId != null) {
      setLoading(true)
      getTask({ gameId, taskId })
        .then((result) => {
          if (result.success) {
            setData(parseTaskResponseToTaskVm(result.value.data))
          } else {
            setError(result.error.message)
          }
        })
        .catch((e: any) => {
          setError(e?.message ?? 'An error occurred')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [gameId, taskId])

  return {data, loading, error}
}
