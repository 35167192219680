// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Settings_sectionsContainer__Q7vHd {\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n}\n\n.Settings_section__GDYRF {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n\n.Settings_sectionTitle__kwYx0 {\n  font-size: 2.07rem;\n  font-weight: 700;\n}\n\n.Settings_sectionCardContainer__HQAqC {\n  padding: 2rem;\n  background-color: #fff;\n  border-radius: 9px;\n}\n\n.Settings_divider__C6Qfq {\n  height: 0.0625rem;\n  width: 100%;\n  background-color: var(--grey-200);\n  margin: 1rem 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/settings/Settings.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,iCAAiC;EACjC,cAAc;AAChB","sourcesContent":[".sectionsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n}\n\n.section {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n\n.sectionTitle {\n  font-size: 2.07rem;\n  font-weight: 700;\n}\n\n.sectionCardContainer {\n  padding: 2rem;\n  background-color: #fff;\n  border-radius: 9px;\n}\n\n.divider {\n  height: 0.0625rem;\n  width: 100%;\n  background-color: var(--grey-200);\n  margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionsContainer": "Settings_sectionsContainer__Q7vHd",
	"section": "Settings_section__GDYRF",
	"sectionTitle": "Settings_sectionTitle__kwYx0",
	"sectionCardContainer": "Settings_sectionCardContainer__HQAqC",
	"divider": "Settings_divider__C6Qfq"
};
export default ___CSS_LOADER_EXPORT___;
