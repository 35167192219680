import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import shared from '../../common/styles/shared.module.css'
import { MenuItem } from './components/MenuItem'
import styles from './ContextMenu.module.css'
import { GameAction } from '../../types/commonTypes'

type ContextMenuProps = {
  containerStyle?: React.CSSProperties
  anchorX: string | undefined
  anchorY: string | undefined
  isOpen: boolean
  isPositioned: boolean
  onMenuItemClick: (action: GameAction) => void
  libraryMode?: boolean
}

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ onMenuItemClick, containerStyle, anchorX, anchorY, isOpen, isPositioned, libraryMode = false }, ref) => {
    const onContainerClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation()
    }
    const { t } = useTranslation()
    if (!isOpen) {
      return null
    }

    return (
      <div
        ref={ref}
        onClick={onContainerClick}
        className={classNames(
          styles.container,
          shared.smallShadow,
          shared.col,
          anchorX && anchorY ? styles.displayContextMenu : undefined,
        )}
        style={{
          ...containerStyle,
          right: anchorX,
          top: anchorY,
          pointerEvents: 'auto',
          touchAction: 'auto',
          visibility: isPositioned ? 'visible' : 'hidden',
        }}
      >
        <MenuItem
          icon='view'
          text={t('context_menu.preview_game', 'Preview game')}
          onMenuItemClick={() => onMenuItemClick(GameAction.VIEW)}
        />
        {libraryMode ? (
          <>
            <MenuItem
              icon='text'
              text={t('context_menu.import_game', 'Import game')}
              onMenuItemClick={() => onMenuItemClick(GameAction.IMPORT)}
            />
          </>
        ) : (
          <>
            <MenuItem
              icon='text'
              text={t('context_menu.game_in_editor', 'Open game in editor')}
              onMenuItemClick={() => onMenuItemClick(GameAction.EDIT)}
            />

            <MenuItem
              icon='duplicate'
              text={t('context_menu.duplicate', 'Duplicate game')}
              onMenuItemClick={() => onMenuItemClick(GameAction.COPY)}
            />
            <div className={styles.rowSeparator} />
            <MenuItem
              icon='trash'
              text={t('context_menu.delete_game', 'Delete game')}
              onMenuItemClick={() => onMenuItemClick(GameAction.DELETE)}
            />
          </>
        )}
      </div>
    )
  },
)
