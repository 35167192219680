import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getIcon } from '../icons/utils'

import shared from '../../styles/shared.module.css'
import styles from './Card.module.css'

type CreateGameCardProps = {
  onClick: () => void
}

export const CreateGameCard: React.FC<CreateGameCardProps> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <div>
      <button
        type={'button'}
        className={classNames(styles.container, styles.outlined, shared.mAxisC, shared.sAxisC)}
        style={{ width: '100%' }}
        onClick={onClick}
      >
        <span className={styles.iconWrapper}>{getIcon('plus')}</span>
        <p className={styles.text}>{t('dashboard.create_button', 'New game')}</p>
      </button>
    </div>
  )
}
