import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { parseUserInfoResponseToTUser } from '../api/typeConverters'
import { getUserInfo, login, sendPasswordResetLink, updateUserInfo } from '../api/userApiService'
import { getOldUIRoutes } from '../routes'
import { TUser, UserDataField } from '../types/commonTypes'
import { useGoogleSignIn } from '../util/googleSignIn'
import { DEFAULT_THEME_SETTINGS, useTheme } from './ThemeContext'

export type UserUpdate = {
  key: UserDataField
  value: any
}

type LoginState = 'PENDING' | 'LOGGED_IN' | 'LOGGED_OUT'

export type UserContextType = {
  loginState: LoginState
  user: TUser | null
  updateUser: (newValue: UserUpdate, doRefresh?: boolean, forceReload?: boolean) => Promise<boolean>
  refreshUser: () => void
  doLogin: (email: string, password: string, gToken?: string, fbToken?: string) => Promise<boolean>
  doLogout: () => void
  requestPasswordReset: () => Promise<boolean>
  DEVupdateUser: (debugValues: Partial<TUser>) => void
}

export const UserContext = createContext<UserContextType | null>(null)

interface Props {
  children?: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = React.useState<TUser | null>(null)
  const [loginState, setLoginState] = React.useState<LoginState>('PENDING')
  const { i18n } = useTranslation()
  const loginTimeChecker = useRef<number>(0)
  // Key exists if user is redirected from old dashboard with a readily generated session key
  const key = window.location.search.split('key=')[1]

  const { userCredentials, rawToken, clearState } = useGoogleSignIn()

  const { updateThemeSettings } = useTheme()
  useEffect(() => {
    if (user?.theme != null) {
      updateThemeSettings({
        logoUrl: user.theme?.themeLogoUrl ?? DEFAULT_THEME_SETTINGS.logoUrl,
        faviconUrl: user.theme?.themeFaviconUrl ?? DEFAULT_THEME_SETTINGS.faviconUrl,
        colorPrimary: user.theme?.themeMainColor ?? DEFAULT_THEME_SETTINGS.colorPrimary,
        colorSecondary: user.theme?.themeSecondaryColor ?? DEFAULT_THEME_SETTINGS.colorSecondary,
      })
    }
  }, [user?.theme, updateThemeSettings])

  useEffect(() => {
    i18n.changeLanguage(user?.language)
  }, [i18n, user?.language])

  const refreshUser = useCallback(() => {
    getUserInfo({}).then((userResponse) => {
      if (userResponse.success) {
        setUser(parseUserInfoResponseToTUser(userResponse.value))
        setLoginState('LOGGED_IN')
        if (userResponse.value.is_sponsored_user) {
          //Sponsored users are always forced to dashboard2
          window.location.href = getOldUIRoutes.oldDashboard()
        }
      } else {
        console.log('Could not load data')
        setLoginState('LOGGED_OUT')
        setUser(null)
      }
    })
  }, [])

  const doLogin = useCallback((email?: string, password?: string, gToken?: string, fbToken?: string) => {
    console.log('Logging in')
    return login({ userName: email, password: password, gToken: gToken, fbToken: fbToken }).then((userResponse) => {
      if (userResponse.success && userResponse.value.name) {
        console.log('Now logged in')
        console.log(userResponse)
        if (userResponse.value.use_dashboard2) {
          localStorage.setItem('sessionKey', userResponse.value.session_id)
          setUser(parseUserInfoResponseToTUser(userResponse.value))
          window.location.href = getOldUIRoutes.oldDashboard()
        } else {
          setLoginState('LOGGED_IN')
          localStorage.setItem('sessionKey', userResponse.value.session_id)
          setUser(parseUserInfoResponseToTUser(userResponse.value))
        }
        return true
      } else {
        console.log('Login failed')
        setLoginState('LOGGED_OUT')
        alert('Login: ' + JSON.stringify(userResponse))
        console.log(userResponse)
        return false
      }
    })
  }, [])

  useEffect(() => {
    if (key) {
      localStorage.setItem('sessionKey', key)
      window.location.href = '/'
      return
    }
    if (loginState === 'LOGGED_IN') return
    if (userCredentials != null) {
      doLogin('', '', rawToken || undefined, undefined)
    } else if ((localStorage.getItem('sessionKey') || '').length > 0) {
      console.log('refresh user to see if login still ok')
      refreshUser()
    }
  }, [doLogin, key, loginState, rawToken, refreshUser, userCredentials])

  const doLogout = useCallback(() => {
    clearState()
    console.log('Logging out')
    const id = user?.id
    const sessionId = localStorage.getItem('sessionKey')
    localStorage.removeItem('sessionKey')
    setUser(null)
    setLoginState('LOGGED_OUT')
    // Note! Redirect to old UI root as it decides where the next login should happen (if something changed it)
    window.location.href = `${process.env.REACT_APP_OLD_UI_LOGIN_URL}/users/logout?id=${id}&token=${sessionId}`
  }, [clearState, user?.id])

  useEffect(() => {
    if (loginTimeChecker.current > 0) {
      window.clearInterval(loginTimeChecker.current)
    }
    if (user) {
      //Refresh user once in an hour
      //If user session has gotten interrupted before that, axios error handler will redirect to login page
      loginTimeChecker.current = window.setInterval(() => {
        refreshUser()
      }, 3600000)
    }
  }, [refreshUser, user])

  const updateUser = useCallback(
    async (newValue: UserUpdate, doRefresh = false, forceReload = false) => {
      if (!user) {
        return false
      }
      const oldUser = user
      setUser({
        ...user,
        [newValue.key]: newValue.value,
      })

      const response = await updateUserInfo({ userId: user.id, updateData: newValue })
      if (response.success && doRefresh) {
        refreshUser()
      } else if (response.success && forceReload) {
        window.location.reload()
      } else if (!response.success) {
        console.error(response.error)
        //Should we just ignore failure or rollback. For now rollback
        setUser(oldUser)
      }
      return response.success
    },
    [user, refreshUser],
  )

  const requestPasswordReset = useCallback(async () => {
    if (user?.email == null) {
      console.warn('Cannot request password reset, user email is not set')
      return false
    } else {
      const response = await sendPasswordResetLink({ userEmail: user.email })
      return response.success
    }
  }, [user?.email])

  const DEVupdateUser = useCallback((debugValues: Partial<TUser>) => {
    if (process.env.REACT_APP_ENV === 'development') {
      setUser((prev) =>
        prev == null
          ? null
          : {
              ...prev,
              ...debugValues,
            },
      )
    }
  }, [])

  return (
    <UserContext.Provider
      value={{ user, loginState, updateUser, refreshUser, doLogin, doLogout, requestPasswordReset, DEVupdateUser }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('Expected to be wrapped in UserContext!')
  }
  return context
}
