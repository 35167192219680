// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorFormField_editorWrapper__olTgj {\n  width: 100%;\n}\n\n.EditorFormField_editorWrapper__olTgj .tox .tox-toolbar__primary {\n  /* prevents weird toolbar blur in Firefox */\n  transform: unset;\n  /* removes unneeded bottom line on Chrome after the fix above */\n  background-image: none;\n}\n\n.EditorFormField_hasError__CoFhg .tox.tox-tinymce {\n  outline: 0.125rem solid var(--validation-error);\n}\n", "",{"version":3,"sources":["webpack://./src/common/components/Form/EditorFormField/EditorFormField.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,2CAA2C;EAC3C,gBAAgB;EAChB,+DAA+D;EAC/D,sBAAsB;AACxB;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".editorWrapper {\n  width: 100%;\n}\n\n.editorWrapper :global(.tox .tox-toolbar__primary) {\n  /* prevents weird toolbar blur in Firefox */\n  transform: unset;\n  /* removes unneeded bottom line on Chrome after the fix above */\n  background-image: none;\n}\n\n.hasError :global(.tox.tox-tinymce) {\n  outline: 0.125rem solid var(--validation-error);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorWrapper": "EditorFormField_editorWrapper__olTgj",
	"hasError": "EditorFormField_hasError__CoFhg"
};
export default ___CSS_LOADER_EXPORT___;
