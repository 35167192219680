// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExpandableToggle_container__hDM7F {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.ExpandableToggle_titleAndDescription__Ex90Y > span {\n  display: block;\n}\n\n.ExpandableToggle_titleAndDescription__Ex90Y > span:first-child {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  color: var(--grey-900);\n}\n\n.ExpandableToggle_titleAndDescription__Ex90Y > span:last-child {\n  font-size: 0.75rem;\n  line-height: 1rem;\n  color: var(--grey-700);\n}\n\n.ExpandableToggle_uncheckedTitle__Aj0Ub {\n  font-weight: 500;\n}\n\n.ExpandableToggle_checkedTitle__tV8\\+X {\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GameEditor/components/Tasks/TaskModal/SubtaskFields/components/ExpandableToggle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.titleAndDescription > span {\n  display: block;\n}\n\n.titleAndDescription > span:first-child {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  color: var(--grey-900);\n}\n\n.titleAndDescription > span:last-child {\n  font-size: 0.75rem;\n  line-height: 1rem;\n  color: var(--grey-700);\n}\n\n.uncheckedTitle {\n  font-weight: 500;\n}\n\n.checkedTitle {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ExpandableToggle_container__hDM7F",
	"titleAndDescription": "ExpandableToggle_titleAndDescription__Ex90Y",
	"uncheckedTitle": "ExpandableToggle_uncheckedTitle__Aj0Ub",
	"checkedTitle": "ExpandableToggle_checkedTitle__tV8+X"
};
export default ___CSS_LOADER_EXPORT___;
