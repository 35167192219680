import classNames from 'classnames'
import sharedStyles from '../../Settings.module.css'
import { getIcon } from '../../../../common/components/icons/utils'
import styles from './OrganizationInfo.module.css'
import { useTranslation } from 'react-i18next'
import { safeIsNullOrEmpty } from '../../../../util/string'

type OrganizationInfoProps = {
  name: string
  membersCount?: number
  licenseValidUntilDate?: string
}

export const OrganizationInfo: React.FC<OrganizationInfoProps> = ({ name, membersCount, licenseValidUntilDate }) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(sharedStyles.sectionCardContainer, styles.container)}>
      <div className={styles.organizationIconContainer}>
        <span>{getIcon('organizationGradient')}</span>
      </div>
      <div className={styles.orgInfoList}>
        <h1>{name}</h1>
        {membersCount != null && (
          <span className='bold normal'>
            {t('settings_organization.org_info.members_count', {
              count: membersCount,
              defaultValue: '%{count} members',
            })}
          </span>
        )}
        {!safeIsNullOrEmpty(licenseValidUntilDate) && (
          <span className='normal'>
            {t('settings_organization.org_info.license_valid_until', {
              next_invoice: licenseValidUntilDate,
              defaultValue: 'License valid until: %{next_invoice}',
            })}
          </span>
        )}
      </div>
    </div>
  )
}
