import classNames from 'classnames'
import React, { useCallback } from 'react'
import { getIcon, IconName } from '../icons/utils'
import { Tooltip } from '../tooltip/Tooltip'
import styles from './SelectableIcon.module.css'

type SelectableIconProps = {
  iconName?: IconName
  checked: boolean
  disabled?: boolean
  large?: boolean
  id: string
  text: string
  tooltipText?: string
  size?: 'Small' | 'Medium' | 'Large'
  iconStyle?: React.CSSProperties
  onChange: (id: string, checked: boolean) => void
  className?: string
}

export const SelectableIcon: React.FC<SelectableIconProps> = ({
  text,
  iconName,
  id,
  checked = false,
  disabled = false,
  iconStyle,
  tooltipText = '',
  size = 'Small',
  onChange,
  className,
}) => {
  const onChangeInternal = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      onChange(id, e.currentTarget.checked)
    },
    [onChange, id],
  )
  const iconSelector = React.useMemo(() => {
    if (iconName == null) {
      return null
    }
    return getIcon(iconName)
  }, [iconName])
  return (
    <label
      className={classNames(
        styles.label,
        styles[`label${size}`],
        checked && !disabled && styles.labelActive,
        disabled && styles.labelDisabled,
        className,
      )}
    >
      {!!tooltipText && (
        <div className={styles.tooltipWrapper}>
          <Tooltip text={tooltipText} iconColor={checked ? 'white' : undefined} />
        </div>
      )}
      <input
        id={id}
        className={styles.originalCheckbox}
        type='checkbox'
        name='selectableIcon'
        onChange={onChangeInternal}
        checked={checked}
        disabled={disabled}
      />
      <span className={classNames(checked && styles.iconContainer, styles.uncheckedIcon)} style={iconStyle}>
        {iconSelector}
      </span>
      {text}
    </label>
  )
}
