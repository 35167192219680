import { ExerciseType } from '../../../../../api/gameTypes'
import { IconName } from '../../../../../common/components/icons/utils'

export const getIconNameForTaskType = (type: string): IconName => {
  switch (type) {
    case ExerciseType.CombineExercise:
      return 'taskMatchPairs'
    case ExerciseType.MissingWordExercise:
      return 'taskMissingWord'
    case ExerciseType.PollExercise:
      return 'circleCheckmark'
    case ExerciseType.MultichoiceExercise:
      return 'taskMultipleChoice'
    case ExerciseType.CollaborationExercise:
        return 'taskCreative'
    default:
      if (type !== ExerciseType.CreativeExercise) {
        console.warn(`Unknown task type [${type}], returning icon for creative task.`)
      }
      return 'taskCreative'
  }
}

export const getTitleForTaskType = (type: string): string => {
  switch (type) {
    case ExerciseType.CombineExercise:
      return 'Match pairs'
    case ExerciseType.MissingWordExercise:
      return 'Missing word'
    case ExerciseType.PollExercise:
      return 'Checkbox'
    case ExerciseType.MultichoiceExercise:
      return 'Multiple choice'
    case ExerciseType.CollaborationExercise:
        return 'Create and comment'
    default:
      if (type !== ExerciseType.CreativeExercise) {
        console.warn(`Unknown task type [${type}], returning icon for creative task.`)
      }
      return 'Creative'
  }
}

export const getDescriptionForTaskType = (type: string): string => {
  switch (type) {
    case ExerciseType.CombineExercise:
      return 'In this task type you can match: words, words and pictures, or just pictures. Remember to add the alternative texts to the images.'
    case ExerciseType.MissingWordExercise:
      return 'Write a sentence, place the cursor where you want the player to fill the missing word and click Add missing word. '
    case ExerciseType.PollExercise:
      return 'This task allows players to select multiple answers to a question. Answer are graded automatically based on the points and feedback you define.'
    case ExerciseType.MultichoiceExercise:
      return 'General description of the task type here (from system)'
    case ExerciseType.CollaborationExercise:
        return 'General description of the task type here (from system)'
    default:
      if (type !== ExerciseType.CreativeExercise) {
        console.warn(`Unknown task type [${type}], returning icon for creative task.`)
      }
      return 'Creative tasks allow players to create their own answers using the options you enable, from text to images and video to audio. Define the ask, toggle the answer options and let your players craft their responses.'
  }
}
