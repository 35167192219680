// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PointsInput_scoreItem__5UYKa {\n  display: flex;\n  align-items: center;\n}\n\n.PointsInput_scoreIcon__ne9Ff {\n  font-size: 2rem;\n  display: flex;\n  justify-items: center;\n  color: var(--secondary-normal)\n}\n", "",{"version":3,"sources":["webpack://./src/pages/GameEditor/components/Tasks/TaskModal/SubtaskFields/components/PointsInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,qBAAqB;EACrB;AACF","sourcesContent":[".scoreItem {\n  display: flex;\n  align-items: center;\n}\n\n.scoreIcon {\n  font-size: 2rem;\n  display: flex;\n  justify-items: center;\n  color: var(--secondary-normal)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreItem": "PointsInput_scoreItem__5UYKa",
	"scoreIcon": "PointsInput_scoreIcon__ne9Ff"
};
export default ___CSS_LOADER_EXPORT___;
