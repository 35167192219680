// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneralSection_languageSelectContainer__T8i2f {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n}\n\n.GeneralSection_languageSelectContainer__T8i2f label {\n  color: var(--grey-700);\n  font-size: 1.125rem;\n}\n\n.GeneralSection_languageSelect__Wc5Kr {\n  min-width: 15rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/settings/general/components/general/GeneralSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".languageSelectContainer {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n}\n\n.languageSelectContainer label {\n  color: var(--grey-700);\n  font-size: 1.125rem;\n}\n\n.languageSelect {\n  min-width: 15rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSelectContainer": "GeneralSection_languageSelectContainer__T8i2f",
	"languageSelect": "GeneralSection_languageSelect__Wc5Kr"
};
export default ___CSS_LOADER_EXPORT___;
