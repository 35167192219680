import { ObjectGeneric } from '../../types/commonTypes'

export const ORGANISATION_TYPE_OPTIONS = [
  // t('onboarding_wizard.organisation_types.business', 'Business')
  { value: 'Business', labelKey: 'onboarding_wizard.organisation_types.business' },
  // t('onboarding_wizard.organisation_types.education', 'Education')
  { value: 'Education', labelKey: 'onboarding_wizard.organisation_types.education' },
  // t('onboarding_wizard.organisation_types.non_profit', 'Non-profit')
  { value: 'Non-profit', labelKey: 'onboarding_wizard.organisation_types.non_profit' },
]

export const ALL_ORG_TYPES = ORGANISATION_TYPE_OPTIONS.map((option) => option.value)

export const ORGANISATION_SUBTYPE_OPTIONS: ObjectGeneric<{ value: string; labelKey: string }[]> = {
  Business: [
    // t('onboarding_wizard.business_types.company', 'Company')
    { value: 'Company', labelKey: 'onboarding_wizard.business_types.company' },
    // t('onboarding_wizard.business_types.training_company', 'Training Company')
    { value: 'Training Company', labelKey: 'onboarding_wizard.business_types.training_company' },
    // t('onboarding_wizard.business_types.consulting', 'Consulting')
    { value: 'Consulting', labelKey: 'onboarding_wizard.business_types.consulting' },
    // t('onboarding_wizard.business_types.event_organiser', 'Event Organiser')
    { value: 'Event Organizer', labelKey: 'onboarding_wizard.business_types.event_organiser' },
  ],
  Education: [
    // t('onboarding_wizard.education_types.primary', 'Primary')
    { value: 'Primary', labelKey: 'onboarding_wizard.education_types.primary' },
    // t('onboarding_wizard.education_types.secondary', 'Secondary')
    { value: 'Secondary', labelKey: 'onboarding_wizard.education_types.secondary' },
    // t('onboarding_wizard.education_types.high_school', 'High School')
    { value: 'High School', labelKey: 'onboarding_wizard.education_types.high_school' },
    // t('onboarding_wizard.education_types.vocational_school', 'Vocational School')
    { value: 'Vocational School', labelKey: 'onboarding_wizard.education_types.vocational_school' },
    // t('onboarding_wizard.education_types.university', 'University')
    { value: 'University', labelKey: 'onboarding_wizard.education_types.university' },
  ],
  'Non-profit': [
    // t('onboarding_wizard.non_profit_types.library', 'Library')
    { value: 'Library', labelKey: 'onboarding_wizard.non_profit_types.library' },
    // t('onboarding_wizard.non_profit_types.museum', 'Museum')
    { value: 'Museum', labelKey: 'onboarding_wizard.non_profit_types.museum' },
    // t('onboarding_wizard.non_profit_types.ngo', 'Non-Governmental Organisation')
    { value: 'NGO', labelKey: 'onboarding_wizard.non_profit_types.ngo' },
    // t('onboarding_wizard.non_profit_types.public_sector', 'Public sector')
    { value: 'Public sector', labelKey: 'onboarding_wizard.non_profit_types.public_sector' },
  ],
}

export const BUSINESS_TOPICS_OPTIONS = [
  // t('onboarding_wizard.business_topics.onboarding', 'Help onboard a new employee')
  { value: 'Onboarding', labelKey: 'Help onboard a new employee' },
  // t('onboarding_wizard.business_topics.strategy_and_values_engagement', 'Get engagement on our strategy and values')
  { value: 'Engagement', labelKey: 'Get engagement on our strategy and values' },
  // t('onboarding_wizard.business_topics.build_team_spirit', 'Build team spirit')
  { value: 'TeamSpirit', labelKey: 'Build team spirit' },
  // t('onboarding_wizard.business_topics.safety_training', 'Make safety training engaging')
  { value: 'SafetyTraining', labelKey: 'Make safety training engaging' },
  // t('onboarding_wizard.business_topics.customer_service_training', 'Customer service training')
  { value: 'CustomerService', labelKey: 'Customer service training' },
  // t('onboarding_wizard.business_topics.recruitment', 'Recruit in a fresh way')
  { value: 'Recruitment', labelKey: 'Recruit in a fresh way' },
]

export const SCHOOL_SUBJECTS_OPTIONS = [
  // t('onboarding_wizard.school_subjects.arts', 'Arts')
  { value: 'Arts', labelKey: 'Arts' },
  // t('onboarding_wizard.school_subjects.biology', 'Biology')
  { value: 'Biology', labelKey: 'Biology' },
  // t('onboarding_wizard.school_subjects.environmental_studies', 'Environmental studies')
  { value: 'Environmental-studies', labelKey: 'Environmental studies' },
  // t('onboarding_wizard.school_subjects.geography', 'Geography')
  { value: 'Geography', labelKey: 'Geography' },
  // t('onboarding_wizard.school_subjects.history', 'History')
  { value: 'History', labelKey: 'History' },
  // t('onboarding_wizard.school_subjects.languages', 'Languages')
  { value: 'Languages', labelKey: 'Languages' },
  // t('onboarding_wizard.school_subjects.math', 'Math')
  { value: 'Math', labelKey: 'Math' },
  // t('onboarding_wizard.school_subjects.physical_education', 'Physical education')
  { value: 'PE', labelKey: 'Physical Education' },
  // t('onboarding_wizard.school_subjects.social_studies', 'Social studies')
  { value: 'Social-studies', labelKey: 'Social studies' },
]

export const UNIVERSITY_SUBJECTS_OPTIONS = [
  // t('onboarding_wizard.university_subjects.arts', 'Arts')
  { value: 'Arts', labelKey: 'Arts' },
  // t('onboarding_wizard.university_subjects.economics', 'Economics')
  { value: 'Economics', labelKey: 'Economics' },
  // t('onboarding_wizard.university_subjects.education', 'Education')
  { value: 'Education', labelKey: 'Education' },
  // t('onboarding_wizard.university_subjects.engineering_and_science', 'Engineering & Science')
  { value: 'Engineering-Science', labelKey: 'Engineering & Science' },
  // t('onboarding_wizard.university_subjects.health_and_social', 'Health & Social')
  { value: 'Health-Social', labelKey: 'Health & Social' },
  // t('onboarding_wizard.university_subjects.languages', 'Languages')
  { value: 'Languages', labelKey: 'Languages' },
  // t('onboarding_wizard.university_subjects.orientation_and_team_building', 'Orientation & Team building')
  { value: 'Orientation-Team-building', labelKey: 'Orientation & Team building' },
  // t('onboarding_wizard.university_subjects.services', 'Services')
  { value: 'Services', labelKey: 'Services' },
]

export const PLAYER_OPTIONS = [
  // t('onboarding_wizard.player_options.0_50', '0-50')
  { value: '1', labelKey: 'onboarding_wizard.player_options.0_50' },
  // t('onboarding_wizard.player_options.51_100', '51-100')
  { value: '2', labelKey: 'onboarding_wizard.player_options.51_100' },
  // t('onboarding_wizard.player_options.101_300', '101-300')
  { value: '3', labelKey: 'onboarding_wizard.player_options.101_300' },
  // t('onboarding_wizard.player_options.301_1000', '301-1000')
  { value: '4', labelKey: 'onboarding_wizard.player_options.301_1000' },
  // t('onboarding_wizard.player_options.1000+', '1000+')
  { value: '5', labelKey: 'onboarding_wizard.player_options.1000+' },
  // t('onboarding_wizard.player_options.unknown', `I don't know yet`)
  { value: '6', labelKey: 'onboarding_wizard.player_options.unknown' },
]

export const OTHER_OPTION_VALUE = 'Other'
