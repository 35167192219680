import React from 'react'

import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { MapType } from '../../../../../api/gameTypes'
import { FormFieldSpy } from '../../../../../common/components/Form/FormFieldSpy/FormFieldSpy'
import { GameForm } from '../../../types'
import styles from './GameSettingsComponents.module.css'
import { GameSettingsToggle } from './GameSettingsToggle'

type AdvancedProps = {
  mapType: MapType | undefined
}

export const Advanced: React.FC<AdvancedProps> = ({ mapType }) => {
  const { values } = useFormState<GameForm>()
  const { t } = useTranslation()

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>{t('game_settings.advanced.mechanics', 'Game Mechanics')}</span>

        <GameSettingsToggle
          name='chatEnabled'
          label={t('game_settings.advanced.in_game_chat', 'Enable in-game chat')}
          description={t('game_settings.advanced.in_game_chat_desc', 'Players can chat during the game.')}
        />

        {/* Hide if 360 map. Disable if exercise order and levels is enabled */}
        {mapType !== 'PANORAMA' && (
          <GameSettingsToggle
            name='allowBranching'
            disabled={values.levelsEnabled}
            label={t('game_settings.advanced.allow_branching', 'Branching game paths')}
            description={t(
              'game_settings.advanced.allow_branching_desc',
              'Connect tasks to paths that the players must follow.',
            )}
          />
        )}

        <GameSettingsToggle
          name='gpsEnabled'
          label={t('game_settings.advanced.enable_gps', 'Enable GPS positioning')}
          description={t(
            'game_settings.advanced.enable_gps_desc',
            'GPS positioning of players will be used in this game.',
          )}
        />

        <GameSettingsToggle
          name='explorationMode'
          disabled={values.allowBranching}
          label={t('game_settings.advanced.exploration_mode', 'Exploration mode')}
          description={t(
            'game_settings.advanced.exploration_mode_desc',
            'Game uses exploration mode for level & map settings.',
          )}
        />

        {/* Enable automatically if game uses exploration mode.
      If this is enabled, then Branching game must be disabled.
      */}
        <GameSettingsToggle
          name='levelsEnabled'
          disabled={values.allowBranching || values.explorationMode}
          label={t('game_settings.advanced.follow_order_and_levels', 'Follow task order and levels')}
          description={t(
            'game_settings.advanced.follow_order_and_levels_desc',
            'This will mandate players to go through the game in the predetermined order.',
          )}
        />

        <GameSettingsToggle
          name='playerContinuesSentAnsEnabled'
          label={t('game_settings.advanced.allow_improving_answers', 'Allow players to improve their answers')}
          description={t(
            'game_settings.advanced.allow_improving_answers_desc',
            'This will allow players to go back and change their answers after sending.',
          )}
        />
      </div>

      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>{t('game_settings.advanced.scoring', 'Scoring')}</span>

        <GameSettingsToggle
          name='noPointsGame'
          label={t('game_settings.advanced.no_points_game', 'Play game without points')}
          description={t(
            'game_settings.advanced.no_points_game_desc',
            'This will remove points from the game entirely.',
          )}
        />

        <GameSettingsToggle
          name='hideScoreboard'
          label={t('game_settings.advanced.hide_scoreboard', 'Hide scoreboard')}
          description={t(
            'game_settings.advanced.hide_scoreboard_desc',
            'This will hide the scoreboard from the players.',
          )}
        />
      </div>

      <div className={styles.sectionContainer}>
        <span className={styles.sectionTitle}>{t('game_settings.advanced.technical', 'Technical')}</span>
        <GameSettingsToggle
          name='happyOrNot'
          label={t('game_settings.advanced.player_feedback', 'Ask players for feedback')}
          description={t(
            'game_settings.advanced.player_feedback_desc',
            'This will ask players three automated questions in the middle of the game.',
          )}
        />
      </div>

      <FormFieldSpy
        spyFieldName='explorationMode'
        dependantFieldName='levelsEnabled'
        getShouldUpdate={(fieldValue) => fieldValue}
        getUpdateToValue={(fieldValue) => fieldValue}
      />

      <FormFieldSpy
        spyFieldName='levelsEnabled'
        dependantFieldName='branchingGamePaths'
        getShouldUpdate={(fieldValue) => {
          return fieldValue
        }}
        getUpdateToValue={(fieldValue) => (fieldValue ? !fieldValue : undefined)}
      />
    </div>
  )
}
