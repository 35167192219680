import classNames from 'classnames'
import React from 'react'
import shared from '../../../common/styles/shared.module.css'
import styles from '../OnboardingWizard.module.css'
import { useTranslation } from 'react-i18next'
import { InputFormField } from '../../../common/components/Form/InputFormField/InputFormField'

export const NameStep: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={classNames(shared.flex, shared.col, shared.sAxisC)}>
        <h2>{t('onboarding_wizard.name_step.title', 'Hello!')}</h2>
        <h3>{t('onboarding_wizard.name_step.subtitle', 'What is your name?')}</h3>
      </div>
      <div className={classNames(shared.flex, shared.mAxisC, shared.gap_075)}>
        <InputFormField
          fieldContainerClassName={styles.inputField}
          name='firstName'
          placeholder={t('onboarding_wizard.name_step.first_name', 'First name')}
          label={t('onboarding_wizard.name_step.first_name', 'First name')}
          srOnlyLabel
          reserveErrorSpace
        />
        <InputFormField
          fieldContainerClassName={styles.inputField}
          name='lastName'
          placeholder={t('onboarding_wizard.name_step.last_name', 'Last name')}
          label={t('onboarding_wizard.name_step.last_name', 'Last name')}
          srOnlyLabel
          reserveErrorSpace
        />
      </div>
    </>
  )
}
