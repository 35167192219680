import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'

import { ThreeDots } from '../../common/components/loaders/ThreeDots/ThreeDots'
import { RadioItem } from '../../common/components/radio/RadioItem'
import { useConfirmation } from '../../contexts/ConfirmationContext'
import { GameDataProviderInterface } from '../../contexts/OwnGamesContextProvider'
import { routes } from '../../routes'
import { GameAction, TGameCard } from '../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../util/string'
import { GamePreviewModal } from '../GamePreviewModal/GamePreviewModal'
import styles from './GamesOverview.module.css'
import { EmptyContent, EmptyContentProps } from './components/EmptyContent'
import { GamesGrid } from './components/GamesGrid'
import { GamesList } from './components/GamesList'
import { LoaderWithOverlay } from './components/LoaderWithOverlay'
import { PaginationControls, PaginationSelectOption } from './components/PaginationControls'
import { getFocusableGameItemId } from './helpers'

type GamesOverviewProps = {
  gameData: GameDataProviderInterface
  title?: string | JSX.Element
  filterSection?: JSX.Element
  libraryMode?: boolean
  showCreateGameCard?: boolean
  onClickCreateGame?: () => void
  emptyContent?: EmptyContentProps
}

enum GameDisplay {
  LIST = 'LIST',
  GRID = 'GRID',
}

export const GamesOverview: React.FC<GamesOverviewProps> = ({
  title,
  filterSection,
  libraryMode,
  showCreateGameCard,
  onClickCreateGame,
  gameData,
  emptyContent,
}) => {
  const navigate = useNavigate()
  const [selectedGameDisplay, setSelectedGameDisplay] = useState<GameDisplay>(GameDisplay.GRID)
  const [gameBeingPreviewed, setGameBeingPreviewed] = useState<TGameCard>()
  const { requestConfirmation } = useConfirmation()
  const { t } = useTranslation()

  const {
    allPageOptions,
    copyGame,
    currentPage,
    deleteGame,
    importGame,
    lastPage,
    loading,
    next,
    pageData,
    pageSize,
    prev,
    selectPageSize,
    setPage,
  } = gameData

  const onRadioSelect = useCallback((e: any) => {
    setSelectedGameDisplay(e.currentTarget.id)
  }, [])

  const onChangePageSize = useCallback(
    (option: SingleValue<PaginationSelectOption>) => {
      selectPageSize(option!.value)
    },
    [selectPageSize],
  )

  const onChangeCurrentPage = useCallback(
    (option: SingleValue<PaginationSelectOption>) => {
      setPage(option!.value)
    },
    [setPage],
  )

  const onEditGame = (gameId: number) => {
    navigate(routes.createGameEditorWithId(gameId.toString()))
  }

  const handleGameAction = (action: GameAction, game: TGameCard) => {
    switch (action) {
      case GameAction.EDIT:
        onEditGame?.(parseInt(game.id))
        break
      case GameAction.VIEW:
        setGameBeingPreviewed(game)
        break
      case GameAction.COPY:
        if (copyGame) copyGame(parseInt(game.id), game.gameName)
        break
      case GameAction.DELETE:
        if (deleteGame) {
          requestConfirmation({
            title: t('games_overview.delete_confirmation.title', 'Confirm action'),
            text: safeIsNullOrEmpty(game.gameName)
              ? t(
                  'games_overview.delete_confirmation.message_game_without_name',
                  'Are you sure you want to delete game?',
                )
              : t('games_overview.delete_confirmation.message_game_with_name', {
                  defaultValue: 'Are you sure you want to delete game %{name}?',
                  name: game.gameName,
                }),
          }).then((response) => {
            if (response) {
              deleteGame(parseInt(game.id), game.gameName)
              if (gameBeingPreviewed != null) {
                setGameBeingPreviewed(undefined)
              }
            }
          })
        }
        break
      case GameAction.IMPORT:
        if (importGame) {
          importGame(parseInt(game.id), game.gameName)
        }
        break
    }
  }

  const handleClosePreviewModal = () => {
    if (gameBeingPreviewed == null) {
      return
    }
    const gameId = gameBeingPreviewed.id
    setGameBeingPreviewed(undefined)
    document.querySelector<any>(`#${getFocusableGameItemId(gameId)}`)?.focus?.()
  }

  return (
    <>
      <div className={styles.headerContainer}>
        {!safeIsNullOrEmpty(title) && <h1>{title}</h1>}
        <div className={styles.headerActionsContainer}>
          {filterSection != null && <div className={styles.filterContainer}>{filterSection}</div>}
          {pageData?.length ? (
            <>
              <div className={styles.displayStyleRadioContainer}>
                <RadioItem
                  id={GameDisplay.GRID}
                  name='gameDisplayStyle'
                  label={t('games_overview.display_style_label.grid', 'GRID')}
                  iconName='grid'
                  isChecked={selectedGameDisplay}
                  onSelect={onRadioSelect}
                />
                <RadioItem
                  id={GameDisplay.LIST}
                  name='gameDisplayStyle'
                  label={t('games_overview.display_style_label.list', 'LIST')}
                  iconName='list'
                  isChecked={selectedGameDisplay}
                  onSelect={onRadioSelect}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.gamesAndPaginationContainer}>
        {pageData != null && pageData?.length > 0 ? (
          <>
            {loading && (
              <LoaderWithOverlay>
                <ThreeDots />
              </LoaderWithOverlay>
            )}
            {selectedGameDisplay === GameDisplay.GRID ? (
              <GamesGrid
                games={pageData}
                libraryMode={libraryMode}
                showCreateGameCard={showCreateGameCard}
                onClickCreateGame={onClickCreateGame}
                onGameAction={handleGameAction}
                loading={loading}
              />
            ) : (
              <GamesList games={pageData} onGameAction={handleGameAction} loading={loading} />
            )}
            <PaginationControls
              currentPage={currentPage}
              loading={loading}
              pageSize={pageSize}
              lastPage={lastPage}
              allPageOptions={allPageOptions}
              onChangePageSize={onChangePageSize}
              onChangeCurrentPage={onChangeCurrentPage}
              onClickNext={next}
              onClickPrev={prev}
            />
            {gameBeingPreviewed != null && (
              <GamePreviewModal
                game={gameBeingPreviewed}
                onClose={handleClosePreviewModal}
                onGameAction={handleGameAction}
              />
            )}
          </>
        ) : emptyContent != null ? (
          <EmptyContent
            title={emptyContent.title}
            subtitle={emptyContent.subtitle}
            browseButton={emptyContent.browseButton}
          />
        ) : null}
      </div>
    </>
  )
}
